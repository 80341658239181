import { CommandFunction, toggleMark } from "@educationperfect/tiptap-commands";
import { Strike } from "@educationperfect/tiptap-extensions";
import { MarkType } from "prosemirror-model";

/**
 * Override keyboard shortcut of tip-tap Strike extension
 */
export class EPStrike extends Strike {
    public keys({ type }: { type: MarkType }): { [keyCombo: string]: CommandFunction } {
        return { "Alt-Shift-5": toggleMark(type) };
    }

    public get schema() {
        return super.schema;
    }
}
