import { AttributesParser, BaseComponent, BaseEmbeddedComponent, IComponentParser, InlineHighlightComponent, InlineTextboxComponent, IRenderingParameters, LinkComponent, SoundComponent, ImageComponent } from "@educationperfect/ep-web-renderer";
import { Dictionary, DictionaryHelper } from "@educationperfect/ep-web-utils";
import { EditorFeatureFlags } from "../../../../../src/models/EditorFeatureFlags";

// import { BaseComponent } from "./BaseComponent";
// import { AnnotatedQuestionComponent } from "./embedded/AnnotatedQuestionComponent";
// import { AnnotatedTextComponent } from "./embedded/AnnotatedTextComponent";
// import { BaseStatefulComponent } from "./embedded/BaseStatefulComponent";
// import { CheckboxComponent } from "./embedded/CheckboxComponent";
// import { DropDownComponent } from "./embedded/DropDownComponent";
// import { FileUploaderComponent } from "./embedded/FileUploaderComponent";
// import { FillInTheGapsComponent } from "./embedded/FillInTheGapsComponent";
// import { InlineDropzoneComponent } from "./embedded/fitg/InlineDropzoneComponent";
// import { InlineTextboxComponent } from "./embedded/fitg/InlineTextboxComponent";
// import { FormulaComponent } from "./embedded/FormulaComponent";
// import { HighlightComponent } from "./embedded/HighlightComponent";
// import { IFrameComponent } from "./embedded/IFrameComponent";
// import { ImageComponent } from "./embedded/ImageComponent";
// import { LinkComponent } from "./embedded/LinkComponent";
// import { LongAnswerComponent } from "./embedded/LongAnswerComponent";
// import { MultiChoiceComponent } from "./embedded/MultiChoiceComponent";
// import { NewTextBoxComponent } from "./embedded/NewTextBoxComponent";
// import { NumberBoxComponent } from "./embedded/NumberBoxComponent";
// import { PDFComponent } from "./embedded/PDFComponent";
// import { ScratchPadComponent } from "./embedded/ScratchPadComponent";
// import { SoundComponent } from "./embedded/SoundComponent";
// import { SoundRecorderComponent } from "./embedded/SoundRecorderComponent";
// import { VideoComponent } from "./embedded/VideoComponent";
// import { WorkingFormulaComponent } from "./embedded/WorkingFormulaComponent";
// import { YoutubeComponent } from "./embedded/YoutubeComponent";

export class ComponentParser implements IComponentParser
{
    // ---------- static -------------

    public static MULTICHOICE_TEMPLATE_CODE: string = "multichoice";
    public static DROPDOWN_TEMPLATE_CODE: string = "dropdown";
    public static FORMULA_TEMPLATE_CODE: string = "formula";
    public static WORKING_FORMULA_TEMPLATE_CODE: string = "working-formula";
    public static FREEFORM_WORKING_TEMPLATE_CODE: string = "freeform-working";
    public static LONG_ANSWER_TEMPLATE_CODE: string = "long-answer";
    public static FILL_IN_GAPS_TEMPLATE_CODE: string = "fill-gaps";
    public static ANNOTATED_QUESTION_TEMPLATE_CODE: string = "annotated-question";
    public static ANNOTATED_TEXT_TEMPLATE_CODE: string = "annotated-text";

    public static componentStates: Dictionary<any>;

    // private static externals: Dictionary<string>;
    private static embeddedTypes: Dictionary<any> = {
        "image": ImageComponent,
        // "iframe": IFrameComponent,
        // "pdf": PDFComponent,
        // "video": VideoComponent,
        "link": LinkComponent,
        // "checkbox": CheckboxComponent,
        "sound": SoundComponent,
        // "youtube": YoutubeComponent,

        // // Used for FITG.
        "gap": InlineTextboxComponent,
        "highlight": InlineHighlightComponent
        // "inline-dropzone": InlineDropzoneComponent,
    };

    public static builderTypes: Dictionary<any> = {
        // "multichoice": MultiChoiceComponent,
        // "dropdown": DropDownComponent,
        // "formula": FormulaComponent,
        // "working-formula": WorkingFormulaComponent,
        // "freeform-working": WorkingFormulaComponent, // FreeformWorkingFormulaComponent,
        // "fill-gaps": FillInTheGapsComponent,
        // "long-answer": LongAnswerComponent,
        // "scratch-pad": ScratchPadComponent,
        // "file-uploader": FileUploaderComponent,
        // "sound-recorder": SoundRecorderComponent,
        // "highlight": HighlightComponent,
        // "text-box": NewTextBoxComponent,
        // "number-box": NumberBoxComponent,
        // "annotated-question": AnnotatedQuestionComponent,
        // "annotated-text": AnnotatedTextComponent
    };

    // ---------- instance -------------

    constructor(private enabledFeatures: EditorFeatureFlags)
    {
    }

    public parse(line: string, renderingParameters: IRenderingParameters, baseAttributes: Dictionary<string>): BaseComponent | null
    {
        if (line == null || line == "")
        {
            return null;
        }

        let type: string;
        let attribs: Dictionary<string> = (baseAttributes) ? DictionaryHelper.clone(baseAttributes) : {};
        const firstSpace: number = line.indexOf(" ");
        if (firstSpace >= 0)
        {
            type = line.substring(0, firstSpace);
            const isFitgOrAnnotation: boolean = this.isFitgOrAnnotation(type);

            if (this.enabledFeatures.ANNOTATION && isFitgOrAnnotation)
            {
                const gapData: RegExpMatchArray | null = line.match(/(\d+): (.*):([0-8])/);
                if (gapData)
                {
                    type = "gap";
                    attribs = { id: gapData[1], text: gapData[2], color: gapData[3] };
                }
            }
            else if (this.enabledFeatures.FITG && isFitgOrAnnotation)
            {
                const gapData: string[] = line.split(":");
                type = "gap";

                attribs = { id: gapData[0], text: gapData[1] };
            }
            else if (this.enabledFeatures.HIGHLIGHT && this.isHighlight(type))
            {
                const gapData: string[] = line.split(":");
                type = "highlight";

                attribs = { id: gapData[0], text: gapData[1], correct: gapData[2] };
            }
            else
            {
                const attributeString: string = line.substring(firstSpace);
                const attributeParser: AttributesParser = new AttributesParser(attributeString, attribs);
                attribs = attributeParser.attributes;
            }
        }
        else
        {
            type = line;
        }

        const component: BaseComponent | null = this.createComponent(type, renderingParameters, attribs);
        return component;
    }

    private isFitgOrAnnotation(typeString: string): boolean
    {
        const match: number = typeString.search(/(\d+):/);
        return match > -1;
    }

    private isHighlight(typeString: string): boolean
    {
        const match: number = typeString.search(/hl/);
        return match > -1;
    }

    private createComponent(componentType: string, renderingParameters: IRenderingParameters, attributes?: Dictionary<string>): BaseComponent | null
    {
        if (!attributes)
        {
            attributes = {};
        }

        if (componentType in ComponentParser.embeddedTypes)
        {
            const componentConstructor: typeof BaseEmbeddedComponent = ComponentParser.embeddedTypes[componentType];
            if (componentConstructor)
            {
                // if (renderingParameters.forPreload && componentConstructor == SoundComponent)
                // {
                //     if (DeviceUtil.safari || DeviceUtil.iOS)
                //     {
                //         return null;
                //     }
                // }

                const baseComponent: BaseComponent = new componentConstructor(componentType, renderingParameters, attributes);
                return baseComponent;
            }
        }
        // else if (componentType in ComponentParser.builderTypes)
        // {
        //     // NB: this breaks the usual camelCase variable rule, because it's a constructor variable
        //     const ComponentStateConstructor: typeof BaseStatefulComponent = ComponentParser.builderTypes[componentType];
        //     if (ComponentStateConstructor)
        //     {
        //         let state: BaseComponentState = null;
        //         if (this.builderFactory && "id" in attributes)
        //         {
        //             const componentID: string = attributes["id"];
        //             state = this.builderFactory.getComponentState(componentID);
        //         }
        //         else
        //         {
        //             return null;
        //         }

        //         const builderComponent: BaseStatefulComponent = new ComponentStateConstructor(componentType, renderingParameters, attributes, state, this);
        //         return builderComponent;
        //     }
        // }
        // else if (componentType in ComponentParser.externals)
        // {
        //    var componentURL: string = ComponentParser.externals[componentType].toString();
        //    var extComponent: ExternalComponent = new ExternalComponent(componentType, componentURL, attributes);
        //    return extComponent;
        // }

        return null;
    }

}
