/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */

const namedColors = {
    AliceBlue: "f0f8ff",
    AntiqueWhite: "faebd7",
    Aqua: "00ffff",
    Aquamarine: "7fffd4",
    Azure: "f0ffff",
    Beige: "f5f5dc",
    Bisque: "ffe4c4",
    Black: "000000",
    BlanchedAlmond: "ffebcd",
    Blue: "0000ff",
    BlueViolet: "8a2be2",
    Brown: "a52a2a",
    BurlyWood: "deb887",
    CadetBlue: "5f9ea0",
    Chartreuse: "7fff00",
    Chocolate: "d2691e",
    Coral: "ff7f50",
    CornflowerBlue: "6495ed",
    Cornsilk: "fff8dc",
    Crimson: "dc143c",
    Cyan: "00ffff",
    DarkBlue: "00008b",
    DarkCyan: "008b8b",
    DarkGoldenRod: "b8860b",
    DarkGray: "a9a9a9",
    DarkGrey: "a9a9a9",
    DarkGreen: "006400",
    DarkKhaki: "bdb76b",
    DarkMagenta: "8b008b",
    DarkOliveGreen: "556b2f",
    DarkOrange: "ff8c00",
    DarkOrchid: "9932cc",
    DarkRed: "8b0000",
    DarkSalmon: "e9967a",
    DarkSeaGreen: "8fbc8f",
    DarkSlateBlue: "483d8b",
    DarkSlateGray: "2f4f4f",
    DarkSlateGrey: "2f4f4f",
    DarkTurquoise: "00ced1",
    DarkViolet: "9400d3",
    DeepPink: "ff1493",
    DeepSkyBlue: "00bfff",
    DimGray: "696969",
    DimGrey: "696969",
    DodgerBlue: "1e90ff",
    FireBrick: "b22222",
    FloralWhite: "fffaf0",
    ForestGreen: "228b22",
    Fuchsia: "ff00ff",
    Gainsboro: "dcdcdc",
    GhostWhite: "f8f8ff",
    Gold: "ffd700",
    GoldenRod: "daa520",
    Gray: "808080",
    Grey: "808080",
    Green: "008000",
    GreenYellow: "adff2f",
    HoneyDew: "f0fff0",
    HotPink: "ff69b4",
    IndianRed: "cd5c5c",
    Indigo: "4b0082",
    Ivory: "fffff0",
    Khaki: "f0e68c",
    Lavender: "e6e6fa",
    LavenderBlush: "fff0f5",
    LawnGreen: "7cfc00",
    LemonChiffon: "fffacd",
    LightBlue: "add8e6",
    LightCoral: "f08080",
    LightCyan: "e0ffff",
    LightGoldenRodYellow: "fafad2",
    LightGray: "d3d3d3",
    LightGrey: "d3d3d3",
    LightGreen: "90ee90",
    LightPink: "ffb6c1",
    LightSalmon: "ffa07a",
    LightSeaGreen: "20b2aa",
    LightSkyBlue: "87cefa",
    LightSlateGray: "778899",
    LightSlateGrey: "778899",
    LightSteelBlue: "b0c4de",
    LightYellow: "ffffe0",
    Lime: "00ff00",
    LimeGreen: "32cd32",
    Linen: "faf0e6",
    Magenta: "ff00ff",
    Maroon: "800000",
    MediumAquaMarine: "66cdaa",
    MediumBlue: "0000cd",
    MediumOrchid: "ba55d3",
    MediumPurple: "9370db",
    MediumSeaGreen: "3cb371",
    MediumSlateBlue: "7b68ee",
    MediumSpringGreen: "00fa9a",
    MediumTurquoise: "48d1cc",
    MediumVioletRed: "c71585",
    MidnightBlue: "191970",
    MintCream: "f5fffa",
    MistyRose: "ffe4e1",
    Moccasin: "ffe4b5",
    NavajoWhite: "ffdead",
    Navy: "000080",
    OldLace: "fdf5e6",
    Olive: "808000",
    OliveDrab: "6b8e23",
    Orange: "ffa500",
    OrangeRed: "ff4500",
    Orchid: "da70d6",
    PaleGoldenRod: "eee8aa",
    PaleGreen: "98fb98",
    PaleTurquoise: "afeeee",
    PaleVioletRed: "db7093",
    PapayaWhip: "ffefd5",
    PeachPuff: "ffdab9",
    Peru: "cd853f",
    Pink: "ffc0cb",
    Plum: "dda0dd",
    PowderBlue: "b0e0e6",
    Purple: "800080",
    RebeccaPurple: "663399",
    Red: "ff0000",
    RosyBrown: "bc8f8f",
    RoyalBlue: "4169e1",
    SaddleBrown: "8b4513",
    Salmon: "fa8072",
    SandyBrown: "f4a460",
    SeaGreen: "2e8b57",
    SeaShell: "fff5ee",
    Sienna: "a0522d",
    Silver: "c0c0c0",
    SkyBlue: "87ceeb",
    SlateBlue: "6a5acd",
    SlateGray: "708090",
    SlateGrey: "708090",
    Snow: "fffafa",
    SpringGreen: "00ff7f",
    SteelBlue: "4682b4",
    Tan: "d2b48c",
    Teal: "008080",
    Thistle: "d8bfd8",
    Tomato: "ff6347",
    Turquoise: "40e0d0",
    Violet: "ee82ee",
    Wheat: "f5deb3",
    White: "ffffff",
    WhiteSmoke: "f5f5f5",
    Yellow: "ffff00",
    YellowGreen: "9acd32",
};

const colorNames = Object.keys(namedColors);
const colorHexes = Object.values(namedColors);

export namespace ColorUtils {
    // Taken from https://awik.io/determine-color-bright-dark-using-javascript/

    /** Regex used to match whether or not a hex is valid */
    export const HEX_REGEX: RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    /** Used to determine whether to color things withe or black */
    export enum ITheme {
        LIGHT = 1,
        DARK = 2,
    }

    /**
     * Detect is color is light or dark. Defaults to light
     */
    export function lightOrDark(color: string): ITheme {
        if (!color || (color && color === "")) {
            return ITheme.LIGHT;
        }

        // Variables for red, green, blue values
        let r: number = 0;
        let b: number = 0;
        let g: number = 0;
        let hsp: number = 0;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
            const rgbColor: RegExpMatchArray | null = color.match(
                /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
            );
            if (!rgbColor) {
                return ITheme.LIGHT;
            }

            // If HEX --> store the red, green, blue values in separate variables
            r = +rgbColor[1];
            g = +rgbColor[2];
            b = +rgbColor[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            const colorNumber: number = +`0x${color
                .slice(1)
                .replace((color.length < 5 && /./g) as string | RegExp, "$&$&")}`;
            /* eslint-disable no-bitwise */
            r = colorNumber >> 16;
            g = (colorNumber >> 8) & 255;
            b = colorNumber & 255;
            /* eslint-enable no-bitwise */
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        // Using the HSP value, determine whether the color is light or dark
        return hsp > 127.5 ? ITheme.LIGHT : ITheme.DARK;
    }

    /**
     * Ensures that the colour is outputted in a format UGLEE understands
     */
    export function ensureColorPartFormat(color: string, isHighlight: boolean = false): string {
        // if RGB, convert then return as HEX without hash
        if (color.indexOf("rgb") !== -1) {
            const rgb: RegExpMatchArray | null = color.match(
                /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
            );
            return rgb && rgb.length === 4
                ? `0${parseInt(rgb[1], 10).toString(16)}`.slice(-2) +
                      `0${parseInt(rgb[2], 10).toString(16)}`.slice(-2) +
                      `0${parseInt(rgb[3], 10).toString(16)}`.slice(-2)
                : "";
        }

        // check for any HTML color names, if there's a match then return as HEX without hash
        const names: string[] = colorNames;
        for (let i = 0; i < names.length; i++) {
            if (color.toLowerCase() === names[i].toLowerCase()) {
                const hexes = colorHexes;
                return hexes[i];
            }
        }

        // check for any CSS styles that we dont support: https://educationperfect.atlassian.net/browse/CA-675
        if (!isHighlight) {
            const otherStyles: string[] = disallowedStyles();
            for (let i = 0; i < otherStyles.length; i++) {
                if (color.toLowerCase() === otherStyles[i].toLowerCase()) {
                    return "000000"; // set to default color
                }
            }
        }

        // fallback to hex, return without hash
        return color.replace("#", "");
    }

    function getColorNames() {
        return colorNames;
    }

    function getColorHexs() {
        return colorHexes;
    }

    function disallowedStyles() {
        return ["transparent", "inherit"];
    }
}
