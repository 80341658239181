import template from "./sound-editor-dialog.html";

import "./sound-editor-dialog.less";

import { MediaLibraryItemDetails } from "@educationperfect/ep-web-services/lib/services/MediaLibrary/BusinessObjects/MediaLibraryItemDetails";
import { MediaTypes } from "@educationperfect/ep-web-utils";
import { Switch } from "@educationperfect/view-design";
import { Component, Prop, Watch } from "vue-property-decorator";

import { MediaBaseController } from "../../../components/mediaBase/MediaBaseController";
import { MediaDialog } from "../../../components/mediaBase/mediaDialog/MediaDialog";
import { Seperator } from "../../../components/seperator/Seperator";
import { ToolbarIcon } from "../../../components/toolbarIcon/ToolbarIcon";
import { TipTapComponents } from "../../../TipTapComponents";
import { ISoundMetaData } from "../interfaces/ISoundMetaData";

/** The name of the event that is called when there is a change in metadata */
const ON_SAVE_EVENT: string = "on-save";

@Component({
    template,
    components: {
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        [TipTapComponents.MediaDialog]: MediaDialog,
        iSwitch: Switch,
        [TipTapComponents.Seperator]: Seperator,
    },
})
export class SoundEditorDialog extends MediaBaseController {
    // Variables
    // ============================================

    // Props

    /** [Prop] Whether or not the dialog is visible */
    @Prop({ required: true, type: Boolean, default: false }) private readonly visible!: boolean;

    /** [Prop] metadata for the component being edited */
    @Prop({ required: false, type: Object }) private readonly componentMetaData?: ISoundMetaData;

    /** [Prop] whether or not auto play and compact mode options are visible */
    @Prop({ required: false, type: Boolean, default: false }) private editingBlock!: boolean;

    /** [Prop, bound] Whether or not the currently logged in user is an admin */
    @Prop({ required: false, type: Boolean, default: false }) private readonly isAdmin!: boolean;

    // Fields

    /** [Bound] the value of the title field */
    private title: string | null = null;

    /** Whether or not the player autoplays */
    private autoPlay: boolean | null = false;

    /** Whether or not the player can be paused */
    private canPause: boolean | null = true;

    /** The limit to how many times the audio can be played */
    private playLimit: number | null = null;

    // Lifecycle Events
    // ============================================

    /**
     * Lifecycle Event:
     * Setup component container
     */
    protected async mounted(): Promise<void> {
        super.mounted();
    }

    // Logic
    // ============================================

    /**
     * [Abstract]
     * Inititalise the component with metadata
     */
    protected initialise(): void {
        if (!this.componentMetaData) {
            return;
        }

        this.mediaUrl = this.componentMetaData.url ?? "";
        this.title = this.componentMetaData.title;
        this.autoPlay = this.componentMetaData.autoPlay ?? false;
        this.canPause = this.componentMetaData.canPause ?? true;
        this.playLimit = this.componentMetaData.playLimit;

        if (this.playLimit === 0) {
            // If the play limit is zero, null it out so that the text field doesn't display a zero.
            this.playLimit = null;
            // Can pause can't be set if there is no play limit, so enable it by default
            this.canPause = true;
        }
    }

    /**
     * [Abstract]
     * Validate the fields and show any errors
     */
    protected validate(): boolean {
        return true;
    }

    /**
     * [Abstract]
     * Reset all fields to their defaults
     */
    protected reset(): void {
        this.mediaUrl = "";
        this.title = "";
        this.autoPlay = false;
        this.canPause = true;
        this.playLimit = null;
    }

    // DOM Events
    // ============================================

    /**
     * DOM Click:
     * Called when the "Done" button is clicked.
     * Validate that the fields are correct and emit new audio metadata
     * to parent.
     */
    protected onDoneClick(): void {
        super.onDoneClick();

        const metadata: Partial<ISoundMetaData> = {
            url: this.mediaUrl,
            title: this.title,
            compact: !this.editingBlock,
            autoPlay: this.editingBlock ? this.autoPlay : null,
            canPause: this.editingBlock ? this.canPause : null,
            playLimit: this.editingBlock ? this.playLimit : null,
        };

        this.$emit(ON_SAVE_EVENT, metadata);
    }

    /**
     * Called when the media gallery changes.
     * Call super and set title to item name.
     *
     * @param item the newly selected media gallery item
     */
    protected onMediaGalleryChange(item: MediaLibraryItemDetails): void {
        super.onMediaGalleryChange(item);
        if (!this.editingBlock) {
            this.title = item.Title;
        }
    }

    // Gets
    // ============================================

    /** [Abstrct] Get the media type for the media gallery */
    protected get mediaType(): MediaTypes {
        return MediaTypes.AUDIO;
    }

    // Get whether or not the can pause checkbox can be enabled
    private get enableCanPauseCheckbox(): boolean {
        return (this.playLimit ?? 0) > 0;
    }

    // Watchers
    // ============================================

    /**
     * Ensure that if the play limit is changed to 0
     * that we enable can pause because the renderer
     * enforces it.
     */
    @Watch("playLimit")
    private onPlayLimitChange(): void {
        if (this.playLimit === 0) {
            this.canPause = true;
        }
    }

    // Pipes
    // ==========================================

    // Vue class component is dumb and incorrectly uses
    // `this` which means that we can't call functions
    // that come from an emit event. Pipe them to super here.
    // Intentionally undocumented

    protected onVisibleChange(visible: boolean): void {
        super.onVisibleChange(visible);
    }

    protected onCancelClick(): void {
        super.onCancelClick();
    }

    protected onReset(): void {
        super.onReset();
    }

    protected onURLFieldChange(url: string): void {
        super.onURLFieldChange(url);
    }
}
