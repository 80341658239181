import { CommandGetter, Extension } from "@educationperfect/tiptap";
import { CommandFunction } from "@educationperfect/tiptap-commands";
import { MarkSpec, NodeSpec, Schema } from "prosemirror-model";

import { ClearFormattingCommands } from "./ClearFormattingCommands";

export class ClearFormatting extends Extension {
    // Variables
    // =======================================

    // Static
    public static readonly NAME: string = "clear_formatting";

    // Overrides
    // =======================================

    /**
     * Override:
     * Return an object with all commands that can be run
     * from the toolbar
     */
    public commands({
        schema,
        attrs,
    }: {
        schema: Schema | NodeSpec | MarkSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        return () => ClearFormattingCommands.clearFormatting();
    }

    /**
     * Override:
     * Return object with keyboard shortcuts and the action they run
     *
     * @param schema the active schema
     */
    public keys({ schema }: { schema: Schema | NodeSpec | MarkSpec }): { [keyCombo: string]: CommandFunction } {
        return {
            "Mod-/": ClearFormattingCommands.clearFormatting(),
        };
    }

    // Gets
    // =======================================

    /** Get the name of the extension */
    public get name(): string {
        return ClearFormatting.NAME;
    }
}
