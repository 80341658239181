import { IRange, Keyboard } from "@educationperfect/ep-web-browser-utils";
import { Extension } from "@educationperfect/tiptap";
import { Fragment, Node, Schema } from "prosemirror-model";
import { EditorState, Plugin, Selection, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { EPEditor } from "../../components/editor/EPEditor";
import { EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";
import { IImeEditorInterface } from "./IImeEditorInterface";
import { ImeCommands } from "./ImeCommands";
import { ITiptapCursorPosition } from "./ITiptapCursorPosition";

export class Ime extends Extension {
    public static readonly NODE_NAME: string = "ime";

    public static getInterface(epEditor: EPEditor): IImeEditorInterface {
        return {
            getSelection: (): IRange | undefined => {
                if (!epEditor.editor) {
                    return;
                }

                const selection = epEditor.editor.state.selection;

                const selectionContent: Fragment = selection.content().content;
                let text: string = "";

                selectionContent.nodesBetween(0, selectionContent.size, (node) => {
                    if (node.isText) {
                        text = text + node.textContent;
                        return false;
                    }
                });

                return {
                    start: selection.anchor,
                    end: selection.head,
                    length: selection.content().size,
                    text,
                };
            },
            getCaretPosition: (): number | undefined => {
                if (!epEditor.editor) {
                    return;
                }

                const selection: Selection = epEditor.editor.state.selection;
                return selection.$head.pos;

                return undefined;
            },
            getCursorPosition: (): ITiptapCursorPosition | undefined => {
                if (!epEditor.editor) {
                    return;
                }

                const view: EditorView = epEditor.editor.view;
                const state: EditorState = view.state;

                // Update ime position
                const cursorPosition: number = state.selection.to;
                const cursorCoord = view.coordsAtPos(cursorPosition);
                const prevPosition = view.coordsAtPos(cursorPosition - 1);

                const result: ITiptapCursorPosition = {
                    top: cursorCoord.top,
                    left: cursorCoord.left,
                    bottom: cursorCoord.bottom,
                    right: cursorCoord.right,
                    prevPosition: cursorPosition === 1 ? prevPosition.left : prevPosition.right,
                };

                return result;
            },
            getPreviousCharacter: (): string | undefined => {
                if (!epEditor.editor) {
                    return;
                }

                const state: EditorState = epEditor.editor.state;
                const tr: Transaction = state.tr;

                const prevCharSelection = Selection.findFrom(state.selection.$head, -1, true);

                if (prevCharSelection) {
                    const node: Node = state.doc.cut(prevCharSelection.from, prevCharSelection.to);

                    if (!node.isText || !node.text) {
                        return;
                    }

                    return node.text;
                }
            },
            moveCursorToEndOfSelection: (deleteLastCharacter: boolean = false) => {
                if (!epEditor.editor) {
                    return;
                }

                const state = epEditor.editor.state;
                const tr = epEditor.editor.state.tr;
                const selection = state.selection;
                let newSelection = new TextSelection(selection.$to, selection.$to);

                if (deleteLastCharacter) {
                    newSelection = new TextSelection(tr.doc.resolve(selection.to - 1), selection.$to);
                }

                tr.setSelection(newSelection);
                tr.setMeta("addToHistory", false);
                epEditor.editor.view.dispatch(tr);
            },
            clearSelection: () => {
                if (!epEditor.editor) {
                    return;
                }

                const editor = epEditor.editor;
                const state = editor.state;
                const tr = editor.state.tr;
                const selection = state.selection;
                const noSelection = new TextSelection(selection.$head, selection.$head);
                tr.setSelection(noSelection);
                editor.view.dispatch(tr);
            },
        };
    }

    public get name(): string {
        return Ime.NODE_NAME;
    }

    public get plugins(): Plugin[] {
        let ignoreNextUpdate: boolean;
        let pendingInputCharacter: string | undefined;

        return [
            new Plugin({
                state: {
                    init: (config, editorState) => {
                        return {};
                    },
                    apply: (transaction: Transaction, value: any, oldState: EditorState, newState: EditorState) => {
                        if (transaction.getMeta("addToHistory") === false) {
                            ignoreNextUpdate = true;
                        }
                    },
                },
                props: {
                    handleTextInput: (view, from, to, text) => {
                        pendingInputCharacter = text;
                        return false;
                    },
                    handleKeyDown: (view, event) => {
                        this.dispatchEditorEvent(view.state, EditorEvents.KeyDown, event);
                        if (!event.defaultPrevented && event.which === Keyboard.BACKSPACE) {
                            pendingInputCharacter = "backspace";
                        }
                        return event.defaultPrevented;
                    },
                    handleKeyPress: (view, event) => {
                        this.dispatchEditorEvent(view.state, EditorEvents.KeyPress, event);
                        return false;
                    },
                    handleClick: (view, event) => {
                        this.dispatchEditorEvent(view.state, EditorEvents.Click, event);
                        return false;
                    },
                    handlePaste: (view, event) => {
                        this.dispatchEditorEvent(view.state, EditorEvents.Paste, event);
                        return false;
                    },
                    handleDOMEvents: {
                        mouseover: (view, event) => {
                            this.dispatchEditorEvent(view.state, EditorEvents.MouseOver);
                            return false;
                        },
                        mouseleave: (view, event) => {
                            this.dispatchEditorEvent(view.state, EditorEvents.MouseOut);
                            return false;
                        },
                        // input: (view, event) =>
                        // {
                        //     const inputEvent: InputEvent = event as InputEvent;

                        //     if (!inputEvent)
                        //     {
                        //         return false;
                        //     }

                        //     this.dispatchEditorEvent(view.state, EditorEvents.InputChanged, inputEvent.data);

                        //     return false;
                        // },
                        compositionstart: (view, event) => {
                            this.dispatchEditorEvent(view.state, EditorEvents.Composition);
                            return false;
                        },
                        compositionupdate: (view, event) => {
                            this.dispatchEditorEvent(view.state, EditorEvents.Composition);
                            return false;
                        },
                        compositionend: (view, event) => {
                            this.dispatchEditorEvent(view.state, EditorEvents.Composition);
                            return false;
                        },
                    },
                },
                view: () => {
                    return {
                        update: (view: EditorView, prevState: EditorState) => {
                            const state: EditorState = view.state;

                            // Update ime position
                            this.dispatchEditorEvent(state, EditorEvents.ImePositionUpdated, null);
                            // if (!state.selection.empty)
                            // {
                            // }

                            if (view.state.doc.textContent !== prevState.doc.textContent && !ignoreNextUpdate) {
                                if (pendingInputCharacter) {
                                    this.dispatchEditorEvent(
                                        view.state,
                                        EditorEvents.InputChanged,
                                        pendingInputCharacter
                                    );
                                    pendingInputCharacter = undefined;
                                }
                            }
                            ignoreNextUpdate = false;
                        },
                    };
                },
            }),
        ];
    }

    public commands({ schema, attrs }: { schema: Schema; attrs: { [key: string]: string } }): ImeCommands.Interface {
        return {
            replaceText: ({
                startPosition,
                endPosition,
                replacementText,
                highlight,
            }: {
                startPosition: number;
                endPosition: number;
                replacementText: string;
                highlight: boolean;
            }) => ImeCommands.replaceText(startPosition, endPosition, replacementText, highlight),
            setSelectionRange: ({ startPosition, endPosition }: { startPosition: number; endPosition: number }) =>
                ImeCommands.setSelectionRange(startPosition, endPosition),
        };
    }

    private dispatchEditorEvent(state: EditorState, event: EditorEvents, data?: any): void {
        const eventsPluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(state);
        eventsPluginState.dispatch(event, data);
    }
}

export interface EditorInterface {
    ime?: IImeEditorInterface;
}
