import { InlineBuildableNode } from "../../InlineBuildableNode";
import { InputBoxView } from "../common/view/InputBoxView";

export class NumberBoxNode extends InlineBuildableNode {
    // Variables
    // =======================================

    /** The name of the node */
    public static readonly NODE_NAME = "number-box";

    // Overrides
    // =======================================

    /** [Override] Get the name of the extension */
    public get name(): string {
        return NumberBoxNode.NODE_NAME;
    }

    /** [Override] Get the view for the number box */
    public get view(): typeof InputBoxView {
        return InputBoxView;
    }
}
