export enum TipTapComponents {
    EPEditor = "EpEditor",
    EPToolbar = "EpToolbar",
    ToolbarIcon = "ToolbarIcon",
    ColorPicker = "ColorPicker",
    ColorPickerDialog = "ColorPickerDialog",
    FloatingHighlightBubbles = "FloatingHighlightBubbles",
    GapFloatingBubble = "GapFloatingBubble",
    FloatingToolbar = "FloatingToolbar",
    EPEditorAngular = "EPEditorAngular",
    TextEmitBubble = "TextEmitBubble",
    EditorMenuBubble = "EditorMenuBubble",
    ImageDialog = "ImageDialog",
    SoundEditorDialog = "SoundEditorDialog",
    MediaDialog = "MediaDialog",
    Seperator = "Seperator",
    FileChoicePicker = "FileChoicePicker",
    MediaPickerButtons = "MediaPickerButtons",
    DropDownEditor = "DropDownEditor",
    InlineSpinner = "InlineSpinner",
    Toast = "Toast",
    NumberBoxEditor = "NumberBoxEditor",
    TextBoxEditor = "TextBoxEditor",
    InlineComponentFloatingToolbar = "InlineComponentFloatingToolbar",
    AiAnnotationUi = "AiAnnotationUi",
}
