import template from "./media-dialog.html";

import "./media-dialog.less";

import { MediaLibraryItemDetails } from "@educationperfect/ep-web-services/lib/services/MediaLibrary/BusinessObjects/MediaLibraryItemDetails";
import { EdsPrimaryButton, EdsSecondaryButton, EdsTertiaryButton } from "@educationperfect/ep-web-ui-components";
import { MediaTypes } from "@educationperfect/ep-web-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../../TipTapComponents";
import { Seperator } from "../../seperator/Seperator";
import { ToolbarIcon } from "../../toolbarIcon/ToolbarIcon";
import { MediaPickerButtons } from "../mediaPickerButtons/MediaPickerButtons";

/** The name of the event that is called to signify dialog close */
const ON_CLOSE_EVENT: string = "on-close";

/** The name of the event that is called when there is a change in metadata */
const ON_SAVE_EVENT: string = "on-save";

/** The name of the event that is called when the dialog changes visiblity */
const ON_VISIBLE_CHANGE_EVENT = "on-visible-change";

/** The name of the event that is called when the "Change" button is clicked. */
const ON_RESET_EVENT = "on-reset";

/** The name of the event that is called when the media gallery changes content */
const ON_MEDIA_CHANGE = "on-change";

/** The name of the event that is called when the url field changes */
const ON_URL_FIELD_CHANGE: string = "on-url-field-change";

@Component({
    template,
    components: {
        [TipTapComponents.Seperator]: Seperator,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        [TipTapComponents.MediaPickerButtons]: MediaPickerButtons,
        EdsPrimaryButton,
        EdsSecondaryButton,
        EdsTertiaryButton
    }
})
export class MediaDialog extends Vue {
    // Varaibles
    // =========================================

    // Props

    /** [Prop] If specified, whether or not the editor is open */
    @Prop({ required: true, type: Boolean, default: false }) public readonly visible!: boolean;

    /** [Prop] The context of the media gallery */
    @Prop({ required: true, type: Number }) private readonly mediaType!: MediaTypes;

    /** [Prop] The current file url */
    @Prop({ required: false, type: String, default: "" }) private readonly mediaUrl!: string;

    /** [Prop, bound] The width of the dialog */
    @Prop({ required: false, type: Number, default: 600 }) private readonly width!: number;

    /** [Prop, bound] Whether or not the currently logged in user is an admin */
    @Prop({ required: false, type: Boolean, default: false }) private readonly isAdmin!: boolean;

    // DOM Events
    // =========================================

    /** DOM Click: Called when the "Done" button is clicked. Pipe save event to parent. */
    private onDoneClick(): void {
        this.$emit(ON_SAVE_EVENT);
    }

    /** DOM Click: Called when the "Cancel" button is clicked. pipe close event to parent. */
    private onCancelClick(): void {
        this.$emit(ON_CLOSE_EVENT);
    }

    /**
     * DOM Click:
     * Called when the "Change" button is clicked.
     * Emit reset event to reset the dialog to defaults
     */
    private onChangeClick(): void {
        this.$emit(ON_RESET_EVENT);
    }

    /**
     * DOM Emit:
     * Called when the dialog changes visibility.
     * Pipe on visible change event to parent.
     *
     * @param visible whether or not the dialog is visible
     */
    private onVisibleChange(visible: boolean) {
        this.$emit(ON_VISIBLE_CHANGE_EVENT, visible);
    }

    private onMediaChange(item: MediaLibraryItemDetails): void {
        this.$emit(ON_MEDIA_CHANGE, item);
    }

    private onUrlFieldChange(inputValue: string): void {
        this.$emit(ON_URL_FIELD_CHANGE, inputValue);
    }

    private get title(): string {
        switch (this.mediaType) {
            case MediaTypes.IMAGE:
                return "image";
            case MediaTypes.AUDIO:
                return "audio";
            case MediaTypes.VIDEO:
                return "video";
            case MediaTypes.PDF:
                return "PDF";
            default:
                return "";
        }
    }
}
