import { FuzyyTextMatchParams } from "./FuzzyTextMatcher.worker";

/**
 * The result object returned from a text search.
 */
export type TextMatch = {
    /**
     * The start index of the matched text (inclusive).
     */
    startIndex: number;
    /**
     * The end index of the matched text (inclusive).
     */
    endIndex: number;
    /**
     * The matched text.
     */
    matchedText: string;
    /**
     * The original search text.
     * This will be the same as `matchedText` in exact matches.
     */
    originalSearchText: string;
    /**
     * The score of the match.
     */
    score: number;
};

/**
 * LLM's don't quote text word for word, so we need to do a fuzzy match
 * sometimes to match up their outputs to the original text.
 *
 * @param sourceText - The text to search for.
 * @param mininumScore - The minimum score required for a match. Defaults to 0.8.
 * @param allowedWordCountDeviation - The allowed deviation in word count for a
 * match. Defaults to 2. For example, if the annotated text is 5 words long, a
 * match can be between 3 and 7 words long by default.
 */
export class FuzzyTextMatcher {
    private worker: Worker;

    private pendingCallbacks: Map<string, (result: TextMatch) => void> = new Map();

    constructor(private sourceText: string, private mininumScore = 0.8, private allowedWordCountDeviation = 2) {
        // eslint-disable-next-line dot-notation
        this.worker = new Worker(new URL("./FuzzyTextMatcher.worker.ts", import.meta["url"]));

        this.worker.onmessage = (event: MessageEvent) => {
            const data: TextMatch = event.data;

            const callback = this.pendingCallbacks.get(data.originalSearchText);
            if (!callback) {
                throw new Error(`No callback found for search text: ${data.originalSearchText}`);
            }

            callback(data);
        };
    }

    /**
     * Finds a match for the given search text. This will
     * @param searchText - The text to search for.
     * @param mininumScore - The minimum score required for a match. Defaults to 0.8.
     * @param allowedWordCountDeviance - The allowed deviation in word count for a match. Defaults to 2.
     * @returns The exact match if found, otherwise the best similar match.
     */
    public findMatch(searchText: string) {
        return new Promise<TextMatch>((resolve) => {
            this.worker.postMessage({
                sourceText: this.sourceText,
                searchText,
                mininumScore: this.mininumScore,
                allowedWordCountDeviation: this.allowedWordCountDeviation,
            } as FuzyyTextMatchParams);

            this.pendingCallbacks.set(searchText, resolve);
        });
    }

    public destroy() {
        this.worker?.terminate();
    }
}
