export const BlackAndWhite: string[] = [
    "#000000",
    "#444444",
    "#666666",
    "#999999",
    "#B7B7B7",
    "#CCCCCC",
    "#D9D9D9",
    "#EFEFEF",
    "#F3F3F3",
    "#FFFFFF",
];

export const ColorDefinitions: string[][] = [
    ["#ce4676", "#7b2b47", "#a5395e", "#d86b91", "#e290ad", "#ebb5c8", "#f5dae4"],
    ["#b81313", "#6d0f0f", "#931a1d", "#c64242", "#d47171", "#e3a1a1", "#f1d0d0"],
    ["#df6d20", "#85421d", "#b25927", "#e58a4d", "#eca779", "#f2c5a6", "#f9e2d2"],
    ["#c48a1b", "#755321", "#9b6f2a", "#d0a149", "#dcb976", "#e7d0a4", "#f3e8d1"],
    ["#76a23a", "#47622d", "#5e8139", "#91b561", "#adc789", "#c8dab0", "#e4ecd8"],
    ["#017951", "#014830", "#006040", "#349474", "#67af97", "#99c9b9", "#cce4dc"],
    ["#006994", "#003f58", "#015476", "#3387a9", "#66a5bf", "#99c3d4", "#cce1ea"],
    ["#598bda", "#355483", "#476faf", "#7aa2e1", "#9bb9e9", "#bdd1f0", "#dee8f8"],
    ["#9876bb", "#5b4770", "#795f95", "#ad91c9", "#c1add4", "#d6c8e4", "#eae4f1"],
    ["#89327a", "#521e49", "#6e2861", "#a15b95", "#b884af", "#d0adca", "#e7d6e4"],
    ["#1E2E67", "#101a3c", "#182552", "#4a5886", "#7881a3", "#a5abc2", "#d2d6e1"],
    ["#198381", "#0f4e4d", "#156867", "#469b99", "#75b4b3", "#a3cccc", "#d2e6e5"],
];
