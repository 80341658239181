import _Vue, { PluginObject } from "vue";

import AiAnnotationUi from "./components/aiAnnotationUi/AiAnnotationUi.vue";
import { EPEditor } from "./components/editor/EPEditor";
import { EPEditorAngular } from "./components/epEditorAngular/EPEditorAngular";
import { EPToolbar } from "./components/toolbar/EPToolbar";
import { TipTapComponents } from "./TipTapComponents";

export * from "./components/editor/EditorMediaBus";
export * from "./components/mediaBase/MediaBaseController";
export * from "./components/mediaBase/mediaPickerButtons/MediaPickerButtons";
export * from "./components/mediaBase/mediaDialog/MediaDialog";
export * from "./components/mediaBase/mediaDialog/interfaces/IMediaButtonsContextInfo";
export * from "./components/editor/interfaces/IEditorMediaBus";
export * from "./extensions/script/Subscript";
export * from "./extensions/gapNode/GapNode";
export * from "./components/editor/EPEditor";
export * from "./components/toolbar/EPToolbar";
export * from "./components/epEditorAngular/EPEditorAngular";
export * from "./parsing/TextTemplateParser";
export * from "./parsing/TemplateSerializer";
export * from "./models/EditorFeatureFlags";
export * from "./models/GapData";
export * from "./extensions/highlight/HighlightData";
export * from "./extensions/annotations/AnnotationData";
export * from "./extensions/editorEvents/EditorEvents";
export * from "./extensions/ime/ITiptapCursorPosition";
export * from "./extensions/ime/IImeEditorInterface";
export * from "./components/editor/interfaces/EPEditorProps";
export * from "./components/colorPicker/ColorPicker";
export * from "./models/EditorContext";
export * from "./models/EditorUserFactKeys";
export * from "./extensions/image/imageDialog/ImageDialog";
export * from "./extensions/image/imageDialog/interfaces/IImageMetadata";
export * from "./extensions/sound/dialog/SoundEditorDialog";
export * from "./extensions/sound/interfaces/ISoundMetaData";
export * from "./extensions/inlineBuildable/dropDown/editor/DropDownEditor";
export * from "./components/toolbarIcon/ToolbarIcon";
export * from "./directives/dragging/Sortable";
export * from "./components/aiAnnotationUi/AiAnnotationUi.vue";

export { TipTapEvents, IInlineComponentSelectedState } from "./TipTapEvents";
export { GapNodeCommands } from "./extensions/gapNode/GapNodeCommands";
export { HighlightCommands } from "./extensions/highlight/HighlightCommands";
export { AnnotationNodeCommands } from "./extensions/annotations/AnnotationNodeCommands";
export { ImeCommands } from "./extensions/ime/ImeCommands";

const components = {
    [TipTapComponents.EPEditor]: EPEditor,
    [TipTapComponents.EPToolbar]: EPToolbar,
    [TipTapComponents.EPEditorAngular]: EPEditorAngular,
    [TipTapComponents.AiAnnotationUi]: AiAnnotationUi,
};

declare global {
    interface Window {
        EPTipTapPlugin: PluginObject<any>;
    }
}

export const epTipTapPlugin: PluginObject<any> = {
    install: (vue, opts) => {
        Object.keys(components).forEach((name) => {
            vue.component(name, components[name]);
        });
    },
};

window.EPTipTapPlugin = epTipTapPlugin;

export * from "./TipTapComponents";
