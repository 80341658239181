import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { RenderingMode } from "../../render/RenderingMode";
import { BaseComponent } from "../BaseComponent";

export class BaseEmbeddedComponent extends BaseComponent {
    public renderingParameters: IRenderingParameters;

    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super();
        this.renderingParameters = renderingParameters;
        this.loadParameters(parameters);

        this.visualElement = this.createVisualElement(parameters);
    }

    protected loadParameters(parameters: Dictionary<string>): void {}

    public createVisualElement(parameters: Dictionary<string>): HTMLElement | null {
        // to be overriden by sub-classes
        return null;
    }

    public get isPreviewOrQuestionMode(): boolean {
        return (
            this.renderingParameters.mode === RenderingMode.MODE_PREVIEW ||
            this.renderingParameters.mode === RenderingMode.MODE_QUESTION
        );
    }

    public get isPreviewMode(): boolean {
        return this.renderingParameters.mode === RenderingMode.MODE_PREVIEW;
    }
}
