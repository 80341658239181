import { CommandGetter, Node as TiptapNode } from "@educationperfect/tiptap";
// @ts-ignore
import { nodeInputRule } from "@educationperfect/tiptap-commands";
import { NodeSpec, NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, Plugin, PluginKey, Selection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { InlineEditablePluginState, ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { FormulaCommands } from "./FormulaCommands";
import FormulaView from "./FormulaView";

export class Formula extends TiptapNode<FormulaView> {
    public static readonly NODE_NAME = "formula";
    public static readonly FORMULA_PLUGIN_KEY: PluginKey = new PluginKey("formula");

    public get name(): string {
        return Formula.NODE_NAME;
    }

    // ===========================================================================
    //  Node Setup
    // ===========================================================================

    public get schema(): NodeSpec {
        return {
            attrs: {
                // id: {},
                latex: {
                    default: "",
                },
            },
            marks: "",
            inline: true,
            selectable: true,
            group: "inline",
            atom: false,
            draggable: false,
            parseDOM: [
                {
                    tag: Formula.NODE_NAME,
                    getAttrs: (dom) => {
                        if (dom instanceof Element) {
                            return {
                                latex: dom.getAttribute("latex"),
                            };
                        }
                    },
                },
            ],
            toDOM: (node) => [
                Formula.NODE_NAME,
                {
                    latex: node.attrs.latex,
                },
            ],
        };
    }

    // Create formula plugin
    public get plugins(): Plugin[] {
        return [
            new Plugin({
                props: {
                    handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
                        const deleteTransaction: Transaction | null = ProsemirrorUtils.handleDeleteIntoInlineNodeContent(
                            view.state,
                            event,
                            Formula.NODE_NAME,
                            Formula.FORMULA_PLUGIN_KEY
                        );

                        if (deleteTransaction) {
                            view.dispatch(deleteTransaction);

                            // This will indicate that we have handled the event and the view will call preventDefault.
                            return true;
                        }

                        return false;
                    },
                    handleDOMEvents: {
                        cut: (view: EditorView, event: Event) => {
                            const formulaNode: NodeType = view.state.schema.nodes[Formula.NODE_NAME];
                            const selection: NodeSelection = view.state.selection as NodeSelection;
                            if (selection?.node?.type === formulaNode) {
                                return true;
                            }
                            return false;
                        },
                    },
                },
                filterTransaction: (tran, editorState) => {
                    // This is a workaround to fix mouse selection when a formula node is the last node in the document or
                    // when it is the only node in a table cell.
                    const { selectionSet, selection, docChanged } = tran;
                    if (selectionSet && !docChanged) {
                        if (selection instanceof NodeSelection) {
                            const isFormulaNode = selection.node.type.name === Formula.NODE_NAME;
                            if (isFormulaNode) {
                                if (!selection.$to.nodeAfter && tran.getMeta("pointer")) {
                                    return false;
                                }
                            }
                        }
                    }

                    return true;
                },
            }),
            /**
             * This plugin handles moving in and out of formulas (in either mode) using the arrow keys
             */
            new Plugin<FormulaPluginState>({
                key: Formula.FORMULA_PLUGIN_KEY,
                state: {
                    init: (config, instance: EditorState) => {
                        return { latexSymbol: null, symbolType: null, previousSelection: undefined };
                    },
                    apply: (tr, pluginState, oldState, newState) => {
                        if (tr.selectionSet) {
                            pluginState.previousSelection = undefined;

                            // check we are entering a formula node. This condition is met every time the formula node is updated, so also check we aren't editing the latex.
                            if (
                                newState.selection instanceof NodeSelection &&
                                newState.selection.node.type.name === "formula" &&
                                pluginState.latexSymbol == undefined
                            ) {
                                if (!(oldState.selection instanceof NodeSelection)) {
                                    // We are entering a formula node from something that isn't a node (ie text).
                                    pluginState.previousSelection = oldState.selection;
                                }
                                // We are entering a formula node from another node.
                                else if (
                                    oldState.selection instanceof NodeSelection &&
                                    newState.selection.node !== oldState.selection.node
                                ) {
                                    // pluginState.previousSelection = oldState.selection; // WIP: this is doing some weird stuff. This check may not be needed though
                                }
                            }
                        }
                        return pluginState;
                    },
                },
            }),
        ];
    }

    public get view(): typeof FormulaView {
        return FormulaView;
    }

    public commands({ type, schema }: { type: NodeType; schema: NodeSpec }): CommandGetter {
        return {
            insertFormula: () => FormulaCommands.insertFormula(type),
            insertSymbol: ({ latex, type }: { latex: string; type: string }) =>
                FormulaCommands.insertSymbol(latex, type),
        };
    }

    public keys({ type }): any {
        return {
            "Mod-`": FormulaCommands.insertFormula(type),
        };
    }

    /**
     * Automatically create a formula node when text is wrapped in triple back ticks. (e.g. ```1+1=2```)
     */
    public inputRules({ type }): any {
        return [nodeInputRule(/(?:```)([^`]+)(?:```)$/, type, (text: string[]) => ({ latex: text[1] }))];
    }
}

export interface FormulaPluginState extends InlineEditablePluginState {
    latexSymbol: string | null;
    symbolType: string | null;
    previousSelection?: Selection;
}
