import { ElementUtil } from "@educationperfect/ep-web-browser-utils";
import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class SoundComponent extends BaseEmbeddedComponent {
    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        const soundContainer: HTMLElement = ElementUtil.create("sound");

        if ("url" in parameters) {
            soundContainer.setAttribute("url", parameters["url"]);
        }

        if ("title" in parameters) {
            soundContainer.setAttribute("title", parameters["title"]);
        }

        if ("auto-play" in parameters) {
            soundContainer.setAttribute("auto-play", parameters["auto-play"]);
        }

        if ("compact" in parameters) {
            soundContainer.setAttribute("compact", parameters["compact"]);
        }

        return soundContainer;
    }
}
