











import { Editor } from "@educationperfect/tiptap";
import { createPopper, Instance as PopperInstance, VirtualElement } from "@popperjs/core";
import { EditorState, Transaction } from "prosemirror-state";
import snarkdown from "snarkdown";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { AiAnnotation as AiAnnotationData, AiAnnotationType } from "../../../typings/mfe-ai-assistant/annotations";
import { AiAnnotationPluginKey } from "../../extensions/aiAnnotation/AiAnnotationPlugin";
import { AiAnnotationState } from "../../extensions/aiAnnotation/AiAnnotationState";
import { TipTapComponents } from "../../TipTapComponents";

type AnnotationPopperData = {
    id: string;
    title: string;
    comment: string;
    type: AiAnnotationType;
    targetElement: VirtualElement | HTMLElement;
    annotationElement: HTMLElement;
};

@Component({
    name: TipTapComponents.AiAnnotationUi,
    components: {},
})
export default class AiAnnotationUi extends Vue {
    @Prop({ type: Editor }) private readonly editor?: Editor;

    /* ---------------------------- Bound Properties ---------------------------- */

    public comment: string | null = null;

    public title: string | null = null;

    public type: AiAnnotationType | null = null;

    public isShown: boolean = false;

    /* -------------------------------------------------------------------------- */

    private annotationElements: HTMLElement[] = [];

    private currentPopper: PopperInstance | undefined;

    private showAnnotationEventCallback: (e: MouseEvent | FocusEvent) => void = this.onShowAnnotationEvent.bind(this);

    private hideAnnotationEventCallback: (e: MouseEvent | FocusEvent) => void = this.onHideAnnotationEvent.bind(this);

    private keyDownEventCallback: (e: KeyboardEvent) => void = this.onKeydown.bind(this);

    private annotations: AiAnnotationData[] | undefined;

    private isTabPressed: boolean = false;

    @Watch("editor", { immediate: true })
    onEditorChange(editor?: Editor): void {
        editor?.on("transaction", ({ state }: { state: EditorState }) => {
            this.onEditorUpdate(state);
        });
    }

    private onEditorUpdate(state: EditorState): void {
        this.cleanUpAnnotations();
        this.setupAnnotations(state);
    }

    private setupAnnotations(state: EditorState): void {
        const annotationState = AiAnnotationPluginKey.getState(state) as AiAnnotationState;
        this.annotations = annotationState.annotations;

        if (!this.annotations || this.annotations.length === 0) {
            return;
        }

        this.attachAnnotationEventListeners();
    }

    private markTabFocusEvent(e: KeyboardEvent): void {
        if (e.key === "Tab") {
            this.isTabPressed = true;
        }
    }

    private onKeydown(e: KeyboardEvent): void {
        switch (e.key) {
            case "Escape":
                this.removePopper();
                break;
            case "Tab":
                this.markTabFocusEvent(e);
                break;
        }
    }

    private onHideAnnotationEvent(): void {
        this.removePopper();
    }

    private onShowAnnotationEvent(e: MouseEvent | FocusEvent): void {
        if (!this.annotations) {
            throw new Error("Annotations not set");
        }

        const trigger = e instanceof FocusEvent ? "focus" : "hover";

        if (trigger === "focus" && !this.isTabPressed) {
            return;
        }

        this.isTabPressed = false;

        const annotationElement = e.currentTarget as HTMLElement;
        let targetElement: HTMLElement | VirtualElement = annotationElement;
        const annotationId = annotationElement.getAttribute("data-ai-annotation-id") as string;
        const annotationData = this.annotations.find((annotation) => annotation.id === annotationId);

        if (!annotationData) {
            throw new Error("Annotation not found");
        }

        if (trigger === "hover" && e instanceof MouseEvent) {
            // const caretBoundingRect = getCaretBoundingRectFromPoint(e.clientX, e.clientY);

            const boundingRect = annotationElement.getBoundingClientRect();

            targetElement = {
                getBoundingClientRect: () => {
                    return {
                        top: boundingRect.top,
                        left: e.clientX,
                        right: e.clientX,
                        bottom: boundingRect.bottom,
                        width: 0,
                        height: boundingRect.height,
                    } as DOMRect;
                },
            };
        }

        this.showPopper({
            id: annotationId,
            title: annotationData.type,
            comment: snarkdown(annotationData.comment),
            type: annotationData.type,
            targetElement,
            annotationElement,
        });

        if (trigger === "hover") {
            this.blurFocusedAnnotation();
        }
    }

    private showPopper({ comment, title, type, targetElement, annotationElement }: AnnotationPopperData): void {
        if (this.currentPopper) {
            this.currentPopper.destroy();
        }

        this.comment = comment;
        this.title = title;
        this.type = type;

        this.$nextTick(() => {
            // Ensure the root element is rendered before creating the popper to
            // make sure it is positioned correctly.
            this.currentPopper = createPopper(targetElement, this.rootElement, {
                placement: "top",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    },
                    // {
                    //     name: "arrow",
                    // },
                    {
                        name: "preventOverflow",
                        options: {
                            padding: 8,
                        },
                    },
                    {
                        name: "cleanUp",
                        enabled: true,
                        phase: "main",
                        fn: () => {
                            // no-op
                        },
                        effect: () => {
                            annotationElement.classList.add("popper-active");
                            this.editorElement.classList.add("ai-annotation-active");
                            return () => {
                                annotationElement.classList.remove("popper-active");
                                this.editorElement.classList.remove("ai-annotation-active");
                            };
                        },
                    },
                ],
            });
        });

        this.isShown = true;
    }

    private blurFocusedAnnotation(): void {
        if (document.activeElement?.classList.contains("ai-annotation")) {
            (document.activeElement as HTMLElement).blur();
        }
    }

    private removePopper(): void {
        this.isShown = false;

        setTimeout(() => {
            if (!this.isShown) {
                this.currentPopper?.destroy();
            }
        });
    }

    private attachAnnotationEventListeners(): void {
        if (!this.editor) {
            throw new Error("Editor not set");
        }

        this.annotationElements = Array.from(this.editor.view.dom.querySelectorAll(".ai-annotation-highlight"));

        this.annotationElements.forEach((annotationElement: HTMLElement) => {
            annotationElement.addEventListener("mouseenter", this.showAnnotationEventCallback);
            annotationElement.addEventListener("mouseleave", this.hideAnnotationEventCallback);
            annotationElement.addEventListener("focus", this.showAnnotationEventCallback);
            annotationElement.addEventListener("blur", this.hideAnnotationEventCallback);
        });
    }

    private cleanUpAnnotations(): void {
        this.annotationElements.forEach((annotationElement: HTMLElement) => {
            annotationElement.removeEventListener("mouseleave", this.hideAnnotationEventCallback);
            annotationElement.removeEventListener("mouseenter", this.showAnnotationEventCallback);
            annotationElement.removeEventListener("focus", this.showAnnotationEventCallback);
            annotationElement.removeEventListener("blur", this.hideAnnotationEventCallback);
        });
    }

    private get editorElement(): HTMLElement {
        if (!this.editor) {
            throw new Error("Editor not set");
        }

        return this.editor?.view.dom as HTMLElement;
    }

    private mounted(): void {
        document.addEventListener("keydown", this.keyDownEventCallback);
    }

    private destroyed(): void {
        document.removeEventListener("keydown", this.keyDownEventCallback);
        this.cleanUpAnnotations();
    }

    get rootElement() {
        return this.$refs.rootElement as HTMLElement;
    }
}
