import { EditorState, Plugin, PluginKey } from "prosemirror-state";

import { EditorPluginState } from "../interfaces/EditorPluginState";

export class EditorPlugin extends Plugin<EditorPluginState> {
    /** The EP Editor plugin instance */
    public static readonly EditorPluginKey: PluginKey = new PluginKey("epEditor");

    constructor() {
        super({
            key: EditorPlugin.EditorPluginKey,
            state: {
                init: (config, instance: EditorState) => {
                    return {
                        isAdmin: () => false,
                    };
                },
                apply: (tr, pluginState, oldState, newState) => {
                    return pluginState;
                },
            },
        });
    }
}
