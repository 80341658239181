import { TableCell } from "@educationperfect/tiptap-extensions";
import { Node, NodeSpec, NodeType, Schema, Slice } from "prosemirror-model";
import { EditorState, Plugin, Selection, Transaction } from "prosemirror-state";
import { CellSelection, mergeCells, splitCell } from "prosemirror-tables";
import {
    ContentNodeWithPos,
    findChildren,
    findParentNode,
    findParentNodeOfType,
    findTable,
    isTableSelected,
} from "prosemirror-utils";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { EPTableNodes } from "./EPTableNodes";
import { VerticalAlignments } from "./VerticalAlignments";

export class EPTableCell extends TableCell {
    public static DEFAULT_CELL_BACKGROUND_COLOR: string = "#fff";

    public get schema(): NodeSpec {
        const cellSchema: NodeSpec = EPTableNodes().table_cell;
        return cellSchema;
    }

    public get plugins(): Plugin[] {
        return [
            new Plugin({
                appendTransaction: (transactions, oldState, newState) => {
                    if (!transactions.some((tr) => tr.docChanged)) {
                        return;
                    }

                    const parentCell: ContentNodeWithPos | undefined = findParentNodeOfType(
                        newState.schema.nodes[ExtensionNames.tableCell]
                    )(newState.selection);

                    if (!parentCell || isTableSelected(newState.selection)) {
                        return;
                    }

                    const cellAlignment: TextAlignments = parentCell.node.attrs.align;
                    const alignableTypes: NodeType[] | undefined = ProsemirrorUtils.getNodeTypes(newState, [
                        ExtensionNames.paragraph,
                        ExtensionNames.heading,
                    ]);

                    if (!alignableTypes) {
                        return;
                    }

                    const alignTransaction: Transaction = newState.tr;

                    const alignableNodes = findChildren(parentCell.node, (node) => {
                        return alignableTypes.includes(node.type);
                    });

                    for (const alignable of alignableNodes) {
                        if (alignable.node.attrs.align !== cellAlignment) {
                            const attr = { ...alignable.node.attrs, align: cellAlignment };
                            alignTransaction.setNodeMarkup(
                                parentCell.start + alignable.pos,
                                alignable.node.type,
                                attr,
                                alignable.node.marks
                            );
                        }
                    }

                    if (alignTransaction.docChanged) {
                        return alignTransaction;
                    }
                },
                props: {
                    transformPasted: (slice: Slice) => {
                        slice.content.descendants((node) => {
                            // if there are any table cells, apply the table cell's alignment to all it's child nodes
                            if (node.type.name == ExtensionNames.tableCell) {
                                const alignment: any | null = node.attrs.align || null;
                                node.content.descendants((child) => {
                                    const attrs: object = {
                                        ...child.attrs,
                                        align: alignment,
                                    };
                                    child.attrs = attrs;
                                });
                            }
                        });
                        return slice;
                    },
                },
            }),
        ];
    }

    public static getCellVerticalAlignment(schema: Schema, selection: Selection): VerticalAlignments {
        const parentCell: Node | null = this.findCell(schema, selection);

        if (parentCell) {
            return parentCell.attrs.verticalAlign as VerticalAlignments;
        }

        return VerticalAlignments.MIDDLE;
    }

    public static enableCellBorderOverrideOption(selection: Selection): boolean {
        const table: ContentNodeWithPos | undefined = findTable(selection);

        if (table) {
            const showTableBorders: boolean = table.node.attrs.showBorders;
            return !showTableBorders;
        } else {
            return false;
        }
    }

    public static getCellBorderOverride(schema: Schema, selection: Selection): boolean {
        const parentCell: Node | null = this.findCell(schema, selection);

        if (parentCell) {
            if (parentCell.attrs.showBorderOverride) {
                return true;
            }
        }

        return false;
    }

    /**
     * Get the cell padding attribute.
     */
    public static getCellPadding(schema: Schema, selection: Selection): number {
        const parentCell: Node | null = this.findCell(schema, selection);

        if (parentCell) {
            const padding: number | undefined = parentCell.attrs.padding;
            return padding != undefined ? padding : 6;
        } else {
            return 6;
        }
    }

    /**
     * Get the cell background color attribute.
     */
    public static getCellBackgroundColor(schema: Schema, selection: Selection): string {
        const parentCell: Node | null = this.findCell(schema, selection);

        if (parentCell) {
            const color: string | undefined = parentCell.attrs.background;
            return color ? color : EPTableCell.DEFAULT_CELL_BACKGROUND_COLOR;
        } else {
            return EPTableCell.DEFAULT_CELL_BACKGROUND_COLOR;
        }
    }

    /** Returns true if the current selection allows cell merge toggle action. */
    public static canToggleMergeCell(state: EditorState): boolean {
        const { selection } = state;
        if (selection instanceof CellSelection) {
            return mergeCells(state) || splitCell(state);
        }

        return false;
    }

    /** Returns true if the cell is merged. */
    public static isCellMerged(schema: Schema, selection: Selection): boolean {
        const parentCell: Node | null = this.findCell(schema, selection);
        if (parentCell) {
            return parentCell.attrs.colspan > 1 || parentCell.attrs.rowspan > 1;
        }

        return false;
    }

    public static findCell(schema: Schema, selection: Selection): Node | null {
        const cellTypes: NodeType[] = [
            schema.nodes[ExtensionNames.tableCell],
            schema.nodes[ExtensionNames.tableHeader],
        ];

        const parentCell: ContentNodeWithPos | undefined = findParentNode((node) => cellTypes.includes(node.type))(
            selection
        );

        if (parentCell) {
            return parentCell.node;
        } else {
            return null;
        }
    }
}
