import "./paragraph.less";

import { Node as ProsemirroNode } from "@educationperfect/tiptap";
import { CommandFunction, setBlockType } from "@educationperfect/tiptap-commands";
import { chainCommands, deleteSelection, selectNodeBackward } from "prosemirror-commands";
import { NodeSpec, NodeType } from "prosemirror-model";
import { Plugin } from "prosemirror-state";
import { ContentNodeWithPos, findParentNodeOfType } from "prosemirror-utils";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { ParagraphCommands } from "./ParagraphCommands";
import { RTLCommands } from "./RTLCommands";
import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { TipTapEvents } from "../../TipTapEvents";

export class Paragraph extends ProsemirroNode {
    // Variables
    // ===============================================

    // Static
    public static readonly NODE_NAME = "paragraph";
    public static readonly ALIGN_PATTERN: RegExp = /(left|right|center|justify)/;

    // Node Setup
    // ===============================================

    get name(): string {
        return Paragraph.NODE_NAME;
    }

    private checkAlignment(dom: HTMLElement): string | undefined {
        const textAlign: string | undefined = dom.style.textAlign;
        let align: string | undefined = dom.getAttribute("align") || textAlign || TextAlignments.LEFT;
        align = Paragraph.ALIGN_PATTERN.test(align) ? align : undefined;
        return align;
    }

    get schema(): NodeSpec {
        return {
            attrs: {
                align: { default: TextAlignments.LEFT },
                dir: { default: "ltr" },
                trailingNodeId: { default: undefined },
            },
            content: "inline*",
            group: "block",
            parseDOM: [
                {
                    // The renderer treats Header as an inline element nested inside <p></p>, thi breaks the
                    // prosemirror parser which expects Header to be a block node.

                    // This rule will stop the parser from creating a Paragraph node that contains Header but
                    // instead render the Header directly
                    tag: "p.header",
                    skip: true,
                },
                {
                    /**
                     * In the process of converting the Uglee template into html for tiptap's consumption, the
                     * table cell text content is nested inside `.inline-paragraph` span as such:
                     *
                     * @example
                     * <table>
                     *   <tbody>
                     *     <tr>
                     *       <td style="text-align: center">
                     *         <span class="inline-paragraph">
                     *           <span>test</span>
                     *           <span style="font-weight: bold">bold text</span>
                     *           <br>
                     *           <span>new line</span>
                     *         </span>
                     *       </td>
                     *     </tr>
                     *   </tbody>
                     * </table>
                     *
                     * To remove this extra level of nesting we need to create a rule to extract the `span.inline-paragraph`
                     * node and render its content directly. The final output for the example above will be as follows:
                     *
                     * @example
                     * <table>
                     *   <tbody>
                     *     <tr>
                     *       <td>
                     *         <p>
                     *           "test "
                     *           <strong>bold text</strong>
                     *           <br>
                     *           "new line"
                     *         </p>
                     *       </td>
                     *     </tr>
                     *   </tbody>
                     * </table/>
                     */
                    tag: "table .inline-paragraph",
                    skip: true,
                },
                {
                    /**
                     * When an image is the only element on a line, the Uglee parser will place it in `<div />`
                     * elements rather than a `<p />. We need to create a rule to match this case to allow
                     * the alignment attribute to apply.
                     *
                     * For example, we want to render the following Uglee template:
                     * @example
                     *  [block align="center"
                     *   [image url="image_url" width="100" height="50"]
                     *  ]
                     *
                     *  The template parser will output this html:
                     * @example
                     * <div style="text-align: center;">
                     *   <div style="text-align: center; margin-left: auto; margin-right: auto;"> <--- Use `text-align` style to determine alignment
                     *     <div> <--- Convert this into a `paragraph` node
                     *       <img src="image_url" width="100" height="50" alt="">
                     *     </div>
                     *   </div>
                     * </div>
                     *
                     * In order to render the image with the correct alignment, we need to convert the
                     * `<div />` that in wrapping the `<img />` in to a `paragraph` node and determin the
                     * `align` attribute from the parent `<div />`'s `text-align` style.
                     */
                    tag: "div",
                    getAttrs: (dom) => {
                        if (dom instanceof HTMLElement && dom.firstChild?.nodeName === "IMG" && dom.parentElement) {
                            return { align: this.checkAlignment(dom.parentElement) };
                        } else {
                            return false;
                        }
                    },
                },
                {
                    tag: "p",
                    getAttrs: (dom) => {
                        if (dom instanceof HTMLElement) {
                            return { align: this.checkAlignment(dom) };
                        }
                    },
                },
            ],
            toDOM: (node) => {
                const { align, dir } = node.attrs;
                const attrs: { [key: string]: any } = {};
                let style = "";
                if (align) {
                    style += `text-align: ${align};`;
                }

                if (style) {
                    attrs.style = style;
                }

                attrs.dir = dir;

                return ["p", attrs, 0];
            },
        };
    }

    // when a new paragraph is created will be left aligned and have a dir of LTR. This updates a newly created paragraph with the correct attrs corresponding to the current text dir mode.
    public get plugins(): Plugin[] {
        return [
            new Plugin({
                appendTransaction: (transactions, oldState, newState) => {
                    if (!transactions.some((tr) => tr.docChanged)) {
                        return;
                    }

                    // get the currently selected paragraph
                    const paragraphObj: ContentNodeWithPos | undefined = findParentNodeOfType(
                        newState.schema.nodes[ExtensionNames.paragraph]
                    )(newState.selection);
                    return RTLCommands.setTextDirectionOnNewContent(newState, paragraphObj);
                },
                props: {
                    transformPastedHTML: (html: string) => {
                        const parser: DOMParser = new DOMParser();
                        const htmlDoc: Document = parser.parseFromString(html, "text/html");
                        const body: HTMLElement = htmlDoc.body;
                        const content: HTMLElement = body.children[1] as HTMLElement;

                        if (
                            content &&
                            content.nodeName.toLowerCase() === "b" &&
                            content.id.indexOf("docs-internal-guid-") != -1
                        ) {
                            return content.innerHTML;
                        }

                        return html;
                    },
                },
            }),
            new Plugin({
                props: {
                    handleKeyDown: (view, event) => {
                        if (event.ctrlKey || event.metaKey) {
                            switch (event.which) {
                                case Keyboard.KEY_S:
                                    TipTapEvents.Common.saveShortcutPressed.dispatch();
                                    return true;
                            }
                        }

                        return false;
                    },
                },
            }),
        ];
    }

    /** [Override] Return the keyboard shortcut -> command map */
    public keys?(
        this: ProsemirroNode,
        { type, schema }: { type: NodeType; schema: NodeSpec }
    ): { [keyCombo: string]: CommandFunction } {
        return {
            "Mod-Alt-0": setBlockType(type),
            Backspace: chainCommands(deleteSelection, ParagraphCommands.joinBackward, selectNodeBackward),
            "Mod-Backspace": chainCommands(deleteSelection, ParagraphCommands.joinBackward, selectNodeBackward),
        };
    }
}
