import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { GuidUtil } from "@educationperfect/ep-web-utils";
import { Node as TiptapNode } from "@educationperfect/tiptap";
import { DispatchFn } from "@educationperfect/tiptap-commands";
import { undo } from "prosemirror-history";
import { Node as ProsemirrorNode, NodeSpec, NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, Plugin, PluginKey, Selection, Transaction } from "prosemirror-state";
import { contains } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";

import { GapData } from "../../models/GapData";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";
import { GapNodeCommands } from "./GapNodeCommands";
import GapNodeView from "./GapNodeView";

export class GapNode extends TiptapNode<GapNodeView> {
    public get nodeName(): string {
        return "gap";
    }
    public static readonly GapSelectionPluginKey: PluginKey = new PluginKey("gapSelection");
    public static readonly GapEventsPluginKey: PluginKey = new PluginKey("gapEvents");

    // ===========================================================================
    //  Node Setup
    // ===========================================================================

    public get name(): string {
        return this.nodeName;
    }

    public get schema(): NodeSpec {
        return {
            attrs: {
                id: { default: "" },
                text: { default: "" },
            },
            inline: true,
            selectable: true,
            group: "inline",
            atom: false,
            draggable: true,
            parseDOM: [
                {
                    tag: this.nodeName,
                    getAttrs: (dom) => {
                        if (dom instanceof Element) {
                            return {
                                id: dom.getAttribute("gap-id"),
                                text: dom.getAttribute("text"),
                            };
                        }
                    },
                },
            ],
            toDOM: (node) => [
                this.name,
                {
                    "gap-id": node.attrs.id,
                    text: node.attrs.text,
                },
            ],
        };
    }

    public get view(): typeof GapNodeView {
        return GapNodeView;
    }

    public get plugins(): Plugin[] {
        const _this: GapNode = this;
        return [
            // Add a handler to state transactions and dispatch events when a gap is selected or create
            new Plugin({
                state: {
                    init(config, editorState) {
                        return {};
                    },
                    apply(transaction: Transaction, value: any, oldState: EditorState, newState: EditorState) {
                        if (transaction.docChanged) {
                            let gaps: GapData[] = [];
                            const hasGaps: boolean = contains(transaction.doc, newState.schema.nodes[_this.nodeName]);
                            if (hasGaps) {
                                const htmlContent: string = ProsemirrorUtils.toHTML(newState);
                                gaps = _this.getGaps(htmlContent);
                            }

                            const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                                oldState
                            );
                            pluginState.dispatch(EditorEvents.GapAddedOrRemoved, gaps);
                        }

                        if (transaction.selectionSet) {
                            const selection: any = newState.selection;
                            const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                                oldState
                            );

                            if (selection && selection.hasOwnProperty("node")) {
                                const node = selection.node;
                                const isGapSelected: boolean = node.type.name === _this.nodeName;

                                if (isGapSelected) {
                                    pluginState.dispatch(EditorEvents.GapSelectionChanged, node.attrs.id);
                                    return;
                                }
                            }

                            pluginState.dispatch(EditorEvents.GapSelectionChanged, null);
                        }
                    },
                },
                props: {
                    handleClickOn(view: EditorView<any>, pos: number, node: ProsemirrorNode<any>) {
                        const isGapSelected: boolean = node.type.name === _this.nodeName;
                        const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                            view.state
                        );

                        if (isGapSelected) {
                            pluginState.dispatch(EditorEvents.GapSelectionChanged, node.attrs.id);
                        } else {
                            pluginState.dispatch(EditorEvents.GapSelectionChanged, null);
                        }

                        return false;
                    },
                },
            }),
            // When pressing backspace/delete with the gap node selected (but not the input), remove the gap
            // marking but retain the text.
            // new Plugin({
            //   props: {
            //     handleKeyDown: (view: EditorView, event: KeyboardEvent) =>
            //     {
            //       const keycode: number = event.which;
            //       if (keycode === Keyboard.BACKSPACE || keycode === Keyboard.DELETE)
            //       {
            //         const selection: NodeSelection = view.state.selection as NodeSelection;
            //         if (selection)
            //         {
            //           const node: ProsemirrorNode = selection.node;
            //           if (node && node.type.name === this.nodeName)
            //           {
            //             GapNodeCommands.removeGapCommand(node.attrs.id, true)(view.state, view.dispatch as DispatchFn, view);
            //             return true;

            //           }
            //         }
            //       }
            //       return false;
            //     },
            //   },
            // }),
            // Plugin to handle keydown events when a gap node is selected. By default, in this state, any
            // other character inserted will replace the gap. We want to override this behavior so users
            // won't accidentally delete a node. Thus, only backspace and delete is allowed to trigger a
            // delete action and we only allow specified shortcut keys to work in this state.
            new Plugin({
                props: {
                    handleKeyDown: (view, event) => {
                        const selection: NodeSelection = view.state.selection as NodeSelection;
                        if (
                            !selection ||
                            selection.empty ||
                            !selection.node ||
                            selection.node.type.name !== this.nodeName
                        ) {
                            return false;
                        }

                        const gapID: string = selection.node.attrs.id;
                        const gapNodeType: NodeType = selection.node.type;

                        switch (event.which) {
                            case Keyboard.TAB:
                                // Override the Tab behavior by cycling through all the gaps when the input is not active˝
                                GapNodeCommands.moveToNextGap(gapNodeType, gapID)(
                                    view.state,
                                    view.dispatch as DispatchFn,
                                    view
                                );
                                break;
                            case Keyboard.BACKSPACE:
                            case Keyboard.DELETE:
                                // Only delete a selected gap node when backspace or delete is pressed
                                GapNodeCommands.removeGapCommand(gapID, this.nodeName, false)(
                                    view.state,
                                    view.dispatch as DispatchFn,
                                    view
                                );
                                break;
                            // Allow navigation keys and shortcuts to go through
                            case Keyboard.LEFT_ARROW:
                            case Keyboard.RIGHT_ARROW:
                            case Keyboard.UP_ARROW:
                            case Keyboard.DOWN_ARROW:
                                return false;
                            case Keyboard.KEY_Z:
                                if (event.metaKey || event.ctrlKey) {
                                    undo(view.state, view.dispatch);
                                    break;
                                }
                            // Allow these keys if also has control/cmd
                            case Keyboard.KEY_C:
                            case Keyboard.KEY_X:
                            case Keyboard.SPACE:
                                return !event.metaKey || !event.ctrlKey;
                        }

                        // Block all other key events to prevent gap from being deleted
                        event.preventDefault();
                        return true;
                    },
                },
            }),
            // When a gap is copy and pasted, ensure that a new id is generated so
            // we don't get a duplicate in the data model.
            new Plugin({
                props: {
                    transformPasted: (slice) => {
                        slice.content.descendants((node) => {
                            if (node.type.name == this.nodeName) {
                                node.attrs.id = GuidUtil.create();
                            }
                        });
                        return slice;
                    },
                },
            }),
            // Enable using arrow keys to navigate in and out of a gap node
            new Plugin<GapSelectionPluginState>({
                key: GapNode.GapSelectionPluginKey,
                state: {
                    init: (config, instance: EditorState) => {
                        return { previousSelection: undefined, fromBackspace: false, fromDelete: false };
                    },
                    apply: (tr, pluginState, oldState, newState) => {
                        // Store the previous selection so we know which direction the cursor
                        // is coming from when we start editing a gap node.
                        if (tr.selectionSet) {
                            pluginState.previousSelection = oldState.selection;
                        }

                        return pluginState;
                    },
                    fromJSON: undefined,
                    toJSON: undefined,
                },
                props: {
                    // When we backspace into a gap node we want to edit the gap text rather
                    // than remove the whole gap node, which is the default behaviour.
                    handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
                        const transaction: Transaction | null = ProsemirrorUtils.handleDeleteIntoInlineNodeContent(
                            view.state,
                            event,
                            this.nodeName,
                            GapNode.GapSelectionPluginKey
                        );

                        if (transaction) {
                            view.dispatch(transaction);
                            // This will indicate that we have handled the event and the view will call preventDefault.
                            return true;
                        }
                        return false;
                    },
                },
            }),
        ];
    }

    public stopEvent(event: Event): boolean {
        // If the gap input is highlighted we need to tell Prosemirror to leave it alone
        let targetGapInput: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
        if (targetGapInput && targetGapInput.selectionEnd !== targetGapInput.selectionStart) {
            return true;
        }

        // Setting the node to be draggable causes Prosemirror to interfere with selection events in the gap,
        // inline input. We need to limit Prosemirror's handling of events  to those related to dragging only.
        const isDragging: boolean = ["mousedown", "dragstart", "dragenter", "dragover"].includes(event.type);
        if (isDragging) {
            return false;
        }

        return true;
    }

    public commands({ type, schema }: { type: NodeType; schema: NodeSpec }): GapNodeCommands.Interface {
        return {
            toggleGap: () => GapNodeCommands.toggleGapCommand(type, this.nodeName),
            markGap: () => GapNodeCommands.markGapCommand(type, this.nodeName),
            selectGap: ({ gapID }: { gapID: string }) => GapNodeCommands.selectGapCommand(gapID, this.nodeName),
            removeGap: ({ gapID, selectText }: { gapID: string; selectText?: boolean }) =>
                GapNodeCommands.removeGapCommand(gapID, this.nodeName, selectText),
            updateGapText: ({ gapID, newText }: { gapID: string; newText: string }) =>
                GapNodeCommands.updateGapTextCommand(type, gapID, newText, this.nodeName),
            moveToNextGap: ({ gapID }: { gapID: string }) => GapNodeCommands.moveToNextGap(type, gapID),
        };
    }

    public keys({ type }): any {
        return {
            "Control-Space": GapNodeCommands.toggleGapCommand(type, this.nodeName),
            "Alt-Space": GapNodeCommands.toggleGapCommand(type, this.nodeName),
        };
    }

    // ===========================================================================
    // Helper functions
    // ===========================================================================

    /** Overriden in subclasses. Return the gaps from the document */
    public getGaps(htmlContent: string): GapData[] {
        return [];
    }
}

export interface GapSelectionPluginState {
    previousSelection?: Selection;
    pendingDeletion?: boolean;
    fromBackspace?: boolean;
    fromDelete?: boolean;
}
