import { AttributeSpec, Mark, MarkSpec, MarkType } from "prosemirror-model";
import { CommandGetter, Mark as TipTapMark } from "@educationperfect/tiptap";

import { ColorCommands } from "../ColorCommands";

export class TextHighlight extends TipTapMark {
    public static readonly MARK_NAME = "text_highlight";

    public get name(): string {
        return TextHighlight.MARK_NAME;
    }

    public get schema(): MarkSpec {
        return {
            attrs: {
                highlightColor: {
                    default: "",
                },
            },
            inline: true,
            group: "inline",
            parseDOM: [
                {
                    tag: "span[style*=background-color]",
                    getAttrs: (highlightColor: string | Node) => {
                        if (highlightColor instanceof Node) {
                            let color: string = (highlightColor as HTMLElement).style.backgroundColor!;
                            return {
                                highlightColor: color,
                            };
                        }
                        return { highlightColor };
                    },
                },
            ],
            toDOM(mark: Mark, inline: boolean) {
                const highlightColor: string = mark.attrs.highlightColor;
                const style: string = highlightColor != null ? `background-color: ${highlightColor};` : "";
                return ["span", { style }, 0];
            },
        };
    }

    public commands({
        type,
        schema,
        attrs,
    }: {
        type: MarkType;
        schema: MarkSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        return (color: string) => {
            return ColorCommands.applyMark(type, { highlightColor: color });
        };
    }
}
