import { AsciiMathToLatexConverter, FormulaFormats, KatexFacade } from "@educationperfect/ep-web-math";
import { ElementUtil } from "@educationperfect/ep-web-browser-utils";

import { IDOMComputedStyle } from "../dom/IDOMComputedStyle";

export class InlineFormulaGraphic {
    private elem: HTMLElement;
    private containerGroup: HTMLElement | null;

    constructor(
        formula: string,
        containerGroup: HTMLElement | null,
        style: IDOMComputedStyle,
        format: FormulaFormats = FormulaFormats.AUTO_DETECT
    ) {
        this.containerGroup = containerGroup;
        KatexFacade.requireKatex();
        this.elem = InlineFormulaGraphic.createInlineElement(formula, format, style.mathFontSize);
    }

    private static createInlineElement(formula: string, format: FormulaFormats, mathFontSize: number): HTMLElement {
        const latex: string =
            format === FormulaFormats.LATEX ? formula : AsciiMathToLatexConverter.Instance.ParseAMtoTeX(formula);
        const element: HTMLElement = ElementUtil.create("formula");
        element.setAttribute("latex", latex);
        return element;
    }

    public get elements(): HTMLElement[] {
        return [this.elem];
    }
}
