import { NodeSpec, NodeType } from "prosemirror-model";

import { GapData } from "../../models/GapData";
import { GapNode } from "../gapNode/GapNode";
import { GapNodeCommands } from "../gapNode/GapNodeCommands";
import { HighlightCommands } from "./HighlightCommands";
import { HighlightData } from "./HighlightData";
import { HighlightNodeView } from "./HighlightNodeView";

export class HighlightNode extends GapNode {
    public static readonly NODE_NAME: string = "highlight";
    public get nodeName(): string {
        return HighlightNode.NODE_NAME;
    }

    /** [Override] Get the node schema */
    public get schema(): NodeSpec {
        const schema: NodeSpec = super.schema;

        schema.attrs = {
            ...schema.attrs,
            correct: { default: false },
        };

        schema.parseDOM = [
            {
                tag: this.nodeName,
                getAttrs: (dom) => {
                    if (dom instanceof Element) {
                        return {
                            id: dom.getAttribute("gap-id"),
                            text: dom.getAttribute("text"),
                            correct: dom.getAttribute("correct"),
                        };
                    }
                },
            },
        ];

        schema.toDOM = (node) => [
            this.name,
            {
                "gap-id": node.attrs.id,
                text: node.attrs.text,
                correct: node.attrs.correct,
            },
        ];

        return schema;
    }

    /** [Override] Get the view to show for the node */
    public get view(): typeof HighlightNodeView {
        return HighlightNodeView;
    }

    /** [Override] The executable commands */
    public commands({ type, schema }: { type: NodeType; schema: NodeSpec }): HighlightCommands.Interface {
        return {
            ...super.commands({ type, schema }),
            createCorrectGap: () => HighlightCommands.createCorrectGap(type),
            updateGapNodeCorrect: ({ gapID, correct }: { gapID: string; correct: boolean }) =>
                HighlightCommands.updateGapNodeCorrect(type, gapID, correct),
            markAllTextAsGap: () => HighlightCommands.markAllTextAsGap(type),
            removeAllGaps: () => HighlightCommands.removeAllGaps(type),
            toggleHighlightGap: ({ actionCorrect }: { actionCorrect: boolean }) =>
                HighlightCommands.toggleHighlightGap(type, actionCorrect),
        };
    }

    /** [Overrde] Set the keyboard shortcut for creating a highlight */
    public keys({ type }): any {
        return {
            "Control-Space": HighlightCommands.toggleHighlightGap(type, false),
            "Control-Shift-Space": HighlightCommands.toggleHighlightGap(type, true),
        };
    }

    /** [Override] Get the gaps from htm;  */
    public getGaps(htmlContent: string): GapData[] {
        return HighlightNode.getGapsFromHtml(htmlContent);
    }

    /** Get the highlight gaps  */
    public static getGapsFromHtml(documentHtml: string): HighlightData[] {
        const parser = new DOMParser();
        const html: Document = parser.parseFromString(documentHtml, "text/html");
        const gapNodes: Element[] = Array.from(html.getElementsByTagName(HighlightNode.NODE_NAME));

        const highlights: HighlightData[] = [];
        for (const gap of gapNodes) {
            const id = gap.getAttribute("gap-id");
            const text = gap.getAttribute("text");
            const correct = gap.getAttribute("correct");

            if (id != null && text != null && correct != null) {
                let highlightData: HighlightData = { id, text, correct: correct == "true" };
                highlights.push(highlightData);
            } else {
                throw Error("Highlight missing id, answer or correctness");
            }
        }

        return highlights;
    }
}
