import { Dictionary } from "@educationperfect/ep-web-utils";

export class DOMTableStyle {
    public cellPadding: number;
    public border: string;
    public percentWidth: number = NaN;
    public width: number = NaN;
    public percentHeight: number = NaN;
    public height: number = NaN;
    public columnAlignments: string[]; // *[] indexed by cell index
    public columnWidths: number[]; // *[] indexed by cell index
    public columnVerticalAlignments: string[]; // *[] indexed by cell index

    constructor(params: Dictionary<string> | null) {
        params = params || {};
        this.columnAlignments = [];
        this.columnVerticalAlignments = [];
        this.columnWidths = [];
        this.cellPadding = 6;

        // border
        this.border = "2px solid black";
        if ("border" in params) {
            var border: string = params["border"].toString();
            if (border == "off" || border == "no" || border == "0") {
                this.border = "none";
            }
        }

        // width/percentWidth
        if ("width" in params) {
            var width: string = params["width"];
            if (width.length > 0 && width.charAt(width.length - 1) == "%") {
                var percentWidth: number = parseFloat(width.substr(0, width.length - 1));
                this.percentWidth = percentWidth;
            } else {
                this.width = parseFloat(width);
            }
        }

        // height/percentHeight
        if ("height" in params) {
            var height: string = params["height"];
            if (height.length > 0 && height.charAt(height.length - 1) == "%") {
                var percentHeight: number = parseFloat(height.substr(0, height.length - 1));
                this.percentHeight = percentHeight;
            } else {
                this.height = parseFloat(height);
            }
        }
    }
}
