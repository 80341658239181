import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { RenderingMode } from "../../render/RenderingMode";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class InlineImageComponent extends BaseEmbeddedComponent {
    // Setup
    // =======================================

    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    // Question Rendering
    // =======================================

    /**
     * [Override]
     * Create element for specific render contexts
     *
     * @param parameters the image parameters
     */
    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        let visualElement: HTMLElement | null;

        switch (this.renderingParameters.mode) {
            case RenderingMode.MODE_ANSWER:
            case RenderingMode.MODE_EXAM_MARKING:
            case RenderingMode.MODE_IN_REVIEW:
            case RenderingMode.MODE_PREVIEW:
            case RenderingMode.MODE_QUESTION:
            default:
                visualElement = this.createQuestionVisualElement(parameters);
                break;
        }

        return visualElement!;
    }

    /**
     * Create visual element for render mode question.
     *
     * @param parameters the image's parameters
     */
    private createQuestionVisualElement(parameters: Dictionary<string>): HTMLElement | null {
        if (!parameters || !parameters.url) {
            return null;
        }
        const imgTag: HTMLImageElement = new Image();
        const url: string = parameters["url"].toString();
        imgTag.src = url;

        const width: number = parameters["width"] !== undefined ? parseInt(parameters["width"]) : imgTag.naturalWidth;
        const height: number =
            parameters["height"] !== undefined ? parseInt(parameters["height"]) : imgTag.naturalHeight;
        const title: string = parameters["title"] !== undefined ? parameters["title"].toString() : "";

        imgTag.width = width;
        imgTag.height = height;
        imgTag.alt = title;

        return imgTag;
    }
}
