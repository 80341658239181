import "./ep-toolbar.less";
import "./ep-toolbar.mobile.less";
import template from "./ep-toolbar.html";
import mobileTemplate from "./ep-toolbar.mobile.html";

import { DeviceUtil } from "@educationperfect/ep-web-browser-utils";
import { DropDownComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/DropDownComponentMetaData";
import { NumberBoxComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/NumberBoxComponentMetaData";
import { QuestionComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/QuestionComponentMetaData";
import { TextBoxComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/TextBoxComponentMetaData";
import { EdsSecondaryButton, EdsTertiaryButton, EdsTooltip } from "@educationperfect/ep-web-ui-components";
import { Editor, EditorMenuBar } from "@educationperfect/tiptap";
import { DispatchFn } from "@educationperfect/tiptap-commands";
import { markIsActive } from "@educationperfect/tiptap-utils";
import { Input, Poptip } from "@educationperfect/view-design";
import { redo, undo } from "prosemirror-history";
import { Mark, MarkType, Node as ProsemirrorNode, NodeType, ResolvedPos } from "prosemirror-model";
import { EditorState, TextSelection } from "prosemirror-state";
import { setCellAttr } from "prosemirror-tables";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ColorCommands } from "../../extensions/color/ColorCommands";
import { TextColor } from "../../extensions/color/textColor/TextColor";
import { TextHighlight } from "../../extensions/color/textHighlight/TextHighlight";
import { EditorEvents } from "../../extensions/editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../../extensions/editorEvents/EditorEventsPlugin";
import { SymbolDefinitions } from "../../extensions/formula/SymbolDefinitions";
import { ImageCommands } from "../../extensions/image/ImageCommands";
import { ImageDialog } from "../../extensions/image/imageDialog/ImageDialog";
import { IImageMetadata } from "../../extensions/image/imageDialog/interfaces/IImageMetadata";
import { ImageNode } from "../../extensions/image/ImageNode";
import { DropDownNode } from "../../extensions/inlineBuildable/dropDown/DropDownNode";
import { DropDownEditor } from "../../extensions/inlineBuildable/dropDown/editor/DropDownEditor";
import { InlineBuildableCommands } from "../../extensions/inlineBuildable/InlineBuildableCommands";
import { NumberBoxEditor } from "../../extensions/inlineBuildable/inputBox/numberBox/editor/NumberBoxEditor";
import { NumberBoxNode } from "../../extensions/inlineBuildable/inputBox/numberBox/NumberBoxNode";
import { TextBoxEditor } from "../../extensions/inlineBuildable/inputBox/textBox/editor/TextBoxEditor";
import { TextBoxNode } from "../../extensions/inlineBuildable/inputBox/textBox/TextBoxNode";
import { EPLink } from "../../extensions/link/EPLink";
import { EPLinkCommands } from "../../extensions/link/EPLinkCommands";
import { UnorderedList } from "../../extensions/lists/BulletList";
import { OrderedList } from "../../extensions/lists/NumberedList";
import RTLMode, { RTLPluginState } from "../../extensions/RTLMode/RTLMode";
import { SoundEditorDialog } from "../../extensions/sound/dialog/SoundEditorDialog";
import { ISoundMetaData } from "../../extensions/sound/interfaces/ISoundMetaData";
import { SoundCommands } from "../../extensions/sound/SoundCommands";
import { SoundNode } from "../../extensions/sound/SoundNode";
import { EPTable } from "../../extensions/table/EPTable";
import { EPTableCell } from "../../extensions/table/EPTableCell";
import { VerticalAlignments } from "../../extensions/table/VerticalAlignments";
import { TextAlignments } from "../../extensions/textAlign/enums/TextAlignments";
import { TextAlign } from "../../extensions/textAlign/TextAlign";
import { EditorContext } from "../../models/EditorContext";
import { EditorFeatureFlags } from "../../models/EditorFeatureFlags";
import { TipTapComponents } from "../../TipTapComponents";
import { ColorUtils } from "../../utils/ColorUtils";
import { ExtensionNames } from "../../utils/ExtensionNames";
import { ColorPicker } from "../colorPicker/ColorPicker";
import { EditorPluginState } from "../editor/interfaces/EditorPluginState";
import { EditorPlugin } from "../editor/plugins/EditorPlugin";
import { ToolbarIcon } from "../toolbarIcon/ToolbarIcon";
import { ToolbarItemShortcuts } from "./enums/ToolbarItemShortcuts";
import { ISymbolGroup } from "./interfaces/ISymbolGroup";
import { ITableIndex } from "./interfaces/ITableRowIndex";

/** The minimum number of row/cols the table create dropdown can have */
const MIN_TABLE_SIZE: number = 5;

/** The maximum number of row/cols the table create dropdown can have */
const MAX_TABLE_SIZE: number = 10;

/** The minimum number of row/cols the table create dropdown can have */
const MIN_TABLE_SIZE_MOBILE: number = 5;

/** The maximum number of row/cols the table create dropdown can have */
const MAX_TABLE_SIZE_MOBILE: number = 5;

/** The number of heading supported in the toolbar (h1-6) */
const NUMBER_OF_HEADINGS: number = 6;

/** The default color for the picker. This was chosen for it's nice offset from the top right */
const DEFAULT_CUSTOM_COLOR: string = "#f3290c";

/** The name of the event that is emitted when an editor opens/closes */
const ON_EDITOR_VISIBLE_CHANGE_EVENT: string = "on-editor-visible-change";

enum DropdownTypes {
    TABLE = "table",
    TEXT_COLOR = "text-color",
    HIGHLIGHT_COLOR = "highlight-color",
    CELL_BACKGROUND_COLOR = "cell-background-color",
    TABLE_BORDER = "table-border",
    CELL_PADDING = "cell-padding",
    SYMBOL_GROUP = "symbol-group",
}

@Component({
    template: DeviceUtil.mobile ? mobileTemplate : template,
    components: {
        [TipTapComponents.EPToolbar]: EPToolbar,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        [TipTapComponents.ColorPicker]: ColorPicker,
        [TipTapComponents.ImageDialog]: ImageDialog,
        [Poptip.name]: Poptip,
        EditorMenuBar,
        [TipTapComponents.SoundEditorDialog]: SoundEditorDialog,
        [TipTapComponents.DropDownEditor]: DropDownEditor,
        [TipTapComponents.NumberBoxEditor]: NumberBoxEditor,
        [TipTapComponents.TextBoxEditor]: TextBoxEditor,
        EdsSecondaryButton,
        EdsTertiaryButton,
        EdsTooltip,
    },
    name: TipTapComponents.EPToolbar,
})
export class EPToolbar extends Vue {
    ToolbarItems = ToolbarItemShortcuts;

    private get DropdownTypes(): typeof DropdownTypes {
        return DropdownTypes;
    }
    // Variables
    // =========================================

    // Props

    /** The currently active tiptap editor */
    @Prop() private readonly editor?: Editor;

    /** The list of enabled features */
    @Prop() private readonly features?: EditorFeatureFlags;

    // Mobile Dropdown Flags

    /** Whether or not the text mark dropdown is open */
    private textMarksDropdownOpen: boolean = false;

    /** Whether or not the alignment dropdown is open */
    private alignmentDropdownOpen: boolean = false;

    /** The list of custom colors for the color picker */
    @Prop({ type: Array, default: () => [] }) public readonly colorPickerCustomColors?: string[];

    /** The context that the editor/toolbar is being used in */
    @Prop({ type: Number, default: EditorContext.STANDARD }) public readonly context: EditorContext | undefined;

    /** Whether or not the insert component dropdown is open */
    private insertDropdownOpen: boolean = false;

    /** Whether or not the insert column or row dropdown is open */
    private tablesInsertsDropdownOpen: boolean = false;

    /** Whether or not the cell options dropdown is open */
    private cellOptionsDropdownOpen: boolean = false;

    // Mobile Dialog Flag
    private canClickOutsideDialog: boolean = false;

    private symbolGridOpen: boolean = false;

    // Table

    /** The current active item in the table that has been hovered over */
    private selectedTableIndex: ITableIndex = { colsCount: 0, rowsCount: 0 };

    /** The number of displayed iterable rows */
    private numberOfIterableRows: number = MIN_TABLE_SIZE;

    /** The number of displayed iterable columns */
    private numberOfIterableCols: number = MIN_TABLE_SIZE;

    // Color Pickers

    /**
     * Shadow list of custom colors for color picker. This is used when EPToolbar is manually
     * mounted with the { data } property. Don't use at any other time.
     */
    private dataColorPickerCustomColors: string[] | null = null;

    /** Whether or not the text color dropdown is open */
    private textColorDropdownOpen: boolean = false;

    /** Whether or not the highlight color dropdown is open */
    private highlightColorDropdownOpen: boolean = false;

    /** Whether or not the cell background color dropdown is open */
    private cellBackgroundColorDropdownOpen: boolean = false;

    /** Whether or not the color picker dialog is open */
    private colorPickerDialogOpen: boolean = false;

    /** The color to initiate the color picker dialog with */
    private colorPickerDialogColor: string = DEFAULT_CUSTOM_COLOR;

    // Audio Picker

    /** Whether or not the audio picker dialog is open */
    private isAudioPickerDialogOpen: boolean = false;

    // Drop Down

    /** Whether or not the dropdown dialog is open */
    private isDropDownDialogOpen: boolean = false;

    // Number Box

    /** Whether or no the number box dialog is open */
    private isNumberBoxDialogOpen: boolean = false;

    // Text Box

    /** Whether or not the text box dialog is open */
    private isTextBoxDialogOpen: boolean = false;

    // Other Dropdowns

    /** Whether or not the more options dropdown is open */
    private moreOptionsDropdownOpen: boolean = false;

    /** Whether or not the create table dropdown is open */
    private createTableDropdownOpen: boolean = false;

    /** Whether or not the text styles (h1, 2, 3, p etc) is open */
    private textStylesDropdownOpen: boolean = false;

    /** Whether or not the table borders dropdown is open */
    private tableBordersDropdownOpen: boolean = false;

    /** Whether or not the cell padding dropdown is open */
    private cellPaddingDropdownOpen: boolean = false;

    /** Whether or not cell vertical align options dropdown is open */
    private cellVerticalAlignDropdownOpen: boolean = false;

    /** Whether or not symbols dropdown is open */
    private symbolsDropdownOpen: boolean = false;

    /** Whether or not the table alignment dropdown is open */
    private tableAlignmentDropdownOpen: boolean = false;

    private imageDialogOpen: boolean = false;

    // Symbols
    private symbolGroups: ISymbolGroup[] = SymbolDefinitions;

    private emptySymbolGroup: ISymbolGroup = {
        groupTitle: "",
        groupSymbol: "",
        symbolList: [],
    };

    protected selectedSymbolGroup: ISymbolGroup | undefined = this.emptySymbolGroup;

    private isTableActive: boolean = false;
    private isFormulaActive: boolean = false;
    private isHeadingActive: boolean = false;

    // Logic
    // =========================================

    @Watch("editor.isActive")
    private onEditorActiveChange(isActive: any): void {
        /** Checks if the secondary toolbar was shown */
        const oldExpandedState = this.isTableActive || this.isFormulaActive;

        this.isTableActive = isActive?.table ? isActive.table() : false;
        this.isFormulaActive = isActive?.formula ? isActive.formula() : false;
        this.isHeadingActive = isActive?.heading ? isActive.heading() : false;

        /** Should the secondary toolbar now be shown */
        const newExpandedState = this.isTableActive || this.isFormulaActive;

        if (oldExpandedState !== newExpandedState) {
            this.$emit("on-expanded", newExpandedState);
        }
    }

    private stopPropagation(event: MouseEvent): void {
        event.stopPropagation();
    }

    /**
     * Return the current or stored attributes for a specific
     * mark
     *
     * @param markName the name of the mark (style)
     * @param attribute the value of the mark (style)
     * @param defaultValue the default value of the mark (style)
     */
    private getActiveOrStoredMarkAttrs(markName: string, attribute: string, defaultValue: any): any | undefined {
        let value: any = defaultValue;
        if (!this.editor || !this.editorState) {
            return value;
        }

        const { selection } = this.editorState;
        if (selection.empty && selection instanceof TextSelection) {
            const cursor: ResolvedPos | null | undefined = selection.$cursor;
            if (cursor) {
                const marks: Mark[] = cursor.marks();
                const matchedMark: Mark | undefined = marks.find((m) => m.type.name === markName);

                if (matchedMark && matchedMark.attrs[attribute]) {
                    value = matchedMark.attrs[attribute] ? matchedMark.attrs[attribute] : defaultValue;
                }
            }
        } else if (attribute in this.editor.getMarkAttrs(markName)) {
            value = this.editor.getMarkAttrs(markName)[attribute].toUpperCase();
        }

        if (value === defaultValue) {
            const { storedMarks } = this.editor.state;
            if (storedMarks) {
                const targetMark: Mark | undefined = storedMarks.find((m: any) => m.type.name === markName);
                value = targetMark ? targetMark.attrs[attribute] : defaultValue;
            } else {
                value = defaultValue;
            }
        }

        if (attribute === "color") {
            value = `#${ColorUtils.ensureColorPartFormat(value)}`;
        }

        return value;
    }

    /**
     * Get the current active text style, default to paragraph.
     *
     * @param isActive the tiptap isActive object
     */
    private getActiveTextStyle(isActive: any): string {
        const align: string = this.getActiveTextAlignment() || TextAlignments.CENTRE;
        for (let level = 1; level <= NUMBER_OF_HEADINGS; level++) {
            if (isActive.heading({ level, align })) {
                return `Heading ${level}`;
            }
        }

        return "Paragraph";
    }

    /** Get whether or not the undo button is available. Aka there is history */
    private isUndoAvailable(): boolean {
        return this.editorState && undo(this.editorState);
    }

    /** Get whether or not the redo button is available. Aka there is future */
    private isRedoAvailable(): boolean {
        return this.editorState && redo(this.editorState);
    }

    /** Get the current active text alignment */
    private getActiveTextAlignment(): string | undefined {
        return TextAlign.activeAlignment(this.editorState);
    }

    /**
     * Emit changed metadata to parent and dispatch creating
     * node.
     *
     * @param metaData the component editor metadata
     * @param nodeName the name of the node to create
     */
    private onComponentEditorClose(metaData: QuestionComponentMetaData, nodeName: string): void {
        if (!metaData || !this.editorState || !this.editor) {
            return;
        }

        const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(this.editorState);
        if (!pluginState) {
            return;
        }

        // Add metadata to store
        pluginState.dispatch(EditorEvents.ComponentMetaDataChange, { componentId: metaData.ComponentID, metaData });

        // Create dropdown node
        const nodeType: NodeType = this.editorState.schema.nodes[nodeName];
        InlineBuildableCommands.createInlineBuildable(nodeType, metaData.ComponentID)(
            this.editorState,
            this.editor.view.dispatch as DispatchFn,
            this.editor.view
        );
    }

    // DOM Events
    // =========================================

    /**
     * DOM Emit:
     * Called when the user hovers over a table cell in the create
     * table menu. Store the current col and row hovered over and
     * increase/decrease number or rows/cols if required
     *
     * @param colsCount the column hovered over (zero based)
     * @param rowsCount the row hovered over (zero based)
     */
    private onTableItemHover(colsCount: number, rowsCount: number): void {
        if (colsCount == null || rowsCount == null) {
            return;
        }

        const increaseDecreaseFn: (displayedIterableRows: number, count: number) => number = (
            displayedIterableRows: number,
            hoverIndex: number
        ) => {
            const zeroBased: number = 1;
            const offsetAndZeroBased: number = 1 + zeroBased;

            const maxTableSize = DeviceUtil.mobile ? MAX_TABLE_SIZE_MOBILE : MAX_TABLE_SIZE;

            if (hoverIndex >= displayedIterableRows - zeroBased) {
                return Math.min((displayedIterableRows += 1), maxTableSize);
            }

            if (hoverIndex === displayedIterableRows - offsetAndZeroBased) {
                return displayedIterableRows;
            }

            if (hoverIndex !== maxTableSize - offsetAndZeroBased) {
                return Math.max((displayedIterableRows -= 1), MIN_TABLE_SIZE);
            }

            return displayedIterableRows;
        };

        this.numberOfIterableRows = increaseDecreaseFn(this.numberOfIterableRows, rowsCount);
        this.numberOfIterableCols = increaseDecreaseFn(this.numberOfIterableCols, colsCount);
        this.selectedTableIndex = { colsCount, rowsCount };
    }

    /**
     * DOM Click:
     * Called when a table item cell is clicked from the create menu.
     * Create a table where the selected cursor is.
     */
    private createTable(): void {
        if (!this.editor || (this.editor && !this.editor.commands)) {
            return;
        }

        if (!this.editor.commands.createTable || !this.selectedTableIndex) {
            return;
        }

        // Add 1 to offset zero based number
        this.editor.commands.createTableWithColumnWidths({
            colsCount: this.selectedTableIndex.colsCount + 1,
            rowsCount: this.selectedTableIndex.rowsCount + 1,
            withHeaderRow: false,
            width: 150,
        });

        // reset row and col values after table creation so that when a
        // new table is created, these values don't persist
        this.selectedTableIndex = { colsCount: 0, rowsCount: 0 };

        this.numberOfIterableRows = MIN_TABLE_SIZE;
        this.numberOfIterableCols = MIN_TABLE_SIZE;

        this.onClickOutsideDialog(DropdownTypes.TABLE); // close table dialog
    }

    private async createTableOnMobile(col: number, row: number): Promise<void> {
        await this.onTableItemHover(col, row);
        this.createTable();
    }

    /**
     * DOM Emit:
     * Called when the color picker dialog is opened/closed.
     * Set the new state executed in timeout so last in que.
     *
     * @param open whether or not to open the dialog
     */
    private onSetColorPickerDialogState(open: boolean, color?: string) {
        setTimeout(() => {
            if (color) {
                this.colorPickerDialogColor = color;
            }
            this.onSetDialogVisible("color", open);
        });
    }

    private onSetTableDialogState(open: boolean) {
        setTimeout(() => (this.createTableDropdownOpen = open));
    }

    private onSetImageDialogState(open: boolean) {
        setTimeout(() => this.onSetDialogVisible("image", open));
    }

    /**
     * DOM Emit:
     * Called when the user changes any of the custom colors
     *
     * @param colors the list of custom colors the user has selected
     */
    private onCustomColorsChanged(colors: string[]): void {
        if (!this.editor) {
            return;
        }

        ColorCommands.emitColorsChanged(colors)(this.editor.state, undefined, this.editor.view);
        this.onSetColorPickerDialogState(false);
    }

    /**
     * DOM Emit:
     * Called when a user selects a color from the picker.
     * Change selection's text color.
     *
     * @param color the hex string to change to
     */
    private onTextColorChanged(color: string): void {
        if (!this.editor || (this.editor && !this.editor.commands) || !this.editor.commands.text_color) {
            return;
        }

        this.editor.commands.text_color(color);
        this.textColorDropdownOpen = false;
    }

    /**
     * DOM Emit:
     * Called when a user selects a color from the picker.
     * Change selection's highlight color.
     *
     * @param color the hex string to change to
     */
    private onHighlightColorChanged(color: string): void {
        if (!this.editor || (this.editor && !this.editor.commands) || !this.editor.commands.text_highlight) {
            return;
        }

        this.editor.commands.text_highlight(color);
        this.highlightColorDropdownOpen = false;
    }

    /**
     * DOM Emit:
     * Called when the audio player dialog
     * closes. Create new audio player with data.
     *
     * @param metaData the new audio metadata
     */
    private onAudioDialogChange(metaData: ISoundMetaData): void {
        this.isAudioPickerDialogOpen = false;
        if (!metaData || !this.editorState || !this.editor) {
            console.log("failed");
            return;
        }

        const nodeType: NodeType = this.editorState.schema.nodes[SoundNode.NODE_NAME];
        SoundCommands.createSound(nodeType, metaData)(
            this.editorState,
            this.editor.view.dispatch as DispatchFn,
            this.editor.view
        );
    }

    /**
     * DOM Emit:
     * Called when the dropdown editor closes.
     * Create dropdown with data
     *
     * @param metaData the dropdown metadata
     */
    private onDropDownEditorSave(metaData: DropDownComponentMetaData): void {
        this.onComponentEditorClose(metaData, DropDownNode.NODE_NAME);
    }

    /**
     * DOM Emit:
     * Called when the number box editor closes.
     * Create number box with data
     *
     * @param metaData the number box metadata
     */
    private onNumberBoxEditorSave(metaData: NumberBoxComponentMetaData): void {
        this.onComponentEditorClose(metaData, NumberBoxNode.NODE_NAME);
    }

    /**
     * DOM Emit:
     * Called when the text box editor closes.
     * Create textbox with data
     *
     * @param metaData the text box metadata
     */
    private onTextBoxEditorSave(metaData: TextBoxComponentMetaData): void {
        this.onComponentEditorClose(metaData, TextBoxNode.NODE_NAME);
    }

    /**
     * DOM Emit:
     * Called when a user selects a color from the picker.
     * Change selected cell's background highlight color.
     *
     * @param color the hex string to change to
     */
    private onCellBackgroundColorChanged(color: string): void {
        if (!this.editor || !this.editorState) {
            return;
        }

        setCellAttr("background", color)(this.editorState, this.editor.view.dispatch);
        this.cellBackgroundColorDropdownOpen = false;
    }

    /**
     * DOM Emit:
     * Called when a user selects the table alignment.
     */
    private onTableAlignmentChanged(alignment: TextAlignments): void {
        if (!this.editor || !this.editorState) {
            return;
        }

        // check whether the alignment passed in is a text or table alignment
        const isCellAlignment = !Object.values(TextAlignments).includes(alignment);
        if (isCellAlignment) {
            setCellAttr("verticalAlign", alignment)(this.editorState, this.editor.view.dispatch);
        } else {
            this.editor.commands.setTableAlignment({ alignment });
        }
    }

    protected onImageDialogSave(imgMetadata: IImageMetadata): void {
        if (!this.editor || !imgMetadata) {
            return;
        }

        const type: NodeType = this.editor.state.schema.nodes[ImageNode.NODE_NAME];
        ImageCommands.createImage(type, imgMetadata)(
            this.editor.state,
            this.editor.view.dispatch as DispatchFn,
            this.editor.view
        );
        ImageCommands.emitImageChanged(imgMetadata)(this.editor.state, undefined, this.editor.view);
        this.onSetImageDialogState(false);
    }

    protected openImageDialog(): void {
        this.onSetDialogVisible("image", true);
    }

    protected onImageDialogClose(): void {
        this.onSetImageDialogState(false);
    }

    // This is called from dropdown items
    protected openDialog(dropdownName: string, isActive: boolean = false, symbolGroup?: ISymbolGroup): void {
        if (!isActive) {
            switch (dropdownName) {
                case DropdownTypes.TABLE:
                    this.createTableDropdownOpen = true;
                    break;
                case DropdownTypes.TEXT_COLOR:
                    this.textColorDropdownOpen = true;
                    break;
                case DropdownTypes.HIGHLIGHT_COLOR:
                    this.highlightColorDropdownOpen = true;
                    break;
                case DropdownTypes.CELL_BACKGROUND_COLOR:
                    if (!this.cellBackgroundColorDropdownOpen) {
                        setTimeout(() => {
                            this.cellBackgroundColorDropdownOpen = true;
                        }); // this is needed to stop the dialog from closing itself as it is opened.
                    }
                    break;
                case DropdownTypes.TABLE_BORDER:
                    this.tableBordersDropdownOpen = true;
                    break;
                case DropdownTypes.CELL_PADDING:
                    this.cellPaddingDropdownOpen = true;
                    break;
                case DropdownTypes.SYMBOL_GROUP:
                    this.symbolGridOpen = true;
                    this.selectedSymbolGroup = symbolGroup;
                    break;
                default:
                    break;
            }
        }
    }

    protected onClickOutsideDialog(dropdownName: string): void {
        switch (dropdownName) {
            case DropdownTypes.TABLE:
                this.createTableDropdownOpen = false;
                break;
            case DropdownTypes.TEXT_COLOR:
                this.onTextColorDropdownOutsideClick();
                break;
            case DropdownTypes.HIGHLIGHT_COLOR:
                this.onHighlightColorDropdownOutsideClick();
                break;
            case DropdownTypes.CELL_BACKGROUND_COLOR:
                this.onCellBackgroundColorDropdownOutsideClick();
                break;
            case DropdownTypes.TABLE_BORDER:
                this.tableBordersDropdownOpen = false;
                break;
            case DropdownTypes.CELL_PADDING:
                this.cellPaddingDropdownOpen = false;
                break;
            case DropdownTypes.SYMBOL_GROUP:
                this.symbolGridOpen = false;
                this.selectedSymbolGroup = this.emptySymbolGroup;
                break;
            default:
                break;
        }
        this.canClickOutsideDialog = false;
    }

    /**
     * Open and close modal dialog
     * components. This is a temp gross fix
     * to stop safari from destroying dialogs.
     */
    private onSetDialogVisible(type: string, open: boolean): void {
        this.$emit(ON_EDITOR_VISIBLE_CHANGE_EVENT, open);
        switch (type) {
            case "audio":
                this.isAudioPickerDialogOpen = open;
                break;
            case "dropdown":
                this.isDropDownDialogOpen = open;
                break;
            case "color":
                this.colorPickerDialogOpen = open;
                break;
            case "image":
                this.imageDialogOpen = open;
                break;
            case "text-box":
                this.isTextBoxDialogOpen = open;
                break;
            case "number-box":
                this.isNumberBoxDialogOpen = open;
                break;
            default:
                console.log("Unsupported dialog type:", type);
        }
    }

    /**
     * DOM Emit:
     * Called when a click outside the color picker dropdown occurs.
     * Close color picker dropdown if color picker dialog is not open.
     */
    private onTextColorDropdownOutsideClick(): void {
        this.textColorDropdownOpen = !!this.colorPickerDialogOpen;
    }

    /**
     * DOM Emit:
     * Called when a click outside the color picker dropdown occurs.
     * Close color picker dropdown if color picker dialog is not open.
     */
    private onHighlightColorDropdownOutsideClick(): void {
        this.highlightColorDropdownOpen = !!this.colorPickerDialogOpen;
    }

    /**
     * DOM Emit:
     * Called when a click outside the color picker dropdown occurs.
     * Close color picker dropdown if color picker dialog is not open.
     */
    private onCellBackgroundColorDropdownOutsideClick(): void {
        this.cellBackgroundColorDropdownOpen = !!this.colorPickerDialogOpen;
    }

    // Gets
    // =========================================

    /** Get the current state of the editor */
    private get editorState(): EditorState | null {
        return this.editor ? this.editor.state : null;
    }

    private get linkEnabled(): boolean {
        if (!this.editor || !this.editorState) {
            return false;
        }

        const markType: MarkType = this.editorState.schema.marks[EPLink.MARK_NAME];
        if (markIsActive(this.editorState, markType)) {
            return true;
        }

        const type: MarkType = this.editor.state.schema.marks[EPLink.MARK_NAME];
        return EPLinkCommands.addEmptyLink(type)(this.editorState, undefined, this.editor.view);
    }

    protected get RTLModeActive(): boolean {
        if (this.editorState) {
            const RTLModePluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(
                this.editorState
            ) as RTLPluginState;
            return RTLModePluginState.RTLMode;
        }
        return false;
    }

    protected get alignmentEnabled(): boolean {
        if (this.editorState && this.editor && this.editor.commands[TextAlign.NAME]) {
            return true;
        }
        return false;
    }

    private get linkInputElement(): Input {
        return this.$refs.linkInput as Input;
    }

    /** Get the currently link's href */
    protected get selectedLink(): string {
        return this.getActiveOrStoredMarkAttrs(EPLink.MARK_NAME, "href", null);
    }

    /**
     * Get whether or not text formatting should be enabled, given the current selection.
     */
    protected formattingOptionsEnabled(forTextStyles: boolean = false, forLink: boolean = false): boolean {
        if (!this.editorState) {
            return false;
        }
        const { selection } = this.editorState;

        let allowSelection: boolean = true;

        if (this.features && this.features.LINK) {
            const markType: MarkType = this.editorState.schema.marks[EPLink.MARK_NAME];
            if (!forLink && markIsActive(this.editorState, markType)) {
                return false;
            }
        }

        if (!forTextStyles && selection.empty && selection instanceof TextSelection) {
            return true;
        }

        this.editorState.doc.nodesBetween(selection.$from.pos, selection.$to.pos, (node: ProsemirrorNode) => {
            if (
                forTextStyles &&
                [ExtensionNames.orderedList, ExtensionNames.bulletList, ExtensionNames.listItem].includes(
                    node.type.name
                )
            ) {
                allowSelection = false;
                return false;
            }
            if (
                ![
                    ExtensionNames.text,
                    ExtensionNames.paragraph,
                    ExtensionNames.heading,
                    ExtensionNames.orderedList,
                    ExtensionNames.bulletList,
                    ExtensionNames.listItem,
                    ExtensionNames.tableCell,
                    ExtensionNames.tableHeader,
                    ExtensionNames.tableRow,
                    ExtensionNames.table,
                ].includes(node.type.name)
            ) {
                allowSelection = false;
                return false; // exit closure
            }
        });
        return allowSelection;
    }

    /** Get the currently selected text color */
    protected get selectedTextColor(): string {
        return this.getActiveOrStoredMarkAttrs(TextColor.MARK_NAME, "color", "#444444");
    }

    /** Get the currently selected highlight color */
    protected get selectedHighlightColor(): string {
        return this.getActiveOrStoredMarkAttrs(TextHighlight.MARK_NAME, "highlightColor", "#FFFFFF");
    }

    /** Get the currently selected cell background color */
    protected get selectedCellBackgroundColor(): string {
        if (!this.editorState) {
            return EPTableCell.DEFAULT_CELL_BACKGROUND_COLOR;
        }
        const { selection, schema } = this.editorState;

        return EPTableCell.getCellBackgroundColor(schema, selection);
    }

    protected get cellPadding(): number {
        if (!this.editorState) {
            return 6;
        }

        const { selection, schema } = this.editorState;
        return EPTableCell.getCellPadding(schema, selection);
    }

    protected set cellPadding(padding: number) {
        if (!this.editorState || !this.editor) {
            return;
        }

        setCellAttr("padding", padding)(this.editorState, this.editor.view.dispatch);
    }

    protected get isCellBorderOverride(): boolean {
        if (!this.editorState) {
            return false;
        }

        const { selection, schema } = this.editorState;

        return EPTableCell.getCellBorderOverride(schema, selection);
    }

    protected get enableCellBorderOverrideOption(): boolean {
        if (!this.editorState) {
            return false;
        }

        const { selection } = this.editorState;
        return EPTableCell.enableCellBorderOverrideOption(selection);
    }

    protected get cellVerticalAlignment(): VerticalAlignments {
        if (!this.editorState) {
            return VerticalAlignments.MIDDLE;
        }

        const { schema, selection } = this.editorState;
        return EPTableCell.getCellVerticalAlignment(schema, selection);
    }

    protected get isCellMerged(): boolean {
        if (!this.editorState) {
            return false;
        }

        const { schema, selection } = this.editorState;
        return EPTableCell.isCellMerged(schema, selection);
    }

    protected get enableMergeCellButton(): boolean {
        if (!this.editorState) {
            return false;
        }

        return this.isCellMerged || EPTableCell.canToggleMergeCell(this.editorState);
    }

    protected get enableListButtons(): boolean {
        if (!this.editorState || !this.editor) {
            return false;
        }
        const { editorState } = this;
        let imageOrSoundSelected: boolean = false;
        // check if the selection contains an image or sound node
        editorState.doc.nodesBetween(editorState.selection.from, editorState.selection.to, (node: ProsemirrorNode) => {
            if (!imageOrSoundSelected && node.type.name === ExtensionNames.paragraph) {
                // check for image and sound nodes in the selected paragraph
                imageOrSoundSelected = this.recursivelyFindImageOrSoundNode(node);
            }
        });
        return (
            !imageOrSoundSelected &&
            (UnorderedList.canInsertList(editorState, this.editor.view) ||
                OrderedList.canInsertList(editorState, this.editor.view))
        );
    }

    /**
     * Searches a node and all its children nodes for the presence of a
     * sound or image node. Returns a boolean to indicate if a sound or
     * image node was found.
     * @param node The node to search
     */
    private recursivelyFindImageOrSoundNode(node: ProsemirrorNode): boolean {
        const { name } = node.type;
        if ([ImageNode.NODE_NAME, SoundNode.NODE_NAME].includes(name)) {
            return true;
        }

        if (node.childCount === 0) {
            return false;
        }

        let foundImageOrSound: boolean = false;
        for (let i = 0; i < node.childCount && !foundImageOrSound; i++) {
            foundImageOrSound = this.recursivelyFindImageOrSoundNode(node.child(i));
        }
        return foundImageOrSound;
    }

    protected get tableAlignment(): TextAlignments {
        if (!this.editorState) {
            return TextAlignments.CENTRE;
        }

        const { schema, selection } = this.editorState;
        return EPTable.getTableAlignment(schema, selection);
    }

    /** The list of custom color picker colors to use */
    private get _colorPickerCustomColors(): string[] {
        if (this.dataColorPickerCustomColors) {
            return this.dataColorPickerCustomColors;
        }

        if (this.colorPickerCustomColors) {
            return this.colorPickerCustomColors;
        }

        return [];
    }

    private get isInList(): boolean {
        const state: EditorState | null = this.editorState;
        if (state == null) {
            return false;
        }
        let isInList: boolean = false;
        state.doc.nodesBetween(state.selection.from, state.selection.to, (node: ProsemirrorNode) => {
            const nodeName: string = node.type.name;
            if ([ExtensionNames.orderedList, ExtensionNames.bulletList, ExtensionNames.listItem].includes(nodeName)) {
                isInList = true;
            }
        });
        return isInList;
    }

    private get isExpanded(): boolean {
        if (!this.editor || !this.features) {
            return false;
        }
        return this.isTableActive || this.isFormulaActive;
    }

    /** Get the plugin editor state */
    public get editorPluginState(): EditorPluginState | null {
        if (!this.editorState) {
            return null;
        }

        const pluginState: EditorPluginState = EditorPlugin.EditorPluginKey.getState(this.editorState);
        if (!pluginState) {
            return null;
        }

        return pluginState;
    }

    /**
     * Gets the OS appropriate keyboard shortcut for toolbar items
     * @param toolbarItem The toolbar item ie: Bold
     */
    private getToolbarShortcut(toolbarItem: ToolbarItemShortcuts): string {
        return DeviceUtil.windows ? toolbarItem : toolbarItem.replace("Ctrl", "⌘").replace("Alt", "⌥");
    }

    /** Get whether or not the current user is an admin */
    private get isAdmin(): boolean {
        return this.editorPluginState?.isAdmin?.() ?? false;
    }

    /** Get the base language id of the current editing lesson */
    private get baseLanguageId(): number | undefined {
        return this.editorPluginState?.baseLanguageId?.();
    }

    /** Get the target language id of the current editing lesson */
    private get targetLanguageId(): number | undefined {
        return this.editorPluginState?.targetLanguageId?.();
    }
}
