import { Editor, EditorCommandSet, FloatingMenuData, IsActiveChecker, MenuDisplayData } from "@educationperfect/tiptap";
import { Prop, Vue } from "vue-property-decorator";
import { Dictionary } from "@educationperfect/ep-web-services/lib/src/interfaces/Dictionary";

export class BaseFloatingToolbar<C extends EditorCommandSet> extends Vue {
    @Prop() public readonly data!: FloatingMenuData;
    @Prop() public readonly editor!: Editor;

    protected get menu(): MenuDisplayData {
        return this.data.menu;
    }
    protected get commands(): C {
        return this.data.commands as C;
    }
    protected get isActive(): IsActiveChecker {
        return this.data.isActive;
    }
    protected get focused(): boolean {
        return this.data.focused;
    }
    protected get focus(): () => void {
        return this.data.focus;
    }
    protected get getMarkAttrs(): (markName: string) => { [attributeName: string]: any } {
        return this.data.getMarkAttrs;
    }

    /** The list of nodes that should disable the popover */
    protected disallowedNodes: Dictionary<boolean> = {
        heading: true,
        formula: true,
        sound: true,
        image: true,
        link: true,
        fitg: true,
        annotation: true,
        highlight: true,
    };
}
