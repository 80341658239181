import { StringUtil } from "@educationperfect/ep-web-utils";
import { Editor } from "@educationperfect/tiptap";
import { Mark, Node as ProsemirrorNode } from "prosemirror-model";
import { TableMap } from "prosemirror-tables";

import { AnnotationNode } from "../extensions/annotations/AnnotationNode";
import { TextColor } from "../extensions/color/textColor/TextColor";
import { TextHighlight } from "../extensions/color/textHighlight/TextHighlight";
import { FillInTheGapsNode } from "../extensions/fitg/FillInTheGapsNode";
import { Formula } from "../extensions/formula/Formula";
import { HighlightNode } from "../extensions/highlight/HighlightNode";
import { ImageNode } from "../extensions/image/ImageNode";
import { DropDownNode } from "../extensions/inlineBuildable/dropDown/DropDownNode";
import { NumberBoxNode } from "../extensions/inlineBuildable/inputBox/numberBox/NumberBoxNode";
import { TextBoxNode } from "../extensions/inlineBuildable/inputBox/textBox/TextBoxNode";
import { EPLink } from "../extensions/link/EPLink";
import { Subscript } from "../extensions/script/Subscript";
import { Superscript } from "../extensions/script/Superscript";
import { SoundNode } from "../extensions/sound/SoundNode";
import { EPTableMap } from "../extensions/table/EPTableMap";
import { VerticalAlignments } from "../extensions/table/VerticalAlignments";
import { TextAlignments } from "../extensions/textAlign/enums/TextAlignments";
import { TextAlign } from "../extensions/textAlign/TextAlign";
import { ColorUtils } from "../utils/ColorUtils";
import { ExtensionNames } from "../utils/ExtensionNames";
import { MarkdownSerializer, MarkdownSerializerState } from "./libs/prosemirror-markdown/MarkdownSerializer";

// build regex for 'escapeUglee' function
const doubleSlashButNotUrl: RegExp = /(?:^|[^:])\/\//;
const simpleEscapes: RegExp = /\[|\]|`|__|\*\*|~~|\u208d|\u208e|\u207d|\u207e/;
const hexDigit: RegExp = /[0-9a-fA-F]/;
const threeHexDigit: RegExp = new RegExp(`${hexDigit.source}{3}`);
const colorCode: RegExp = new RegExp(`(?:${threeHexDigit.source}(?:${threeHexDigit.source})?|[0-9])`);
const escapeUgleeRegex: RegExp = new RegExp(
    `${simpleEscapes.source}|${doubleSlashButNotUrl.source}|(?:##(?:${colorCode.source}[#])?)|(?:@@(?:${colorCode.source}[@])?)`,
    "g"
);

type NodeConfigFn = (
    state: MarkdownSerializerState,
    node: ProsemirrorNode,
    parent: ProsemirrorNode,
    index: number
) => void;
type MarkConfigCallback = (
    state: MarkdownSerializerState,
    mark: Mark,
    parent: ProsemirrorNode,
    index: number
) => string;
export class TemplateSerializer {
    private serializer!: MarkdownSerializer;

    /**
     * Each gap will be given an id for cross-referencing with the gap data object. As a convention each gap will be
     * given an id based on the order it appeared in the document, starting from 0.
     */
    private gapIndex!: number;

    constructor() {
        this.serializer = new MarkdownSerializer(this.getNodeConfigurations(), this.getMarkConfigurations());
    }

    public serialize(editor: Editor): string {
        try {
            this.gapIndex = 0;
            return this.serializer.serialize(editor.state.doc);
        } catch (error) {
            // tslint:disable-next-line: no-console
            console.warn("Cannot parse editor content");
            return "";
        }
    }

    private getNodeConfigurations(): { [nodeName: string]: NodeConfigFn } {
        return {
            [ExtensionNames.paragraph]: this.serializeParagraph.bind(this),
            [ExtensionNames.text]: this.serializeText.bind(this),
            [ExtensionNames.heading]: this.serializeHeading,
            [ExtensionNames.hardbreak]: this.serializeHardBreak,
            [ExtensionNames.orderedList]: this.serializeOrderedList,
            [ExtensionNames.bulletList]: this.serializeBulletList,
            [ExtensionNames.listItem]: this.serializeListItem,
            [ExtensionNames.table]: this.serializeTable,
            [ExtensionNames.tableHeader]: this.serializeTableCellOrHeader,
            [ExtensionNames.tableRow]: this.serializeTableRow,
            [ExtensionNames.tableCell]: this.serializeTableCellOrHeader,
            [ExtensionNames.tableCell]: this.serializeTableCellOrHeader,
            [Formula.NODE_NAME]: this.serializeFormula,
            [FillInTheGapsNode.NODE_NAME]: this.serializeFITG.bind(this),
            [AnnotationNode.NODE_NAME]: this.serializeAnnotated.bind(this),
            [HighlightNode.NODE_NAME]: this.serializeHighlights.bind(this),
            [DropDownNode.NODE_NAME]: this.serializeDropdown.bind(this),
            [ImageNode.NODE_NAME]: this.serializeImage.bind(this),
            [SoundNode.NODE_NAME]: this.serializeAudio.bind(this),
            [NumberBoxNode.NODE_NAME]: this.serializeNumberBox.bind(this),
            [TextBoxNode.NODE_NAME]: this.serializeTextBox.bind(this),
        };
    }

    private getMarkConfigurations(): { [markName: string]: MarkConfig } {
        return {
            [ExtensionNames.bold]: this.serializeBold,
            [ExtensionNames.italic]: this.serializeItalic,
            [ExtensionNames.underline]: this.serializeUnderline,
            [ExtensionNames.strike]: this.serializeStrikethrough,
            [Subscript.MARK_NAME]: this.serializeSubscript,
            [Superscript.MARK_NAME]: this.serializeSuperscript,
            [TextColor.MARK_NAME]: this.serializeTextColor,
            [TextHighlight.MARK_NAME]: this.serializeTextHighlight,
            [EPLink.MARK_NAME]: this.seralizeLink,
        };
    }

    // =========================================================================
    // Serialization functions
    // =========================================================================

    private serializeTableCellOrHeader(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const {
            align,
            colwidth,
            colspan,
            rowspan,
            padding,
            background,
            showBorderOverride,
            verticalAlign,
        }: {
            align: TextAlignments;
            colwidth: number;
            colspan: number;
            rowspan: number;
            padding: number;
            background: string;
            showBorderOverride: boolean;
            verticalAlign: VerticalAlignments;
        } = node.attrs as any;

        if (index !== 0) {
            state.write("||");
        }

        // Add cell attributes

        if (align) {
            state.write(`align="${align}"`);
        }

        if (colwidth) {
            state.write(` width="${colwidth}"`);
        }

        if (padding != undefined) {
            state.write(` padding="${padding}"`);
        }

        if (background) {
            state.write(` background="${background}"`);
        }

        if (showBorderOverride) {
            state.write(` border="on"`);
        }

        if (verticalAlign) {
            state.write(` valign="${verticalAlign}"`);
        }

        if (colspan) {
            state.write(` colspan="${colspan}"`);
        }

        if (rowspan) {
            state.write(` rowspan="${rowspan}"`);
        }

        state.write("|");

        // Cells with empty content can cause the table to break
        if (!node.firstChild || (node.firstChild.content.size === 0 && node.textContent == "")) {
            state.write(" ");
        } else {
            state.renderInline(node);
        }
    }

    private serializeTableRow(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { header }: { header: boolean } = node.attrs as any;

        state.ensureNewLine();
        state.write(`=\n${header ? "!" : "|"}`);
        state.renderInline(node);
    }

    private serializeTable(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { showBorders, align }: { showBorders: boolean; align: TextAlignments } = node.attrs as any;
        const { pixelWidth } = TableMap.get(node) as EPTableMap;

        if (align) {
            state.write(`[block align="${align}"`);
            state.ensureNewLine();
        }

        state.write("[table");
        state.write(` border="${showBorders ? "yes" : "no"}"`);

        if (pixelWidth) {
            state.write(` width="${pixelWidth}"`);
        }

        state.renderInline(node);
        state.write("\n]");

        if (align) {
            state.write("\n]");
        }

        state.closeBlock(node);
    }

    private serializeAnnotated(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { text, color } = node.attrs;
        const gapTemplate: string = `[${this.gapIndex}: ${text}:${color}]`;
        state.write(gapTemplate);
        this.gapIndex++;
    }

    private serializeHighlights(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { text, correct } = node.attrs;
        const gapTemplate: string = `[hl ${this.gapIndex}:${text}:${correct}]`;
        state.write(gapTemplate);
        this.gapIndex++;
    }

    private serializeDropdown(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { id } = node.attrs;
        const dropdownTemplate: string = `[${DropDownNode.NODE_NAME} id=${id}]`;
        state.write(dropdownTemplate);
    }

    private serializeNumberBox(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { id } = node.attrs;
        const numberBoxTemplate: string = `[${NumberBoxNode.NODE_NAME} id=${id}]`;
        state.write(numberBoxTemplate);
    }

    private serializeTextBox(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { id } = node.attrs;
        const numberBoxTemplate: string = `[${TextBoxNode.NODE_NAME} id=${id}]`;
        state.write(numberBoxTemplate);
    }

    private serializeAudio(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { url, title, compact, autoPlay, playLimit, canPause } = node.attrs;

        let gapTemplate: string = `[sound url="${url}"`;

        if (title != null && title != "") {
            gapTemplate += ` title="${title}"`;
        }
        if (compact != null) {
            gapTemplate += ` compact="${compact}"`;
        }
        if (autoPlay != null) {
            gapTemplate += ` auto-play="${autoPlay}"`;
        }
        if (playLimit != null) {
            gapTemplate += ` play-limit="${playLimit}"`;
        }
        if (canPause != null) {
            gapTemplate += ` can-pause="${canPause}"`;
        }

        gapTemplate += "]";

        state.write(gapTemplate);
    }

    private serializeFITG(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { text } = node.attrs;
        const gapTemplate: string = `[${this.gapIndex}: ${text}]`;
        state.write(gapTemplate);
        this.gapIndex++;
    }

    private serializeFormula(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { latex } = node.attrs;
        state.write(`\`\`\`${latex}\`\`\``);
    }

    private serializeListItem(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        state.renderInline(node);
    }

    private serializeBulletList(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        state.options = { tightLists: true };

        const isNestedList: boolean = parent.type.name === ExtensionNames.listItem;
        if (isNestedList) {
            state.ensureNewLine();
            state.renderList(node, " ", () => `${node.attrs.bullet || "*"} `);
        } else {
            const alignment: TextAlign = node.attrs.align;
            state.write(`[block align="${alignment}"\n`);
            state.renderList(node, "  ", () => `${node.attrs.bullet || " *"} `);
            state.write("]");
            state.closeBlock(node);
        }
    }

    private serializeOrderedList(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        state.options = { tightLists: true };

        const isNestedList: boolean = parent.type.name === ExtensionNames.listItem;
        const start = node.attrs.order || 1;
        let maxW: number;
        let space: string = "";

        if (isNestedList) {
            maxW = String(start + node.childCount - 1).length;
            space = state.repeat(" ", maxW);
            state.ensureNewLine();
            state.renderList(node, space, (i) => {
                const nStr = String(start + i);
                const result = `${state.repeat(" ", maxW - 1) + nStr}. `;
                return result;
            });
        } else {
            maxW = String(start + node.childCount - 1).length + 1;
            space = state.repeat(" ", maxW); // state.repeat(" ", maxW + 2);

            const alignment: TextAlign = node.attrs.align;
            state.write(`[block align="${alignment}"\n`);

            state.renderList(node, space, (i) => {
                const nStr = String(start + i);
                const result = `${state.repeat(" ", maxW - 1) + nStr}. `;
                return result;
            });
            state.write("]");
            state.closeBlock(node);
        }
    }

    /**
     * Wrap each paragraph in a block to allow setting alignment at a paragraph level
     */
    private serializeParagraph(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        if ([ExtensionNames.tableCell, ExtensionNames.tableHeader].includes(parent.type.name)) {
            if (index > 0) {
                state.text("\\n", false);
            }
            state.renderInline(node);
            return;
        }

        // We don't want to render list items as blocks
        if (parent.type.name === ExtensionNames.listItem) {
            state.renderInline(node);
            return;
        }

        // Don't output anything if the paragraph is empty
        if (node.textContent === "" && node.childCount === 0) {
            if (node.attrs.trailingNodeId) {
                return;
            }

            if (parent.type.name === "doc" && parent.lastChild === node) {
                const alignment: TextAlignments = node.attrs.align ? node.attrs.align : TextAlignments.LEFT;
                state.write(`[block align="${alignment}"\n\n]`);

                return;
            }
            state.ensureNewLine();
            state.write("\n");
            return;
        }

        const alignment: TextAlignments = node.attrs.align ? node.attrs.align : TextAlignments.LEFT;
        state.write(`[block align="${alignment}"\n`);
        state.renderInline(node);
        state.write("\n]");

        state.closeBlock(node);
    }

    private serializeText(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        let text = node.text as string;

        // The Uglee template uses specific characters to tokenise elements so we need to escape these charaters before storing to the template.
        // - Component block [component]
        // - Formula `1+1=2` or ```1+1=2```
        const rawText = node.text as string;
        text = this.escapeUglee(rawText);

        state.text(text, false);
    }

    /**
     * Escapes UGLEE text so that it isn't converted into styling by adding a backslash. The following will be escaped
     * with a backslash:
     * * `//` (unless preceded by : as otherwise links like http://www.google.com become http:\//www.google.com)
     * * `[`
     * * `]`
     * * `
     * * `__`
     * * `**`
     * * `~~`
     * * open text highlight tags (e.g. `##1#`, `##eee#`, `##00ff00#`)
     * * open colour tags (e.g. `@@1@`, `@@eee@`, `@@00ff00@`)
     * * superscript parenthesis
     * * subscript parenthesis
     * @param text The text to update
     */
    private escapeUglee(text: string): string {
        escapeUgleeRegex.lastIndex = 0; // regexs with global flag are stateful, so we need to clear the lastIndex before we use it
        return text.replace(escapeUgleeRegex, (substring) => `\\${substring}`);
    }

    private serializeHeading(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        if ([ExtensionNames.tableCell, ExtensionNames.tableHeader].includes(parent.type.name)) {
            if (index > 0) {
                state.text("\\n", false);
            }
            state.write(`${state.repeat("#", node.attrs.level)} `);
            state.renderInline(node);
            return;
        }

        // We don't want to render list items as blocks
        if (parent.type.name === ExtensionNames.listItem) {
            state.write(`${state.repeat("#", node.attrs.level)} `);
            state.renderInline(node);
            return;
        }

        // Don't output anything if the paragraph is empty
        if (node.textContent === "" && node.childCount === 0) {
            state.ensureNewLine();
            state.write("\n");
            return;
        }

        const alignment: TextAlignments = node.attrs.align ? node.attrs.align : TextAlignments.CENTRE;
        state.write(`[block align="${alignment}"\n`);
        state.write(`${state.repeat("#", node.attrs.level)} `);
        state.renderInline(node);
        state.write("\n]");
        state.closeBlock(node);
    }

    private serializeHardBreak(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        state.text("\\n", false);
    }

    private serializeItalic: MarkConfig = {
        open: "//",
        close: "//",
    };

    private serializeBold: MarkConfig = {
        open: "**",
        close: "**",
    };

    private serializeUnderline: MarkConfig = {
        open: "__",
        close: "__",
    };

    private serializeStrikethrough: MarkConfig = {
        open: "~~",
        close: "~~",
    };

    private serializeSubscript: MarkConfig = {
        open: "₍",
        close: "₎",
    };

    private serializeSuperscript: MarkConfig = {
        open: "⁽",
        close: "⁾",
    };

    private serializeTextColor: MarkConfig = {
        open(state, mark, parent, index) {
            const colorPart: string = ColorUtils.ensureColorPartFormat(mark.attrs.color);

            // do not serialize default document colour
            if (colorPart == "000000") {
                return "";
            }
            return `@@${colorPart}@`;
        },
        close(state, mark, parent, index) {
            const colorPart: string = ColorUtils.ensureColorPartFormat(mark.attrs.color);

            // do not serialize default document colour
            if (colorPart == "000000") {
                return "";
            }
            return `@@`;
        },
    };

    private serializeTextHighlight: MarkConfig = {
        open(state, mark, parent, index) {
            const colorPart: string = ColorUtils.ensureColorPartFormat(mark.attrs.highlightColor, true);

            // do not serialize default background colour
            if (colorPart == "transparent") {
                return "";
            }
            return `##${colorPart}#`;
        },
        close(state, mark, parent, index) {
            const colorPart: string = ColorUtils.ensureColorPartFormat(mark.attrs.highlightColor, true);

            // do not serialize default background colour
            if (colorPart == "transparent") {
                return "";
            }
            return `##`;
        },
    };

    private seralizeLink: MarkConfig = {
        open(state, mark, parent, index) {
            const { href } = mark.attrs;
            if (!href) {
                return "";
            }

            return `[link label="`;
        },
        close(state, mark, parent, index) {
            const { href } = mark.attrs;
            if (!href) {
                return "";
            }

            return `" url="${href}"]`;
        },
    };

    private serializeImage(
        state: MarkdownSerializerState,
        node: ProsemirrorNode,
        parent: ProsemirrorNode,
        index: number
    ): void {
        const { src, width, height, alt } = node.attrs;
        let imgTextTemplate: string = `[image`;
        if (!StringUtil.isNullOrWhitespace(src)) {
            imgTextTemplate += ` url="${src}"`;
        }
        if (width != null) {
            imgTextTemplate += ` width="${width}"`;
        }
        if (height != null) {
            imgTextTemplate += ` height="${height}"`;
        }
        if (!StringUtil.isNullOrWhitespace(alt)) {
            imgTextTemplate += ` title="${alt}"`;
        }
        imgTextTemplate += "]";
        state.write(imgTextTemplate);
    }
}

interface MarkConfig {
    open?: string | MarkConfigCallback;
    close?: string | MarkConfigCallback;
    mixable?: boolean;
    escape?: boolean;
    expelEnclosingWhitespace?: boolean;
}
