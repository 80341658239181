import template from "./color-picker-dialog.html";

import "./color-picker-dialog.less";

import { EdsPrimaryButton, EdsSecondaryButton } from "@educationperfect/ep-web-ui-components";
import { ColorJoe, rgb } from "colorjoe";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ColorUtils } from "../../utils/ColorUtils";

/** Emit for closing dialog */
const ON_DIALOG_EXIT: string = "on-exit";

/** Emit for closing dialog */
const ON_COLOR_ADDED: string = "on-color-added";

/** The default color for the picker. This was chosen for it's nice offset from the top right */
const DEFAULT_COLOR: string = "#f3290c";

/** The DOM ID for the color picker. Must be an id as that is what ColorJoe expects */
const COLOR_JOE_PICKER_ID: string = "color-joe-picker";

@Component({
    template,
    components: {
        EdsPrimaryButton,
        EdsSecondaryButton
    }
})
export class ColorPickerDialog extends Vue {
    // Variables
    // ============================================

    // Props

    /** Whether or not the dialog is visible */
    @Prop() private readonly visible: boolean | undefined;

    /** The hex color that the color picker is initiated with */
    @Prop() private readonly startingColor: string | undefined;

    // Misc

    /** The color picker instance */
    private colorPicker: ColorJoe.Picker | null = null;

    /** The selected hex color in the color picker */
    private selectedColor: string = DEFAULT_COLOR;

    /** Get a unique id for the color joe picker */
    private uniqueID: string = `${COLOR_JOE_PICKER_ID}-${this.generateRandomID()}`;

    // Lifecycle Hooks
    // ============================================

    /**
     * Lifecycle Hook:
     * Called when the component first gets mounted to the
     * DOM
     */
    private mounted(): void {
        this.colorPicker = rgb(this.uniqueID, DEFAULT_COLOR, []);
        if (!this.colorPicker) {
            return;
        }

        this.colorPicker.on("change", (color: ColorJoe.Color) => (this.selectedColor = color.hex()));
    }

    // Logic
    // ============================================

    @Watch("startingColor")
    private onStartingColorChanged(newColor: string, oldColor: string): void {
        if (newColor === oldColor) {
            return;
        }
        this.selectedColor = newColor;

        if (this.colorPicker) {
            this.colorPicker.set(this.selectedColor);
        }
    }

    /**
     * Check that passed in string is a valid hex code
     *
     * @param hex the hex string to validate
     */
    private validateHEXCode(hex: string | null): boolean {
        if (!hex || (hex && hex == "")) {
            return false;
        }

        return ColorUtils.HEX_REGEX.test(hex);
    }

    /** Generate a random id to be used to make unique instances */
    private generateRandomID(): string {
        return Math.random().toString(36).substring(7);
    }

    // DOM Events
    // ============================================

    /**
     * DOM Click:
     * Called when the "Cancel" button is clicked.
     * Close the dialog.
     */
    private onCancelClick(): void {
        this.$emit(ON_DIALOG_EXIT);
    }

    /**
     * DOM Click:
     * Called when the "Add Color" button is clicked.
     * Close the dialog and send the color to parent
     */
    private onAddColorClick(): void {
        this.$emit(ON_COLOR_ADDED, this.selectedColor.toLowerCase());
    }

    /**
     * DOM Emit:
     * Called when the user loses focus on the hex input field
     * or user presses enter with textfield focused. Validate entered
     * hex is valid and change if so, otherwise revert to previous good
     * selection.
     *
     * @param event the blur event
     */
    private onHexInputBlur(event: FocusEvent): void {
        if (!event || !event.target || !this.colorPicker) {
            return;
        }

        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        if (!this.validateHEXCode(inputElement.value)) {
            inputElement.value = this.selectedColor;
            return;
        }

        this.selectedColor = inputElement.value;
        this.colorPicker.set(this.selectedColor);
        inputElement.blur();
    }

    // Gets
    // ============================================
}
