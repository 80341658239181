import { CommandGetter, Extension } from "@educationperfect/tiptap";
import { CommandFunction } from "@educationperfect/tiptap-commands";
import { MarkSpec, NodeSpec, NodeType, Schema } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { RTLCommands } from "../paragraph/RTLCommands";
import { TextAlignments } from "../textAlign/enums/TextAlignments";

export default class RTLMode extends Extension {
    // Variables
    // ===============================================

    // Static
    public static readonly NAME = "RTLMode";
    public static readonly RTLPluginKey: PluginKey = new PluginKey("RTLMode");

    public static readonly ALLOWED_ALIGNMENT_CHANGE: Set<string> = new Set([
        ExtensionNames.paragraph,
        ExtensionNames.orderedList,
        ExtensionNames.bulletList,
        ExtensionNames.tableCell,
    ]);

    // Node Setup
    // ===============================================

    get name(): string {
        return RTLMode.NAME;
    }

    public commands({
        type,
        schema,
        attrs,
    }: {
        type: NodeType;
        schema: Schema | NodeSpec | MarkSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        return () => RTLCommands.toggleRTLMode(type);
    }

    public keys({ type, schema }: { type: NodeType; schema: NodeSpec }): { [keyCombo: string]: CommandFunction } {
        return {
            "Mod-Shift-`": RTLCommands.toggleRTLMode(type),
        };
    }

    public get plugins(): Plugin[] {
        return [
            new Plugin<RTLPluginState>({
                key: RTLMode.RTLPluginKey,
                state: {
                    init: (config, state: EditorState) => {
                        return { RTLMode: false, defaultTextAlign: TextAlignments.LEFT };
                    },
                    apply: (tr, pluginState, oldState, newState) => {
                        return pluginState;
                    },
                },
            }),
        ];
    }
}

export interface RTLPluginState {
    RTLMode: boolean;
    defaultTextAlign: TextAlignments;
}
