import { InlineBuildableNode } from "../InlineBuildableNode";
import { DropDownView } from "./view/DropDownView";

export class DropDownNode extends InlineBuildableNode {
    // Variables
    // =======================================

    /** The name of the node */
    public static readonly NODE_NAME = "dropdown";

    // Overrides
    // =======================================

    /** [Override] Get the name of the extension */
    public get name(): string {
        return DropDownNode.NODE_NAME;
    }

    /** [Override] Get the view for the dropdown */
    public get view(): typeof DropDownView {
        return DropDownView;
    }
}
