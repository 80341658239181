import { Dictionary } from "@educationperfect/ep-web-utils";

import { TemplateFormat } from "../render/TemplateFormat";
import { IDOMComputedStyle } from "./IDOMComputedStyle";
import { SizingModels } from "./SizingModels";

export class DOMComputedStyle implements IDOMComputedStyle {
    public static PIXELS_WIDE: number = 906;
    public static PIXELS_NORMAL: number = 700;
    public static PIXELS_NARROW: number = 500;
    public static LARGE_MATH_FONT: number = 30;
    public static SMALL_MATH_FONT: number = 14;
    public static MEDIUM_MATH_FONT: number = 20;

    public static WIDTH_NARROW: string = "narrow";
    public static WIDTH_NORMAL: string = "normal";
    public static WIDTH_WIDE: string = "wide";
    public static FONT_SIZE_SMALL: string = "small";
    public static FONT_SIZE_MEDIUM: string = "medium";
    public static FONT_SIZE_LARGE: string = "large";
    public static ALIGN_LEFT: string = "left";
    public static ALIGN_CENTER: string = "center";
    public static ALIGN_RIGHT: string = "right";

    public readonly format: Readonly<TemplateFormat>;
    public containerWidth!: number;
    public alignment!: string;
    public mathFontSize!: number;
    public widthSizingModel!: SizingModels;

    constructor(format: Partial<TemplateFormat> | null, widthSizingModel: SizingModels = SizingModels.MAXIMUM) {
        this.format = Object.freeze({
            Alignment: (format && format.Alignment) || DOMComputedStyle.ALIGN_CENTER,
            FontSize: (format && format.FontSize) || DOMComputedStyle.FONT_SIZE_MEDIUM,
            Width: (format && format.Width) || DOMComputedStyle.WIDTH_NORMAL,
        });
        this.loadFormat(this.format, widthSizingModel);
    }

    public clone(): DOMComputedStyle {
        return new DOMComputedStyle(this.format, this.widthSizingModel);
    }

    public get defaultParameters(): Dictionary<string> {
        return {
            "math-font-size": String(this.mathFontSize),
            alignment: this.alignment,
        };
    }

    private loadFormat(format: TemplateFormat, widthSizingModel: SizingModels): void {
        this.widthSizingModel = widthSizingModel || SizingModels.FIXED;

        // alignment
        switch (format.Alignment) {
            case DOMComputedStyle.ALIGN_LEFT:
            case DOMComputedStyle.ALIGN_RIGHT:
                this.alignment = format.Alignment;
                break;

            case DOMComputedStyle.ALIGN_CENTER:
            default:
                this.alignment = DOMComputedStyle.ALIGN_CENTER;
                break;
        }

        // font-size
        switch (format.FontSize) {
            case DOMComputedStyle.FONT_SIZE_LARGE:
                this.mathFontSize = DOMComputedStyle.LARGE_MATH_FONT;
                break;

            case DOMComputedStyle.FONT_SIZE_SMALL:
                this.mathFontSize = DOMComputedStyle.SMALL_MATH_FONT;
                break;

            case DOMComputedStyle.FONT_SIZE_MEDIUM:
            default:
                this.mathFontSize = DOMComputedStyle.MEDIUM_MATH_FONT;
                break;
        }

        // content width
        if (!isNaN(parseInt(format.Width))) {
            this.containerWidth = parseInt(format.Width);
        }
    }
}
