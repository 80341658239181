import { EventOutlet } from "@educationperfect/ep-web-utils";

import { IEditorMediaBus } from "./interfaces/IEditorMediaBus";

export class EditorMediaBus {
    public static readonly openMediaGallery: EventOutlet<IEditorMediaBus.IOpenMediaGallery> = new EventOutlet<
        IEditorMediaBus.IOpenMediaGallery
    >("openMediaGallery");
    public static readonly openMediaUploader: EventOutlet<IEditorMediaBus.IOpenMediaUploader> = new EventOutlet<
        IEditorMediaBus.IOpenMediaUploader
    >("openMediaUploader");
    public static readonly openSoundRecorder: EventOutlet<IEditorMediaBus.IOpenSoundRecorder> = new EventOutlet<
        IEditorMediaBus.IOpenSoundRecorder
    >("openSoundRecorder");
}
