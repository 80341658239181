import { Extension } from "@educationperfect/tiptap";

import AiAnnotationPlugin from "./AiAnnotationPlugin";

/**
 * A TipTap extension to add support for displaying annotations provided by
 * AI generated feedback.(Not to be mistaken with the {@link Annotation} extension,
 * which is used for support Fill-in-the-gap components)
 *
 * # How it works
 *
 * There are two parts of the annotations:
 * 1. The {@link AiAnnotationPlugin}
 *
 * 2. The {@link AiAnnotationUi}
 *
 *    Responsible for styling the annotations visually and displaying the
 *    comment popper when an annotation is interactive with.
 *
 *
 *
 */
export default class AiAnnotation extends Extension {
    get name() {
        return "aiAnnotation";
    }

    get plugins() {
        return [AiAnnotationPlugin];
    }
}
