import { IAnnotationColor } from "./interfaces/IAnnotationColor";

export const AnnotationColorDefinitions: IAnnotationColor[] = [
    { light: "#FAEFC8", dark: "#DEB314" },
    { light: "#FFEAFB", dark: "#C954B3" },
    { light: "#E4F5C8", dark: "#70AA13" },
    { light: "#DCE0FC", dark: "#2F44CC" },
    { light: "#CEEFE3", dark: "#10A26C" },
    { light: "#DAF8F6", dark: "#20B2AB" },
    { light: "#EEE4FC", dark: "#5E24B4" },
    { light: "#F6DBC2", dark: "#CE7B2D" },
    { light: "#CAE8FA", dark: "#0C85D0" },
];
