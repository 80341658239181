import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { DOMSerializer, Node as ProsemirrorNode, NodeType, ResolvedPos } from "prosemirror-model";
import {
    EditorState,
    NodeSelection,
    Plugin,
    PluginKey,
    Selection,
    TextSelection,
    Transaction,
} from "prosemirror-state";
import { setTextSelection } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";

import { ExtensionNames } from "./ExtensionNames";

export class ProsemirrorUtils {
    // =========================================================================
    // Public functions
    // =========================================================================

    public static registerPlugin(state: EditorState, view: EditorView, plugin: Plugin): EditorState {
        const newState: EditorState = state.reconfigure({
            plugins: state.plugins.concat([plugin]),
        });

        view.updateState(newState);
        return newState;
    }

    /**
     * Set the selection to the immediate left or right position of the selected Node.
     * If a node is not selected there will be no action taken.
     *
     * This is useful for seemless navigation between the editor's text and inline nodes
     * with editable content.
     * @param exitLeft: boolean - true if exiting to the left of the node and vice versa.
     */
    public static exitNode(exitLeft: boolean, view: EditorView): void {
        const { tr, selection } = view.state;

        if (!(selection instanceof NodeSelection)) {
            return;
        }

        const currentPosition: number = selection.$anchor.pos;

        if (exitLeft) {
            setTextSelection(currentPosition, -1)(tr);
        } else {
            setTextSelection(currentPosition + 1, 1)(tr);
        }

        view.focus();
        view.dispatch(tr);
    }

    /**
     * If the cursor is in a word, returns a TextSelection that contains the whole word.
     */
    public static getWordSelectionFromCursor(selection: Selection, state: EditorState): TextSelection | null {
        // We are only interested in cursor selection
        if (selection.empty) {
            const boundaryOffsets = ProsemirrorUtils.findWordBoundaryForCursorSelection(selection);
            const { $from } = selection;

            if (boundaryOffsets) {
                const startOfWord: ResolvedPos = state.doc.resolve($from.pos - boundaryOffsets.startOffset);
                const endOfWord: ResolvedPos = state.doc.resolve($from.pos + boundaryOffsets.endOffset);
                return new TextSelection(startOfWord, endOfWord);
            }
        }

        return null;
    }

    /**
     * Output html from the state
     */
    public static toHTML(state: EditorState): string {
        const div = document.createElement("div");
        const fragment = DOMSerializer.fromSchema(state.schema).serializeFragment(state.doc.content);

        div.appendChild(fragment);

        return div.innerHTML;
    }

    /*
     * Update an existing attribute by creating a new object to ensure the
     * dom is updated.
     */
    public static setAttribute(attrs: any, name: string, value: any): any {
        const result = {};
        for (const prop in attrs) {
            if (prop) {
                result[prop] = attrs[prop];
                result[name] = value;
            }
        }
        return result;
    }

    /**
     * Add a style to a Dom Attribute object
     *
     * @param attrs - A node attribute object
     * @param styleName - The name of the style to be added
     * @param value - The value of the style
     */
    public static addStyleToDomAttribute(attrs: any, styleName: string, value: any): void {
        let styleString: string | undefined = attrs.style;

        if (!styleString) {
            styleString = "";
        }

        styleString += `${styleName}: ${value};`;
        attrs.style = styleString;
    }

    /**
     * Finds if a style exists in a string and returns the value
     *
     * @param styleString - The style string
     * @param styleName - The name of the style to be found
     */
    public static getStyleFromStyleString(styleString: string | null, styleName: string): string | null {
        if (!styleString) {
            return null;
        }

        const index: number = styleString.indexOf(styleName);
        const styleNameLength = styleName.length;
        let styleValue: string | null = null;

        if (index !== -1) {
            styleValue = styleString.substring(index + styleNameLength + 1);
            styleValue = styleValue.substr(0, styleValue.indexOf(";"));
            styleValue = styleValue.trim();
        }
        return styleValue;
    }

    /**
     * Returns a transaction that overrides the default behavior of Backspace/Delete if the next node matches the
     * specified node name. The node will be selected and the direction of the deletion will be stored in the
     * plugin state specified by the pluginKey. This allows the inline node to update its content accordingly.
     */
    public static handleDeleteIntoInlineNodeContent(
        state: EditorState,
        event: KeyboardEvent,
        nodeName: string,
        pluginKey?: PluginKey<InlineEditablePluginState>
    ): Transaction | null {
        const deleteBackward = event.which === Keyboard.BACKSPACE;
        const deleteForward = event.which === Keyboard.DELETE;
        if (deleteBackward || deleteForward) {
            const { selection } = state;
            const isCursorSelection: boolean = selection.empty;

            if (!isCursorSelection) {
                return null;
            }

            const nextNode = deleteBackward ? selection.$anchor.nodeBefore : selection.$anchor.nodeAfter;
            if (nextNode instanceof ProsemirrorNode && nextNode.type.name === nodeName) {
                if (pluginKey) {
                    const pluginState: InlineEditablePluginState = pluginKey.getState(
                        state
                    ) as InlineEditablePluginState;

                    if (deleteBackward) {
                        pluginState.fromBackspace = true;
                    } else {
                        pluginState.fromDelete = true;
                    }
                }

                const transaction: Transaction = state.tr;
                const nodePosition: number = deleteBackward ? selection.anchor - nextNode.nodeSize : selection.anchor;
                const resolvedPos: ResolvedPos = transaction.doc.resolve(nodePosition);
                const nodeSelection: NodeSelection = new NodeSelection(resolvedPos);

                transaction.setSelection(nodeSelection);
                return transaction;
            }
        }

        return null;
    }

    /**
     * Returns a transaction that stores whether we are arrow-keying into a node, if the next node matches the
     * specified node name. The node will be selected and the direction of the arrow-keying will be stored in the
     * plugin state specified by the pluginKey. This allows the inline node to update its content accordingly.
     */
    public static handleArrowIntoInlineNodeContent(
        state: EditorState,
        event: KeyboardEvent,
        nodeName: string,
        pluginKey: PluginKey<InlineEditablePluginState>
    ): Transaction | null {
        const keyLeft = event.which === Keyboard.LEFT_ARROW;
        const keyRight = event.which === Keyboard.RIGHT_ARROW;
        if (keyLeft || keyRight) {
            const { selection } = state;
            const isCursorSelection: boolean = selection.empty;

            if (!isCursorSelection) {
                return null;
            }

            const nextNode = keyLeft ? selection.$anchor.nodeBefore : selection.$anchor.nodeAfter;
            // NOTE: remove the check to see if next node is an instance of "Node", as this was always returning false. Not sure why
            if (nextNode && nextNode.type.name === nodeName) {
                const pluginState: InlineEditablePluginState = pluginKey.getState(state) as InlineEditablePluginState;

                if (keyLeft) {
                    pluginState.fromLeftArrow = true;
                } else {
                    pluginState.fromRightArrow = true;
                }

                const transaction: Transaction = state.tr;
                const nodePosition: number = keyLeft ? selection.anchor - nextNode.nodeSize : selection.anchor;
                const resolvedPos: ResolvedPos = transaction.doc.resolve(nodePosition);
                const nodeSelection: NodeSelection = new NodeSelection(resolvedPos);

                transaction.setSelection(nodeSelection);
                return transaction;
            }
        }

        return null;
    }

    public static getNodeTypes(state: EditorState, nodeNames: string[]): NodeType[] | undefined {
        const nodeTypes: NodeType[] = [];
        nodeNames.forEach((node: string) => {
            const nodeType: NodeType | undefined = ProsemirrorUtils.getNodeType(state, node);
            if (nodeType) {
                nodeTypes.push(nodeType);
            }
        });

        if (nodeTypes.length > 0) {
            return nodeTypes;
        }
        return undefined;
    }

    public static getNodeType(state: EditorState, nodeName: string): NodeType | undefined {
        return state.schema.nodes[nodeName];
    }

    /**
     * Get text content from range
     *
     * @param view the view to extract text from
     * @param range the to/from to get text from
     */
    public static extractTextContentFromRange(view: EditorView, range: { from: number; to: number }): string {
        const { tr } = view.state;
        const anchor = tr.doc.resolve(range.from);
        const head = tr.doc.resolve(range.to);
        const selection: Selection = new Selection(anchor, head);
        const slice = selection.content();
        const fragment = slice.content;

        let selectionText = "";
        fragment.nodesBetween(0, fragment.size, (node) => {
            if (node.type.name === ExtensionNames.text) {
                selectionText += node.textContent;
            }
        });

        return selectionText;
    }

    /**
     * Handle stopping prosemirror from handling events related
     * to dragging
     *
     * @param event the event to check
     */
    public static handleDragNodeViewStopEvents(event: Event): boolean {
        const isDragging: boolean = ["mousedown", "dragstart", "dragenter", "dragover"].includes(event.type);
        if (isDragging) {
            return false;
        }

        return true;
    }

    // =========================================================================
    // Helper functions
    // =========================================================================

    private static findWordBoundaryForCursorSelection(
        selection: Selection
    ): { startOffset: number; endOffset: number } | null {
        if (!selection.empty) {
            // We are only interested in cursor selections
            return null;
        }

        let startOffset: number = 0;
        let endOffset: number = 0;

        const head: ResolvedPos = selection.$head;
        const { nodeBefore, nodeAfter } = head;

        startOffset = this.findDistanceToWordBounary(nodeBefore, -1);
        endOffset = this.findDistanceToWordBounary(nodeAfter, 1);

        return {
            startOffset,
            endOffset,
        };
    }

    private static findDistanceToWordBounary(textNode: ProsemirrorNode | null | undefined, direction: -1 | 1): number {
        if (textNode && textNode.isText && textNode.text) {
            const regex: RegExp = direction > 0 ? /^\S+/ : /\S+$/;
            const match: RegExpMatchArray | null = textNode.text.match(regex);

            if (match) {
                return match[0].length;
            }

            return 0;
        }

        return 0;
    }

    /** Used to override the default behaviour when clicking on a toolbar element, which be default will cause the editor to lose focus  */
    public static handleToolbarClickBlurEvent(event): boolean {
        /**
         * Needed to fix firefox issue where clicking inline toolbar buttons would cause the component to become deselected before the edit or delete handler was fired (so the buttons did nothing)
         * If related target has test id of 'keep-node-focus', then don't kill the focus, as we have clicked on the inline toolbar.
         */
        if (
            event.relatedTarget !== null &&
            (event.relatedTarget as HTMLElement).hasAttribute("data-testid") &&
            (event.relatedTarget as HTMLElement).getAttribute("data-testid") === "keep-node-focus"
        ) {
            return true;
        }

        /**
         * Needed to ensure that when aligning an inline image, that the editor doesn't lose focus.
         */
        if (
            event.relatedTarget !== null &&
            (event.relatedTarget as HTMLElement).hasAttribute("data-keep-link-popup-open") &&
            (event.relatedTarget as HTMLElement).getAttribute("data-keep-link-popup-open") === "true"
        ) {
            return true;
        }
        return false;
    }
}

export interface InlineEditablePluginState {
    fromBackspace?: boolean;
    fromDelete?: boolean;
    fromLeftArrow?: boolean;
    fromRightArrow?: boolean;
}
