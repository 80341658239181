import { ElementUtil } from "@educationperfect/ep-web-browser-utils";
import { Dictionary, StringUtil } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { RenderingMode } from "../../render/RenderingMode";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class InlineLinkComponent extends BaseEmbeddedComponent {
    // Setup
    // =======================================

    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    // Question Rendering
    // =======================================

    /**
     * [Override]
     * Create element for specific render contexts
     *
     * @param parameters the link paremeters
     */
    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        let visualElement: HTMLElement | null;

        switch (this.renderingParameters.mode) {
            case RenderingMode.MODE_ANSWER:
            case RenderingMode.MODE_EXAM_MARKING:
            case RenderingMode.MODE_IN_REVIEW:
            case RenderingMode.MODE_PREVIEW:
            case RenderingMode.MODE_QUESTION:
            default:
                visualElement = this.createQuestionVisualElement(parameters);
                break;
        }

        return visualElement!;
    }

    /**
     * Create visual element for render mode question.
     *
     * @param parameters the link parameters
     */
    private createQuestionVisualElement(parameters: Dictionary<string>): HTMLElement | null {
        if (!parameters || !parameters.label || parameters.url == null) {
            return null;
        }

        const url: string = parameters["url"].toString();
        const label: string = parameters["label"] !== undefined ? parameters["label"].toString() : url;

        const anochorTag: HTMLAnchorElement = ElementUtil.create("a", {});
        anochorTag.textContent = label;

        anochorTag.href = StringUtil.startsWithAny(url, ["http://", "https://"]) ? url : "http://" + url;
        anochorTag.target = "_blank";

        return anochorTag;
    }
}
