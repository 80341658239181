import { Node as ProseMirrorNode } from "prosemirror-model";
import { findTextNodes } from "prosemirror-utils";

import { AiAnnotation as AiAnnotationData } from "./AnnotationTypings";
import { FuzzyTextMatcher } from "./FuzzyTextMatcher";

type AnnotationWithPos = {
    id: string;
    from: number;
    to: number;
    data: AiAnnotationData;
};

/**
 * Class responsible for mapping annotations to positions of matched texts in the document.
 *
 * This is done by first creating a mapping of each character in the text
 * content to its position in the document. This mapping is then used to find
 * the positions of the annotations in the document
 */
export class AnnotationTextProcessor {
    /**
     * Mapping of the position of each character in the text content to its
     * position in the TipTap document.
     */
    private _textPositionMapping: number[] | undefined;

    private _textContent: string | undefined;

    /**
     * Call this whenever the document is changed to ensure the text position
     * mapping is kept in sync.
     * @param doc - The ProseMirrorNode representing the updated document.
     */
    public onContentChange(doc: ProseMirrorNode) {
        this.updateTextMapping(doc);
    }

    /**
     * Finds the positions of the annotations in the text content using the
     * {@link FuzzyTextMatcher}.
     *
     * The general strategy is to find the positions of the matching text of
     * each annotation in relation to the text content of the document. Once we
     * have the indices each matching text segments, we can use the
     * text-to-position mapping we created earlier to find the exact positions of each
     * annotated text in the document.
     *
     * @param annotationData - The annotations to be applied to the document.
     * @returns An array of annotation positions.
     * @throws Error if the text content has not been set yet. Make sure
     * updateTextMapping() is called first.
     * @throws Error if the text position does not exist in mapped document positions.
     */
    public async findAnnotationPositions(annotationData: AiAnnotationData[]): Promise<AnnotationWithPos[]> {
        if (this._textContent === undefined || this._textPositionMapping === undefined) {
            throw Error("You must call updateTextMapping() before calling findAnnotationPositions()");
        }

        if (this._textContent === "" || this._textPositionMapping.length === 0) {
            // Document is empty.
            return Promise.resolve([]);
        }

        const annotationPositions: AnnotationWithPos[] = [];

        const matcher = new FuzzyTextMatcher(this.textContent!);

        const promises = annotationData.map(async (data) => {
            // Find the positions of the matching text in the document.
            const textMatch = await matcher.findMatch(data.matchText);
            if (textMatch.score !== 0) {
                // Find the positions of the matching text in the document.
                const from = this._textPositionMapping![textMatch.startIndex];
                const to = this._textPositionMapping![textMatch.endIndex];

                if (from === undefined || to === undefined) {
                    throw Error("The text position does not exist in mapped document positions.");
                }

                annotationPositions.push({
                    id: data.id,
                    from,
                    to,
                    data,
                });
            }
        });

        await Promise.all(promises);
        matcher.destroy();

        return annotationPositions;
    }

    /**
     * Constructs the text content of the document from iterating through the
     * text nodes in the document and creates a mapping of each text character
     * to its position in the document.
     *
     * This is used for locating the position of the annotations later on.
     * @param doc - The ProseMirrorNode representing the document.
     */
    private updateTextMapping(doc: ProseMirrorNode) {
        if (!this._textPositionMapping) {
            this._textPositionMapping = [];
        }

        const textNodes = findTextNodes(doc, true);
        let currentTextContentPos = 0;

        // Iterate through the text nodes in the document.
        textNodes.forEach(({ node, pos }) => {
            // Incrementally build up the text content from each text node.
            this._textContent = (this._textContent ?? "") + node.textContent;

            // Create a mapping of each character in the text content to its position in the document.
            node.text?.split("").forEach((_, index) => {
                this._textPositionMapping![currentTextContentPos] = pos + index;
                currentTextContentPos++;
            });
        });
    }

    /**
     * Gets the current text content.
     * @returns The text content.
     */
    public get textContent() {
        return this._textContent;
    }
}
