var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShown,
            expression: "isShown"
          }
        ],
        ref: "rootElement",
        staticClass: "ai-annotation-ui",
        attrs: { "data-type": _vm.type }
      },
      [
        _vm.title
          ? _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "comment",
          domProps: { innerHTML: _vm._s(_vm.comment) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }