import { DOMComposition } from "./DOMComposition";
import { DOMComputedStyle } from "./DOMComputedStyle";

export class DOMFormatBlock extends DOMComposition {
    constructor(style: DOMComputedStyle, private pageStyle: DOMComputedStyle) {
        super(style);
    }

    public get alignment(): string {
        let result: string = "";
        if (this.style && this.style.alignment) {
            result = this.style.alignment;
        }
        return result;
    }
    protected createDiv(style: DOMComputedStyle): HTMLDivElement {
        let div = super.createDiv(style);

        // Set up horizontal margins (to ensure that this block ends up in the right place)
        switch (this.pageStyle.alignment) {
            case DOMComputedStyle.ALIGN_CENTER:
                div.style.marginLeft = "auto";
                div.style.marginRight = "auto";
                break;

            case DOMComputedStyle.ALIGN_LEFT:
                div.style.marginRight = "auto";
                break;

            case DOMComputedStyle.ALIGN_RIGHT:
                div.style.marginLeft = "auto";
                break;
        }

        return div;
    }
}
