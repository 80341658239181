import "./ep-link-popup.less";
import template from "./ep-link-popup.html";

import { OptionalCSSStyles } from "@educationperfect/ep-web-browser-utils";
import {
    EdsIconCopy,
    EdsIconDeletion,
    EdsIconEdit,
    EdsPrimaryButton,
    EdsSecondaryButton,
    EdsTertiaryButton,
} from "@educationperfect/ep-web-ui-components";
import { HTMLTextUtil, StringUtil } from "@educationperfect/ep-web-utils";
import { DispatchFn } from "@educationperfect/tiptap-commands";
import { getMarkAttrs } from "@educationperfect/tiptap-utils";
import { MarkType } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { Component, Vue } from "vue-property-decorator";

import { ToolbarIcon } from "../../../components/toolbarIcon/ToolbarIcon";
import { EPLink } from "../EPLink";
import { EPLinkCommands } from "../EPLinkCommands";
import { EPLinkData } from "../interfaces/EPLinkData";

/** The margin top value for how much the popover sits above test */
const MARGIN_VALUE: number = 8;

/** The padding of the popover */
const PADDING_VALUE: number = 16;

/**
 * The regex to validate URLs, originally https://www.regexpal.com/93652
 *
 * 18/07/20: Increased maximum length of top level domain to 63 characters (as specified in RFC 1034)
 */
const LINK_REGEX: RegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/;

@Component({
    components: {
        ToolbarIcon,
        EdsIconDeletion,
        EdsTertiaryButton,
        EdsIconEdit,
        EdsPrimaryButton,
        EdsIconCopy,
        EdsSecondaryButton
    },
    template,
})
export class EPLinkPopup extends Vue {
    // Variables
    // ========================================

    // Data
    // There properties are set in EPLink

    /* Prosemirror editor view for the current isntance */
    public view: EditorView | undefined;

    /** The range of the current selection */
    public range: { from: number; to: number } | undefined;

    // Bindables

    /** [Bound] the value of the text field */
    protected textValue: string = "";

    /** [Bound] the value of the link field */
    protected linkValue: string = "";

    /** Whether or not the popup is visible */
    public visible: boolean = false;

    /** The position styles for popover based on selecion */
    private positionStyles: OptionalCSSStyles = {};

    /** Whether or not the link is being created */
    private isCreating: boolean = false;

    // State

    /** The value of the text error is any */
    private textValueError: string | null = null;

    /** The value of the error link error if any */
    private linkValueError: string | null = null;

    /** Whether or not the state has explictly been set to editing */
    private explicitEditorMode: boolean = false;

    // Logic
    // ========================================

    /**
     * Validate all fields and show errors if any
     */
    protected isValid(): boolean {
        if (!this.isLinkValueValid) {
            this.linkValueError = "Please enter a valid URL.";
        }

        if (!this.isTextValueValid) {
            this.textValueError = "Please enter at least one character.";
        }

        return !this.textValueError && !this.linkValueError;
    }

    /**
     * Validate fields are correct then create link
     */
    private createLink(): void {
        if (!this.isValid()) {
            return;
        }

        if (!this.view) {
            return;
        }

        EPLinkCommands.updateLink(this.markType, this.textValue!, this.linkValue!)(
            this.view.state,
            this.view.dispatch as DispatchFn,
            this.view
        );

        this.explicitEditorMode = false;
        this.range = undefined;
        this.view.focus();
        this.close();
    }

    /**
     * Open the dialog and setup relevant
     * fields
     *
     * @param data the link data
     */
    public open(data: EPLinkData): void {
        this.visible = true;
        this.linkValue = data.link;
        this.textValue = data.text;
        this.range = data.range;
        this.view = data.view;

        if (!data.link) {
            this.isCreating = true;
        }

        Vue.nextTick(() => {
            if (this.$refs.linkField) {
                (this.$refs.linkField as HTMLInputElement).focus();
            }

            this.updatePosition();
        });

        // attch event listener for detecting the the window has resized and the dialog needs repositioned
        window.addEventListener("resize", this.updatePosition);

        // attach event listener for detecting clicks to close the link preview and edit popups
        document.body.addEventListener("click", this.closeLinkPopup);
    }

    /**
     * Close the dialog and cleanup any
     * fields
     */
    public close(): void {
        if (this.isCreating && !this.linkValue && this.view && this.range) {
            EPLinkCommands.removeLinkAtRange(this.markType, this.range)(
                this.view.state,
                this.view.dispatch as DispatchFn,
                this.view
            );
        }

        this.visible = false;
        this.linkValue = "";
        this.textValue = "";
        this.linkValueError = null;
        this.textValueError = null;
        this.explicitEditorMode = false;
        this.isCreating = false;
        this.range = undefined;
        this.positionStyles = {};

        window.removeEventListener("resize", this.updatePosition);
        document.body.removeEventListener("click", this.closeLinkPopup);
    }

    /**
     * Closes the ep link preview popup or edit-popup if you click anywhere other than an allowed location. These locations are:
     * - within the popup itself
     * - on the link in the tiptap editor
     * - on an element that has the data-keep-link-popup-open set (the alignment and list buttons in the toolbar)
     *
     * @param e A mouse event
     */
    private closeLinkPopup(e: MouseEvent): void {
        const previewLinkPopupElement: HTMLElement | null | undefined = document.getElementById("preview-link-popup")
            ?.parentElement;
        const createLinkPopupElement: HTMLElement | null | undefined = document.getElementById("create-link-popup")
            ?.parentElement;
        if (previewLinkPopupElement || createLinkPopupElement) {
            const clickedWithinPopup: boolean | undefined =
                previewLinkPopupElement?.contains(e.target as Node) ||
                createLinkPopupElement?.contains(e.target as Node);
            const clickedOnLinkElement: boolean | undefined = e.target instanceof HTMLAnchorElement;
            const keepLinkPopupOpenAttribute: Element | null = (e.target as HTMLElement).closest(
                "[data-keep-link-popup-open]"
            );
            if (!clickedWithinPopup && !clickedOnLinkElement && !keepLinkPopupOpenAttribute) {
                this.close();
            }
            return;
        }
    }

    /**
     * Update popup position from current view state.
     *
     * @param pluginState the link plugin state
     */
    private async updatePosition(): Promise<void> {
        const view: EditorView | undefined = this.view;
        const range: { from: number; to: number } | undefined = this.range;
        if (!view || !range || !this.$el) {
            return;
        }

        const parentNode: HTMLBodyElement = document.body as HTMLBodyElement;
        if (!parentNode) {
            return;
        }

        // Calculate new positions
        const popupRect: ClientRect | DOMRect = this.$el.querySelector(".popup")!.getBoundingClientRect();
        const popoverRect: ClientRect | DOMRect = parentNode.getBoundingClientRect();
        const containerRect: ClientRect | DOMRect = parentNode.getBoundingClientRect();
        const start: { left: number; right: number; top: number; bottom: number } = view.coordsAtPos(range.from);
        const end: { left: number; right: number; top: number; bottom: number } = view.coordsAtPos(range.to);
        const newLeft: number = start.left - containerRect.left;
        const newTop: number = end.bottom - popoverRect.top + MARGIN_VALUE;

        const styles: OptionalCSSStyles = { top: newTop + "px" };

        // Verify that the new styles do not put the dialog off screen
        // if so, correct.

        const maxLeft: number = window.innerWidth - popupRect.width - PADDING_VALUE;
        if (newLeft >= maxLeft) {
            styles.right = window.innerWidth - (containerRect.left + containerRect.width) + "px";
        } else {
            styles.left = newLeft + "px";
        }

        this.positionStyles = styles;
    }

    // DOM $Events
    // ========================================

    /**
     * DOM Click:
     * Called when the "Create link" button is pressed.
     * Pipe function call to create link.
     *
     */
    private onCreateLinkClick($event: Event): void {
        this.createLink();
    }

    /**
     * DOM Click:
     * Called when the "Copy" button is clicked.
     * Copy the current URL to the clipboard.
     */
    private onCopyClick(): void {
        if (!this.view) {
            return;
        }

        const attrs: { [key: string]: any } = getMarkAttrs(this.view.state, this.markType);
        if (attrs.href) {
            HTMLTextUtil.copyToClipboard(attrs.href);
        }
    }

    /**
     * DOM Click:
     * Called when the "Remove" button is clicked.
     * Remove the link mark.
     */
    private onRemoveClick(): void {
        if (!this.view) {
            return;
        }

        EPLinkCommands.removeLink(this.markType)(this.view.state, this.view.dispatch as DispatchFn, this.view);
    }

    /**
     * DOM Click:
     * Called when the link icon/text is called when in viewing mode.
     * Open the link in a new window.
     */
    private onLinkClick(): void {
        if (!this.linkValue) {
            return;
        }

        const url: string = StringUtil.startsWithAny(this.linkValue, ["http://", "https://"])
            ? this.linkValue
            : "http://" + this.linkValue;

        window.open(url, "_blank");
    }

    /**
     * DOM Click:
     * Called when the cancel button is presssed.
     * Exit explicit edit mode and remove link
     * if creating.
     */
    private onCancelClick(): void {
        if (!this.explicitEditorMode && this.view) {
            EPLinkCommands.removeLink(this.markType)(this.view.state, this.view.dispatch as DispatchFn, this.view);
        }

        this.close();
    }

    // Gets
    // ========================================

    /** Whether or not the state is in editorMode */
    private get editorMode(): boolean {
        return this.explicitEditorMode || this.isCreating;
    }

    /** Get the mark type from view schema */
    private get markType(): MarkType {
        return this.view!.state.schema.marks[EPLink.MARK_NAME];
    }

    /** Indicates whether `this.linkValue is valid URL */
    protected get isLinkValueValid(): boolean {
        return this.linkValue != null && LINK_REGEX.test(this.linkValue);
    }

    /** Indicates whether `this.textValue` is valid */
    protected get isTextValueValid(): boolean {
        return this.textValue != null && this.textValue.trim().length > 0;
    }
}
