import { Dictionary } from "@educationperfect/ep-web-utils";

enum AttributeState {
    PARAMETER = 1,
    VALUE = 2,
}

enum AttributeType {
    NUMBER = 0,
    STRING = 1,
}

export class AttributesParser {
    private static NUMERIC_MATCH: RegExp = /^[-+]?[0-9]*\.?[0-9]+$/;

    public attributes: Dictionary<string>;
    public length: number;

    private state: number;
    private builder: string;
    private currentAttribute!: string | null;
    private insideQuotes!: boolean;
    private escaped!: boolean;
    private treatAsString!: boolean;

    public static Parse(text: string, baseAttributes: Dictionary<string> | null = null): Dictionary<string> {
        var parser: AttributesParser = new AttributesParser(text, baseAttributes);
        return parser.attributes;
    }

    constructor(line: string, existingDictionary?: Dictionary<string> | null) {
        line.trim();

        this.attributes = existingDictionary || {};
        this.builder = "";
        this.state = AttributeState.PARAMETER;

        let length = 0;
        for (var i = 0; i < line.length + 1; i++) {
            length++;
            const char: string = i < line.length ? line.charAt(i) : " ";

            switch (this.state) {
                case AttributeState.VALUE:
                    this.processValueChar(char);
                    break;

                case AttributeState.PARAMETER:
                    this.processParameterChar(char);
                    break;
            }

            if (!this.insideQuotes && char == "]") {
                break;
            }
        }

        this.length = length;
    }

    private processParameterChar(char: string): void {
        if (char == "=") {
            this.currentAttribute = this.builder.trim();
            this.builder = "";
            this.state = AttributeState.VALUE;
        } else {
            this.builder += char;
        }
    }

    private processValueChar(char: string): void {
        if (char == " " || char == "]") {
            // got space
            if (this.insideQuotes) {
                this.builder += char;
            } else {
                if (this.currentAttribute) {
                    this.attributes[this.currentAttribute] = this.builder;
                }
                this.currentAttribute = null;
                this.treatAsString = false;
                this.builder = "";
                this.state = AttributeState.PARAMETER;
            }
        } else if (char == '"') {
            // got quotes
            if (this.insideQuotes) {
                this.treatAsString = true;
                this.insideQuotes = false;
            } else {
                this.insideQuotes = true;
            }
        } else {
            // append to value
            this.builder += char;
        }
    }

    // private ParseValue(valueText: string): string
    // {
    //    if (!this.treatAsString && AttributesParser.looksNumeric(valueText))
    //    {
    //        var numberValue: number = parseFloat(valueText);
    //        return numberValue
    //    }
    //    else
    //    {
    //        return valueText;
    //    }
    // }

    // private static looksNumeric(text: string): boolean
    // {
    //    return AttributesParser.NUMERIC_MATCH.test(text);
    // }
}
