import { NodeSpec, NodeType } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { contains } from "prosemirror-utils";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { GapNode } from "../gapNode/GapNode";
import { AnnotationData } from "./AnnotationData";
import { GapData } from "../../models/GapData";
import { GapNodeCommands } from "../gapNode/GapNodeCommands";
import { AnnotationNodeCommands } from "./AnnotationNodeCommands";

export class AnnotationNode extends GapNode {
    public static readonly NODE_NAME: string = "annotation";
    public get nodeName(): string {
        return AnnotationNode.NODE_NAME;
    }
    public static readonly GapColorPluginKey: PluginKey = new PluginKey("gapColor");

    //  Node Setup
    // ====================================

    public get schema(): NodeSpec {
        const schema: NodeSpec = super.schema;

        schema.attrs = {
            ...schema.attrs,
            color: { default: 0 },
        };

        schema.parseDOM = [
            {
                tag: this.nodeName,
                getAttrs: (dom) => {
                    if (dom instanceof Element) {
                        return {
                            id: dom.getAttribute("gap-id"),
                            text: dom.getAttribute("text"),
                            color: dom.getAttribute("color"),
                        };
                    }
                },
            },
        ];

        schema.toDOM = (node) => [
            this.name,
            {
                "gap-id": node.attrs.id,
                text: node.attrs.text,
                color: node.attrs.color,
            },
        ];

        return schema;
    }

    public get plugins(): Plugin[] {
        const gapNodePlugins: Plugin[] = super.plugins;

        const storeGapsPlugin = new Plugin<AnnotationPluginState>({
            key: AnnotationNode.GapColorPluginKey,
            state: {
                init: (config, instance: EditorState) => {
                    return { gapCount: 0 };
                },
                apply: (tr, pluginState, oldState, newState) => {
                    const hasGaps: boolean = contains(tr.doc, newState.schema.nodes[AnnotationNode.NODE_NAME]);
                    if (hasGaps) {
                        const htmlContent: string = ProsemirrorUtils.toHTML(newState);
                        pluginState.gapCount = AnnotationNode.getGapsFromHtml(htmlContent).length;
                    } else {
                        pluginState.gapCount = 0;
                    }

                    return pluginState;
                },
                fromJSON: undefined,
                toJSON: undefined,
            },
        });

        gapNodePlugins.push(storeGapsPlugin);
        return gapNodePlugins;
    }

    /** [Override] The executable commands */
    public commands({ type, schema }: { type: NodeType; schema: NodeSpec }): AnnotationNodeCommands.Interface {
        return {
            ...super.commands({ type, schema }),
            toggleGap: () => AnnotationNodeCommands.toggleGapCommand(type),
            markGap: () => AnnotationNodeCommands.markGapCommand(type),
            updateAnnoationColor: ({ id, color }: { id: string; color: string }) =>
                AnnotationNodeCommands.updateAnnoationColor(type, id, color),
        };
    }

    /** [Override] the keyboard shortcuts for  */
    public keys({ type }): any {
        return {
            "Control-Space": AnnotationNodeCommands.toggleGapCommand(type),
            "Alt-Space": AnnotationNodeCommands.toggleGapCommand(type),
        };
    }

    /** [Override] Get the gaps from htm;  */
    public getGaps(htmlContent: string): GapData[] {
        return AnnotationNode.getGapsFromHtml(htmlContent);
    }

    /** Get the highlight gaps  */
    public static getGapsFromHtml(documentHtml: string): AnnotationData[] {
        const parser = new DOMParser();
        const html: Document = parser.parseFromString(documentHtml, "text/html");
        const gapNodes: Element[] = Array.from(html.getElementsByTagName(AnnotationNode.NODE_NAME));

        const annotations: AnnotationData[] = [];
        for (const gap of gapNodes) {
            const id = gap.getAttribute("gap-id");
            const text = gap.getAttribute("text");
            const color = gap.getAttribute("color");

            if (id != null && text != null && color != null) {
                let highlightData: AnnotationData = { id, text, color };
                annotations.push(highlightData);
            } else {
                throw Error("Annoitation missing id, answer or color");
            }
        }

        return annotations;
    }
}

export interface AnnotationPluginState {
    gapCount: number;
}
