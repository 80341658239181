import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { EditorState, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

// tslint:disable-next-line:no-namespace
export namespace ClearFormattingCommands {
    /**
     * Clear all marks (styles) from the current selection
     */
    export function clearFormatting(): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const transaction: Transaction = state.tr;
            transaction.setSelection(state.selection);

            if (!transaction.selection) {
                return false;
            }
            transaction.removeMark(transaction.selection.from, transaction.selection.to);

            // commented because this assumes the default is left... not necessarily true
            // transaction.setNodeMarkup(0, undefined, { align: TextAlignments.LEFT});

            return transaction.docChanged && dispatch ? dispatch(transaction) : false;
        };
    }
}
