/* eslint-disable class-methods-use-this */
import { Mark as TiptapMark } from "@educationperfect/tiptap";
import { CommandFunction } from "@educationperfect/tiptap-commands";
import { getMarkAttrs, getMarkRange, markIsActive } from "@educationperfect/tiptap-utils";
import { MarkSpec, MarkType } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { EPLinkCommands } from "./EPLinkCommands";
import { EPLinkPopup } from "./EpLinkPopup/EPLinkPopup";
import { EPLinkData } from "./interfaces/EPLinkData";
import { EPLinkPluginState } from "./interfaces/EPLinkPluginState";

export class EPLink extends TiptapMark {
    // Variables
    // =======================================

    public static readonly MARK_NAME = "link";

    public static readonly LinkPluginKey: PluginKey = new PluginKey("link");

    // Overrides
    // =======================================

    /** [Override] Get the name of the extension */
    public get name(): string {
        return EPLink.MARK_NAME;
    }

    /** [Override] Get the extension schema */
    public get schema(): MarkSpec {
        return {
            attrs: {
                href: {
                    default: null,
                },
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: "a",
                    getAttrs: (dom) => {
                        if (dom instanceof Element) {
                            return {
                                href: dom.getAttribute("href"),
                            };
                        }
                    },
                },
            ],
            toDOM: (node) => {
                return [
                    "a",
                    {
                        ...node.attrs,
                        rel: "noopener noreferrer nofollow",
                    },
                    0,
                ];
            },
        };
    }

    /** [Override] Return list of commands that can be executed related to links */
    public commands({
        type,
        schema,
        attrs,
    }: {
        type: MarkType;
        schema: MarkSpec;
        attrs: { [key: string]: string };
    }): EPLinkCommands.Interface {
        return {
            toggleLink: () => EPLinkCommands.toggleLink(type),
            updateLink: ({ text, href }: { text: string; href: string }) => EPLinkCommands.updateLink(type, text, href),
        };
    }

    /** [Override] Return the keyboard shortcut -> command map */
    public keys({ type }: { type: MarkType }): { [keyCombo: string]: CommandFunction } {
        return {
            "Mod-k": EPLinkCommands.toggleLink(type),
        };
    }

    /** [Override] Get the plugins for link */
    public get plugins(): Plugin[] {
        // tslint:disable-next-line: variable-name tslint:disable-next-line: no-this-assignment
        const _this: this = this;

        return [
            new Plugin({
                key: EPLink.LinkPluginKey,
                state: {
                    init() {
                        const data: EPLinkPluginState = {
                            href: undefined,
                            component: undefined,
                            resetOnNextUpdate: false,
                            from: 0,
                        };
                        return data;
                    },
                    apply: (_, pluginState) => pluginState,
                },
                view: () => {
                    return {
                        update: (view: EditorView, prevState: EditorState) => {
                            const pluginState: EPLinkPluginState = EPLink.LinkPluginKey.getState(
                                view.state
                            ) as EPLinkPluginState;
                            if (!pluginState) {
                                return;
                            }

                            if (!view.hasFocus()) {
                                pluginState.resetOnNextUpdate = true;
                            }

                            // Stop if there is no change
                            const noChange: boolean =
                                prevState.doc.eq(view.state.doc) && prevState.selection.eq(view.state.selection);
                            if (noChange && !pluginState.resetOnNextUpdate) {
                                return;
                            }

                            // Choose the resolve point depending on their selection direction
                            const from: number = view.state.selection.from;
                            let pos: number = from;
                            if (pluginState.from != pos) {
                                pos = view.state.selection.to;
                            }
                            pluginState.from = from;

                            // Check if there is an active mark range selection.
                            const markType: MarkType = view.state.schema.marks[EPLink.MARK_NAME];
                            if (!markIsActive(view.state, markType)) {
                                if (pluginState.component?.visible) {
                                    pluginState.component?.close();
                                    pluginState.href = "";
                                }
                                return;
                            }

                            const range: { from: number; to: number } | null = getMarkRange(
                                view.state.tr.doc.resolve(pos),
                                markType
                            );
                            if (!range || !view.dom.parentNode) {
                                if (pluginState.component?.visible) {
                                    pluginState.component?.close();
                                    pluginState.href = "";
                                }
                                return;
                            }

                            // Mount vue component if required
                            const parentNode: HTMLBodyElement = document.body as HTMLBodyElement;
                            if (!pluginState.component) {
                                pluginState.component = new EPLinkPopup().$mount();
                                parentNode.appendChild(pluginState.component.$el);
                            }

                            const text: string = ProsemirrorUtils.extractTextContentFromRange(view, range);
                            const attrs: { [key: string]: string } = getMarkAttrs(view.state, markType);
                            const href: string = attrs.href ?? "";

                            // A different link has been selected
                            if (href && pluginState.href !== href) {
                                pluginState.component.close();
                            }

                            // An un-open link has been selected
                            if (!pluginState.component.visible) {
                                // Generate params
                                const data: EPLinkData = {
                                    view,
                                    range,
                                    text,
                                    link: href,
                                };

                                pluginState.component.open(data);
                                pluginState.href = href;
                            }
                        },
                        destroy: () => {
                            const { editor } = _this as any;
                            const pluginState: EPLinkPluginState = EPLink.LinkPluginKey.getState(
                                editor.state
                            ) as EPLinkPluginState;
                            const epLink: Element | undefined = pluginState.component?.$el;
                            if (epLink) {
                                epLink.remove();
                                pluginState.component?.$destroy();
                                pluginState.component = undefined;
                            }
                        },
                    };
                },
            }),
        ];
    }
}
