import { Dictionary } from "@educationperfect/ep-web-utils";

import { DOMTableStyle } from "./DOMTableStyle";
import { IDOMTableCellStyleAppliedResult } from "./IDOMTableCellStyleAppliedResult";

export class DOMTableCellStyle {
    public alignment: any;
    public padding: number | undefined;
    public border!: string;
    public fontWeight: any;
    public backgroundColor: any;
    public color: any;
    public colSpan: number | undefined;
    public rowSpan: number | undefined;
    public width!: number;
    public verticalAlignment: any;

    constructor(cellIndex: number, header: boolean, params: Dictionary<string> | null, tableStyle: DOMTableStyle) {
        this.loadStyle(cellIndex, header, params, tableStyle);
    }

    public apply(cell: HTMLTableCellElement): IDOMTableCellStyleAppliedResult {
        // alignment
        cell.style.textAlign = this.alignment;
        cell.style.verticalAlign = this.verticalAlignment;

        cell.setAttribute("valign", this.verticalAlignment);

        // cell.setStyle("horizontalAlign", this.style.Alignment);
        // cell.setStyle("textAlign", this.style.Alignment);
        // cell.setStyle("verticalAlign", this.style.VerticalAlignment);

        // width
        var widthSet: boolean = false;
        if (!isNaN(this.width)) {
            cell.setAttribute("data-colwidth", this.width.toString());
            cell.style.width = this.width + "px";
            widthSet = true;
        }
        // BPC 2014-12-19: 100% doesn't mean what we expect in CSS.  Better to let HTML tables just do their thing.
        // else
        // {
        //    //cell.style.width = "100%";
        // }

        // padding
        if (this.padding != undefined) {
            var padding: string = this.padding + "px";
            cell.style.paddingLeft = padding;
            cell.style.paddingRight = padding;
            cell.style.paddingTop = padding;
            cell.style.paddingBottom = padding;
            cell.setAttribute("padding", this.padding.toString());
        }

        // border
        cell.style.border = this.border;
        if (this.border && (this.border === "2px solid black" || this.border === "solid")) {
            cell.setAttribute("border", "on");
        }

        // header styles
        if (this.fontWeight !== undefined) {
            cell.style.fontWeight = this.fontWeight;
        }
        if (this.backgroundColor !== undefined) {
            cell.style.backgroundColor = this.backgroundColor;
            cell.setAttribute("background", this.backgroundColor);
        }

        // color
        if (this.color !== undefined) {
            cell.style.color = this.color;
        }

        // colspan
        if (this.colSpan && !isNaN(this.colSpan)) {
            cell.colSpan = this.colSpan;
        }

        // rowspan
        if (this.rowSpan && !isNaN(this.rowSpan)) {
            cell.rowSpan = this.rowSpan;
        }

        return { didSetWidth: widthSet };
    }

    private loadStyle(
        cellIndex: number,
        header: boolean,
        params: Dictionary<string> | null,
        tableStyle: DOMTableStyle
    ): void {
        params = params || {};

        // alignment
        var alignment: string | null = null;
        if ("align" in params) {
            alignment = params["align"];
        }

        this.alignment = this.getAlignmentStyleName(alignment, tableStyle, cellIndex);

        // vertical-alignment
        var verticalAlignment: string | null = null;
        if ("valign" in params) {
            verticalAlignment = params["valign"];
        }

        this.verticalAlignment = this.getVerticalAlignmentStyleName(verticalAlignment, tableStyle, cellIndex);

        // width
        var width: number | null = null;
        if ("width" in params) {
            width = parseFloat(params["width"]);
        }
        this.width = this.getWidth(width, tableStyle, cellIndex);

        // padding
        this.padding = undefined;
        if ("padding" in params) {
            this.padding = parseFloat(params["padding"]);
        }

        if (this.padding && isNaN(this.padding)) {
            this.padding = tableStyle.cellPadding;
        }

        // border
        this.border = tableStyle.border;
        if ("border" in params) {
            this.border = params["border"] == "off" ? "none" : "2px solid black";
        }

        // header-cell
        this.fontWeight = undefined;
        this.backgroundColor = undefined;

        if (header) {
            this.fontWeight = "bold";
            this.backgroundColor = 0xf2f2f2;
        }

        // background
        if ("background" in params) {
            this.backgroundColor = params["background"].toString();
        }

        // color
        this.color = undefined;
        if ("color" in params) {
            this.color = params["color"].toString();
        }

        // colspan
        this.colSpan = undefined;
        if ("colspan" in params) {
            this.colSpan = parseFloat(params["colspan"]);
        }

        // rowspan
        this.rowSpan = undefined;
        if ("rowspan" in params) {
            this.rowSpan = parseFloat(params["rowspan"]);
        }
    }

    private getVerticalAlignmentStyleName(alignment: string | null, tableStyle: DOMTableStyle, cellIndex: number): any {
        if (alignment == null) {
            if (tableStyle.columnVerticalAlignments[cellIndex] !== undefined) {
                return tableStyle.columnVerticalAlignments[cellIndex];
            } else {
                return "middle";
            }
        } else {
            tableStyle.columnVerticalAlignments[cellIndex] = alignment;
            return alignment;
        }
    }

    private getWidth(width: number | null, tableStyle: DOMTableStyle, cellIndex: number): number {
        if (width === null || isNaN(width)) {
            if (tableStyle.columnWidths[cellIndex] !== undefined) {
                return tableStyle.columnWidths[cellIndex];
            } else {
                return NaN;
            }
        } else {
            tableStyle.columnWidths[cellIndex] = width;
            return width;
        }
    }

    private getAlignmentStyleName(alignment: string | null, tableStyle: DOMTableStyle, cellIndex: number): any {
        if (alignment == null) {
            if (tableStyle.columnAlignments[cellIndex] !== undefined) {
                return tableStyle.columnAlignments[cellIndex];
            } else {
                // BPC 2014-12-19: CSS inherits text-align from parent container, so we have to be explicit about the default
                return "left";
            }
        } else {
            tableStyle.columnAlignments[cellIndex] = alignment;
            return alignment;
        }
    }
}
