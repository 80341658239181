import { AiAnnotation } from "../../../src/extensions/aiAnnotation/AnnotationTypings";

export const annotationTestUserAnswer = `
<p style="text-align: left;" dir="ltr">Auroras, also known as the Northern and Southern Lights, are created by the interaction between the Earth's magnetic field and the solar wind, a stream of charged particles emitted by the sun. These particles are guided towards the Earth's poles by magnetic field lines, where they collide with nitrogen and oxygen molecules in the atmosphere.</p><table border="true" align="center"><tr><td data-colwidth="150" style="text-align: left;vertical-align: middle;"><p style="text-align: left;" dir="ltr">ttest</p></td><td data-colwidth="150" style="text-align: left;vertical-align: middle;"><p style="text-align: left;" dir="ltr">waef</p></td></tr><tr><td data-colwidth="150" style="text-align: left;vertical-align: middle;"><p style="text-align: left;" dir="ltr">awef</p></td><td data-colwidth="150" style="text-align: left;vertical-align: middle;"><p style="text-align: left;" dir="ltr">waef</p></td></tr></table><p style="text-align: left;" dir="ltr">These collisions excite the atmospheric molecules, causing their electrons to jump to higher energy states. When these electrons drop back to their lower energy states, they release light. The different colors of the auroras, such as green, red, blue, and purple, are determined by the type of gas involved and the amount of energy released during these interactions.</p><p>            Earth's magnetosphere usually stops solar wind from bombarding the planet, however, when it becomes overpowered by too much solar wind, a magnetic storm can occur.</p>
`;

// export const annotationTestUserAnswer = `
// <p style="text-align: left;" dir="ltr">Auroras, also known as the Northern and Southern Lights, are created by the interaction between the Earth's magnetic field and the solar wind, a stream of charged particles emitted by the sun. These particles are guided towards the Earth's poles by magnetic field lines, where they collide with nitrogen and oxygen molecules in the atmosphere.</p><p style="text-align: left;" dir="ltr">These collisions excite the atmospheric molecules, causing their electrons to jump to higher energy states. When these electrons drop back to their lower energy states, they release light. The different colors of the auroras, such as green, red, blue, and purple, are determined by the type of gas involved and the amount of energy released during these interactions.</p>`;

// export const annotationsTestData: AiAnnotation[] = [
//     {
//         comment: "You've given a clear and concise explanation of how auroras are formed. Great job!",
//         location: "studentAnswer",
//         id: "460d89ac-a0ac-448e-880c-bac127be1145",
//         matchText:
//             "Auroras, also known as the Northern and Southern Lights, are created by the interaction between the Earth's magnetic field and the solar wind",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment:
//             "This is a good point! You've correctly explained how the solar wind particles are directed towards the Earth's magnetic poles. Keep it up!",
//         location: "studentAnswer",
//         id: "c6ee7abc-1dbf-44bd-877f-cb358a215a41",
//         matchText: "These particles are guided towards the Earth's poles by magnetic field lines",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment:
//             "Well done! You've accurately described the process of electron excitation and the energy transfer in the formation of auroras.",
//         location: "studentAnswer",
//         id: "1e183b23-eff9-4d59-882d-6cc00b60f0c2",
//         matchText:
//             "These collisions excite the atmospheric molecules, causing their electrons to jump to higher energy states.",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment:
//             "Excellent! You've correctly identified the factors that determine the colors of auroras. This shows a deep understanding of the topic.",
//         location: "studentAnswer",
//         id: "9e3346c8-376c-43af-8400-010846698bfd",
//         matchText:
//             "The different colors of the auroras, such as green, red, blue, and purple, are determined by the type of gas involved and the amount of energy released during these interactions.",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment: "You've nicely explained the final step in the process of why we see the light of auroras. Well done!",
//         location: "studentAnswer",
//         id: "3054b013-c040-4ca3-bca6-01e33b431560",
//         matchText: "When these electrons drop back to their lower energy states, they release light.",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment:
//             "Remember to mention how a magnetic storm forms due to excessive solar wind stressing the magnetosphere. This is an important detail for a complete answer.",
//         location: "modelAnswer",
//         id: "b84b90ab-c256-41f7-af12-796f541652bb",
//         matchText: "A magnetic storm forms when too much solar wind stresses the magnetosphere",
//         type: "info",
//         title: "info",
//     },
//     {
//         comment:
//             "In your answer, try to include information about the Sun's corona releasing high-energy particles, as this is a key part of how auroras are formed.",
//         location: "modelAnswer",
//         id: "d89bddd7-ef01-4916-8eb3-f8f9cc2596fe",
//         matchText: "The outer layer of the Sun, known as the Corona, releases high-energy particles into space.",
//         type: "info",
//         title: "info",
//     },
// ];

export const annotationsTestData: AiAnnotation[] = [
    {
        id: "annotation_1",
        location: "studentAnswer",
        matchText:
            "Auroras, also known as the Northern and Southern Lights, are created by the interaction between the Earth's magnetic field and the solar wind, a stream of charged particles emitted by the sun.",
        comment:
            "Great job explaining how the Earth's magnetic field and the solar wind interact to create the auroras.",
        title: "Good overview",
        type: "error",
    },
    {
        id: "annotation_2",
        location: "studentAnswer",
        matchText:
            "These particles are guided towards the Earth's poles by magnetic field lines, where they collide with nitrogen and oxygen molecules in the atmosphere.",
        comment:
            "You've clearly explained how the magnetic field lines guide the solar particles towards the poles, where they collide with atmospheric molecules.",
        title: "Nicely described",
        type: "strength",
    },
    {
        id: "annotation_5",
        location: "studentAnswer",
        matchText: "waef",
        comment: "There seems to be a spelling mistake here. Please double-check the word.",
        title: "Spelling mistake",
        type: "spelling",
    },
    {
        id: "annotation_3",
        location: "studentAnswer",
        matchText:
            "These collisions excite the atmospheric molecules, causing their electrons to jump to higher energy states. When these electrons drop back to their lower energy states, they release light.",
        comment:
            "Great job explaining the process of how the collisions excite the molecules and then they release light when the electrons return to their lower energy states.",
        title: "Good detail",
        type: "grammar",
    },
    {
        id: "annotation_4",
        location: "studentAnswer",
        matchText:
            "The different colors of the auroras, such as green, red, blue, and purple, are determined by the type of gas involved and the amount of energy released during these interactions.",
        comment: "Excellent, you've identified the key factors that determine the different colors of the auroras.",
        title: "Well explained",
        type: "info",
    },
    {
        id: "annotation_6",
        location: "studentAnswer",
        matchText:
            "Earth's magnetosphere usually stops solar wind from bombarding the planet, however, when it becomes overpowered by too much solar wind, a magnetic storm can occur.",
        comment:
            "You could also mention how the Earth's magnetic field acts as a protective barrier, normally stopping the solar wind, but that it can become overpowered during magnetic storms.",
        title: "Add more about the protective barrier",
        type: "omission",
    },
];
