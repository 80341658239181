import { findParentNode } from "prosemirror-utils";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { DispatchFn, wrapInList, liftListItem, CommandFunction } from "@educationperfect/tiptap-commands";

// tslint:disable-next-line:no-namespace
export namespace ListCommands {
    export function toggleList(listType, itemType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const schema = state.schema;
            const selection = state.selection;
            const $from = selection.$from;
            const $to = selection.$to;
            const range = $from.blockRange($to);

            if (!range) {
                return false;
            }

            var parentList = findParentNode((node) => {
                return isList(node, schema);
            })(selection);

            if (range.depth >= 1 && parentList && range.depth - parentList.depth <= 1) {
                if (parentList.node.type === listType) {
                    return liftListItem(itemType)(state, dispatch, view);
                }

                if (isList(parentList.node, schema) && listType.validContent(parentList.node.content)) {
                    var tr = state.tr;
                    tr.setNodeMarkup(parentList.pos, listType, parentList.node.attrs);

                    if (dispatch) {
                        dispatch(tr);
                    }

                    return false;
                }
            }

            return wrapInList(listType)(state, dispatch, view);
        };
    }

    function isList(node, schema) {
        return (
            node.type === schema.nodes.bullet_list ||
            node.type === schema.nodes.ordered_list ||
            node.type === schema.nodes.todo_list
        );
    }
}
