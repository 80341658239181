import { TextBoxNode } from "../../../../../extensions/inlineBuildable/inputBox/textBox/TextBoxNode";
import { InlineBuildableComponent } from "./InlineBuildableComponent";

export class InlineTextBoxComponent extends InlineBuildableComponent {
    // Overrides
    // ===================================

    /** Get the name of the component to build */
    public get nodeName(): string {
        return TextBoxNode.NODE_NAME;
    }
}
