import { KatexFacade } from "@educationperfect/ep-web-math";
import { Selection } from "prosemirror-state";

import { ExtensionNames } from "../../utils/ExtensionNames";

export class FormulaUtils {
    /** Regular expression matching TeX commands before \var{..}. We need a insert a space in case the variable evaluates to text, since \timesA is invalid. */
    private static insertLatexSpacesForVariables: RegExp = /(\\[a-z]+)(\\var\{)/gi;

    // =========================================================================
    // Public functions
    // =========================================================================

    /**
     * Writes latex to the mathfield, and returns whether or not this was successful.
     * @param latex the latex string to be validated
     */
    public static isValidMathquill(latex: string, mathField: mq.IEditableMathField | undefined): boolean {
        if (mathField) {
            mathField.latex(latex);

            // check to see whether MathQuill has removed any characters by comparing the current latex to the modified latex, ignoring spaces (mathquill may add or remove whitespace but that is OK)
            let currentLatex = latex.replace(/\s/g, "");
            let newLatex = mathField.latex().replace(/\s/g, "");

            return (latex.length == 0 || mathField.latex().length > 0) && currentLatex === newLatex;
        }
        return false;
    }

    public static isValidKatex(latex: string): boolean {
        const katexRenderable = KatexFacade.typesetToString(latex); // returns an empty string if the katex fails to render.
        return katexRenderable !== "";
    }

    public static extractTextContentFromSelection(selection: Selection, nodeName: string): string {
        const slice = selection.content();
        const fragment = slice.content;

        let selectionText = "";

        fragment.nodesBetween(0, fragment.size, (node) => {
            let nodeText: string = "";

            // Only allow text nodes to be marked as gaps
            if (node.type.name === ExtensionNames.text) {
                nodeText = node.textContent;
            }

            selectionText += nodeText;
        });

        return selectionText;
    }

    public static validationDebugging(
        debuggingOn: boolean,
        latex: string,
        canRenderMathquill: boolean,
        canRenderKatex: boolean
    ): void {
        if (debuggingOn) {
            /** Regular expression to test whether a LATEX format equation contains variables. */
            const latexContainsVar: RegExp = /\\var\{.*?\}/;

            if (canRenderMathquill && canRenderKatex) {
                console.log("can render mathquill and KaTeX");
            } else if (canRenderMathquill && latexContainsVar.test(latex)) {
                console.log("latex contains var");
            } else if (canRenderMathquill && !canRenderKatex) {
                console.log("latex cannot be rendered by KaTeX");
            } else if (!canRenderMathquill && canRenderKatex) {
                console.log("latex cannot be rendered by Mathquill");
            } else if (!canRenderMathquill && !canRenderKatex) {
                console.error("latex cannot be rendered");
            }
        }
    }
}
