/* eslint-disable @typescript-eslint/no-namespace */
import { EventOutlet } from "@educationperfect/ep-web-utils";

export interface IFormulaConversion {
    from: string;
    to: string;
}

export interface IInlineComponentSelectedState {
    selected: boolean;
    editorElement: Element;
}

// tslint:disable-next-line: no-namespace
export namespace TipTapEvents {
    export class Common {
        public static readonly formulaConvertedEvent = new EventOutlet<IFormulaConversion>("FormulaConverted");

        public static readonly inlineComponentSelected = new EventOutlet<IInlineComponentSelectedState>(
            "InlineComponentSelected"
        );
        public static readonly saveShortcutPressed = new EventOutlet(
            "SaveShortcutPressed"
        );
    }
}
