import "./inline-component-floating-toolbar.less";
import template from "./inline-component-floating-toolbar.html";

import { EdsIconDeletion, EdsIconEdit, EdsTertiaryButton } from "@educationperfect/ep-web-ui-components";
import { Editor } from "@educationperfect/tiptap";
import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../TipTapComponents";
import { TipTapEvents } from "../../TipTapEvents";

const TOOLBAR_POS_RIGHT = -8; // shift the toolbar to the right so it lines up with the selected state border
const TOOLBAR_POS_TOP = 45; // positions toolbar below the node, with a little bit of space between the node and the toolbar

@Component({
    template,
    components: {
        EdsTertiaryButton,
        EdsIconEdit,
        EdsIconDeletion,
    },
    name: TipTapComponents.InlineComponentFloatingToolbar,
})
export class InlineComponentFloatingToolbar extends Vue {
    // Props

    @Prop({ type: Editor, required: true }) private readonly editor!: Editor;

    // Default positioning to get inline toolbar in the right spot can be overriden using these props (see image-node-view)

    @Prop({ type: Number, default: TOOLBAR_POS_RIGHT }) private readonly right!: number;

    @Prop({ type: Number, default: TOOLBAR_POS_TOP }) private readonly top!: number;

    private async mounted(): Promise<void> {
        /**
         * This await is needed to handle an issue that appears when the user clicks from an inline component in one text block to an inlne component in another.
         * This causes selected = false to be dispatched when the first toolbar is destroyed, and then selected = true
         * to be dispatched when the next component is clicked on.
         * This can cause the toolbar to remain visible in the block you have clicked away from
         *  */

        await Vue.nextTick();
        TipTapEvents.Common.inlineComponentSelected.dispatch({ selected: true, editorElement: this.editor.element });
    }

    private destroyed(): void {
        TipTapEvents.Common.inlineComponentSelected.dispatch({ selected: false, editorElement: this.editor.element });
    }

    private deleteSelectedNode(): void {
        const { tr } = this.editor.state;

        tr.deleteSelection();
        this.editor.view.dispatch(tr);
        this.editor.focus();
    }

    private editClicked(): void {
        // TODO: All inline component dialogs should extend a base class, so that this only needs done in one place.
        // Currently this needs implemented in both InlineComponentFloatingToolbar and InlineBuildableNode.
        // See https://educationperfect.atlassian.net/browse/CA-323
        this.editor.blur();
        this.$emit("on-edit-clicked");
    }
}
