import { NodeSpec } from "prosemirror-model";
import { TableRow } from "@educationperfect/tiptap-extensions";

import { EPTableNodes } from "./EPTableNodes";

export class EPTableRow extends TableRow {
    public get schema(): NodeSpec {
        const rowSchema: NodeSpec = EPTableNodes().table_row;
        rowSchema.attrs = {
            header: {
                default: false,
            },
        };
        return rowSchema;
    }
}
