import { Node } from "@educationperfect/tiptap";
import { CommandFunction, setBlockType } from "@educationperfect/tiptap-commands";
import { Heading, HeadingOptions } from "@educationperfect/tiptap-extensions";
import { Node as ProsemirrorNode, NodeSpec, NodeType } from "prosemirror-model";

import { EPLink } from "../link/EPLink";
import { TextAlignments } from "../textAlign/enums/TextAlignments";

export class EPHeading extends Heading {
    constructor(private defaultAlignment: TextAlignments, options?: HeadingOptions) {
        super(options);
    }

    public static readonly NODE_NAME = "heading";

    get schema(): NodeSpec {
        // tslint:disable-next-line: no-this-assignment
        const schema: NodeSpec | undefined = super.schema;
        if (!schema) {
            return {};
        }

        schema.content = "text*";
        schema.attrs = { ...schema.attrs, align: { default: this.defaultAlignment } };
        schema.parseDOM = (this as Node).options.levels.map((level: number) => ({
            tag: `h${level}`,
            getAttrs: (dom) => {
                if (dom instanceof HTMLElement) {
                    const parentTextAlign: TextAlignments | undefined = this.getNearestParentTextAlign(dom);

                    return {
                        align: parentTextAlign ? parentTextAlign : this.defaultAlignment,
                        level,
                    };
                }
            },
        }));

        schema.toDOM = (node: ProsemirrorNode) => {
            node = this.clearMarks(node, EPLink.MARK_NAME);

            // All headings expect H6 are styled as bold, so do not need the bold mark. H6 is not bold by default, but can still be bolded.
            if (node.attrs.level !== 6) {
                node = this.clearMarks(node, "bold");
            }
            const alignmentAttrs = { style: `text-align: ${node.attrs.align};` };
            return [`h${node.attrs.level}`, alignmentAttrs, 0];
        };

        return schema;
    }

    /**
     * Iterates through every child in the Heading node, and clears the provided mark on them.
     * @param node The node to clear given mark from
     */
    private clearMarks(node: ProsemirrorNode, markName: string): ProsemirrorNode {
        node.content.forEach((childContent) => {
            childContent.marks.some((childMark) => {
                if (childMark.type.name === markName) {
                    childContent.marks = childMark.removeFromSet(childContent.marks);
                }
            });
        });
        return node;
    }

    /**
     * Finds the text alignment of the given DOM element's parent. If that doesn't exist, the grandparents text alignment is obtained.
     * If neither exist, return undefined.
     * @param dom The DOM element used to find its parent's text alignment
     */
    private getNearestParentTextAlign(dom: HTMLElement): TextAlignments | undefined {
        if (!dom.parentElement) {
            return;
        }

        const parentTextAlign: string = dom.parentElement.style.textAlign;

        if (parentTextAlign) {
            return parentTextAlign as TextAlignments;
        } else {
            const grandparentTextAlign: string | undefined = dom.parentElement.parentElement?.style.textAlign;
            if (grandparentTextAlign) {
                return grandparentTextAlign as TextAlignments;
            }
        }
    }

    public keys?(
        this: Node,
        { type, schema }: { type: NodeType; schema: NodeSpec }
    ): { [keyCombo: string]: CommandFunction } {
        const levels: number[] = this.options.levels;
        return levels.reduce(
            (items: {}, level: number) => ({
                ...items,
                ...{
                    [`Mod-Alt-${level}`]: setBlockType(type, { level }),
                },
            }),
            {}
        );
    }
}
