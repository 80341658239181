import { Plugin, PluginKey } from "prosemirror-state";
import { cellAround, TableMap } from "prosemirror-tables";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { EPTableView, updateColumns } from "./EPTableView";

export const key = new PluginKey("tableColumnResizing");

export function columnResizing({
    handleWidth = 5,
    cellMinWidth = 40,
    View = EPTableView,
    lastColumnResizable = true,
} = {}) {
    let plugin = new Plugin({
        key,
        state: {
            init(_, state) {
                this["spec"].props.nodeViews[tableNodeTypes(state.schema).table.name] = (node, view) =>
                    new View(node, cellMinWidth, view);
                return new ResizeState(-1, false);
            },
            apply(tr, prev) {
                return prev.apply(tr);
            },
        },
        props: {
            attributes(state) {
                let pluginState = key.getState(state);
                let className: string | null = null;

                if (pluginState.activeHandle > -1) {
                    className = "resize-cursor";
                }

                // added this part to help with dragging info bubble
                if (pluginState.dragging) {
                    className = "resize-cursor currently-dragging";
                }

                return className ? { class: className } : null;
            },

            handleDOMEvents: {
                mousemove(view, event) {
                    if (!view.editable) {
                        return false;
                    }

                    return handleMouseMove(view, event, handleWidth, cellMinWidth, lastColumnResizable);
                },
                mouseleave(view) {
                    if (!view.editable) {
                        return false;
                    }

                    return handleMouseLeave(view);
                },
                mousedown(view, event) {
                    if (!view.editable) {
                        return false;
                    }

                    return handleMouseDown(view, event, cellMinWidth);
                },
            },

            decorations(state) {
                let pluginState = key.getState(state);
                if (pluginState.activeHandle > -1) {
                    return handleDecorations(state, pluginState.activeHandle);
                }
            },

            nodeViews: {},
        },
    });
    return plugin;
}

class ResizeState {
    constructor(public activeHandle, public dragging) {}

    public apply(tr) {
        // tslint:disable-next-line: no-this-assignment
        let state: ResizeState = this;
        let action = tr.getMeta(key);
        if (action && action.setHandle != null) {
            return new ResizeState(action.setHandle, null);
        }
        if (action && action.setDragging !== undefined) {
            return new ResizeState(state.activeHandle, action.setDragging);
        }
        if (state.activeHandle > -1 && tr.docChanged) {
            let handle = tr.mapping.map(state.activeHandle, -1);
            if (!pointsAtCell(tr.doc.resolve(handle))) {
                handle = null;
            }
            state = new ResizeState(handle, state.dragging);
        }
        return state;
    }
}

function handleMouseMove(view, event, handleWidth, cellMinWidth, lastColumnResizable) {
    let pluginState = key.getState(view.state);

    if (!pluginState.dragging) {
        let target = domCellAround(event.target);
        let cell = -1;
        if (target) {
            let { left, right } = target.getBoundingClientRect();
            if (event.clientX - left <= handleWidth) {
                cell = edgeCell(view, event, "left");
            } else if (right - event.clientX <= handleWidth) {
                cell = edgeCell(view, event, "right");
            }
        }

        if (cell != pluginState.activeHandle) {
            if (!lastColumnResizable && cell !== -1) {
                let $cell = view.state.doc.resolve(cell);
                let table = $cell.node(-1);
                let map = TableMap.get(table);
                let start = $cell.start(-1);
                let col = map.colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan - 1;

                if (col == map.width - 1) {
                    return false;
                }
            }

            updateHandle(view, cell);
        }
    }
    return false;
}

function handleMouseLeave(view) {
    let pluginState = key.getState(view.state);
    if (pluginState.activeHandle > -1 && !pluginState.dragging) {
        updateHandle(view, -1);
    }
    return false;
}

function handleMouseDown(view, event, cellMinWidth) {
    let pluginState = key.getState(view.state);
    if (pluginState.activeHandle == -1 || pluginState.dragging) {
        return false;
    }

    let cell = view.state.doc.nodeAt(pluginState.activeHandle);
    let width = currentColWidth(view, pluginState.activeHandle, cell.attrs);
    view.dispatch(view.state.tr.setMeta(key, { setDragging: { startX: event.clientX, startWidth: width } }));

    function finish(ev) {
        window.removeEventListener("mouseup", finish);
        window.removeEventListener("mousemove", move);
        let outerPluginState = key.getState(view.state);
        if (outerPluginState.dragging) {
            updateColumnWidth(
                view,
                outerPluginState.activeHandle,
                draggedWidth(outerPluginState.dragging, ev, cellMinWidth)
                );
                view.dispatch(view.state.tr.setMeta(key, { setDragging: null }));
            }
            updateHandle(view, -1); // ensure that the resize handle is deselected on mouseup (needed for if the mouse has left the bounds of the editor)
    }
    function move(ev) {
        if (!ev.which) {
            return finish(ev);
        }
        let outerPluginState = key.getState(view.state);
        let dragged = draggedWidth(outerPluginState.dragging, ev, cellMinWidth);
        displayColumnWidth(view, outerPluginState.activeHandle, dragged, cellMinWidth);
    }

    // This will force the dragging info bubble to update its position immediately
    displayColumnWidth(view, pluginState.activeHandle, width, cellMinWidth);

    window.addEventListener("mouseup", finish);
    window.addEventListener("mousemove", move);
    event.preventDefault();
    return true;
}

function currentColWidth(view, cellPos, { colspan, colwidth }) {
    let width = colwidth && colwidth[colwidth.length - 1];
    if (width) {
        return width;
    }
    let dom = view.domAtPos(cellPos);
    let node = dom.node.childNodes[dom.offset];
    let domWidth = node.offsetWidth;
    let parts = colspan;
    if (colwidth) {
        for (let i = 0; i < colspan; i++) {
            if (colwidth[i]) {
                domWidth -= colwidth[i];
                parts--;
            }
        }
    }
    return domWidth / parts;
}

function domCellAround(target) {
    while (target && target.nodeName != "TD" && target.nodeName != "TH") {
        target = target.classList.contains("ProseMirror") ? null : target.parentNode;
    }
    return target;
}

function edgeCell(view, event, side) {
    let { pos } = view.posAtCoords({ left: event.clientX, top: event.clientY });
    let $cell = cellAround(view.state.doc.resolve(pos));
    if (!$cell) {
        return -1;
    }
    if (side == "right") {
        return $cell.pos;
    }
    let map = TableMap.get($cell.node(-1));
    let start = $cell.start(-1);
    let index = map.map.indexOf($cell.pos - start);
    return index % map.width == 0 ? -1 : start + map.map[index - 1];
}

function draggedWidth(dragging, event, cellMinWidth) {
    let offset = event.clientX - dragging.startX;
    return Math.max(cellMinWidth, dragging.startWidth + offset);
}

function updateHandle(view: EditorView, cellWidth: number) {
    view.dispatch(view.state.tr.setMeta(key, { setHandle: cellWidth }));
}

function updateColumnWidth(view, cell, width) {
    // added this part for stepped widths
    width = width - (width % 10);

    let $cell = view.state.doc.resolve(cell);
    let table = $cell.node(-1);
    let map = TableMap.get(table);
    let start = $cell.start(-1);
    let col = map.colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan - 1;
    let tr = view.state.tr;

    for (let row = 0; row < map.height; row++) {
        let mapIndex = row * map.width + col;
        // Rowspanning cell that has already been handled
        if (row && map.map[mapIndex] == map.map[mapIndex - map.width]) {
            continue;
        }

        let pos = map.map[mapIndex];
        let attrs = table.nodeAt(pos).attrs;
        let index = attrs.colspan == 1 ? 0 : col - map.colCount(pos);
        if (attrs.colwidth && attrs.colwidth[index] == width) {
            continue;
        }
        let colwidth = attrs.colwidth ? attrs.colwidth.slice() : zeroes(attrs.colspan);
        colwidth[index] = width;
        tr.setNodeMarkup(start + pos, null, ProsemirrorUtils.setAttribute(attrs, "colwidth", colwidth));
    }
    if (tr.docChanged) {
        view.dispatch(tr);
    }
}

function displayColumnWidth(view, cell, width, cellMinWidth) {
    // added this part for stepped widths
    width = width - (width % 10);

    let $cell = view.state.doc.resolve(cell);
    let table = $cell.node(-1);
    let start = $cell.start(-1);
    let col = TableMap.get(table).colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan - 1;
    let dom = view.domAtPos($cell.start(-1)).node;
    while (dom.nodeName != "TABLE") {
        dom = dom.parentNode;
    }
    updateColumns(table, dom.firstChild, dom, cellMinWidth, col, width);
}

function zeroes(n) {
    let result: number[] = [];
    for (let i = 0; i < n; i++) {
        result.push(0);
    }
    return result;
}

function handleDecorations(state, cell) {
    let decorations: Decoration[] = [];
    let $cell = state.doc.resolve(cell);
    let table = $cell.node(-1);
    let map = TableMap.get(table);
    let start = $cell.start(-1);
    let col = map.colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan;
    for (let row = 0; row < map.height; row++) {
        let index = col + row * map.width - 1;
        // For positions that are have either a different cell or the end
        // of the table to their right, and either the top of the table or
        // a different cell above them, add a decoration
        if (
            (col == map.width || map.map[index] != map.map[index + 1]) &&
            (row == 0 || map.map[index - 1] != map.map[index - 1 - map.width])
        ) {
            let cellPos = map.map[index];
            let pos = start + cellPos + table.nodeAt(cellPos).nodeSize - 1;
            let dom = document.createElement("div");
            dom.className = "column-resize-handle";
            decorations.push(Decoration.widget(pos, dom));
        }
    }
    return DecorationSet.create(state.doc, decorations);
}

export function tableNodeTypes(schema) {
    let result = schema.cached.tableNodeTypes;
    if (!result) {
        result = schema.cached.tableNodeTypes = {};
        // tslint:disable-next-line: forin
        for (let name in schema.nodes) {
            let type = schema.nodes[name];
            let role = type.spec.tableRole;
            if (role) {
                result[role] = type;
            }
        }
    }
    return result;
}

export function pointsAtCell($pos) {
    return $pos.parent.type.spec.tableRole == "row" && $pos.nodeAfter;
}
