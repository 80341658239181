import { GuidUtil } from "@educationperfect/ep-web-utils";

import { DOMParagraphInlineComponent } from "./DOMParagraphInlineComponent";
import { DOMParagraphInlineFormula } from "./DOMParagraphInlineFormula";
import { DOMParagraphInlineText } from "./DOMParagraphInlineText";
import { IDOMBlockBase } from "./IDOMBlockBase";
import { IDOMComputedStyle } from "./IDOMComputedStyle";
import { IDOMParagraphInline } from "./IDOMParagraphInline";

export class DOMParagraph implements IDOMBlockBase {
    public id: string;

    private inlineElements: IDOMParagraphInline[];
    private insideTable: boolean;
    private cachedRender!: HTMLElement;

    constructor(insideTable: boolean) {
        this.inlineElements = [];

        this.id = GuidUtil.create();

        this.insideTable = insideTable;
    }

    public get elements(): IDOMParagraphInline[] {
        return this.inlineElements;
    }

    public addInlineElement(inline: IDOMParagraphInline): void {
        if (inline != null) {
            this.inlineElements.push(inline);
        }
    }

    public render(style: IDOMComputedStyle): HTMLElement | null {
        var visualElement: HTMLElement;

        if (!this.empty) {
            visualElement = this.renderMultipleElements(style);
            visualElement.id = this.id;
        } else {
            return null;
        }

        return visualElement;
    }

    public get paragraphText(): string {
        let paragraphText = "";

        for (let inlineElement of this.inlineElements) { // IDomParagraphInline
            if (inlineElement instanceof DOMParagraphInlineText) {
                const paragraphInlineText = inlineElement as DOMParagraphInlineText;
                paragraphText = `${paragraphText}${paragraphInlineText.paragraphText}\n`;
            }
        }
        return paragraphText;
    }

    private renderMultipleElements(style: IDOMComputedStyle): HTMLElement {
        let outputElement: HTMLElement = document.createElement("p");

        if (
            this.inlineElements.some((e) => {
                return e instanceof DOMParagraphInlineFormula;
            })
        ) {
            outputElement.classList.add("inline-formula-paragraph");
        }

        let previousInlineElement: IDOMParagraphInline | undefined;

        for (let inline of this.inlineElements) {
            const isLastElement: boolean = this.inlineElements.indexOf(inline) === this.inlineElements.length - 1;
            let renderedElement: HTMLElement | null = inline.render(
                style,
                this.insideTable,
                previousInlineElement,
                isLastElement
            );

            if (!renderedElement) {
                continue;
            }

            previousInlineElement = inline;

            if (inline.hasHeader) {
                if (this.insideTable) {
                    this.handleHeaderInTable(renderedElement, outputElement);
                } else {
                    outputElement = this.handleHeader(renderedElement, inline);
                    break;
                }
            } else {
                outputElement.appendChild(renderedElement);
            }
        }

        this.setStyles(outputElement, style);

        return outputElement;
    }

    private handleHeaderInTable(inlineElement: HTMLElement, outputElement: HTMLElement): void {
        // In a table a DOMParagraph is the content container for each cell.
        // Each DOMParagraphInline node can contain a mixture of headers and normal text lines,
        // we need to lift each new line to the top level as headers needs to be placed directly
        // inside the table cell for Prosemirror to render it correctly.
        const children: HTMLCollection | undefined = inlineElement.children;
        if (children) {
            // tslint:disable-next-line
            while (children.length > 0) {
                outputElement.appendChild(children[0]);
            }
        }
    }

    private handleHeader(inlineElement: HTMLElement, inlineNode: IDOMParagraphInline): HTMLDivElement {
        // For compatability with prosemirror dom parsing since headers are
        // block nodes and not inline.
        let div: HTMLDivElement = document.createElement("div");

        // Outside of a table context a header is created as it's own DOMParagraph
        // and as such can only exist as the only element in a DOMParagraph.
        if (this.inlineElements.indexOf(inlineNode) > 0) {
            throw new Error("Parsing error: A header must be the exclusive node in a paragraph.");
        }

        div.appendChild(inlineElement);

        return div;
    }

    private setStyles(paragraphElement: HTMLElement, style: IDOMComputedStyle): void {
        paragraphElement.style.maxWidth = style.containerWidth + "px";

        if (!this.insideTable) {
            paragraphElement.style.textAlign = style.alignment;
        }
    }

    public get hasHeader(): boolean {
        return this.inlineElements.some((e) => (e.hasHeader ? e.hasHeader : false));
    }

    public get hasSingleElement(): boolean {
        var single: boolean = this.inlineElements.length === 1;
        return single;
    }

    public get empty(): boolean {
        return this.inlineElements.length === 0;
    }

    protected get hasManyReadableComponents(): boolean {
        return (
            this.inlineElements.findIndex(
                (inline) => inline instanceof DOMParagraphInlineComponent && !this.inlineHasLinkComponent(inline)
            ) != -1
        );
    }

    private inlineHasLinkComponent(inline: DOMParagraphInlineComponent): boolean {
        return false;
    }
}
