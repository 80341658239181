import { BaseComponent } from "../components/BaseComponent";
import { IDOMBlockBase } from "./IDOMBlockBase";
import { IDOMComputedStyle } from "./IDOMComputedStyle";

export class DOMComponentBlock implements IDOMBlockBase {
    constructor(private component: BaseComponent) {}

    public get blockComponent(): BaseComponent {
        return this.component;
    }

    public render(style: IDOMComputedStyle): HTMLElement | null {
        if (this.component.visualElement) {
            var blockDiv: HTMLDivElement = document.createElement("div");
            blockDiv.appendChild(this.component.visualElement);
            return blockDiv;
        }

        return null;
    }
}
