import "./seperator.css";
import template from "./seperator.html";

import { OptionalCSSStyles } from "@educationperfect/ep-web-browser-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../TipTapComponents";

@Component({
    template,
    name: TipTapComponents.Seperator,
})
export class Seperator extends Vue {
    // Props
    // ================================================

    @Prop({ type: String, required: true }) private readonly text!: string;
    @Prop({ type: String, default: "center" }) private readonly textAlign!: string;
    @Prop({ type: String }) private readonly lineSize?: string;
    @Prop({ type: String }) private readonly margin?: string;
    @Prop({ type: String }) private readonly lineColor?: string;
    @Prop({ type: String }) private readonly fontSize?: string;
    @Prop({ type: String }) private readonly textColor?: string;
    @Prop({ type: String }) private readonly vertical?: boolean;
    @Prop({ type: String }) private readonly fontWeight?: string;

    // Gets
    // ================================================

    /**
     * Get the css styles modified
     */
    private get modifiedStyles(): OptionalCSSStyles {
        const styles: OptionalCSSStyles = {};

        if (this.margin) {
            let marginString = this.margin;

            /*
             * Next block is legacy support.
             * When we only provide a number, append the px unit.
             */
            const margin: number = +marginString;
            if (!isNaN(margin)) {
                marginString = margin + "px";
            }

            if (this.vertical) {
                styles.marginLeft = marginString;
                styles.marginRight = marginString;
            } else {
                styles.marginTop = marginString;
                styles.marginBottom = marginString;
            }
        }

        return styles;
    }

    /**
     * Get the css styles for lines
     */
    private get lineStyles(): OptionalCSSStyles {
        const styles: OptionalCSSStyles = {};
        if (this.lineColor) {
            styles.borderColor = this.lineColor;
        }

        if (this.lineSize) {
            styles.borderWidth = this.lineSize;
        }

        return styles;
    }

    /**
     * Get the css styles for text
     */
    private get textStyles(): OptionalCSSStyles {
        const styles: OptionalCSSStyles = {};
        if (this.textColor) {
            styles.color = this.textColor;
        }

        if (this.fontWeight) {
            styles.fontWeight = this.fontWeight;
        }

        if (this.fontSize) {
            styles.fontSize = this.fontSize;
            styles.lineHeight = this.fontSize;
        }

        return styles;
    }

    protected get isTextCenterAligned(): boolean {
        return !this.isTextLeftAligned && !this.isTextRightAligned;
    }

    protected get isTextLeftAligned(): boolean {
        if (!this.textAlign) {
            return false;
        }

        return this.textAlign.trim().toLowerCase() == "left";
    }

    protected get isTextRightAligned(): boolean {
        if (!this.textAlign) {
            return false;
        }

        return this.textAlign.trim().toLowerCase() == "right";
    }
}
