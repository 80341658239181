import { Node as TiptapNode } from "@educationperfect/tiptap";
import { Node as ProsemirrorNode, NodeSpec } from "prosemirror-model";
import { NodeSelection, Plugin, Selection, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";

export class InlineBuildableNode extends TiptapNode {
    // Overrides
    // =======================================

    /** [Override] Get the name of the extension */
    public get name(): string {
        return "";
    }

    /** [Override] Get the extension schema */
    public get schema(): NodeSpec {
        return {
            attrs: {
                id: {
                    default: "",
                },
            },
            inline: true,
            selectable: true,
            group: "inline",
            atom: false,
            draggable: true,
            parseDOM: [
                {
                    tag: this.name,
                    getAttrs: (dom: string | Node) => {
                        if (dom instanceof Element) {
                            return {
                                id: dom.getAttribute("id"),
                            };
                        }
                    },
                },
            ],
            toDOM: (node: ProsemirrorNode) => [
                this.name,
                {
                    id: node.attrs.id,
                },
            ],
        };
    }

    /** [Override] Get the plugins for the dropdown node */
    public get plugins(): Plugin[] {
        return [
            new Plugin({
                props: {
                    // When we backspace into a inline buildable node we want to select the node rather
                    // than remove the whole node straight away
                    handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
                        const transaction: Transaction | null = ProsemirrorUtils.handleDeleteIntoInlineNodeContent(
                            view.state,
                            event,
                            this.name
                        );

                        if (transaction) {
                            view.dispatch(transaction);
                            // This will indicate that we have handled the event and the view will call preventDefault.
                            return true;
                        }
                        return false;
                    },
                    handleDoubleClick: (view: EditorView, pos: number, event: MouseEvent) => {
                        const selection: Selection = view.state.selection;
                        // If we are double clicking text, return false so default isn't prevented
                        if (selection instanceof TextSelection) {
                            return false;
                        }
                        if (selection instanceof NodeSelection) {
                            if (selection?.node?.type === view.state.schema.nodes[this.name]) {
                                // TODO: All inline component dialogs should extend a base class, so that this only needs done in one place.
                                // Currently this needs implemented in both InlineComponentFloatingToolbar and InlineBuildableNode.
                                // See https://educationperfect.atlassian.net/browse/CA-323
                                this.editor?.blur();
                                return true;
                            }
                        }
                        return false;
                    },
                },
            }),
        ];
    }

    /** Override: Return whether or not the event should be processed */
    public stopEvent(event: Event): boolean {
        return ProsemirrorUtils.handleDragNodeViewStopEvents(event);
    }
}
