import { Paragraph, Text } from "@educationperfect/tiptap";
import {
    Bold,
    BulletList,
    HardBreak,
    Heading,
    Italic,
    ListItem,
    OrderedList,
    Strike,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Underline,
} from "@educationperfect/tiptap-extensions";

/** Helper to get tiptap extension names */
export class ExtensionNames {
    public static get bold(): string {
        return new Bold().name as string;
    }
    public static get italic(): string {
        return new Italic().name as string;
    }
    public static get underline(): string {
        return new Underline().name as string;
    }
    public static get strike(): string {
        return new Strike().name as string;
    }
    public static get hardbreak(): string {
        return new HardBreak().name as string;
    }
    public static get paragraph(): string {
        return new Paragraph().name as string;
    }
    public static get text(): string {
        return new Text().name as string;
    }
    public static get heading(): string {
        return new Heading().name as string;
    }
    public static get orderedList(): string {
        return new OrderedList().name as string;
    }
    public static get listItem(): string {
        return new ListItem().name as string;
    }
    public static get bulletList(): string {
        return new BulletList().name as string;
    }
    public static get table(): string {
        return new Table().name as string;
    }
    public static get tableHeader(): string {
        return new TableHeader().name as string;
    }
    public static get tableRow(): string {
        return new TableRow().name as string;
    }
    public static get tableCell(): string {
        return new TableCell().name as string;
    }
}
