import { EditorCommandSet } from "@educationperfect/tiptap";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { ResolvedPos } from "prosemirror-model";
import { EditorState, Selection, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

// tslint:disable-next-line
export namespace ImeCommands {
    export interface Interface extends EditorCommandSet {
        replaceText: ({
            startPosition,
            endPosition,
            replacementText,
            highlight,
        }: {
            startPosition: number;
            endPosition: number;
            replacementText: string;
            highlight: boolean;
        }) => CommandFunction;
        setSelectionRange: ({
            startPosition,
            endPosition,
        }: {
            startPosition: number;
            endPosition: number;
        }) => CommandFunction;
    }

    export function setSelectionRange(startPosition: number, endPosition: number): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            const startPos: ResolvedPos = tr.doc.resolve(startPosition);
            const endPos: ResolvedPos = tr.doc.resolve(endPosition);
            const newSelection: Selection = new TextSelection(startPos, endPos);

            tr.setSelection(newSelection);

            if (dispatch) {
                tr.setMeta("addToHistory", false);
                dispatch(tr);
            }

            return true;
        };
    }

    export function replaceText(
        startPosition: number,
        endPosition: number,
        replacementText: string,
        highlight: boolean
    ): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { tr, selection }: { tr: Transaction; selection: Selection } = state;

            // const fromPos = tr.doc.resolve(startPosition);
            // const toPos = tr.doc.resolve(endPosition);
            // const replaceSelection: TextSelection = new TextSelection(fromPos, toPos);

            // tr.setSelection(replaceSelection);

            // Replace text
            tr.insertText(replacementText, startPosition, endPosition);

            // Highlight text

            if (highlight) {
                const anchorPos: ResolvedPos = tr.selection.$from;
                const headPos: ResolvedPos = tr.doc.resolve(anchorPos.pos - replacementText.length);

                const highlightSelection: TextSelection = new TextSelection(anchorPos, headPos);
                tr.setSelection(highlightSelection);
            }

            if (dispatch) {
                tr.setMeta("addToHistory", false);
                dispatch(tr);
            }

            return true;
        };
    }
}
