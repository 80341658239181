/* eslint-disable @typescript-eslint/no-namespace */
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { Node, NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, Selection, Transaction } from "prosemirror-state";
import { CellSelection } from "prosemirror-tables";
import { findChildren, NodeWithPos } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";

import { ExtensionNames } from "../../utils/ExtensionNames";
import RTLMode, { RTLPluginState } from "../RTLMode/RTLMode";
import { TextAlignments } from "./enums/TextAlignments";
import { ITransactionTask } from "./interfaces/ITransactionTask";
import { TextAlign } from "./TextAlign";

const listTypes: string[] = [ExtensionNames.orderedList, ExtensionNames.bulletList];
const textTypes: string[] = [ExtensionNames.paragraph, ExtensionNames.heading];

function checkRootList(node: Node, parent: Node): boolean {
    if (parent.type.name === ExtensionNames.listItem) {
        return false;
    }

    const isList: boolean = listTypes.includes(node.type.name);
    return isList;
}

function getAllChildLists(rootList: Node): NodeWithPos[] {
    const childLists: NodeWithPos[] = findChildren(rootList, (child) => {
        return listTypes.includes(child.type.name);
    });

    return childLists;
}

export namespace TextAlignmentCommands {
    /**
     * Set the new alignment of the current selection
     *
     * @param alignment the new alignment for the selection
     */
    export function alignCommand({ alignment }: { alignment: TextAlignments }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const pluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(state) as RTLPluginState;
            const selection: Selection = state.selection;
            const transaction: Transaction = state.tr;

            // When aligning content we also need to set the dir attribute.
            let dir: string;
            if (pluginState.RTLMode) {
                dir = "rtl";
            } else {
                dir = "ltr";
            }

            const canAlignText = setTextAlignInTransaction(transaction, state, dir, alignment);

            if (transaction.docChanged) {
                if (dispatch) {
                    dispatch(transaction);
                }
            }

            return canAlignText;
        };
    }

    /**
     * Update the selected text with new alignment.
     *
     * @param transaction the new prosemirror transaction
     * @param alignment the new alignment value
     * @param updateAlignment whether or not to update the actual alignment of the nodes
     */
    export function setTextAlignInTransaction(
        transaction: Transaction,
        state: EditorState,
        dir: string = "ltr",
        alignment?: TextAlignments,
        updateAlignment: boolean = true
    ): boolean {
        if (alignment) {
            const rtlModePluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(state) as RTLPluginState;
            rtlModePluginState.defaultTextAlign = alignment;
        }
        const selection = transaction.selection;
        const transactionTasks: ITransactionTask[] = [];
        let canAlignText: boolean = false;

        if (selection instanceof CellSelection) {
            canAlignText = true;
            selection.forEachCell((cell, pos) => {
                const align: any | null = cell.attrs.align || null;
                if (align !== alignment) {
                    transactionTasks.push({
                        node: cell,
                        pos,
                        nodeType: cell.type,
                    });
                }
                const childNodes: NodeWithPos[] = findChildren(cell, (child) => {
                    return textTypes.includes(child.type.name);
                });

                // the child's position always starts from 0. relativeOffset corrects the pos globally
                const relativeOffset: number = pos + 1;
                for (const textItem of childNodes) {
                    if (textItem.node.attrs.align !== alignment) {
                        transactionTasks.push({
                            node: textItem.node,
                            pos: textItem.pos + relativeOffset,
                            nodeType: textItem.node.type,
                        });
                    }
                }
            });
        } else {
            transaction.doc.nodesBetween(
                transaction.selection.from,
                transaction.selection.to,
                (node, pos, parentNode) => {
                    const nodeType: NodeType = node.type;

                    if (checkRootList(node, parentNode)) {
                        canAlignText = true;
                        transactionTasks.push({
                            node,
                            pos,
                            nodeType,
                        });

                        const childLists: NodeWithPos[] = getAllChildLists(node);

                        // add 1 to the pos since the child is nexted down a level
                        const nestLevel: number = 1;
                        for (const list of childLists) {
                            const listAlign: any | null = list.node.attrs.align;
                            if (listAlign !== alignment) {
                                transactionTasks.push({
                                    node: list.node,
                                    pos: list.pos + nestLevel,
                                    nodeType: list.node.type,
                                });
                            }
                        }

                        return false;
                    }
                    // Lists need to be aligned as a block. In order to do this we need prevent alignment actions in
                    // list items and let the parent list handle the alignment.
                    if (parentNode.type.name === ExtensionNames.listItem) {
                        return true;
                    }
                    const align: any | null = node.attrs.align || null;
                    if (TextAlign.ALLOWED_NODE_TYPES.has(nodeType.name)) {
                        canAlignText = true;

                        if (align !== alignment) {
                            transactionTasks.push({
                                node,
                                pos,
                                nodeType,
                            });
                        }
                    }
                    return true;
                }
            );
        }

        for (const task of transactionTasks) {
            const attrs: any = {
                ...task.node.attrs,
                dir,
            };

            if (updateAlignment) {
                attrs.align = alignment;
            }

            transaction = transaction.setNodeMarkup(task.pos, task.nodeType, attrs, task.node.marks);
        }

        // Ensures the selected formatting (e.g. bold, italic, color etc) persists after the alignment is
        // changed.
        if (canAlignText && state.storedMarks) {
            transaction.ensureMarks(state.storedMarks);
        }

        return canAlignText;
    }
}
