export const BlackAndWhiteMobile: string[] = [
    "#FFFFFF",
    "#D9D9D9",
    "#B7B7B7",
    "#999999",
    "#666666",
    "#444444",
    "#222222",
    "#000000",
];

export const ColorDefinitionsMobile: string[][] = [
    ["#000000", "#666666", "#b7b7b7"],
    ["#b81313", "#6d0f0f", "#e3a1a1"],
    ["#c48a1b", "#755321", "#e7d0a4"],
    ["#76a23a", "#47622d", "#c8dab0"],
    ["#598bda", "#355483", "#bdd1f0"],
    ["#198381", "#0f4e4d", "#a3cccc"],
    ["#9876bb", "#5b4770", "#d6c8e4"],
    ["#ce4676", "#7b2b47", "#ebb5c8"],
];
