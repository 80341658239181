import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { AllSelection, EditorState, Transaction } from "prosemirror-state";
import { ContentNodeWithPos, isTableSelected } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";

import RTLMode, { RTLPluginState } from "../RTLMode/RTLMode";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { TextAlignmentCommands } from "../textAlign/TextAlignCommands";

// tslint:disable-next-line: no-namespace
export namespace RTLCommands {
    export function toggleRTLMode(type: NodeType, overrideRTLMode: boolean | null = null): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const paragraphPluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(state) as RTLPluginState;

            // either explicitly set RTLMode based what is passed into the function, or toggle it.
            paragraphPluginState.RTLMode = overrideRTLMode !== null ? overrideRTLMode : !paragraphPluginState.RTLMode;
            paragraphPluginState.defaultTextAlign = paragraphPluginState.RTLMode
                ? TextAlignments.RIGHT
                : TextAlignments.LEFT;

            RTLCommands.updateContentAttrs(paragraphPluginState.RTLMode, true)(state, dispatch, view);
            return true;
        };
    }

    /**
     * Set the text direction and alignment of all text items in the editor.
     *
     * @param currentRTLMode whether or not RTL mode is active
     * @param updateAlignments whether or not to update the actual alignment of the nodes
     */
    export function updateContentAttrs(currentRTLMode: boolean, updateAlignments: boolean): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            let doc = state.doc;
            const paragraphPluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(state) as RTLPluginState;

            // get dir and align attrs based on the current text dir mode
            let dir: string;
            if (currentRTLMode) {
                paragraphPluginState.defaultTextAlign = TextAlignments.RIGHT;
                dir = "rtl";
            } else {
                paragraphPluginState.defaultTextAlign = TextAlignments.LEFT;
                dir = "ltr";
            }

            // select the content of the editor
            let sel: AllSelection = new AllSelection(doc);
            const tr: Transaction = state.tr;
            tr.setSelection(sel);

            // set the alignment of the editor
            TextAlignmentCommands.setTextAlignInTransaction(
                tr,
                state,
                dir,
                paragraphPluginState.defaultTextAlign,
                updateAlignments
            );

            // revert back to original selection
            tr.setSelection(state.selection.map(tr.doc, tr.mapping));

            if (dispatch) {
                dispatch(tr);
            }

            return true;
        };
    }

    export function setTextDirectionOnNewContent(
        newState: EditorState,
        contentObject: ContentNodeWithPos | undefined
    ): Transaction | undefined {
        if (!contentObject || isTableSelected(newState.selection)) {
            return undefined;
        }
        const RTLModePluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(newState) as RTLPluginState;
        const tr: Transaction = newState.tr;
        let textDir: string;
        RTLModePluginState.RTLMode ? (textDir = "rtl") : (textDir = "ltr");

        // update the alignment of new content based on the text dir mode, but only if it is in the allowed set of extensions.
        if (RTLMode.ALLOWED_ALIGNMENT_CHANGE.has(contentObject.node.type.name)) {
            RTLModePluginState.RTLMode
                ? (RTLModePluginState.defaultTextAlign = TextAlignments.RIGHT)
                : (RTLModePluginState.defaultTextAlign = TextAlignments.LEFT);
        } else {
            RTLModePluginState.defaultTextAlign = contentObject.node.attrs.align; // keep the default alignment
        }

        // If a newly created paragraph doesn't match the current text mode, set its attrs to the correct ones.
        if (contentObject.node.attrs.dir != textDir) {
            const attrs: object = {
                ...contentObject.node.attrs,
                dir: textDir,
                align: RTLModePluginState.defaultTextAlign,
            };

            tr.setNodeMarkup(contentObject.pos, contentObject.node.type, attrs);

            if (tr.docChanged) {
                return tr;
            }

            return undefined;
        }
    }
}
