import { Node, NodeSpec, NodeType } from "prosemirror-model";
import { CommandFunction } from "@educationperfect/tiptap-commands";
import { OrderedList as TiptapOrderedList } from "@educationperfect/tiptap-extensions";
import { toggleList } from "@educationperfect/tiptap-commands";

import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { ExtensionNames } from "../../utils/ExtensionNames";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { CommandGetter } from "@educationperfect/tiptap";
import { ListCommands } from "./ListCommands";

export class OrderedList extends TiptapOrderedList {
    get schema(): NodeSpec {
        return {
            attrs: {
                order: {
                    default: 1,
                },
                align: {
                    default: TextAlignments.LEFT,
                },
            },
            content: "list_item+",
            group: "block",
            parseDOM: [
                {
                    tag: "ol",
                    getAttrs: (dom) => {
                        if (dom instanceof HTMLElement) {
                            let align: string | null = dom.hasAttribute("align")
                                ? dom.getAttribute("align")
                                : TextAlignments.CENTRE;
                            if (dom.style.textAlign != "") {
                                align = dom.style.textAlign;
                            }

                            return {
                                order: dom.hasAttribute("start")
                                    ? parseInt(dom.getAttribute("start") as string, 10)
                                    : 1,
                                align,
                            };
                        }
                    },
                },
            ],
            toDOM: (node: Node) => {
                const divAttrs = { style: `text-align: ${node.attrs.align}` };
                const olAttrs = { style: `list-style-position:outside;display: inline-block; text-align: left;` };
                return node.attrs.order === 1
                    ? ["div", divAttrs, ["ol", olAttrs, 0]]
                    : ["div", divAttrs, ["ol", Object.assign({ start: node.attrs.order }, olAttrs), 0]];
            },
        };
    }

    public keys({ type, schema }: { type: NodeType; schema: NodeSpec }): { [keyCombo: string]: CommandFunction } {
        return { "Mod-Shift-7": toggleList(type, schema.nodes.list_item) };
    }

    public commands({ type, schema }): CommandGetter {
        return () => ListCommands.toggleList(type, schema.nodes.list_item);
    }

    /**
     * Check whether a list can be inserted at the current selection.
     */
    public static canInsertList(state: EditorState, view: EditorView): boolean {
        const { schema } = state;

        return toggleList(state.schema.nodes[ExtensionNames.orderedList], schema.nodes[ExtensionNames.listItem])(
            state,
            undefined,
            view
        );
    }
}
