import { ElementUtil } from "@educationperfect/ep-web-browser-utils";
import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { RenderingMode } from "../../render/RenderingMode";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class InlineGap extends BaseEmbeddedComponent {
    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    // protected loadParameters(parameters: Dictionary<string>): void
    // {
    // }

    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        var visualElement: HTMLElement;

        switch (this.renderingParameters.mode) {
            case RenderingMode.MODE_PREVIEW:
                visualElement = this.createQuestionVisualElement(parameters, true);
                break;

            case RenderingMode.MODE_QUESTION:
            default:
                visualElement = this.createQuestionVisualElement(parameters);
                break;
        }

        return visualElement;
    }

    private createQuestionVisualElement(parameters: Dictionary<string>, previewMode: boolean = false): HTMLElement {
        let element: HTMLElement;
        if ("color" in parameters) {
            element = ElementUtil.create("annotation");
            element.setAttribute("color", parameters.color);
        } else {
            element = ElementUtil.create("fitg");
        }

        element.setAttribute("gap-id", parameters.id);
        element.setAttribute("text", parameters.text.trim());

        return element;
    }
}
