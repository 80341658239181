import "./drop-down-view.less";
import template from "./drop-down-view.html";

import { OptionalCSSStyles } from "@educationperfect/ep-web-browser-utils";
import { DropDownComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/DropDownComponentMetaData";
import { Editor, NodeView } from "@educationperfect/tiptap";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue } from "vue-property-decorator";

import { EditorPluginState } from "../../../../components/editor/interfaces/EditorPluginState";
import { EditorPlugin } from "../../../../components/editor/plugins/EditorPlugin";
import { InlineComponentFloatingToolbar } from "../../../../components/inlineComponentFloatingToolbar/InlineComponentFloatingToolbar";
import { TipTapComponents } from "../../../../TipTapComponents";
import { ProsemirrorUtils } from "../../../../utils/ProsemirrorUtils";
import { EditorEvents } from "../../../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../../../editorEvents/EditorEventsPlugin";
import { DropDownEditor } from "../editor/DropDownEditor";

@Component({
    template,
    components: {
        [TipTapComponents.DropDownEditor]: DropDownEditor,
        [TipTapComponents.InlineComponentFloatingToolbar]: InlineComponentFloatingToolbar,
    },
})
export class DropDownView extends Vue implements NodeView {
    // Variables
    // ======================================

    // TipTap Props

    /** The current node */
    @Prop() public node!: ProsemirrorNode;

    /** The instance of the editor */
    @Prop() public editor!: Editor;

    /** Whether or not the node is selected */
    @Prop() public selected!: boolean;

    /** The current editor view */
    @Prop() public view!: EditorView;

    // State

    /** The component metadata */
    private componentMetaData: DropDownComponentMetaData | null = null;

    /** Whether or not the editor audio dialog is open */
    private editorOpen: boolean = false;

    /** Keeps track of whether the editor has focus - used for displaying/hiding the inline toolbar */
    private hasFocus: boolean = false;

    // Lifecycle Events
    // ======================================

    /** Lifecyle Hook: Called when the component is first mounted to the DOM. Get the components metadata */
    private mounted(): void {
        this.componentMetaData = this.getComponentMetaData();
        this.editor.on("focus", () => {
            this.hasFocus = true;
        });
        this.editor.on("blur", ({ event }: { event: FocusEvent }) => {
            this.hasFocus = ProsemirrorUtils.handleToolbarClickBlurEvent(event);
        });
    }

    private destroyed(): void {
        // this.editor.off("focus", () => {});
        // this.editor.off("blur", () => {});
    }

    // Logic
    // ======================================

    /**
     * Get the components metadata via plugin callback
     */
    private getComponentMetaData(): DropDownComponentMetaData | null {
        const pluginState: EditorPluginState = EditorPlugin.EditorPluginKey.getState(this.editor.state);
        if (!pluginState || !pluginState.getComponentById) {
            return null;
        }

        return pluginState.getComponentById(this.id) as DropDownComponentMetaData;
    }

    // DOM Events
    // ======================================

    /**
     * DOM Emit:
     * Called when the dropdown editor closes.
     * Dispatch update to the component meta data
     *
     * @param componentMetaData the metadata from the editor
     */
    private onEditorSave(componentMetaData: DropDownComponentMetaData): void {
        const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(this.editor.state);
        if (!pluginState) {
            return;
        }

        pluginState.dispatch(EditorEvents.ComponentMetaDataChange, {
            componentId: this.id,
            metaData: componentMetaData,
        });
        this.componentMetaData = this.getComponentMetaData();
    }

    private onDoubleClick(): void {
        this.editorOpen = true;
    }

    // Gets
    // ======================================

    /** Get the id of the component */
    private get id(): string {
        return this.node.attrs.id;
    }

    /** The width of the dropdown */
    private get widthStyles(): OptionalCSSStyles {
        if (!this.componentMetaData || !this.componentMetaData.Width) {
            return {};
        }

        return {
            width: `${this.componentMetaData.Width}px`,
        };
    }

    /** Get the none key (taken from flash) */
    private get defaultNoneKey(): string {
        return " < None > ";
    }

    /**
     * Check that the inline node is selected and the editor it lives in is also focused.
     * This is done because we want to keep the selected state when the user clicks away
     * from a given editor instance (ie: clicking from one text block to another), but
     * we don't want the selected state border to persist.
     */
    private get isSelected(): boolean {
        return this.selected && this.hasFocus;
    }
}
