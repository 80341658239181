import "./image-node-view.less";
import template from "./image-node-view.html";

import { Editor, NodeView } from "@educationperfect/tiptap";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { EditorPluginState } from "../../../components/editor/interfaces/EditorPluginState";
import { EditorPlugin } from "../../../components/editor/plugins/EditorPlugin";
import { InlineComponentFloatingToolbar } from "../../../components/inlineComponentFloatingToolbar/InlineComponentFloatingToolbar";
import { Seperator } from "../../../components/seperator/Seperator";
import { EditorEvents } from "../../../extensions/editorEvents/EditorEvents";
import { TipTapComponents } from "../../../TipTapComponents";
import { ProsemirrorUtils } from "../../../utils/ProsemirrorUtils";
import { EditorEventsPlugin, EditorEventsState } from "../../editorEvents/EditorEventsPlugin";
import { ImageDialog } from "../imageDialog/ImageDialog";
import { IImageMetadata } from "../imageDialog/interfaces/IImageMetadata";

@Component({
    template,
    components: {
        [TipTapComponents.ImageDialog]: ImageDialog,
        [TipTapComponents.Seperator]: Seperator,
        [TipTapComponents.InlineComponentFloatingToolbar]: InlineComponentFloatingToolbar,
    },
})
export class ImageNodeView extends Vue implements NodeView {
    // Variables
    // ======================================

    // TipTap Props

    /** The current node */
    @Prop() public node!: ProsemirrorNode;

    /** Whether or not the dropdown node is selected */
    @Prop() public selected!: boolean;

    /** Fn to update the attributes of current node */
    @Prop() public updateAttrs!: (attrs: unknown) => unknown;

    /** The instance of the editor */
    @Prop() public editor!: Editor;

    /** The current editor view */
    @Prop() public view!: EditorView;

    // Instance

    /** Whether or not the image editor is open */
    private editorOpen: boolean = false;

    /** Keeps track of whether the editor has focus - used for displaying/hiding the inline toolbar */
    private hasFocus: boolean = false;

    // Lifecycle Events
    // ======================================

    /** Lifecyle Hook: Called when the component is first mounted to the DOM. Get the components metadata */
    private mounted(): void {
        this.editor.on("focus", (e: FocusEvent) => {
            this.hasFocus = true;
        });
        this.editor.on("blur", ({ event }: { event: FocusEvent }) => {
            this.hasFocus = ProsemirrorUtils.handleToolbarClickBlurEvent(event);
        });
    }

    private destroyed(): void {
        // this.editor.off("focus", () => {});
        // this.editor.off("blur", () => {});
    }

    // Watchers
    // ======================================
    /**
     * HACK ALERT
     * For some reason, even though the event listener in mounted sets 'hasFocus' to true when the editor blurs
     * in the case where the aligmnent buttons are pressed, this doesn't seem to update the 'isSelected' getter.
     * This is a patch for that, as when the alignment of an image changes, it gets unselected by default, and then manually reselected (see 'alignCommand' is TextAlignCommands.ts)
     * Because the image becomes selected, this causes 'hasFocus' to be set to true and the 'isSelected' getter functions correctly.
     *
     * If you can see a way to make sure 'isSelected' is using the updated value for 'hasFocus', please delete me!
     * */
    @Watch("selected")
    private onSelectedChange(newVal, oldVal): void {
        if (newVal) {
            this.hasFocus = true;
        }
    }

    // DOM Events
    // ======================================

    /**
     * DOM Click:
     * Called when the image node is double clicked.
     * Open the editor and dispatch open event.
     */
    private onDoubleClick(): void {
        this.openEditor();
    }

    private onEditorClose(): void {
        this.editorOpen = false;
        this.dispatchEditorEvent(EditorEvents.ImageClosed);
    }

    /**
     * DOM Emit:
     * Called when the image editor saves,
     * update the node attrs
     *
     * @param metaData the new image metadata
     */
    private onEditorSave(metaData: IImageMetadata): void {
        this.editorOpen = false;
        if (!metaData) {
            return;
        }

        this.updateAttrs({ ...metaData });
    }

    private onEditorOpened(): void {
        this.openEditor();
    }

    private openEditor(): void {
        this.editorOpen = true;
        this.dispatchEditorEvent(EditorEvents.ImageOpened);
    }

    private dispatchEditorEvent(event: EditorEvents): void {
        const eventsPluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(this.view.state);

        if (!eventsPluginState) {
            return;
        }

        eventsPluginState.dispatch(event);
    }

    // Gets
    // ======================================

    /** Get the list of attributes that should be applied to the image */
    private get attrs(): unknown {
        return this.node.attrs;
    }

    /** Get whether or not the current user is an admin */
    private get isAdmin(): boolean {
        const pluginState: EditorPluginState = EditorPlugin.EditorPluginKey.getState(this.editor.state);
        if (!pluginState) {
            return false;
        }

        return pluginState.isAdmin() ?? false;
    }

    /**
     * Check that the inline node is selected and the editor it lives in is also focused.
     * This is done because we want to keep the selected state when the user clicks away
     * from a given editor instance (ie: clicking from one text block to another), but
     * we don't want the selected state border to persist.
     */
    private get isSelected(): boolean {
        return this.selected && this.hasFocus;
    }

    /**
     * Fairly arbitrary rules that determine the placement of the inline toolbar, depending on the image's size.
     * If the image is below a certain size threshold, show the inline toolbar below the image (otherwise it blocks too much of the image)
     */
    private get top(): number {
        if (this.node.attrs.height < 70 || this.node.attrs.width < 100) {
            return parseInt(this.node.attrs.height, 10) + 15;
        }
        return parseInt(this.node.attrs.height, 10) - 50;
    }

    private get right(): number {
        if (this.node.attrs.height < 70 || this.node.attrs.width < 100) {
            return -3;
        }
        return 15;
    }
}
