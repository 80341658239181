import { CommandGetter, Mark } from "@educationperfect/tiptap";
import { CommandFunction, toggleMark } from "@educationperfect/tiptap-commands";
import { MarkSpec, MarkType } from "prosemirror-model";

import { Superscript } from "./Superscript";

export class Subscript extends Mark {
    public static readonly MARK_NAME = "subscript";
    public get name(): string {
        return Subscript.MARK_NAME;
    }

    public get schema(): MarkSpec {
        return {
            excludes: `${Subscript.MARK_NAME} ${Superscript.MARK_NAME}`,
            parseDOM: [
                { tag: "sub" },
                {
                    style: "vertical-align",
                    getAttrs: (value) => {
                        return value === "sub" && null;
                    },
                },
            ],
            toDOM(mark: MarkSpec) {
                return ["sub", 0];
            },
        };
    }

    public commands({ type }): CommandGetter {
        return () => toggleMark(type);
    }

    public keys({ type }: { type: MarkType }): { [keyCombo: string]: CommandFunction } {
        return { "Mod-,": toggleMark(type) };
    }
}
