/* eslint-disable @typescript-eslint/no-namespace */
import {
    ContentMatch,
    Fragment,
    Node as ProsemirrorNode,
    NodeRange,
    NodeType,
    ResolvedPos,
    Slice,
} from "prosemirror-model";
import { EditorState, NodeSelection, Selection, TextSelection, Transaction } from "prosemirror-state";
import { canJoin, liftTarget, ReplaceAroundStep } from "prosemirror-transform";
import { EditorView } from "prosemirror-view";

// tslint:disable-next-line: no-namespace
export namespace ParagraphCommands {
    /**
     * This mirrors the function of the same name in `prosemirror-commands`,
     * but with one difference; it won't call `deleteBarrier` if nodeBefore
     * is a list (I have highlighted the area where the change has been made)
     */
    export function joinBackward(state: EditorState, dispatch?: (tr: Transaction) => void, view?: EditorView): boolean {
        const ref: TextSelection = state.selection;
        const $cursor: ResolvedPos | null | undefined = ref.$cursor;
        if (!$cursor || (view ? !view.endOfTextblock("backward", state) : $cursor.parentOffset > 0)) {
            return false;
        }

        const $cut: ResolvedPos | null = findCutBefore($cursor);

        // If there is no node before this, try to lift
        if (!$cut) {
            const range: NodeRange | null | undefined = $cursor.blockRange();
            const target: number | null | undefined = range && liftTarget(range);
            if (target == null) {
                return false;
            }
            if (dispatch) {
                dispatch(state.tr.lift(range!, target).scrollIntoView());
            }
            return true;
        }

        const before: ProsemirrorNode = $cut.nodeBefore!;
        // Apply the joining algorithm
        if (!before.type.spec.isolating && deleteBarrier(state, $cut, dispatch)) {
            return true;
        }

        // If the node below has no content and the node above is
        // selectable, delete the node below and select the one above.
        if ($cursor.parent.content.size == 0 && (textblockAt(before, "end") || NodeSelection.isSelectable(before))) {
            if (dispatch) {
                const tr: Transaction = state.tr.deleteRange($cursor.before(), $cursor.after());
                tr.setSelection(
                    textblockAt(before, "end")
                        ? (Selection.findFrom(tr.doc.resolve(tr.mapping.map($cut.pos, -1)), -1) as Selection<any>)
                        : NodeSelection.create(tr.doc, $cut.pos - before.nodeSize)
                );
                dispatch(tr.scrollIntoView());
            }
            return true;
        }

        // If the node before is an atom, delete it
        if (before.isAtom && $cut.depth == $cursor.depth - 1) {
            if (dispatch) {
                dispatch(state.tr.delete($cut.pos - before.nodeSize, $cut.pos).scrollIntoView());
            }
            return true;
        }
        return false;
    }

    function findCutBefore($pos: ResolvedPos): ResolvedPos | null {
        if (!$pos.parent.type.spec.isolating) {
            for (let i = $pos.depth - 1; i >= 0; i--) {
                if ($pos.index(i) > 0) {
                    return $pos.doc.resolve($pos.before(i + 1));
                }
                if ($pos.node(i).type.spec.isolating) {
                    break;
                }
            }
        }
        return null;
    }

    function deleteBarrier(state: EditorState, $cut: ResolvedPos, dispatch?: (tr: Transaction) => void): boolean {
        const before: ProsemirrorNode = $cut.nodeBefore!;
        const after: ProsemirrorNode = $cut.nodeAfter!;
        let conn;
        let match;
        if (before.type.spec.isolating || after.type.spec.isolating) {
            return false;
        }
        if (joinMaybeClear(state, $cut, dispatch)) {
            return true;
        }

        if (
            $cut.parent.canReplace($cut.index(), $cut.index() + 1) &&
            // tslint:disable-next-line: no-conditional-assignment
            (conn = (match = before.contentMatchAt(before.childCount)).findWrapping(after.type)) &&
            match.matchType(conn[0] || after.type).validEnd
        ) {
            if (dispatch) {
                let tr: Transaction;
                // This if statement is where the code differs from `joinBackwards` in `prosemirror-commands`. We want to
                // add the text from the current paragraph to the last list item instead of inserting it in a new list item.
                if (
                    after.type.name === "paragraph" &&
                    (before.type.name === "ordered_list" || before.type.name === "bullet_list")
                ) {
                    const lastChild: ProsemirrorNode = before.lastChild!;
                    tr = state.tr;
                    // delete the paragraph node (this MUST happen first, if it doesn't then extra text will randomly get inserted into the last list item)
                    const startDeletePos: number = $cut.pos;
                    const endDeletePos: number = startDeletePos + after.nodeSize;
                    tr.delete(startDeletePos, endDeletePos);
                    // append text from focused paragraph to last list item
                    const insertTextPos: number = $cut.pos - lastChild.nodeSize + lastChild.textContent.length + 1;
                    const text: string = after.textContent;
                    const insertSelection: TextSelection = TextSelection.create(state.doc, insertTextPos);
                    tr.setSelection(insertSelection);
                    // insert text
                    tr.insertText(text, insertTextPos);
                    // update selection
                    tr.setSelection(insertSelection);
                } else {
                    const end: number = $cut.pos + after.nodeSize;
                    let wrap: Fragment = Fragment.empty;
                    for (let i = conn.length - 1; i >= 0; i--) {
                        wrap = Fragment.from(conn[i].create(null, wrap));
                    }
                    wrap = Fragment.from(before.copy(wrap));
                    tr = state.tr.step(
                        new ReplaceAroundStep(
                            $cut.pos - 1,
                            end,
                            $cut.pos,
                            end,
                            new Slice(wrap, 1, 0),
                            conn.length,
                            true
                        )
                    );
                    const joinAt = end + 2 * conn.length;
                    if (canJoin(tr.doc, joinAt)) {
                        tr.join(joinAt);
                    }
                }
                dispatch(tr.scrollIntoView());
            }
            return true;
        }

        const selAfter: Selection | null | undefined = Selection.findFrom($cut, 1);
        const range: NodeRange | null | undefined = selAfter && selAfter.$from.blockRange(selAfter.$to);
        const target: number | null | undefined = range && liftTarget(range);
        if (target != null && target >= $cut.depth) {
            if (dispatch) {
                dispatch(state.tr.lift(range!, target).scrollIntoView());
            }
            return true;
        }

        return false;
    }

    function joinMaybeClear(state: EditorState, $pos: ResolvedPos, dispatch?: (tr: Transaction) => void): boolean {
        const before: ProsemirrorNode = $pos.nodeBefore!;
        const after: ProsemirrorNode = $pos.nodeAfter!;
        const index: number = $pos.index();
        if (!before || !after || !(before.type as any).compatibleContent(after.type)) {
            return false;
        }
        if (!before.content.size && $pos.parent.canReplace(index - 1, index)) {
            if (dispatch) {
                dispatch(state.tr.delete($pos.pos - before.nodeSize, $pos.pos).scrollIntoView());
            }
            return true;
        }
        if (!$pos.parent.canReplace(index, index + 1) || !(after.isTextblock || canJoin(state.doc, $pos.pos))) {
            return false;
        }
        if (dispatch) {
            dispatch(
                state.tr
                    .clearIncompatible($pos.pos, before.type, before.contentMatchAt(before.childCount))
                    .join($pos.pos)
                    .scrollIntoView()
            );
        }
        return true;
    }

    function textblockAt(node: ProsemirrorNode | null | undefined, side: string): boolean {
        for (; node; node = side == "start" ? node.firstChild : node.lastChild) {
            if (node.isTextblock) {
                return true;
            }
        }
        return false;
    }
}
