import template from "./image-dialog.html";

import "./image-dialog.less";

import { MediaLibraryItemDetails } from "@educationperfect/ep-web-services/lib/services/MediaLibrary/BusinessObjects/MediaLibraryItemDetails";
import { EdsTertiaryButton } from "@educationperfect/ep-web-ui-components";
import { MediaTypes } from "@educationperfect/ep-web-utils";
import { Switch } from "@educationperfect/view-design";
import { Component, Prop } from "vue-property-decorator";

import { MediaBaseController } from "../../../components/mediaBase/MediaBaseController";
import { MediaDialog } from "../../../components/mediaBase/mediaDialog/MediaDialog";
import { TipTapComponents } from "../../../TipTapComponents";
import { IImageMetadata } from "./interfaces/IImageMetadata";

/** The name of the event to create/update image */
const SAVE_EVENT: string = "save";

const CLOSE_EVENT: string = "on-close";

// tslint:disable-next-line:max-classes-per-file
@Component({
    template,
    components: {
        iSwitch: Switch,
        [TipTapComponents.MediaDialog]: MediaDialog,
        EdsTertiaryButton
    },
    name: TipTapComponents.ImageDialog,
})

export class ImageDialog extends MediaBaseController {
    // Variables
    // =========================================

    // Props

    /** [Prop, Bound] Whether or not the dialog is visilbe */
    @Prop({ required: true, type: Boolean, default: false }) public readonly visible!: boolean;

    /** [Prop] the image metadata */
    @Prop({ required: false, type: Object }) public readonly componentMetaData?: IImageMetadata;

    /** [Prop] the max width for the image  */
    @Prop({ required: false, type: Number }) public readonly maxPxWidth?: number;

    /** [Prop, bound] Whether or not the currently logged in user is an admin */
    @Prop({ required: false, type: Boolean, default: false }) private readonly isAdmin!: boolean;

    // Fields

    /** [Bound] the value of the title (alt) filed */
    protected altText: string | null = null;

    /** [Bound] the value of the height field */
    protected height: number | null = null;

    /** [Bound] the valie of the width fields */
    protected width: number | null = null;

    // Preview

    /** The normal (natural) width of the image */
    protected naturalWidth: number = 0;

    /** The normal (natural) height of the image */
    protected naturalHeight: number = 0;

    // Errors

    /** The error (if any) for the width and height fields */
    protected widthOrHeightError: string | null = null;

    // Lifecyle Events
    // =========================================

    /** Lifecycle Event: Called when the component is first mounted to the DOM. Call super. */
    protected async mounted(): Promise<void> {
        super.mounted();
    }

    // Logic
    // =========================================

    /**
     * [Abstract] setup the editor based on the components
     * metadata.
     */
    protected initialise(): void {
        if (!this.componentMetaData) {
            return;
        }

        this.mediaUrl = this.componentMetaData.src!;
        this.width = this.componentMetaData.width;
        this.altText = this.componentMetaData.alt;

        this.loadImage(this.mediaUrl, (imageElement: HTMLImageElement) => {
            if (this.width == null) {
                return;
            }

            // Ensure new sizes are not greater than the max allowed
            if (this.maxPxWidth != null && this.width > this.maxPxWidth) {
                this.width = this.maxPxWidth;
                this.height = this.maxHeight;
            } else {
                const height: number = Math.floor(this.width / this.naturalAspectRatio);
                this.height = height;
            }
        });
    }

    /** [Abstract] Reset field values back to their defaults */
    protected reset(): void {
        this.mediaUrl = "";
        this.width = null;
        this.height = null;
        this.naturalWidth = 0;
        this.naturalHeight = 0;
        this.widthOrHeightError = null;
        this.altText = "";
    }

    /**
     * [Abstract] Validate the input fields and show any errors
     */
    protected validate(): boolean {
        if (this.height == 0 || this.width == 0) {
            this.widthOrHeightError = "Images cannot have a width or height of 0";
            return false;
        }

        return true;
    }

    /**
     * Load imaeg by url and run callback when successfull.
     *
     * @param url the image URL to load
     * @param successFn the function to run on successful load
     */
    private loadImage(url: string, successFn: (audioElement: HTMLImageElement) => void): void {
        if (!url) {
            return;
        }

        const imageElement: HTMLImageElement = new Image();
        imageElement.src = url;
        imageElement.onload = () => {
            this.naturalWidth = imageElement.naturalWidth;
            this.naturalHeight = imageElement.naturalHeight;
            successFn(imageElement);
            imageElement.remove();
        };

        imageElement.onerror = () => {
            imageElement.remove();
        };

        document.body.appendChild(imageElement);
    }

    /**
     * Update the width or height to conform to aspect ratio
     *
     * @param width the new set width
     * @param height the new set height
     */
    private onChangeAspectRatio(width: number | null = null, height: number | null = null): void {
        if (width == null && height == null) {
            return;
        }

        if (width != null) {
            this.height = Math.floor(width / this.naturalAspectRatio);
        }

        if (height != null) {
            this.width = Math.floor(height * this.naturalAspectRatio);
        }
    }

    /**
     * Initialise new image metadata
     */
    private onMediaURLChange(): void {
        this.loadImage(this.mediaUrl, (imageElement: HTMLImageElement) => {
            // Ensure new sizes are not greater than the max allowed
            if (this.maxPxWidth != null && imageElement.naturalWidth > this.maxPxWidth) {
                this.width = this.maxPxWidth;
                this.height = Math.floor(this.width / this.naturalAspectRatio);
            } else {
                this.width = imageElement.naturalWidth;
                this.height = imageElement.naturalHeight;
            }
        });
    }

    // DOM Events
    // =======================================

    /**
     * DOM Click:
     * Called when the "Reset to max size" button is clicked.
     * Reset the width and height back to their natural size.
     */
    private onResetToMaxSizeClick(): void {
        this.width = this.maxWidth;
        this.height = this.maxHeight;
    }

    /**
     * DOM Emit:
     * [Override] Called when the "Save" button is clicked.
     * Saves the current metadata.
     */
    protected onDoneClick(): void {
        super.onDoneClick();

        const imgMetadata: IImageMetadata = {
            src: this.mediaUrl ? this.mediaUrl : "",
            width: this.width,
            height: this.height,
            alt: this.altText,
            aspectRatioNatural: this.naturalAspectRatio,
        };

        this.$emit(SAVE_EVENT, imgMetadata);
    }

    /**
     * DOM Emit:
     * Called when the media gallery changes selection.
     * Initialise new image metadata
     *
     * @param item the newly selected item for the media gallery
     */
    protected onMediaGalleryChange(item: MediaLibraryItemDetails): void {
        super.onMediaGalleryChange(item);
        this.altText = item.Title;
        this.onMediaURLChange();
    }

    /**
     * DOM Emit:
     * Admin Only - Called when a valid url has been entered into
     * the URL field.
     *
     * @param url the URL field value
     */
    protected onURLFieldChange(url: string): void {
        super.onURLFieldChange(url);
        this.onMediaURLChange();
    }

    /**
     * DOM Emit:
     * Called when the width field changes.
     * Update height field to match aspect ratio
     *
     * @param event the new width
     */
    private onWidthChange(newWidth: number): void {
        if (newWidth == null) {
            return;
        }

        this.width = newWidth;
        this.onChangeAspectRatio(newWidth, null);
    }

    /**
     * DOM Emit:
     * Called when the height field changes.
     * Update width field to match aspect ratio
     *
     * @param newHeight the change event
     */
    private onHeightChange(newHeight: number): void {
        if (newHeight == null) {
            return;
        }

        this.height = newHeight;
        this.onChangeAspectRatio(null, newHeight);
    }

    // Gets
    // =======================================

    /** [Abstrct] Get the media type for the media gallery */
    protected get mediaType(): MediaTypes {
        return MediaTypes.IMAGE;
    }

    /** The natural aspect ratio of the image */
    private get naturalAspectRatio(): number {
        if (this.naturalWidth == null || this.naturalHeight == null) {
            return 1;
        }

        return this.naturalWidth / this.naturalHeight;
    }

    /** Get the max allowed width of the image */
    private get maxWidth(): number {
        if (this.maxPxWidth != null && this.width != null) {
            return Math.min(this.maxPxWidth, this.naturalWidth);
        }

        return this.naturalWidth;
    }

    /** Get the max allowed height for the image. */
    private get maxHeight(): number {
        return Math.floor(this.maxWidth / this.naturalAspectRatio);
    }

    /** Get whether or not the image can fill remaining space */
    private get canFillSpace(): boolean {
        if (this.maxPxWidth == null || this.naturalWidth == null) {
            return false;
        }

        return this.naturalWidth > this.maxPxWidth;
    }

    // Pipes
    // ==========================================

    // Vue class component is dumb and incorrectly uses
    // `this` which means that we can't call functions
    // that come from an emit event. Pipe them to super here.
    // Intentionally undocumented

    protected onVisibleChange(visible: boolean): void {
        super.onVisibleChange(visible);
    }

    protected onCancelClick(): void {
        super.onCancelClick();
        this.$emit(CLOSE_EVENT);
    }

    protected onReset(): void {
        super.onReset();
    }
}
