import { GuidUtil } from "@educationperfect/ep-web-utils";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, TextSelection, Transaction } from "prosemirror-state";
import { findChildrenByType, findTextNodes, NodeWithPos } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";
import { nodeIsActive } from "@educationperfect/tiptap-utils";

import { GapNodeCommands } from "../gapNode/GapNodeCommands";
import { HighlightData } from "./HighlightData";
import { HighlightNode } from "./HighlightNode";

// tslint:disable-next-line:no-namespace
export namespace HighlightCommands {
    export interface Interface extends GapNodeCommands.Interface {
        toggleHighlightGap: ({ actionCorrect }: { actionCorrect: boolean }) => CommandFunction;
        markAllTextAsGap: () => CommandFunction;
        removeAllGaps: () => CommandFunction;
        createCorrectGap: () => CommandFunction;
        updateGapNodeCorrect: ({ gapID, correct }: { gapID: string; correct: boolean }) => CommandFunction;
    }

    /**
     * Mark all text as a gap
     *
     * @param type the node type
     */
    export function markAllTextAsGap(type: NodeType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            // Find all text nodes from document
            const tr: Transaction = state.tr;
            let textNodes: NodeWithPos[] = findTextNodes(state.doc);
            let previousNodePos: number = 0;
            while (textNodes.length > 0) {
                const textNode: NodeWithPos = textNodes[0];
                if (textNode.node && textNode.node.text) {
                    // Remove everything but the first word from string
                    const firstWord: string = textNode.node.text.trim().replace(/ .*/, "");

                    // Find the first time that word exists in the real string
                    const actualFirstWordIndex: number = textNode.node.text.indexOf(firstWord);

                    // If the node is empty, remove it and skip
                    if (firstWord.length == 0) {
                        previousNodePos = textNode.pos + 1;
                        textNodes.splice(0, 1);
                        continue;
                    }

                    // Crreate highlight data
                    const highlightData: HighlightData = {
                        id: GuidUtil.create(),
                        text: firstWord,
                        correct: false,
                    };

                    // Get postitions based on where the word actually is, store previous node pos
                    // for filtering out lower nodes.
                    const anchor = tr.doc.resolve(textNode.pos + actualFirstWordIndex);
                    const head = tr.doc.resolve(textNode.pos + actualFirstWordIndex + firstWord.length);
                    previousNodePos = textNode.pos + 1;

                    // Replace the word with highlight
                    const selection: TextSelection = new TextSelection(anchor, head);
                    tr.setSelection(selection);
                    selection.replaceWith(tr, type.create(highlightData));
                }

                // Filter out any nodes that are before the current running postion or that have not real text
                textNodes = findTextNodes(tr.doc).filter((node: NodeWithPos) => node.pos >= previousNodePos);
            }

            if (dispatch) {
                dispatch(tr);
            }

            return true;
        };
    }

    /**
     * Remove all gaps
     *
     * @param type the node type
     */
    export function removeAllGaps(type: NodeType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;

            let highlightNodes: NodeWithPos[] = findChildrenByType(state.doc, type);
            while (highlightNodes.length > 0) {
                const highlightNode = highlightNodes[0];
                const text: string = highlightNode.node.attrs.text;

                const nodeSelection: NodeSelection = new NodeSelection(tr.doc.resolve(highlightNode.pos));

                if (nodeSelection.empty) {
                    continue;
                }

                tr.setSelection(nodeSelection);
                tr.deleteSelection();
                tr.insertText(text);

                highlightNodes = findChildrenByType(tr.doc, type);
            }

            view.dispatch(tr);
            return true;
        };
    }

    /**
     * Update whether or not a highlight is correct
     *
     * @param type the node type
     * @param gapID the id of the highlight
     * @param correct whether or not the highlight is correct
     */
    export function updateGapNodeCorrect(type: NodeType, gapID: string, correct: boolean): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            let nodeSelection: NodeSelection = GapNodeCommands.getGapNodeSelection(
                state.doc,
                gapID,
                HighlightNode.NODE_NAME
            );

            if (nodeSelection.empty) {
                return false;
            } else {
                const gapNodePosition: number = nodeSelection.$from.pos;
                const attrs: HighlightData = { id: gapID, text: nodeSelection.node.attrs.text, correct };
                tr.setNodeMarkup(gapNodePosition, type, attrs);
                nodeSelection = GapNodeCommands.getGapNodeSelection(tr.doc, gapID, HighlightNode.NODE_NAME);
                tr.setSelection(nodeSelection);
                view.dispatch(tr);
                return true;
            }
        };
    }

    /**
     * Create a highlight node that is correct by default
     *
     * @param type the node type
     */
    export function createCorrectGap(type: NodeType): CommandFunction {
        return GapNodeCommands.markGapCommand(type, HighlightNode.NODE_NAME, { correct: true });
    }

    /**
     * Toggle or reverse the current highlight node,
     * depending on the state
     *
     * @param type the type of the node
     * @param intentIsCorrect whether or not the fn is being called from a "correct (e.g. shift, green tick)" action
     */
    export function toggleHighlightGap(type: NodeType, intentIsCorrect: boolean): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const isActive = nodeIsActive(state, type);
            if (!isActive) {
                return GapNodeCommands.markGapCommand(type, HighlightNode.NODE_NAME, { correct: intentIsCorrect })(
                    state,
                    dispatch,
                    view
                );
            }

            const highlightNode = (state.selection as NodeSelection).node;
            const id: string = highlightNode.attrs.id;
            const correct: string | boolean = highlightNode.attrs.correct;
            const isCorrect: boolean = typeof correct == "string" ? correct == "true" : correct;

            if ((isCorrect && intentIsCorrect) || (!correct && !intentIsCorrect)) {
                return GapNodeCommands.removeGapCommand(id, HighlightNode.NODE_NAME)(state, dispatch, view);
            }

            return HighlightCommands.updateGapNodeCorrect(type, id, !isCorrect)(state, dispatch, view);
        };
    }
}
