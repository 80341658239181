import {
    FormulaFormats,
    MathEquationManager,
    MathFormattingOptions,
    WorkingFormulaEditorConverter,
} from "@educationperfect/ep-web-math";
import { Dictionary, StringUtil } from "@educationperfect/ep-web-utils";

import { EditorFeatureFlags } from "../../../../models/EditorFeatureFlags";
import { TipTapEvents } from "../../../../TipTapEvents";
import { BaseComponent } from "../components/BaseComponent";
import { IComponentParser } from "../components/IComponentParser";
import { DOMComputedStyle } from "../dom/DOMComputedStyle";
import { DOMParagraph } from "../dom/DOMParagraph";
import { DOMParagraphInlineComponent } from "../dom/DOMParagraphInlineComponent";
import { DOMParagraphInlineFormula } from "../dom/DOMParagraphInlineFormula";
import { DOMParagraphInlineText } from "../dom/DOMParagraphInlineText";
import { IDOMParagraphInline } from "../dom/IDOMParagraphInline";
import { IRenderingParameters } from "./IRenderingParameters";

export class DOMParagraphRenderer {
    public style: DOMComputedStyle;

    public componentParser: IComponentParser;

    public renderingParameters: IRenderingParameters;

    public allowEmbeddedTextboxComponent: boolean;

    public static SPLIT_INLINE_ELEMENT_REGEX: RegExp = /((?:```|`)[^`]+(?:```|`))|(\[[^\]]+\])(?![^\{\}]*\})/g;

    constructor(
        style: DOMComputedStyle,
        componentParser: IComponentParser,
        renderingParameters: IRenderingParameters,
        private enabledFeatures: EditorFeatureFlags,
        allowEmbeddedTextboxComponent: boolean = false
    ) {
        this.style = style;
        this.componentParser = componentParser;
        this.renderingParameters = renderingParameters;
        this.allowEmbeddedTextboxComponent = allowEmbeddedTextboxComponent;
    }

    public renderParagraph(textTemplate: string, insideTable: boolean): DOMParagraph {
        const paragraph: DOMParagraph = new DOMParagraph(insideTable);
        const tokenisedText: string = textTemplate.replace(/\\\[|\\\]|\\`/g, (s) => {
            switch (s) {
                case "\\[":
                    return "\\@\\";
                case "\\]":
                    return "\\#\\";
                case "\\`":
                    return "\\!\\";
                default:
                    return s;
            }
        });

        const parts: string[] = tokenisedText.split(DOMParagraphRenderer.SPLIT_INLINE_ELEMENT_REGEX).map((part) => {
            if (!part || part === "") {
                return "";
            }

            return part.replace(/\\[@#!]\\/g, (s) => {
                switch (s) {
                    case "\\@\\":
                        return "\\[";
                    case "\\#\\":
                        return "\\]";
                    case "\\!\\":
                        return "\\`";
                    default:
                        return s;
                }
            });
        });

        if (parts.length === 1) {
            const span: IDOMParagraphInline = new DOMParagraphInlineText(textTemplate);
            paragraph.addInlineElement(span);
        } else {
            this.parseAdvancedInlineText(paragraph, parts);
        }
        return paragraph;
    }

    private parseAdvancedInlineText(paragraph: DOMParagraph, parts: string[]): void {
        parts.forEach((text: string) => {
            if (text != null) {
                if (StringUtil.startsEndsWithChar(text, "`")) {
                    if (StringUtil.startsWith(text, "```") && StringUtil.endsWith(text, "```")) {
                        // latex format ```k=\frac{1}{2x^2}```
                        if (text.indexOf("`", 3) === text.length - 3) {
                            // make sure text doesn't contain other closing backtick
                            const formula: string = StringUtil.trimEnds(text, 3);
                            const inlineFormula: IDOMParagraphInline = new DOMParagraphInlineFormula(
                                formula,
                                this.style,
                                FormulaFormats.LATEX
                            );
                            paragraph.addInlineElement(inlineFormula);
                        }
                    }
                    // ascii math `k=1/2x^2`
                    if (text.indexOf("`", 1) === text.length - 1) {
                        // make sure text doesn't contain other closing backtick
                        const mathEquationManager: MathEquationManager = new MathEquationManager();
                        const formattingOptions: MathFormattingOptions = {} as MathFormattingOptions;
                        const formula: string = StringUtil.trimEnds(text);
                        const convertedFormula: string = WorkingFormulaEditorConverter.convertFormulas(
                            formula,
                            mathEquationManager,
                            formattingOptions
                        );

                        const inlineFormula: IDOMParagraphInline = new DOMParagraphInlineFormula(
                            convertedFormula,
                            this.style,
                            FormulaFormats.LATEX
                        );
                        paragraph.addInlineElement(inlineFormula);
                        TipTapEvents.Common.formulaConvertedEvent.dispatch({
                            from: `\`${formula}\``,
                            to: `\`\`\`${convertedFormula}\`\`\``,
                        });
                    }
                } else if (StringUtil.startsEndsWithChar(text, "[", "]")) {
                    const component: IDOMParagraphInline | null = this.parseInlineComponent(StringUtil.trimEnds(text));
                    if (component != null) {
                        paragraph.addInlineElement(component);
                    }
                } else {
                    const span: IDOMParagraphInline = new DOMParagraphInlineText(text);
                    if (text.length > 0) {
                        paragraph.addInlineElement(span);
                    }
                }
            }
        });
    }

    private parseInlineComponent(componentSyntax: string): IDOMParagraphInline | null {
        const defaultAttributes: Dictionary<string> = this.style.defaultParameters;
        const component: BaseComponent = this.componentParser.parse(
            componentSyntax,
            this.renderingParameters,
            defaultAttributes,
            this.enabledFeatures
        );
        const isTextboxComponent = component && component.type === "textbox";
        const shouldRenderComponent = !isTextboxComponent || this.allowEmbeddedTextboxComponent;
        if (component != null && shouldRenderComponent) {
            // if (this.identifiedComponents && component.id)
            // {
            //     this.identifiedComponents.registerBaseComponent(component);
            // }

            return new DOMParagraphInlineComponent(component);
        }

        return null;
    }
}
