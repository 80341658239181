export const enum ArabicScriptTypes {
    UNKNOWN = 0,
    ARABIC = 1,
    SUPPLEMENT = 2,
    EXTENDED_A = 4,
    PRESENTATION_FORMS_A = 8,
    PRESENTATION_FORMS_B = 16,
    RUMI_NUMERAL_SYMBOLS = 32,
    INDIC_SIYAQ_NUMBERS = 64,
    MATHEMATICAL_ALPHABETIC_SYMBOLS = 128,
}
