import "./assets/less/components.less";
import "./demo.less";
import "./libs/common/components/embedded/highlight/highlight.less";
import "@educationperfect/view-design/src/styles/index.less";
import template from "./demo.html";

import "@educationperfect/mathquill/build/mathquill.min.js";

import { MathQuillFacade } from "@educationperfect/ep-web-math";
import { AdvancedRenderer, DOMComputedStyle, RenderingContext, RenderingMode } from "@educationperfect/ep-web-renderer";
import { QuestionComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/QuestionComponentMetaData";
import { EdsPrimaryButton } from "@educationperfect/ep-web-ui-components";
import { URLUtil } from "@educationperfect/ep-web-utils";
import { Editor } from "@educationperfect/tiptap";
import { Input } from "@educationperfect/view-design";
import Vue from "vue";
import Component from "vue-class-component";

import { TipTapComponents } from "../../src";
// eslint-disable-next-line import/no-relative-packages -- required for loading .vue files
import AiAnnotationUi from "../../src/components/aiAnnotationUi/AiAnnotationUi.vue";
import { EPEditor } from "../../src/components/editor/EPEditor";
import { EPToolbar } from "../../src/components/toolbar/EPToolbar";
import { AiAnnotationCommands } from "../../src/extensions/aiAnnotation/AiAnnotationCommands";
import { AiAnnotation } from "../../src/extensions/aiAnnotation/AnnotationTypings";
import { EditorFeatureFlags } from "../../src/models/EditorFeatureFlags";
import { ComponentParser } from "./libs/common/components/ComponentParser";
import { annotationsTestData, annotationTestUserAnswer } from "./testData/AiAnnotationTestData";

// Constanst set during webpack build
declare const MATHQUILL_PATH: string;

/** The current enabled feature flags for testing */
const ENABLED_FEATURES: EditorFeatureFlags = {
    FITG: true,
    TEXT_ALIGN: true,
    LIST: true,
    SUPERSCRIPT: true,
    SUBSCRIPT: true,
    BOLD: true,
    ITALIC: true,
    UNDERLINE: true,
    TABLE: true,
    TEXT_STYLES: true,
    CLEAR_FORMATTING: true,
    TEXT_HIGHLIGHT: true,
    TEXT_COLOR: true,
    FORMULA: true,
    ANNOTATION: false,
    STRIKE: true,
    HARD_BREAK: true,
    HIGHLIGHT: false,
    LINK: true,
    IME: true,
    TEXT_EMIT: true,
    RTL_MODE: true,
    IMAGE: true,
    SOUND: true,
    VARIABLES: true,
    DROPDOWN: true,
    NUMBER_BOX: true,
    TEXT_BOX: true,
    AI_ANNOTATION: true,
};

const initialEditorValue = annotationTestUserAnswer;

@Component({
    template,
    name: "Demo",
    components: {
        EPToolbar,
        EPEditor,
        Input,
        EdsPrimaryButton,
        [TipTapComponents.AiAnnotationUi]: AiAnnotationUi,
    },
})
export class Demo extends Vue {
    // Variables
    // =======================================

    /** The editor reference */
    private editor: Editor | null = null;

    /** The second editor instance in multi editor mode */
    private editor2: Editor | null = null;

    private testMultipleEditors: boolean = false;

    /** Features enabled for current demo version */
    private features: EditorFeatureFlags | null = ENABLED_FEATURES;

    /** Text to use when editor is empty */
    private placeholderText: string = "Enter text here...";

    /** (2-way bound) Uglee text template */
    private editorTemplate: string | undefined = "";

    private templateInput: string | undefined = "";

    /** Html output */
    private htmlOutput: string | undefined = "";

    private show: boolean = true;

    /** The list of custom colors for the editor */
    private customColorPickerColors: string[] = [];

    /** The components metadata that is present in the text block */
    private componentsMetaData: QuestionComponentMetaData[] = [];

    // Lifecycle
    // =======================================

    /**
     * Lifecycle Event:
     * Called when the component is first added to the
     * DOM
     */
    private mounted(): void {
        this.editor = this.$refs.editor ? (this.$refs.editor as EPEditor).editor : null;
        const baseUrl: string = URLUtil.getBaseURL();
        const pathName: string = window.location.pathname;
        const basePath: string = pathName.substr(0, pathName.lastIndexOf("/"));

        MathQuillFacade.setPath(`${baseUrl}${basePath}${MATHQUILL_PATH}`, false);
        MathQuillFacade.requireMathQuill();

        this.editor?.setContent(initialEditorValue);
    }

    // DOM Events
    // =======================================

    /**
     * DOM Event:
     * Update the outputs on a change event
     */
    private onChange(): void {
        this.templateInput = this.epEditor && this.epEditor.getTemplate();
        this.updateOutputs();
        console.log(this.editor?.getHTML());
    }

    private onTemplateChange(): void {
        this.editorTemplate = this.templateInput;
        this.updateOutputs();
    }

    private onTestMultipleEditorsChange(): void {
        this.$nextTick(() => {
            this.editor2 = this.$refs.editor2 ? (this.$refs.editor2 as EPEditor).editor : null;
        });
    }

    private onAnnotate() {
        const staggered = true;

        if (!this.editor) {
            throw new Error("Editor not initialized");
        }

        const runStaggeredAnnotation = () => {
            const testAnnotations = [...annotationsTestData];
            const previousAnnotations: AiAnnotation[] = [];

            const intervalId = window.setInterval(() => {
                const currentAnnotation = testAnnotations.shift();

                if (currentAnnotation) {
                    previousAnnotations.push(currentAnnotation);
                    AiAnnotationCommands.setAiAnnotations(previousAnnotations, this.editor!);
                } else {
                    clearInterval(intervalId);
                }
            }, 1000);
        };

        if (staggered) {
            runStaggeredAnnotation();
        } else {
            AiAnnotationCommands.setAiAnnotations(annotationsTestData, this.editor);
        }
    }

    /**
     * DOM Emit:
     * Called when a color is added or removed from one of
     * the color picker dialogs.
     *
     * @param colors the list of custom colors for the editor
     */
    private onColorPickerCustomColorsChanged(colors: string[]): void {
        this.customColorPickerColors = colors;
    }

    /**
     * Find a specific component by id
     *
     * @param componentId the id of the component to find
     */
    private getComponentById(componentId: string): QuestionComponentMetaData | null {
        if (!componentId || !this.componentsMetaData) {
            return null;
        }

        for (const component of this.componentsMetaData) {
            if (component.ComponentID === componentId) {
                return component;
            }
        }

        return null;
    }

    /**
     * DOM Emit:
     * Called when the component metadata for a child inline component changes.
     * Update, delete or add the metadata to the list of components metadata.
     *
     * @param componentId the id of the component to update/delete
     * @param metaData the metadata to update, null means remove
     */
    private onComponentMetaDataChange({
        componentId,
        metaData,
    }: {
        componentId: string;
        metaData: QuestionComponentMetaData | null;
    }): void {
        if (!componentId || !this.componentsMetaData) {
            return;
        }

        const existingIndex: number = this.componentsMetaData.findIndex(
            (metData: QuestionComponentMetaData) => metData.ComponentID === componentId
        );
        if (existingIndex !== -1) {
            if (!metaData) {
                this.componentsMetaData.splice(existingIndex, 1);
            } else {
                this.componentsMetaData[existingIndex] = metaData;
            }
        } else if (metaData) {
            this.componentsMetaData.push(metaData);
        }
    }

    // Helper functions
    // =======================================

    private async updateOutputs(): Promise<void> {
        await this.$nextTick();

        this.htmlOutput = this.epEditor && this.epEditor.getHtmlContent();
        const renderElement = document.getElementById("rendered-output");
        if (renderElement) {
            const renderer: AdvancedRenderer | null = this.getRenderer(this.templateInput);
            if (renderer) {
                renderer.render(renderElement);
            }
        }
    }

    /**
     * Create an advanced renderer for preview
     */
    private getRenderer(textTemplate: string = ""): AdvancedRenderer | null {
        if (!this.features) {
            return null;
        }

        const componentParser: ComponentParser | null = new ComponentParser(this.features);
        const style: DOMComputedStyle | undefined = undefined;
        const renderGap: boolean = this.features.FITG ? this.features.FITG : false;
        const renderer: AdvancedRenderer = new AdvancedRenderer(
            textTemplate,
            componentParser,
            style,
            {
                mode: RenderingMode.MODE_QUESTION,
                context: RenderingContext.REVISION,
                forPreload: false,
            },
            renderGap
        );

        return renderer;
    }

    // Gets
    // =======================================

    private get epEditor(): EPEditor {
        if (this.$refs.editor) {
            return this.$refs.editor as EPEditor;
        }
        throw new Error("EPEditor not referenced in template.");
    }
}
