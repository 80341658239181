import { Editor } from "@educationperfect/tiptap";

import { AiAnnotationPluginKey } from "./AiAnnotationPlugin";
import { AiAnnotation as AiAnnotationData } from "./AnnotationTypings";

export namespace AiAnnotationCommands {
    export function setAiAnnotations(annotations: AiAnnotationData[], { view, state }: Editor) {
        // We are using a custom function instead of the TipTap command system
        // to get around the limitation of commands being blocked when the
        // editor is in read-only mode.
        if (annotations) {
            view.dispatch(state.tr.setMeta(AiAnnotationPluginKey, annotations));
        }
    }
}
