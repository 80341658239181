import { GuidUtil } from "@educationperfect/ep-web-utils";

import { IDOMBlockBase } from "./IDOMBlockBase";
import { IDOMComputedStyle } from "./IDOMComputedStyle";

export class DOMListItem implements IDOMBlockBase {
    public id: string;
    public textToSpeechReadable: boolean;

    private block: IDOMBlockBase;
    private element!: HTMLElement;

    constructor(block: IDOMBlockBase, textToSpeechReadable: boolean = false) {
        this.id = GuidUtil.create();
        this.block = block;
        this.textToSpeechReadable = textToSpeechReadable;
    }

    public render(style: IDOMComputedStyle): HTMLElement {
        const blockContent = this.block.render(style);

        if (blockContent) {
            this.element = document.createElement("li");
            this.element.id = this.id;
            this.element.style.margin = "0.5em";

            if (blockContent.tagName === "P") {
                // unwrap paragraph contents
                while (blockContent.firstChild) {
                    this.element.appendChild(blockContent.firstChild);
                }
            } else {
                // non-paragraph block
                this.element.appendChild(blockContent);
            }
        }
        return this.element;
    }
}
