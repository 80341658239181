import { DropDownOption } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/DropDownOption";
import { GuidUtil } from "@educationperfect/ep-web-utils";

export class DropDownOptionModel {
    // Setup
    // =======================================

    constructor(
        public id: string,
        public description: string,
        public correct: boolean,
        public sortOrder: number,
        public validity: string = "true" // This is always true in flash...
    ) {}

    // Static
    // =======================================

    /** Create an DropdownModel object from raw option  */
    public static fromDropDownObject({
        Description,
        Correct,
        SortOrder,
        Validity,
    }: DropDownOption): DropDownOptionModel {
        return new DropDownOptionModel(GuidUtil.create(), Description, Correct == "true", SortOrder, Validity);
    }

    // Public
    // =======================================

    /**
     * Get option model data represented as dropdown option
     */
    public getDropDownOption(): DropDownOption {
        return {
            Description: this.description,
            Correct: this.correct.toString(),
            SortOrder: this.sortOrder,
            Validity: this.validity,
        };
    }
}
