import "colorjoe/css/colorjoe.css";
import "../../../static/toolbar-icon/toolbar-icon.css";
import "../../assets/less/placeholder.less";
import "./ep-editor.less";
import editorTemplate from "./ep-editor.html";

import { MathQuillFacade } from "@educationperfect/ep-web-math";
import { QuestionComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/QuestionComponentMetaData";
import { Editor, EditorCommandSet, EditorContent, ExtensionOption } from "@educationperfect/tiptap";
import { DispatchFn } from "@educationperfect/tiptap-commands";
import { Bold, HardBreak, History, Italic, ListItem, Underline } from "@educationperfect/tiptap-extensions";
import { DOMParser } from "prosemirror-model";
import { EditorState, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AiAnnotation from "../../extensions/aiAnnotation/AiAnnotation";
import { AiAnnotationCommands } from "../../extensions/aiAnnotation/AiAnnotationCommands";
import { AiAnnotation as AiAnnotationData } from "../../extensions/aiAnnotation/AnnotationTypings";
import { AnnotationNode } from "../../extensions/annotations/AnnotationNode";
import { ClearFormatting } from "../../extensions/clearFormatting/ClearFormatting";
import { TextColor } from "../../extensions/color/textColor/TextColor";
import { TextHighlight } from "../../extensions/color/textHighlight/TextHighlight";
import { EditorEvents } from "../../extensions/editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../../extensions/editorEvents/EditorEventsPlugin";
import { FillInTheGapsNode } from "../../extensions/fitg/FillInTheGapsNode";
import { Formula } from "../../extensions/formula/Formula";
import { EPHeading } from "../../extensions/heading/EPHeading";
import { HighlightNode } from "../../extensions/highlight/HighlightNode";
import { ImageDialog } from "../../extensions/image/imageDialog/ImageDialog";
import { ImageNode } from "../../extensions/image/ImageNode";
import { EditorInterface, Ime } from "../../extensions/ime/Ime";
import { DropDownNode } from "../../extensions/inlineBuildable/dropDown/DropDownNode";
import { NumberBoxNode } from "../../extensions/inlineBuildable/inputBox/numberBox/NumberBoxNode";
import { TextBoxNode } from "../../extensions/inlineBuildable/inputBox/textBox/TextBoxNode";
import { EPLink } from "../../extensions/link/EPLink";
import { UnorderedList } from "../../extensions/lists/BulletList";
import { OrderedList } from "../../extensions/lists/NumberedList";
import { Paragraph } from "../../extensions/paragraph/Paragraph";
import { RTLCommands } from "../../extensions/paragraph/RTLCommands";
import Placeholder from "../../extensions/Placeholder";
import RTLMode, { RTLPluginState } from "../../extensions/RTLMode/RTLMode";
import { Subscript } from "../../extensions/script/Subscript";
import { Superscript } from "../../extensions/script/Superscript";
import { SoundNode } from "../../extensions/sound/SoundNode";
import { EPStrike } from "../../extensions/strikethrough/EPStrike";
import { EPTable } from "../../extensions/table/EPTable";
import { EPTableCell } from "../../extensions/table/EPTableCell";
import { EPTableHeader } from "../../extensions/table/EPTableHeader";
import { EPTableRow } from "../../extensions/table/EPTableRow";
import { TextAlignments } from "../../extensions/textAlign/enums/TextAlignments";
import { TextAlign } from "../../extensions/textAlign/TextAlign";
import EPTrailingNode from "../../extensions/trailingParagraph/TrailingParagraph";
import EPVariable from "../../extensions/variable/EPVariable";
import { EditorContext } from "../../models/EditorContext";
import { EditorFeatureFlags } from "../../models/EditorFeatureFlags";
import { TemplateParser } from "../../parsing/TemplateParser";
import { TemplateSerializer } from "../../parsing/TemplateSerializer";
import { TipTapComponents } from "../../TipTapComponents";
import { IFormulaConversion, TipTapEvents } from "../../TipTapEvents";
import { ArabicScriptManager } from "../../utils/language-utils/ArabicScriptManager";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import AiAnnotationUi from "../aiAnnotationUi/AiAnnotationUi.vue";
import { FloatingToolbar } from "../floatingToolbar/FloatingToolbar";
import { EditorPluginState } from "./interfaces/EditorPluginState";
import { EPEditorProps } from "./interfaces/EPEditorProps";
import { EditorPlugin } from "./plugins/EditorPlugin";

/**
 * The generic parameter C will determine what getCommands() will expose. You can include more than
 * one sets of commands by using an intersection type i.e. EPEditor<CommandSet1 & CommandSet2>
 */
@Component({
    template: editorTemplate,
    components: {
        [TipTapComponents.EPEditor]: EPEditor,
        [TipTapComponents.FloatingToolbar]: FloatingToolbar,
        [TipTapComponents.ImageDialog]: ImageDialog,
        [TipTapComponents.AiAnnotationUi]: AiAnnotationUi,
        EditorContent,
    },
    name: TipTapComponents.EPEditor,
})
export class EPEditor<CommandSet extends EditorCommandSet = any> extends Vue implements EPEditorProps {
    // Variables
    // =================================

    // Props

    // public static RTLMode: boolean = false;

    /**
     * This value does not have defaults applied, generally you'll want to use [enabledFeatures].
     */
    @Prop() public readonly features: EditorFeatureFlags | undefined;

    @Prop() public readonly placeholder: string | undefined;

    @Prop() public readonly template: string | undefined;

    @Prop() public readonly html: string | undefined;

    @Prop({ type: Boolean, default: true }) public readonly editable: boolean | undefined;

    @Prop({ type: Boolean, default: true }) public readonly spellcheckEnabled: boolean | undefined;

    @Prop({ type: Boolean, default: true }) public readonly autoCompleteEnabled: boolean | undefined;

    @Prop({ type: Boolean, default: true }) public readonly autoCorrectEnabled: boolean | undefined;

    @Prop({ type: Boolean, default: true }) public readonly autoCapitalizeEnabled: boolean | undefined;

    @Prop({ type: Number, default: EditorContext.STANDARD }) public readonly context: EditorContext | undefined;

    @Prop({ type: Boolean, default: false }) public readonly isAdmin: boolean | undefined;

    @Prop({ type: Function, default: null }) public readonly getComponentById:
        | ((componentId: string) => QuestionComponentMetaData | null)
        | undefined;

    /** [Prop] The base language id of the lesson */
    @Prop({ required: false, type: Number }) private baseLanguageId?: number;

    /** [Prop] The target language id of the lesson */
    @Prop({ required: false, type: Number }) private targetLanguageId?: number;

    // Static

    // Other
    private serializer!: TemplateSerializer;

    private parser!: TemplateParser;

    public interfaces: EditorInterface = {};

    public editor: Editor | null = null;

    private show: boolean = true;

    private disableChangeEmit: boolean = false;

    // @Watch("template")
    // private onTextTemplateChangedExternal(newTemplate: string, oldTemplate: string): void {
    //     if (!this.editor || newTemplate === oldTemplate) {
    //         return;
    //     }
    //     this.disableChangeEmit = true;
    //     this.updateEditorContent(newTemplate);
    //     setTimeout(() => (this.disableChangeEmit = false));
    // }

    @Watch("html")
    private onHTMLTemplateChangeExternal(newTemplate: string, oldTemplate: string): void {
        if (!this.editor || newTemplate === oldTemplate) {
            return;
        }

        this.processNewHTMLWithoutHistory(newTemplate);
    }

    public updateEditorContent(template: string): void {
        TipTapEvents.Common.formulaConvertedEvent.addEventListener(this.onFormulaConverted, this);
        const htmlContent: string = new TemplateParser(this.enabledFeatures).parse(template);
        this.processNewHTMLWithoutHistory(htmlContent);
        TipTapEvents.Common.formulaConvertedEvent.removeAllListeners();
    }

    /**
     * For each formula in the text template that is converted, emit and event containing the original formula, and the converted formula to be caught and handled in the Content Editor.
     * Also emits and event to update the text template.
     * @param formalConvertedObj contains the original formula (in ShaneTeX) and the converted formula (in LaTeX)
     */
    private onFormulaConverted(formalConvertedObj: IFormulaConversion): void {
        this.$emit(EditorEvents.FormulaConverted, formalConvertedObj);
        this.$emit("change", { getHtml: this.getHtmlContent, getTemplate: () => this.getTemplate() }); // needed to get the Editor Store to update its text template.
    }

    /**
     * Set the new processed HTML, emiting the history from being
     * stored.
     *
     * @param htmlContent the parsed html content for display
     */
    private processNewHTMLWithoutHistory(htmlContent: string): void {
        if (!this.editor || !editorTemplate) {
            return;
        }

        const { doc, tr } = this.editor.state;

        const element = document.createElement("div");
        element.innerHTML = htmlContent.trim();

        const parsedDocument = DOMParser.fromSchema(this.editor.schema).parse(element, { preserveWhitespace: true });
        const selection = TextSelection.create(doc, 0, doc.content.size);
        const transaction = tr
            .setSelection(selection)
            .replaceSelectionWith(parsedDocument, false)
            .setMeta("preventUpdate", true)
            .setMeta("addToHistory", false);

        this.editor.view.dispatch(transaction);
    }

    /**
     * Return the correct render template for context
     */
    private getRenderTemplate(): string {
        if (this.html) {
            return this.html;
        }

        if (this.template) {
            return new TemplateParser(this.enabledFeatures).parse(this.template);
        }

        return "";
    }

    private setupEditor(): void {
        this.editor = new Editor({
            disableInputRules: ["italic"],
            extensions: this.generateExtensions(this.enabledFeatures),
            content: this.getRenderTemplate(),
            editable: this.editable !== undefined ? this.editable : true,
            parseOptions: {
                preserveWhitespace: true, // Preserves markdown patterns such as " * " in the template.
            },
            onUpdate: this.onEditorUpdate,
            /**
             * This forces the update function to be called on initialisation. This is done
             * to ensure that the any data models associated with the editor are initialised
             * properly.
             * It appears that onInit is called synchronously on creation so we need to call the
             * update function after a timeout so ensure this.editor is accessible.
             */
            onInit: ({ view, state }) => {
                if (!this.spellcheckEnabled) {
                    // HACK: This is here to fool Grammarly into not showing up
                    // the "readonly" attribute only has an effect to the browser when it is on
                    // an <input> or <textarea> tag. This editor uses a <div> with the
                    // "contenteditable" attribute to facilitate text editing. Adding a "readonly" attribute
                    // to the <div> will have no effect on the browser. Grammarly looks for "readonly" and "disabled"
                    // attributes to decide when not to attach itself to a field, regardless of what type of element it is.
                    // We want to deter Grammarly from working when we have specified to disable spellchecking.
                    view.dom.setAttribute("readonly", "true");
                }
                this.setupEditorPlugins(view, state);
                setTimeout(() => {
                    this.setupTemplateParsing();
                    this.onEditorUpdate();

                    // If the editor contains Arabic text, set its text mode to RTL.
                    const RTLModePluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(state) as RTLPluginState;
                    RTLModePluginState.RTLMode = ArabicScriptManager.hasArabicCharacters(this.getHtmlContent());
                    if (RTLModePluginState.RTLMode) {
                        RTLCommands.updateContentAttrs(RTLModePluginState.RTLMode, false)(
                            view.state,
                            view.dispatch as DispatchFn,
                            view
                        );
                    }
                });
            },
            onBlur: ({ event, view, state }) => {
                // Inform the parent (ie: Content Editor text block) that a blur has happened
                this.$emit(EditorEvents.Blur, event);
            },
            onFocus: ({ event, view, state }) => {
                this.$emit(EditorEvents.Focus, event);
            },
        });

        // Once the editor is created, set the RTLMode based on whether the editor text contains Arabic or not.
        // if (this.editor)
        // {
        //     const RTLMode = true; //here detect whether the editor content contains any arabic text. If so, set to true.
        //     const pluginState: ParagraphPluginState = Paragraph.ParagraphPluginKey.getState(this.editor.state) as ParagraphPluginState;
        //     pluginState.RTLMode = RTLMode;
        // }
    }

    private setupTemplateParsing(): void {
        if (this.editor) {
            this.serializer = new TemplateSerializer();
            this.parser = new TemplateParser(this.enabledFeatures);
        }
    }

    /**
     * Setup plugins directly releated to the editor.
     * Allow custom extensions to trigger a Vue event from the EPEditor component.
     */
    private setupEditorPlugins(view: EditorView, state: EditorState): void {
        let newState: EditorState = ProsemirrorUtils.registerPlugin(state, view, new EditorPlugin());
        const editorState: EditorPluginState = EditorPlugin.EditorPluginKey.getState(newState);
        editorState.isAdmin = () => this.isAdmin;
        editorState.getComponentById = this.getComponentById;
        editorState.baseLanguageId = () => this.baseLanguageId;
        editorState.targetLanguageId = () => this.targetLanguageId;

        newState = ProsemirrorUtils.registerPlugin(newState, view, new EditorEventsPlugin());
        const eventsState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
            newState
        ) as EditorEventsState;
        eventsState.setEventListener((event: EditorEvents, data: any) => {
            this.$emit(event, data);
        });
    }

    private generateExtensions(featureFlags: EditorFeatureFlags): ExtensionOption[] {
        const extensions: ExtensionOption[] = [new Paragraph(), new History()];

        if (featureFlags.FITG) {
            extensions.push(new FillInTheGapsNode());
        }

        if (featureFlags.ANNOTATION) {
            extensions.push(new AnnotationNode());
        }

        if (featureFlags.TEXT_ALIGN) {
            extensions.push(new TextAlign());
        }

        if (featureFlags.LIST) {
            extensions.push(new ListItem());
            extensions.push(new UnorderedList());
            extensions.push(new OrderedList());
        }

        if (featureFlags.SUPERSCRIPT) {
            extensions.push(new Superscript());
        }

        if (featureFlags.SUPERSCRIPT) {
            extensions.push(new Subscript());
        }

        if (featureFlags.BOLD) {
            extensions.push(new Bold());
        }

        if (featureFlags.ITALIC) {
            extensions.push(new Italic());
        }

        if (featureFlags.UNDERLINE) {
            extensions.push(new Underline());
        }

        if (featureFlags.TABLE) {
            extensions.push(
                new EPTable({
                    resizable: true,
                })
            );
            extensions.push(new EPTableHeader());
            extensions.push(new EPTableRow());
            extensions.push(new EPTableCell());
        }

        if (featureFlags.TEXT_STYLES) {
            extensions.push(new EPHeading(TextAlignments.LEFT, { levels: [1, 2, 3, 4, 5, 6] }));
        }

        if (featureFlags.STRIKE) {
            extensions.push(new EPStrike());
        }

        if (featureFlags.TEXT_COLOR) {
            extensions.push(new TextColor());
        }

        if (featureFlags.TEXT_HIGHLIGHT) {
            extensions.push(new TextHighlight());
        }

        if (featureFlags.CLEAR_FORMATTING) {
            extensions.push(new ClearFormatting());
        }

        if (featureFlags.FORMULA) {
            extensions.push(new Formula());
        }

        if (featureFlags.HARD_BREAK) {
            extensions.push(new HardBreak());
        }

        if (featureFlags.HIGHLIGHT) {
            extensions.push(new HighlightNode());
        }

        if (featureFlags.LINK) {
            extensions.push(new EPLink());
        }

        if (featureFlags.TRAILING_PARAGRAPH || featureFlags.TABLE) {
            extensions.push(new EPTrailingNode());
        }

        if (featureFlags.IME) {
            extensions.push(new Ime());
            this.interfaces.ime = Ime.getInterface(this);
        }

        if (featureFlags.RTL_MODE) {
            extensions.push(new RTLMode());
        }

        if (featureFlags.SOUND) {
            extensions.push(new SoundNode());
        }

        if (featureFlags.IMAGE) {
            extensions.push(new ImageNode());
        }

        if (featureFlags.VARIABLES) {
            extensions.push(new EPVariable());
        }

        if (featureFlags.DROPDOWN) {
            extensions.push(new DropDownNode());
        }

        if (featureFlags.NUMBER_BOX) {
            extensions.push(new NumberBoxNode());
        }

        if (featureFlags.TEXT_BOX) {
            extensions.push(new TextBoxNode());
        }

        if (this.placeholder) {
            extensions.push(
                new Placeholder({
                    emptyNodeText: this.placeholder,
                })
            );
        }

        if (featureFlags.AI_ANNOTATION) {
            extensions.push(new AiAnnotation());
        }

        return extensions;
    }

    /**
     * This is called when the content of the editor has been changed
     */
    private onEditorUpdate(): void {
        if (!this.editor) {
            return;
        }

        this.onContentChanged();
    }

    private get enabledFeatures(): EditorFeatureFlags {
        const defaults: EditorFeatureFlags = {
            FITG: false,
            TEXT_ALIGN: false,
            LIST: false,
            SUPERSCRIPT: false,
            SUBSCRIPT: false,
            BOLD: false,
            ITALIC: false,
            UNDERLINE: false,
            TABLE: false,
            TEXT_STYLES: false,
            STRIKE: false,
            CLEAR_FORMATTING: false,
            TEXT_HIGHLIGHT: false,
            TEXT_COLOR: false,
            FORMULA: false,
            HARD_BREAK: false,
            LINK: false,
            HIGHLIGHT: false,
            RTL_MODE: true,
            TRAILING_PARAGRAPH: false,
            IME: false,
            VARIABLES: false,
        };
        const featureFlags: EditorFeatureFlags = Object.assign(defaults, this.features);

        return featureFlags;
    }

    // =========================================================================
    // Vue Component Lifecycle
    // =========================================================================

    protected mounted(): void {
        this.setupEditor();
        Vue.nextTick(() => {
            MathQuillFacade.requireMathQuill();
        });
    }

    public beforeDestroy(): void {
        if (this.editor) {
            this.editor.destroy();
        }
    }

    // =========================================================================
    // Public methods
    // =========================================================================

    /**
     * Set new HTML to the editor.
     * Required due to student app not being
     * vue js.
     *
     * @param html the html to set
     */
    public setHtml(html: string): void {
        if (!html) {
            return;
        }

        this.onHTMLTemplateChangeExternal(html, "");
    }

    public getHtmlContent(): string {
        return this.editor ? this.editor.getHTML() : "";
    }

    public getTemplate(): string {
        return this.editor ? this.serializer.serialize(this.editor) : "";
    }

    public getRTLMode(): boolean {
        if (this.editor) {
            const pluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(this.editor.state) as RTLPluginState;
            return pluginState.RTLMode;
        }
        return false;
    }

    public getTextContent(): string | undefined {
        return this.editor ? this.editor.state.doc.textContent : undefined;
    }

    private onContentChanged(): void {
        if (this.disableChangeEmit) {
            return;
        }
        if (this.editor === null) {
            throw new Error("Editor is null!");
        } else {
            this.$emit("change", { getHtml: this.getHtmlContent, getTemplate: () => this.getTemplate() });
        }
    }

    /**
     * Focus the editor
     */
    public focus(): void {
        this.editor?.focus();
    }

    /**
     * Get the extension specific commands as defined by the generic parameter EPEditor<C>
     */
    public getCommands(): CommandSet {
        if (!this.editor) {
            return {} as any as CommandSet;
        }

        return this.editor.commands as CommandSet;
    }

    /**
     * Apply AI annotations on the editor content. Currently this is only
     * intended to work in read-only mode.
     *
     * @param annotations - The array of AI annotation data to be displayed.
     */
    public setAiAnnotations(annotations: AiAnnotationData[]): void {
        if (!this.editor) {
            return;
        }

        AiAnnotationCommands.setAiAnnotations(annotations, this.editor);
    }

    // Getters
    // ============================================

    /** Get the name of the vue component */
    public static get vueName(): string {
        return "EpEditor";
    }
}

export interface EditorContentEvent {
    getTemplate: () => string;
    getHtml: () => string;
}
