import { Dictionary, DictionaryHelper } from "@educationperfect/ep-web-utils";

import { EditorFeatureFlags } from "../../../../models/EditorFeatureFlags";
import { AttributesParser } from "../AttributesParser";
import { IRenderingParameters } from "../render/IRenderingParameters";
import { BaseComponent } from "./BaseComponent";
import { BaseEmbeddedComponent } from "./embedded/BaseEmbeddedComponent";
import { InlineDropDownComponent } from "./embedded/InlineDropDownComponent";
import { InlineGap } from "./embedded/InlineGap";
import { InlineHighlightComponent } from "./embedded/InlineHighlightComponent";
import { InlineImageComponent } from "./embedded/InlineImageComponent";
import { InlineLinkComponent } from "./embedded/InlineLinkComponent";
import { InlineNumberBoxComponent } from "./embedded/InlineNumberBoxComponent";
import { InlineTextBoxComponent } from "./embedded/InlineTextBoxComponent";
import { SoundComponent } from "./embedded/SoundComponent";
import { IComponentParser } from "./IComponentParser";

export class ComponentParser implements IComponentParser {
    // ---------- static -------------

    public static MULTICHOICE_TEMPLATE_CODE: string = "multichoice";
    public static DROPDOWN_TEMPLATE_CODE: string = "dropdown";
    public static FORMULA_TEMPLATE_CODE: string = "formula";
    public static WORKING_FORMULA_TEMPLATE_CODE: string = "working-formula";
    public static FREEFORM_WORKING_TEMPLATE_CODE: string = "freeform-working";
    public static LONG_ANSWER_TEMPLATE_CODE: string = "long-answer";
    public static FILL_IN_GAPS_TEMPLATE_CODE: string = "fill-gaps";
    public static ANNOTATED_QUESTION_TEMPLATE_CODE: string = "annotated-question";
    public static ANNOTATED_TEXT_TEMPLATE_CODE: string = "annotated-text";

    private static embeddedTypes: { [name: string]: any } = {
        // // Used for FITG.
        gap: InlineGap,
        link: InlineLinkComponent,
        image: InlineImageComponent,
        sound: SoundComponent,
        hl: InlineHighlightComponent,
        dropdown: InlineDropDownComponent,
        "number-box": InlineNumberBoxComponent,
        "text-box": InlineTextBoxComponent,
    };

    // ---------- instance -------------

    public parse(
        line: string,
        renderingParameters: IRenderingParameters,
        baseAttributes: Dictionary<string>,
        enabledFeatures: EditorFeatureFlags
    ): BaseComponent | null {
        if (line == null || line == "") {
            return null;
        }

        let type: string;
        let attribs: Dictionary<string> = baseAttributes ? DictionaryHelper.clone(baseAttributes) : {};
        const firstSpace: number = line.indexOf(" ");
        if (firstSpace >= 0) {
            type = line.substring(0, firstSpace);
            const isFitgOrAnnotation: boolean = this.isFitgOrAnnotation(type);

            if (enabledFeatures.ANNOTATION && isFitgOrAnnotation) {
                const gapData: RegExpMatchArray | null = line.match(/(\d+): (.*):([0-8])/);
                if (gapData) {
                    type = "gap";
                    attribs = { id: gapData[1], text: gapData[2], color: gapData[3] };
                }
            } else if (enabledFeatures.FITG && isFitgOrAnnotation) {
                const gapData: string[] = line.split(":");
                type = "gap";

                attribs = { id: gapData[0], text: gapData[1] };
            } else if (enabledFeatures.HIGHLIGHT && type == "hl") {
                const gapData: string[] = line.substring(firstSpace).split(":");
                type = "hl";

                attribs = { id: gapData[0], text: gapData[1], correct: gapData[2] };
            } else {
                const attributeString: string = line.substring(firstSpace);
                const attributeParser: AttributesParser = new AttributesParser(attributeString, attribs);
                attribs = attributeParser.attributes;
            }
        } else {
            type = line;
        }

        const component: BaseComponent | null = this.createComponent(type, renderingParameters, attribs);
        return component;
    }

    private isFitgOrAnnotation(typeString: string): boolean {
        const match: number = typeString.search(/(\d+):/);
        return match > -1;
    }

    private createComponent(
        componentType: string,
        renderingParameters: IRenderingParameters,
        attributes?: Dictionary<string>
    ): BaseComponent | null {
        if (!attributes) {
            attributes = {};
        }

        if (componentType in ComponentParser.embeddedTypes) {
            const componentConstructor: typeof BaseEmbeddedComponent = ComponentParser.embeddedTypes[componentType];
            if (componentConstructor) {
                const baseComponent: BaseComponent = new componentConstructor(
                    componentType,
                    renderingParameters,
                    attributes
                );
                return baseComponent;
            }
        }

        return null;
    }
}
