import { Sortable as ShopifySortable, SortableSortedEvent, SortableStartEvent } from "@educationperfect/draggable";
import { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";

export const Sortable: DirectiveOptions = {
    inserted(el: HTMLElement, node: DirectiveBinding, vnode) {
        if (!node.value) {
            return;
        }

        const options = node.value.options;
        const sortable = new ShopifySortable(el, options || {});

        if (node.value.onSorted) {
            // @ts-ignore
            sortable.on("sortable:sorted", (sortableEvent: SortableSortedEvent) => {
                node.value.onSorted(sortableEvent);
            });
        }

        if (node.value.onStart) {
            // @ts-ignore
            sortable.on("sortable:start", (sortableEvent: SortableStartEvent) => {
                node.value.onStart(sortableEvent);
            });
        }
    },
};
