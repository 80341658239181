export enum ToolbarItemShortcuts {
    // History
    UNDO = "Ctrl+Z",
    REDO = "Ctrl+Y",

    // Text Front/Styles
    BOLD = "Ctrl+B",
    ITALIC = "Ctrl+I",
    UNDERLINE = "Ctrl+U",
    PARAGRAPH = "Ctrl+Alt+0",
    HEADING = "Ctrl+Alt+", // needs heading number appended (1-6)
    STRIKETHROUGH = "Alt+Shift+5",
    SUPERSCRIPT = "Ctrl+.",
    SUBSCRIPT = "Ctrl+,",
    RTL_MODE = "Ctrl+Shift+`",
    CLEAR_FORMATTING = "Ctrl+/",

    // Alignments
    LEFT_ALIGN = "Ctrl+Alt+L",
    CENTER_ALIGN = "Ctrl+Alt+E",
    RIGHT_ALIGN = "Ctrl+Alt+R",
    JUSTIFY = "Ctrl+Alt+J",

    // Lists
    NUMBERED_LIST = "Ctrl+Shift+7",
    BULLET_LIST = "Ctrl+Shift+8",

    // Other
    FORMULA = "Ctrl+`",
    LINK = "Ctrl+K",
}
