import { DOMComputedStyle } from "./DOMComputedStyle";
import { IDOMBlockBase } from "./IDOMBlockBase";
import { SizingModels } from "./SizingModels";

export class DOMComposition implements IDOMBlockBase {
    protected blocks: IDOMBlockBase[];
    public style: DOMComputedStyle;

    constructor(style: DOMComputedStyle, private isNested?: boolean) {
        this.blocks = [];
        this.style = style;
    }

    public addBlock(block: IDOMBlockBase): void {
        if (block != null) {
            if (this.isNested) {
                if (block instanceof DOMComposition) {
                    for (let childBlock of block.blocks) {
                        this.setUpNestedComponentBlock(childBlock);
                    }
                } else {
                    this.setUpNestedComponentBlock(block);
                }
            }
            this.blocks.push(block);
        }
    }

    private setUpNestedComponentBlock(block: IDOMBlockBase): void {
        // if (block instanceof DOMComponentBlock)
        // {
        //     block.blockComponent.parentIsTableParam = true;
        // }
    }

    public render(): HTMLElement | null {
        const container: HTMLElement = this.createDiv(this.style);

        for (let block of this.blocks) {
            const blockElement: HTMLElement | null = block.render(this.style);
            if (blockElement) {
                container.appendChild(blockElement);
            }
        }

        return container;
    }

    public get classes(): string[] {
        if (this.isNested) {
            return [];
        }

        const sizeName = this.style.format.FontSize; // small | medium | large
        return ["ep-render-output", sizeName]; // see ../css/advanced-renderer.less
    }

    // public async preload(): Promise<IPreloadError[]>
    // {
    //     let promises: Promise<IPreloadError | IPreloadError[]>[] = [];
    //     for (let block of this.blocks)
    //     {
    //         promises = [...promises, block.preload().catch((e) => e)];
    //     }

    //     const errors: IPreloadError[] = await Promise.all(promises).then((errors) => ArrayHelper.flatten(errors.filter((e) => !!e)));
    //     return errors;
    // }

    // public notifyAttached(): RenderPromise
    // {
    //     if (this.blocks.length == 0)
    //     {
    //         return RenderPromise.resolve();
    //     }

    //     let returnObject = RenderPromise.all(this.blocks.map((block) => block.notifyAttached()))
    //         .catch((error) =>
    //         {
    //             console.log("notifyAttached error", error);
    //         });

    //     return returnObject;
    // }

    // public notifyDetached()
    // {
    //     for (const block of this.blocks)
    //     {
    //         block.notifyDetached();
    //     }
    // }

    protected createDiv(style: DOMComputedStyle): HTMLDivElement {
        const div: HTMLDivElement = document.createElement("div");

        if (style.containerWidth === DOMComputedStyle.PIXELS_WIDE) {
            div.style.width = "100%";
        } else {
            const width: string = style.containerWidth + "px";
            if (style.widthSizingModel == SizingModels.MAXIMUM) {
                div.style.maxWidth = width;
            } else {
                div.style.width = width;
            }
        }

        div.style.textAlign = style.alignment;
        return div;
    }

    public get domBlocks(): IDOMBlockBase[] {
        return this.blocks;
    }
}
