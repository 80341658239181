import "./text-emit-bubble.less";
import template from "./text-emit-bubble.html";

import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { EditorMenuBubble } from "@educationperfect/tiptap";
import { DispatchFn } from "@educationperfect/tiptap-commands";
import { Component } from "vue-property-decorator";

import AddIcon from "../../assets/icons/add-white.svg?inline";
import { TextEmitCommands } from "../../extensions/textEmit/TextEmitCommands";
import { TipTapComponents } from "../../TipTapComponents";
import { BaseFloatingToolbar } from "../floatingToolbar/BaseFloatingToolbar";

@Component({
    template,
    components: {
        [TipTapComponents.TextEmitBubble]: TextEmitBubble,
        [TipTapComponents.EditorMenuBubble]: EditorMenuBubble,
        AddIcon,
    },
    name: TipTapComponents.TextEmitBubble,
})
export class TextEmitBubble extends BaseFloatingToolbar<any> {
    // Variables
    // ===========================================

    // Media

    // Lifecycle events
    // ===========================================

    /** Lifecycle hook: Called when the component is added to the DOM */
    private mounted(): void {
        window.addEventListener("keydown", this.onKeyDown);
    }

    /** Lifecycle hook: Called when the component is removed from the DOM */
    private destroyed(): void {
        window.removeEventListener("keydown", this.onKeyDown);
    }

    // Logic
    // ===========================================

    /**
     *  Emit the current text from the editor
     */
    private getText(): void {
        if (!this.editor) {
            return;
        }

        TextEmitCommands.emitSelectedText()(
            this.editor.state,
            this.editor.view.dispatch as DispatchFn,
            this.editor.view
        );
    }

    /**
     * Called when a key is pressed
     *
     * @param event the keyboard event
     */
    private onKeyDown(event: KeyboardEvent): void {
        if (!event || event.which == null) {
            return;
        }

        if (event.which == Keyboard.SPACE && (event.ctrlKey || event.metaKey)) {
            this.getText();
        }
    }

    // DOM Events
    // ===========================================

    /**
     * DOM Click:
     * Called when the "+" is clicked.
     * Pipe through to the get text method
     */
    private onGetTextClick(): void {
        this.getText();
    }

    // Gets
    // ===========================================

    /** Get the name of the vue component */
    public static get vueName(): string {
        return "TextEmitBubble";
    }

    /** Whether or not the bubble shhould show */
    private get showBubble(): boolean {
        if (this.isActive.link && this.isActive.link()) {
            return false;
        }

        if (this.isActive.formula && this.isActive.formula()) {
            return false;
        }

        return true;
    }
}
