import { Node as ProsemirrorNode, NodeSpec, NodeType } from "prosemirror-model";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { CommandGetter } from "@educationperfect/tiptap";
import { CommandFunction, toggleList } from "@educationperfect/tiptap-commands";
import { BulletList as TiptapBulletList } from "@educationperfect/tiptap-extensions";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { ListCommands } from "./ListCommands";

export class UnorderedList extends TiptapBulletList {
    get schema(): NodeSpec {
        return {
            attrs: {
                align: {
                    default: TextAlignments.LEFT,
                },
            },
            content: "list_item+",
            group: "block",
            parseDOM: [
                {
                    tag: "ul",
                    getAttrs: (dom) => {
                        if (dom instanceof HTMLElement) {
                            if (dom.style.textAlign != "") {
                                return { align: dom.style.textAlign };
                            }

                            return {
                                align: dom.hasAttribute("align") ? dom.getAttribute("align") : TextAlignments.CENTRE,
                            };
                        }
                    },
                },
            ],
            toDOM: (node: ProsemirrorNode) => {
                const divAttrs = { style: `text-align: ${node.attrs.align}` };
                const ulAttrs = { style: `list-style-position:outside;display: inline-block; text-align: left;` };
                return ["div", divAttrs, ["ul", ulAttrs, 0]];
            },
        };
    }

    public keys({ type, schema }: { type: NodeType; schema: NodeSpec }): { [keyCombo: string]: CommandFunction } {
        return { "Mod-Shift-8": toggleList(type, schema.nodes.list_item) };
    }

    public commands({ type, schema }): CommandGetter {
        return () => ListCommands.toggleList(type, schema.nodes.list_item);
    }

    /**
     * Check whether a list can be inserted at the current selection.
     */
    public static canInsertList(state: EditorState, view: EditorView): boolean {
        const { schema } = state;

        return toggleList(state.schema.nodes[ExtensionNames.bulletList], schema.nodes[ExtensionNames.listItem])(
            state,
            undefined,
            view
        );
    }
}
