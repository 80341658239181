import { DeviceUtil } from "@educationperfect/ep-web-browser-utils";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { Mark, Node, ResolvedPos, Schema } from "prosemirror-model";
import { EditorState, Selection, TextSelection, Transaction } from "prosemirror-state";
import {
    addColumnAfter,
    addColumnBefore,
    addRowAfter,
    addRowBefore,
    isInTable,
    setCellAttr,
    TableMap,
    toggleHeaderRow,
} from "prosemirror-tables";
import { RemoveMarkStep } from "prosemirror-transform";
import {
    ContentNodeWithPos,
    createTable,
    findParentNodeOfType,
    findTable,
    getCellsInTable,
    isTableSelected,
} from "prosemirror-utils";
import { EditorView } from "prosemirror-view";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { EPTableCell } from "./EPTableCell";

// tslint:disable-next-line:no-namespace
export namespace EPTableCommands {
    export const FIX_WIDTH_META_KEY: string = "fixWidth";

    // =========================================================================
    // Table Commands
    // =========================================================================

    /**
     * A command the creates a table with all cells of a given width
     */
    export function createTableWithColumnWidths(
        schema,
        { rowsCount, colsCount, withHeaderRow, width }
    ): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const nodes = createTable(schema, rowsCount, colsCount, withHeaderRow);
            const createTableTR = state.tr.replaceSelectionWith(nodes);

            // There is a bug that affect the post-creation selection if the new table
            // is not the last table on the editor. We need to explicitly set the selection
            // after a table create to workaround this issue.
            const newTablePosition: ResolvedPos = createTableTR.doc.resolve(state.selection.$to.pos + 1);
            const newTableSelection: Selection = new Selection(newTablePosition, newTablePosition);

            // get selection for first cell
            const allCells = getCellsInTable(newTableSelection);

            if (allCells) {
                const firstCellPos = allCells[0].pos;
                const resolvedPos = createTableTR.doc.resolve(firstCellPos);
                createTableTR.setSelection(TextSelection.near(resolvedPos));
            }

            if (dispatch) {
                dispatch(createTableTR);

                // const maxWidth = 600;
                const maxWidth = DeviceUtil.innerWidth - 56; // account for 28px padding on each side of the content div
                if (width * colsCount > maxWidth) {
                    width = Math.floor(maxWidth / colsCount);
                }
                markTransactionForWidthFixing(view, width);
            }

            return true;
        };
    }

    export function addColumnBeforeWithColumnWidths({ width }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            addColumnBefore(state, dispatch);
            markTransactionForWidthFixing(view, width);
            return false;
        };
    }

    export function addColumnAfterWithColumnWidths({ width }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            addColumnAfter(state, dispatch);
            markTransactionForWidthFixing(view, width);
            return false;
        };
    }

    export function addRowBeforeWithColumnWidths({ width }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            addRowBefore(state, dispatch);
            markTransactionForWidthFixing(view, width);
            return false;
        };
    }

    export function addRowAfterWithColumnWidths({ width }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            addRowAfter(state, dispatch);
            markTransactionForWidthFixing(view, width);
            return false;
        };
    }

    export function toggleBorderVisibility(): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (!isInTable(state)) {
                return false;
            }
            let transaction: Transaction = state.tr;
            const tableData: ContentNodeWithPos | undefined = findTable(state.selection);
            if (!tableData) {
                return false;
            }
            const table: Node = tableData.node;
            const tablePos: number = tableData.pos;

            const toShowBorders = !table.attrs.showBorders;
            const newAttrs = ProsemirrorUtils.setAttribute(table.attrs, "showBorders", toShowBorders);

            transaction.setNodeMarkup(tablePos, undefined, newAttrs);

            if (transaction.docChanged) {
                if (toShowBorders) {
                    clearCellBorderOverrides(transaction, state.schema);
                }
                if (dispatch) {
                    return dispatch(transaction);
                } else {
                    return true;
                }
            }
            return false;
        };
    }

    /**
     * A command to toggle the cell border override attribute.
     */
    export function toggleCellBorderOverride(): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { schema, selection } = state;
            const selectedCell: Node | null = EPTableCell.findCell(schema, selection);

            if (selectedCell) {
                const showBorder: boolean | undefined = selectedCell.attrs.showBorderOverride;

                if (showBorder) {
                    return setCellAttr("showBorderOverride", false)(state, dispatch);
                } else {
                    return setCellAttr("showBorderOverride", true)(state, dispatch);
                }
            } else {
                return false;
            }
        };
    }

    /**
     * A command that updates the cells to toggle between td and th and also
     * applies a header attribute to the Table Row itself for parsing.
     */
    export function toggleHeaderAndUpdateRow(): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { selection } = state.tr;

            if (toggleHeaderRow(state, dispatch)) {
                return toggleHeaderAttrForRow(view.state, view.dispatch as DispatchFn);
            }

            return false;
        };
    }

    export function setTableAlignment({
        alignment = TextAlignments.CENTRE,
    }: {
        alignment: TextAlignments;
    }): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { tr, selection } = state;
            const isTableSelection: boolean = isTableSelected(state.selection);

            if (!isTableSelected) {
                return false;
            }

            const table: ContentNodeWithPos | undefined = findTable(selection);
            if (table) {
                const tableAlign: any | null = table.node.attrs.align || null;

                if (tableAlign !== alignment) {
                    tr.setNodeMarkup(table.pos, table.node.type, { align: alignment });

                    if (dispatch) {
                        return dispatch(tr);
                    }
                }

                return true;
            }

            return false;
        };
    }

    // =========================================================================
    // Helper functions
    // =========================================================================

    function toggleHeaderAttrForRow(state: EditorState, dispatch: DispatchFn): boolean {
        const { schema, selection, tr } = state;
        const parentRow: ContentNodeWithPos | undefined = findParentNodeOfType(schema.nodes[ExtensionNames.tableRow])(
            selection
        );

        if (parentRow) {
            const rowAttrs: any = parentRow.node.attrs;
            const enableHeader: boolean = !parentRow.node.attrs.header;

            tr.setNodeMarkup(parentRow.pos, undefined, { ...rowAttrs, header: enableHeader });

            if (dispatch) {
                dispatch(tr);
                return true;
            }
        }

        return false;
    }

    /**
     * Clear all cell border overrides for the selected table. This is
     * used when the table border property is changed.
     */
    function clearCellBorderOverrides(transaction: Transaction, schema: Schema): boolean {
        const allCells: ContentNodeWithPos[] | undefined = getCellsInTable(transaction.selection);

        if (allCells) {
            for (const cell of allCells) {
                const existingAttrs: any = cell.node.attrs;
                const newAttrs = ProsemirrorUtils.setAttribute(existingAttrs, "showBorderOverride", false);
                transaction.setNodeMarkup(cell.pos, undefined, newAttrs);
            }

            return true;
        } else {
            return false;
        }
    }

    /**
     * We will defer the width fixing to a plugin that will use appendTransaction instead.
     * This will allow the width fixing to be treated a related action when undo/redoing.
     */
    function markTransactionForWidthFixing(view: EditorView, width: number): void {
        const tr: Transaction = view.state.tr;
        tr.setMeta(FIX_WIDTH_META_KEY, width);

        // This is a workaround to ensure transaction triggers an update event for the editor
        // by forcing the docChange property to be true. We then need to prevent this transaction
        // from being added to the history so it won't be rolled back with an undo.
        tr.step(new RemoveMarkStep(0, 0, new Mark()));
        tr.setMeta("addToHistory", false);

        view.dispatch(tr);
    }

    /**
     * The generated transaction will ensure any column with an unset width to be updated with
     * a default width.
     */
    export function generateFixWidthsTransaction(state: EditorState, defaultWidth: number): Transaction | undefined {
        const table: ContentNodeWithPos | undefined = findTable(state.selection);
        if (table && table.node) {
            let map = TableMap.get(table.node);
            const tr = state.tr;

            for (let col = 0; col < map.width; col++) {
                for (let row = 0; row < map.height; row++) {
                    let mapIndex = row * map.width + col;
                    let pos = map.map[mapIndex];
                    let nodeAtPos = table.node.nodeAt(pos);
                    if (!nodeAtPos) {
                        continue;
                    }

                    let attrs = nodeAtPos.attrs;
                    if (attrs.colwidth == undefined) {
                        let colwidth = [defaultWidth];
                        tr.setNodeMarkup(
                            table.pos + 1 + pos,
                            undefined,
                            ProsemirrorUtils.setAttribute(attrs, "colwidth", colwidth)
                        );
                    }
                }
            }

            if (tr.docChanged) {
                return tr;
            }
        }
    }
}
