import "./color-picker.less";
import template from "./color-picker.html";

import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../TipTapComponents";
import { ColorUtils } from "../../utils/ColorUtils";
import { ColorPickerDialog } from "../colorPickerDialog/ColorPickerDialog";
import { ToolbarIcon } from "../toolbarIcon/ToolbarIcon";
import { IColorPickerContext } from "./enums/IColorPickerContext";
import { BlackAndWhite, ColorDefinitions } from "./helpers/ColorDefinitions";
import { BlackAndWhiteMobile, ColorDefinitionsMobile } from "./helpers/ColorDefinitionsMobile";

/** The event that is emitted when a colour is successfully chosen */
const ON_COLOR_CHANGED: string = "on-color-changed";

/** The event that is called when the customs colors array changes */
const ON_CUSTOM_COLORS_CHANGED: string = "on-custom-colors-changed";

/** The event to close/open the custom color dialog */
const ON_SET_DIALOG_STATE: string = "on-set-dialog-state";

/** The maximum number of custom colours a user can save */
const MAX_NUMBER_OF_CUSTOM_COLORS: number = 40;

@Component({
    template,
    components: {
        [TipTapComponents.ColorPickerDialog]: ColorPickerDialog,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
    },
    name: TipTapComponents.ColorPicker,
})
export class ColorPicker extends Vue {
    // Variables
    // ============================================

    // Props

    /** A list of custom hex codes loaded from User Facts */
    @Prop() private readonly customColors: string[] | undefined;

    /** The currently selected color */
    @Prop() private readonly selectedColor: string | undefined;

    /** Whether or not the custom color dialog is being shown */
    @Prop() private readonly showCustomColorDialog: boolean | undefined;

    /** The color to initiate the color picker dialog with */
    @Prop() private readonly colorPickerDialogColor: string | undefined;

    /** The current context of the color picker */
    @Prop() private readonly context: IColorPickerContext | undefined;

    /** Whether or not the color picker is in mobile mode */
    @Prop() private readonly mobilePallette: boolean | undefined;

    // Data

    /** Initialise empty 2D array to hold hex codes for colorpicker */
    private defaultColours: string[][] = [];

    private blackAndWhite: string[] = [];

    /** The maximum number of custom colours a user can save */
    private maxNumberOfCustomColors: number = MAX_NUMBER_OF_CUSTOM_COLORS;

    // Lifecycle Hooks
    // ============================================
    private mounted(): void {
        // Set the value of the default colors used by the color picker depending on the mode.
        this.defaultColours = this.mobilePallette ? ColorDefinitionsMobile : ColorDefinitions;
        this.blackAndWhite = this.mobilePallette ? BlackAndWhiteMobile : BlackAndWhite;
    }

    /** Whether or not custom colors are being edited */
    private editingCustomColors: boolean = false;

    // Logic
    // ============================================

    /**
     * Get the classes for the specific item
     *
     * @param color the hex color for the current item
     */
    private getItemClasses(color: string | null): string[] {
        if (!color) {
            return [];
        }

        const classes: string[] = [ColorUtils.lightOrDark(color) == ColorUtils.ITheme.LIGHT ? "light" : "dark"];
        if (color == this.selectedColor) {
            classes.push("selected");
        }

        return classes;
    }
    /**
     * Get the title text for the specific item
     *
     * @param color the hex color for the current item
     */
    private getItemTitleText(color: string | null, custom: boolean = false): string {
        if (!color) {
            return "";
        }
        return `${custom ? "Saved colour" : "Colour"} ${color.toLowerCase()}`;
    }

    // DOM Events
    // ============================================

    // Clicks

    /**
     * DOM Click:
     * Called when the user clicks a color circle.
     * Emit the selected color or remove if editing.
     */
    private onColorClick($event: Event, color: string | null): void {
        if (!$event) {
            return;
        }

        $event.stopPropagation();

        if (!color) {
            return;
        }

        const colorIndex: number = this._customColors.indexOf(color);
        if (this.editingCustomColors && colorIndex != -1) {
            const customColors: string[] = Array.from(this._customColors);
            customColors.splice(colorIndex, 1);
            this.$emit(ON_CUSTOM_COLORS_CHANGED, customColors);
        } else {
            this.$emit(ON_COLOR_CHANGED, color);
        }

        Vue.nextTick(() => {
            if (this.editingCustomColors && this._customColors.length == 0) {
                this.editingCustomColors = false;
            }
        });
    }

    /**
     * DOM Event:
     * Called when the user presses the "+" button.
     * Set the current context to custom color
     */
    private onAddCustomColorClick(): void {
        this.$emit(ON_SET_DIALOG_STATE, true, this.selectedColor);
    }

    /**
     * DOM Click:
     * Called when the user presses the "Edit" or "Done" button.
     * Toggles whether or not editing custom colors
     */
    private onToggleEditClick(): void {
        this.editingCustomColors = !this.editingCustomColors;
    }

    // Emits

    /**
     * DOM Emit:
     * Called when the user selects a custom color from
     * the dialog. Add to the list of custom colors.
     */
    private onCustomColorAdded(color: string): void {
        let customColors: string[] = this._customColors.concat();

        if (this._customColors.indexOf(color) == -1) {
            customColors = this._customColors.concat(color);
        }

        this.$emit(ON_CUSTOM_COLORS_CHANGED, customColors);

        // Commented out since this line was not working properly
        // this.$emit(ON_COLOR_CHANGED, color);
    }

    /**
     * DOM Emit:
     * Called when the user exits the custom color dialog. Close the dialog
     */
    private onExitCustomColorDialog(): void {
        this.$emit(ON_SET_DIALOG_STATE, false);
    }

    // Gets
    // ============================================

    /** A list of custom hex codes loaded from User Facts that is always defined */
    private get _customColors(): string[] {
        return this.customColors || [];
    }
}
