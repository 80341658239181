/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable max-classes-per-file */
import {
    MarkdownSerializer as ProsemirrorMarkdownSerializer,
    MarkdownSerializerState as ProsemirrorMarkdownSerializerState,
} from "prosemirror-markdown";

export class MarkdownSerializer extends ProsemirrorMarkdownSerializer {
    constructor(nodes, marks) {
        super(nodes, marks);
    }

    public serialize(content, options?) {
        const state = new MarkdownSerializerState(this.nodes, this.marks, options);
        state.renderContent(content);
        return (state as any).out;
    }
}

export class MarkdownSerializerState extends ProsemirrorMarkdownSerializerState {
    constructor(nodes, marks, options) {
        // @ts-ignore
        super(nodes, marks, options);
    }

    private flushClose(this: any, size) {
        if (this.closed) {
            if (!this.atBlank()) {
                this.out += "\n";
            }

            if (size == null) {
                size = 1;
            }

            if (size > 1) {
                let delimMin = this.delim;
                const trim = /\s+$/.exec(delimMin);
                if (trim) {
                    delimMin = delimMin.slice(0, delimMin.length - trim[0].length);
                }

                for (let i = 1; i < size; i++) {
                    this.out += `${delimMin}\n`;
                }
            }

            this.closed = false;
        }
    }
}
