import { BaseComponent } from "../components/BaseComponent";
import { IDOMComputedStyle } from "./IDOMComputedStyle";
import { IDOMParagraphInline } from "./IDOMParagraphInline";

export class DOMParagraphInlineComponent implements IDOMParagraphInline {
    private baseComponent: BaseComponent;

    constructor(baseComponent: BaseComponent) {
        this.baseComponent = baseComponent;
    }

    public get component(): BaseComponent {
        return this.baseComponent;
    }

    public render(style: IDOMComputedStyle, insideTable: boolean): HTMLElement | null {
        const element: HTMLElement | null = this.baseComponent.visualElement;
        return element;
    }
}
