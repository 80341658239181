import { NumberBoxNode } from "../../../../../extensions/inlineBuildable/inputBox/numberBox/NumberBoxNode";
import { InlineBuildableComponent } from "./InlineBuildableComponent";

export class InlineNumberBoxComponent extends InlineBuildableComponent {
    // Overrides
    // ===================================

    /** Get the name of the component to build */
    public get nodeName(): string {
        return NumberBoxNode.NODE_NAME;
    }
}
