import { IInterfaceImplementor } from "@educationperfect/ep-web-utils";

export class BaseComponent implements IInterfaceImplementor {
    public type!: string;

    public visualElement!: HTMLElement | null;

    public implementsInterface(name: string): boolean {
        return false;
    }
}
