import { EditorState, Transaction } from "prosemirror-state";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";

import { AiAnnotationPluginKey } from "./AiAnnotationPlugin";
import { AnnotationTextProcessor } from "./AnnotationTextProcessor";
import { AiAnnotation as AiAnnotationData, AiAnnotationType } from "./AnnotationTypings";

export class AiAnnotationState {
    private _decorations: DecorationSet = DecorationSet.empty;

    private _annotations: AiAnnotationData[] = [];

    private textProcessor: AnnotationTextProcessor = new AnnotationTextProcessor();

    /**
     * The most recent annotations to be displayed.
     *
     * We need to keep track of this since the annotations are processed
     * asynchronously and we need to ensure the decorations always reflect the
     * most recent annotations.
     * */
    private currentAnnotations: AiAnnotationData[] = [];

    constructor(editorState: EditorState) {
        // Make sure the text processor initialised with the initial editor content.
        this.textProcessor.onContentChange(editorState.doc);
    }

    /**
     * Generate a set of decorations based on the given annotations data and the
     * document content.
     *
     * The {@link AnnotationTextProcessor} is used to find the positions of the
     * matching text segments in the document that corresponds to the
     * annotations data.
     *
     * The decorations is stored in the state and will be accessed by the
     * decorations view prop in the {@link AiAnnotationslugins}.
     */
    private async generateDecorations(annotations: AiAnnotationData[], { doc }: EditorState) {
        if (this.textContent === undefined) {
            return;
        }

        this._annotations = annotations.filter(this.annotationValidator);

        const decorations: Decoration[] = [];

        const annotationPositions = await this.textProcessor.findAnnotationPositions(this._annotations);

        // Find the positions of the matching text in the document
        annotationPositions.forEach((annotation) => {
            // Each annotated text will be wraped in a <mark /> element with a number
            // of attributes to make it accessible to user allow it to be
            // targted for styling.
            //
            // See
            // https://github.com/aleventhal/aria-annotations/blob/master/README.md
            // for more information on the ARIA annotation standard.
            //
            // Styles for the annotations is contained in AiAnnotationUi.vue.
            const decoration = Decoration.inline(annotation.from, annotation.to + 1, {
                tabindex: "0",
                "data-ai-annotation-id": annotation.id,
                "aria-description": annotation.data.comment,
                "aria-roledescription": annotation.data.type,
                class: "ai-annotation-highlight",
                nodeName: "mark",
                ...(annotation.data.type === "spelling" ? { "aria-invalid": "spelling" } : {}),
                ...(annotation.data.type === "grammar" ? { "aria-invalid": "grammar" } : {}),
            });

            decorations.push(decoration);
        });

        this._decorations = DecorationSet.create(doc, decorations);
    }

    /**
     * Ensure only annotations with a non-empty matched text and comment are
     * displayed to the user.
     */
    private annotationValidator(annotation: AiAnnotationData) {
        return annotation.matchText.length > 0 && annotation.comment.length > 0;
    }

    private get textContent() {
        return this.textProcessor.textContent;
    }

    /**
     * Update the state based on the latest transaction.
     */
    public updateContentMapping(transaction: Transaction, state: EditorState): AiAnnotationState {
        if (transaction.docChanged) {
            // If the content of the editor is changed, we also need to update
            // the textProcessor so it knows how to make the annotations to the
            // correctly positions in the document.
            this.textProcessor.onContentChange(state.doc);
        }

        return this;
    }

    public async processAnnotations(transaction: Transaction, state: EditorState, view: EditorView) {
        const annotation = transaction.getMeta(AiAnnotationPluginKey);
        this.currentAnnotations = annotation;

        if (annotation) {
            // Re-generate the decorations in case the annotations position mapping has changed.
            await this.generateDecorations(annotation, state);

            if (annotation === this.currentAnnotations) {
                // Force the editor to re-render with the new decorations.
                view.dispatch(state.tr);
            }
        }
    }

    get decorations() {
        return this._decorations;
    }

    get annotations() {
        return this._annotations;
    }
}
