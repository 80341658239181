import { GuidUtil } from "@educationperfect/ep-web-utils";

import { DOMComputedStyle } from "./DOMComputedStyle";
import { DOMTable } from "./DOMTable";
import { DOMTableCell } from "./DOMTableCell";

export class DOMTableRow {
    public id: string;
    public textToSpeechReadable: boolean;

    public table: DOMTable;
    private cells: DOMTableCell[];
    public containsComponents: boolean;

    constructor(table: DOMTable, textToSpeechReadable: boolean = false) {
        this.textToSpeechReadable = textToSpeechReadable;
        this.id = GuidUtil.create();
        this.table = table;
        this.cells = [];
        this.containsComponents = false;
    }

    public getCells(): DOMTableCell[] {
        return this.cells;
    }

    public addCell(cell: DOMTableCell): void {
        if (cell != null) {
            this.cells.push(cell);
        }
    }

    public render(style: DOMComputedStyle): HTMLTableRowElement {
        var row: HTMLTableRowElement = document.createElement("tr");

        for (let domCell of this.cells) {
            var cell: HTMLTableCellElement = domCell.render(style);
            row.appendChild(cell);
        }

        return row;
    }
}
