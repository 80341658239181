import { GuidUtil } from "@educationperfect/ep-web-utils";
import { EditorCommandSet } from "@educationperfect/tiptap";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { Node as ProsemirrorNode, NodeType, ResolvedPos } from "prosemirror-model";
import { EditorState, NodeSelection, Selection, TextSelection, Transaction } from "prosemirror-state";
import { findChildren, findChildrenByType, NodeWithPos } from "prosemirror-utils";
import { EditorView } from "prosemirror-view";
import { nodeIsActive } from "@educationperfect/tiptap-utils";

import { GapData } from "../../models/GapData";
import { ExtensionNames } from "../../utils/ExtensionNames";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";

// tslint:disable-next-line
export namespace GapNodeCommands {
    export interface Interface extends EditorCommandSet {
        updateGapText: ({
            gapID,
            newText,
            color,
        }: {
            gapID: string;
            newText: string;
            color?: string;
        }) => CommandFunction;
        removeGap: ({ gapID, selectText }: { gapID: string; selectText?: boolean }) => CommandFunction;
        selectGap: ({ gapID }: { gapID: string }) => CommandFunction;
        toggleGap: () => CommandFunction;
        markGap: () => CommandFunction;
        moveToNextGap: ({ gapID }: { gapID: string }) => CommandFunction;
    }
    export function moveToNextGap(type: NodeType, gapID: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const gapNodes: NodeWithPos[] = findChildrenByType(state.doc, type);

            let nextGapNode: NodeWithPos | undefined;

            gapNodes.forEach((nodeWithPos, index, nodes) => {
                if (nodeWithPos.node.attrs.id == gapID) {
                    const nextGapIndex: number = (index + 1) % nodes.length;
                    nextGapNode = nodes[nextGapIndex];
                    return;
                }
            });

            if (nextGapNode) {
                const tr: Transaction = state.tr;
                const resolvedPos: ResolvedPos = state.doc.resolve(nextGapNode.pos);
                tr.setSelection(new NodeSelection(resolvedPos));
                view.dispatch(tr);
                return true;
            } else {
                return false;
            }
        };
    }

    export function updateGapTextCommand(
        type: NodeType,
        gapID: string,
        newText: string,
        nodeName: string
    ): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            const nodeSelection: NodeSelection = getGapNodeSelection(state.doc, gapID, nodeName);

            if (nodeSelection.empty) {
                return false;
            } else {
                const gapNodePosition: number = nodeSelection.$from.pos;
                const attrs: GapData = { ...nodeSelection.node.attrs, id: gapID, text: newText };
                tr.setNodeMarkup(gapNodePosition, type, attrs);
                // The position of the fitg node would have changed after updating the text, therefore
                // we need to locate the node again after setting the new text.
                const newNodeSelection: NodeSelection = getGapNodeSelection(tr.doc, gapID, nodeName);
                tr.setSelection(newNodeSelection);
                view.dispatch(tr);
                return true;
            }
        };
    }

    export function removeGapCommand(gapID: string, nodeName: string, selectText?: boolean): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            const nodeSelection: NodeSelection = getGapNodeSelection(state.doc, gapID, nodeName);

            if (nodeSelection.empty) {
                return false;
            } else {
                tr.setSelection(nodeSelection);
                tr.deleteSelection();
                const gapText: string = nodeSelection.node.attrs.text;
                tr.insertText(gapText);

                if (selectText) {
                    const anchorPos: number = tr.selection.$anchor.pos;
                    const headPosition: ResolvedPos = tr.doc.resolve(anchorPos - gapText.length);
                    const selection: TextSelection = new TextSelection(tr.selection.$anchor, headPosition);
                    tr.setSelection(selection);
                }

                view.dispatch(tr);
                return true;
            }
        };
    }

    export function selectGapCommand(gapID: string, nodeName: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            const nodeSelection: NodeSelection = getGapNodeSelection(state.doc, gapID, nodeName);

            if (nodeSelection.empty) {
                return false;
            } else {
                tr.setSelection(nodeSelection);
                view.dispatch(tr);
                return true;
            }
        };
    }

    export function toggleGapCommand(type, nodeName: string, customData: object | null = null): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const isActive = nodeIsActive(state, type);

            if (isActive) {
                const gapNode = (state.selection as NodeSelection).node;

                if (gapNode.type != type) {
                    return false;
                }

                const gapID: string = gapNode.attrs.id;

                return removeGapCommand(gapID, nodeName, true)(state, dispatch, view);
            } else {
                return markGapCommand(type, nodeName, customData)(state, dispatch, view);
            }
        };
    }

    export function markGapCommand(
        type: NodeType,
        nodeName: string,
        customData: object | null = null
    ): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            let selection: Selection = state.selection;

            if (!(selection instanceof TextSelection)) {
                return false;
            }
            let { $from, $to } = selection;
            const index = $from.index();
            const tr: Transaction = state.tr;

            if (!$from.parent.canReplaceWith(index, index, type)) {
                return false;
            }

            // If this command is called while the cursor is inside a word, we
            // need to mark the whole word as a gap.
            const wordSelection: TextSelection | null = ProsemirrorUtils.getWordSelectionFromCursor(selection, state);
            if (wordSelection) {
                // Update variables for the subsequent processes
                $from = wordSelection.$from;
                $to = wordSelection.$to;
                tr.setSelection(wordSelection);
                selection = wordSelection;
            }

            // Get selected text
            const selectionText = extractTextContentFromSelection(selection, nodeName);
            if (selectionText === "") {
                return false;
            }
            const { trimmedText, startOffset, endOffset } = trimSpacesForText(selectionText);

            const trimmedFromPos: number = $from.pos + startOffset;
            const trimmedToPos: number = $to.pos - endOffset;

            if (dispatch) {
                let gapID = GuidUtil.create();

                const gapData: GapData = {
                    id: gapID,
                    text: trimmedText,
                    ...customData,
                };

                // Step 1: Convert selected text to fitg node
                tr.replaceWith(trimmedFromPos, trimmedToPos, type.create(gapData));

                // Step 2: Select the fitg node
                const resolvedPosition: ResolvedPos<any> = tr.doc.resolve(trimmedFromPos);
                const gapNodeSelection: NodeSelection = new NodeSelection(resolvedPosition);
                tr.setSelection(gapNodeSelection);
                dispatch(tr);
            }

            return true;
        };
    }

    export function getGapNodeSelection(doc: ProsemirrorNode, gapID: string, nodeName: string): NodeSelection {
        const gapNodePosition: number = findGapNodePositionByID(doc, gapID, nodeName);
        const resolvedPosition = doc.resolve(gapNodePosition);
        return new NodeSelection(resolvedPosition);
    }

    function findGapNodePositionByID(doc: ProsemirrorNode, gapID: string, nodeName: string): number {
        const matchedGapPos = findChildren(doc, (node) => node.type.name == nodeName && node.attrs.id == gapID)[0].pos;
        return matchedGapPos;
    }

    /**
     * Sometime double clicking on a word will unintentionally select the space after it, causing
     * the resulting gap to include a trailing space and stuck to the next word. To prevent this
     * from happening we need to trim the text before converting it into a gap and adjust the
     * selection positions accordingly.
     */
    function trimSpacesForText(selectedText: string): { trimmedText: string; startOffset: number; endOffset: number } {
        let startOffset: number = 0;
        let endOffset: number = 0;

        const trimmedText = selectedText.replace(
            /^(\s*)[\w\W]*?(\s*)$/,
            (match: string, leadingSpace: string, trailingSpace: string) => {
                startOffset = leadingSpace.length;
                endOffset = trailingSpace.length;
                return match.trim();
            }
        );

        return {
            trimmedText,
            startOffset,
            endOffset,
        };
    }

    function extractTextContentFromSelection(selection: Selection, nodeName: string): string {
        const slice = selection.content();
        const fragment = slice.content;

        let selectionText = "";

        fragment.nodesBetween(0, fragment.size, (node) => {
            let nodeText: string = "";

            // Consume gap nodes in a selection
            if (node.type.name === nodeName) {
                nodeText = node.attrs.text;
            }
            // Only allow text nodes to be marked as gaps
            else if (node.type.name === ExtensionNames.text) {
                nodeText = node.textContent;
            }

            selectionText += nodeText;
        });

        return selectionText;
    }
}
