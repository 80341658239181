import template from "./ep-editor.html";

import { Editor } from "@educationperfect/tiptap";
import { Component, Prop, Vue } from "vue-property-decorator";

import { EditorFeatureFlags } from "../../models/EditorFeatureFlags";
import { EPEditor } from "../editor/EPEditor";
import { EPToolbar } from "../toolbar/EPToolbar";
import { TipTapComponents } from "../../TipTapComponents";

/** The name of the event that is fired when the editors content changes */
const ON_EDITOR_CHANGE: string = "on-change";

/** The name of the event that emits the vue editor instance */
const ON_EDITOR_SETUP: string = "on-editor-setup";

@Component({
    template,
    components: {
        [TipTapComponents.EPEditorAngular]: EPEditorAngular,
        [TipTapComponents.EPEditor]: EPEditor,
        [TipTapComponents.EPToolbar]: EPToolbar,
    },
    name: TipTapComponents.EPEditorAngular,
})
export class EPEditorAngular extends Vue {
    // Lifecycle Hooks
    // ======================================

    /** The list of enabled features for the editor/toolbar */
    @Prop() private readonly features?: EditorFeatureFlags;

    /** The placeholder text for the editor */
    @Prop() private readonly placeholder?: string;

    /** The UGLEE template for convert and set to the editor */
    @Prop() private readonly template?: string;

    /** The raw html to set to the editor */
    @Prop() private readonly html?: string;

    // State:

    /** The current editor instance */
    private editor: Editor | null = null;

    // Lifecycle Hooks
    // ======================================

    /**
     * Lifecycle event:
     * Called when the component is first mounted to the DOM.
     * Wait for child components to mount then emit the editor
     * instance for outside reference.
     */
    private async mounted(): Promise<void> {
        await Vue.nextTick();
        if (!this.$refs.editorComponent) {
            return;
        }

        const editorRef: EPEditor = this.$refs.editorComponent as EPEditor;
        if (!editorRef) {
            return;
        }

        this.editor = editorRef.editor;
        this.$emit(ON_EDITOR_SETUP, editorRef);
    }

    // DOM Events
    // ======================================

    /**
     * DOM Emit:
     * Called when the editors content changes.
     * Emit event to parents.
     */
    private onChange(): void {
        this.$emit(ON_EDITOR_CHANGE);
    }

    // Gets
    // ======================================

    /** Get the name of the view component */
    public static get vueName(): string {
        return "EPEditorAngular";
    }
}
