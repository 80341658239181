import { ArabicScriptTypes } from "./ArabicScriptTypes";
import { UnicodeRanges } from "./UnicodeRanges";

export class ArabicScriptManager {
    public static determineTypesOfText(text: string): ArabicScriptTypes {
        let scriptTypes: ArabicScriptTypes = ArabicScriptTypes.UNKNOWN;

        for (let i: number = 0; i < text.length; i++) {
            var charCode: number | undefined = text.codePointAt(i);

            if (!charCode) {
                return ArabicScriptTypes.UNKNOWN;
            }

            if (charCode >= UnicodeRanges.ARABIC_START && charCode <= UnicodeRanges.ARABIC_END) {
                scriptTypes = scriptTypes || ArabicScriptTypes.ARABIC;
            } else if (
                charCode >= UnicodeRanges.ARABIC_SUPPLEMENT_START &&
                charCode <= UnicodeRanges.ARABIC_SUPPLEMENT_END
            ) {
                scriptTypes |= ArabicScriptTypes.SUPPLEMENT;
            } else if (
                charCode >= UnicodeRanges.ARABIC_EXTENDED_A_START &&
                charCode <= UnicodeRanges.ARABIC_EXTENDED_A_END
            ) {
                scriptTypes |= ArabicScriptTypes.EXTENDED_A;
            } else if (
                charCode >= UnicodeRanges.ARABIC_PRESENTATION_FORMS_A_START &&
                charCode <= UnicodeRanges.ARABIC_PRESENTATION_FORMS_A_END
            ) {
                scriptTypes |= ArabicScriptTypes.PRESENTATION_FORMS_A;
            } else if (
                charCode >= UnicodeRanges.ARABIC_PRESENTATION_FORMS_B_START &&
                charCode <= UnicodeRanges.ARABIC_PRESENTATION_FORMS_B_END
            ) {
                scriptTypes |= ArabicScriptTypes.PRESENTATION_FORMS_B;
            } else if (
                charCode >= UnicodeRanges.RUMI_NUMERAL_SYMBOLS_START &&
                charCode <= UnicodeRanges.RUMI_NUMERAL_SYMBOLS_END
            ) {
                scriptTypes |= ArabicScriptTypes.RUMI_NUMERAL_SYMBOLS;
            } else if (
                charCode >= UnicodeRanges.INDIC_SIYAQ_NUMBERS_START &&
                charCode <= UnicodeRanges.INDIC_SIYAQ_NUMBERS_END
            ) {
                scriptTypes |= ArabicScriptTypes.INDIC_SIYAQ_NUMBERS;
            } else if (
                charCode >= UnicodeRanges.ARABIC_MATHEMATICAL_ALPHABETIC_SYMBOLS_START &&
                charCode <= UnicodeRanges.ARABIC_MATHEMATICAL_ALPHABETIC_SYMBOLS_END
            ) {
                scriptTypes |= ArabicScriptTypes.MATHEMATICAL_ALPHABETIC_SYMBOLS;
            }
        }
        return scriptTypes;
    }

    /**
     * Checks if a string contains any arabic text.
     * @param text string to perform check on.
     */
    public static hasArabicCharacters(text: string): boolean {
        return (
            (ArabicScriptManager.determineTypesOfText(text) &
                (ArabicScriptTypes.ARABIC |
                    ArabicScriptTypes.SUPPLEMENT |
                    ArabicScriptTypes.EXTENDED_A |
                    ArabicScriptTypes.PRESENTATION_FORMS_A |
                    ArabicScriptTypes.PRESENTATION_FORMS_B |
                    ArabicScriptTypes.RUMI_NUMERAL_SYMBOLS |
                    ArabicScriptTypes.INDIC_SIYAQ_NUMBERS |
                    ArabicScriptTypes.MATHEMATICAL_ALPHABETIC_SYMBOLS)) >
            0
        );
    }

    /**
     * Checks if a string consists exclusively of arabic text.
     * @param text string to perform check on.
     */
    public static onlyArabicCharacters(text: string): boolean {
        return (ArabicScriptManager.determineTypesOfText(text) & ArabicScriptTypes.UNKNOWN) == 0;
    }
}
