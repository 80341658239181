import { EditorCommandSet } from "@educationperfect/tiptap";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { nodeIsActive } from "@educationperfect/tiptap-utils";
import { MarkType, Node, NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, TextSelection } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { EPHeading } from "../heading/EPHeading";
import { Formula, FormulaPluginState } from "./Formula";
import { FormulaUtils } from "./FormulaUtils";

// tslint:disable-next-line:no-namespace
export namespace FormulaCommands {
    export interface Interface extends EditorCommandSet {
        insertFormula({ type }: { type: NodeType }): CommandFunction;
    }

    export function insertFormula(type: NodeType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { tr, selection } = state;

            // Insert formula node at cursor
            const pos: number = selection.$from.pos;
            const formulaNode: Node = state.schema.node(Formula.NODE_NAME);

            const nodeType: NodeType = view.state.schema.nodes[EPHeading.NODE_NAME];
            if (nodeIsActive(view.state, nodeType)) {
                return true;
            }

            if (!selection.empty) {
                const selectedText: string = FormulaUtils.extractTextContentFromSelection(
                    selection,
                    ExtensionNames.text
                );
                // create a formula, set its latex attr to the selected text, and replace the selected text.
                tr.replaceWith(pos, selection.$to.pos, type.create({ latex: selectedText }));
            } else {
                tr.insert(pos, formulaNode); // create empty formula
            }

            // Focus the space after the formula that is being added (only applies when text is selected and turned into a formula. When a formula is created, the mathfield is focused later on)
            tr.setSelection(new TextSelection(tr.doc.resolve(pos + 1)));

            if (dispatch) {
                dispatch(tr);
            }

            return true;
        };
    }

    export function insertSymbol(symbolLatex: string, latexSymbolType: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const { tr, selection } = state;

            // Get cursor position - the formula is inserted here
            const pos: number = selection.$from.pos;

            const pluginState: FormulaPluginState = Formula.FORMULA_PLUGIN_KEY.getState(state) as FormulaPluginState;

            // Add pending symbol to FormulaPluginState
            pluginState.latexSymbol = symbolLatex;
            pluginState.symbolType = latexSymbolType;

            if (selection instanceof NodeSelection) {
                tr.setNodeMarkup(pos, undefined, selection.node.attrs);
            }

            if (dispatch) {
                return dispatch(tr);
            }

            return true;
        };
    }
}
