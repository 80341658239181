import { NodeSpec } from "prosemirror-model";
import { TableHeader } from "@educationperfect/tiptap-extensions";

import { EPTableNodes } from "./EPTableNodes";

export class EPTableHeader extends TableHeader {
    public get schema(): NodeSpec {
        const headerSchema: NodeSpec = EPTableNodes().table_header;
        return headerSchema;
    }
}
