import { ElementUtil } from "@educationperfect/ep-web-browser-utils";
import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class InlineHighlightComponent extends BaseEmbeddedComponent {
    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        const highlightContainer: HTMLElement = ElementUtil.create("highlight");

        if ("id" in parameters) {
            highlightContainer.setAttribute("gap-id", parameters["id"].trim());
        }

        if ("text" in parameters) {
            highlightContainer.setAttribute("text", parameters["text"].trim());
        }

        if ("correct" in parameters) {
            highlightContainer.setAttribute("correct", parameters["correct"].trim());
        }

        return highlightContainer;
    }
}
