import template from "./floating-toolbar.html";

import "./floating-toolbar.less";

import { EditorMenuBubble } from "@educationperfect/tiptap";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue } from "vue-property-decorator";

import { EditorFeatureFlags } from "../../models/EditorFeatureFlags";
import { TipTapComponents } from "../../TipTapComponents";
import { FloatingHighlightBubbles } from "../editor/floatingHighlightBubbles/FloatingHighlightBubbles";
import { GapFloatingBubble } from "../gapFloatingToolbar/GapFloatingBubble";
import { TextEmitBubble } from "../textEmitBubble/TextEmitBubble";

@Component({
    template,
    components: {
        [TipTapComponents.GapFloatingBubble]: GapFloatingBubble,
        [TipTapComponents.FloatingHighlightBubbles]: FloatingHighlightBubbles,
        EditorMenuBubble,
        TextEmitBubble,
    },
    name: TipTapComponents.FloatingToolbar,
})
export class FloatingToolbar extends Vue {
    @Prop() public readonly features: EditorFeatureFlags | undefined;

    @Prop() public readonly editor: EditorView | undefined;
}
