import { Extension } from "@educationperfect/tiptap";
import { EditorState, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

interface ITextBlocks {
    text: string | null | undefined;
    pos: number;
}

interface IMatchResult {
    from: number;
    to: number;
}

export default class EPVariable extends Extension {
    public get name() {
        return "epVariable";
    }

    public get update() {
        return (view) => {
            view.updateState(view.state);
        };
    }

    public get plugins() {
        return [
            new Plugin({
                props: {
                    decorations: (state: EditorState) => {
                        const { doc, plugins, selection } = state;
                        const decorations: Decoration[] = [];

                        const isDocEmpty =
                            state.doc.textContent === "" &&
                            state.doc.childCount === 1 &&
                            state.doc.firstChild!.childCount === 0;

                        if (isDocEmpty) {
                            return;
                        }

                        const mergedTextNodes: ITextBlocks[] = [];
                        let index = 0;

                        doc.descendants((node, pos) => {
                            if (node.isText) {
                                if (mergedTextNodes[index]) {
                                    const text: string | null | undefined = mergedTextNodes[index].text;
                                    if (text) {
                                        mergedTextNodes[index] = {
                                            text: text + node.text,
                                            pos: mergedTextNodes[index].pos,
                                        };
                                    } else {
                                        index += 1;
                                    }
                                } else {
                                    mergedTextNodes[index] = {
                                        text: node.text,
                                        pos,
                                    };
                                }
                            } else {
                                index += 1;
                            }
                        });

                        mergedTextNodes.forEach(({ text, pos }) => {
                            if (text) {
                                const expression = RegExp(/{.*?}/g);

                                for (
                                    let result = expression.exec(text);
                                    result !== null;
                                    result = expression.exec(text)
                                ) {
                                    const matchIndex = result.index;
                                    const t = result[0].length;
                                    const matchResult: IMatchResult = {
                                        from: pos + matchIndex,
                                        to: pos + matchIndex + t,
                                    };
                                    const decoration = Decoration.inline(matchResult.from, matchResult.to, {
                                        class: "decoration-ep-variable",
                                    });

                                    decorations.push(decoration);
                                }
                            }
                        });

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    }
}
