import { TableNodes, tableNodes } from "prosemirror-tables";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { VerticalAlignments } from "./VerticalAlignments";

export function EPTableNodes(): TableNodes {
    return tableNodes({
        tableGroup: "block",
        cellContent: "(paragraph | heading)+",
        cellAttributes: {
            align: {
                default: TextAlignments.LEFT,
                setDOMAttr: (align: number, attrs: any) => {
                    ProsemirrorUtils.addStyleToDomAttribute(attrs, "text-align", align);
                },
                getFromDOM: (dom: Element) => {
                    let textAlign: string | null = dom.getAttribute("align");

                    if (!textAlign) {
                        const style: string | null = dom.getAttribute("style");
                        textAlign = ProsemirrorUtils.getStyleFromStyleString(style, "text-align");
                    }

                    if (textAlign) {
                        return textAlign;
                    }
                },
            },
            // Sets the padding of a cell. A default padding is applied if the padding is unset.
            padding: {
                default: undefined,
                setDOMAttr: (padding: number, attrs: any) => {
                    // Add padding to the cell styles if appropriate
                    if (isNaN(padding)) {
                        return;
                    }

                    ProsemirrorUtils.addStyleToDomAttribute(attrs, "padding", padding + "px");
                },
                getFromDOM: (dom: Element) => {
                    const paddingString: string | null = dom.getAttribute("padding");
                    if (paddingString) {
                        const padding: number = parseInt(paddingString, 10);
                        return padding;
                    }
                },
            },
            background: {
                default: undefined,
                setDOMAttr: (color: string, attrs: any) => {
                    // Add padding to the cell styles if appropriate
                    if (!color) {
                        return;
                    }

                    ProsemirrorUtils.addStyleToDomAttribute(attrs, "background-color", color);
                },
                getFromDOM: (dom: Element) => {
                    let background: string | null = dom.getAttribute("background");

                    if (!background) {
                        const style: string | null = dom.getAttribute("style");
                        background = ProsemirrorUtils.getStyleFromStyleString(style, "background-color");
                    }

                    if (background) {
                        return background;
                    }
                },
            },
            // If the border is set to be visible for the overall table, the individual cell border attribute will be ignored.
            // If the border is hidden for the overall table, the individual cell border will override.
            showBorderOverride: {
                default: false,
                setDOMAttr: (showBorder: boolean, attrs: any) => {
                    // To be consistent to the renderer, we are only setting the border attribute
                    // if showBorder is true.
                    if (showBorder) {
                        attrs.border = true;
                    }
                },
                getFromDOM: (dom: Element) => {
                    const borderString: string | null = dom.getAttribute("border");

                    // The renderer doesn't care what's set on "border" as long as it's not
                    // undefined.
                    if (borderString) {
                        return true;
                    }
                },
            },
            verticalAlign: {
                default: VerticalAlignments.MIDDLE,
                setDOMAttr: (valign: VerticalAlignments, attrs: any) => {
                    ProsemirrorUtils.addStyleToDomAttribute(attrs, "vertical-align", valign);
                },
                getFromDOM: (dom: Element) => {
                    let verticalAlign: string | null = dom.getAttribute("valign");

                    if (!verticalAlign) {
                        const style: string | null = dom.getAttribute("style");
                        verticalAlign = ProsemirrorUtils.getStyleFromStyleString(style, "vertical-align");
                    }

                    if (verticalAlign) {
                        return verticalAlign;
                    }
                },
            },
        },
    });
}
