import { ElementUtil } from "@educationperfect/ep-web-browser-utils";

import { InlineTextElement } from "../render/InlineTextElement";
import { IDOMComputedStyle } from "./IDOMComputedStyle";
import { IDOMParagraphInline } from "./IDOMParagraphInline";

export class DOMParagraphInlineText implements IDOMParagraphInline {
    private text: string;
    private hasHeaderInternal?: boolean;

    constructor(text: string) {
        this.text = this.unescapeText(text);
    }

    private unescapeText(text: string): string {
        const findEscapeCharactersRegex: RegExp = /\\(?=\[|\]|`)/g;
        return text.replace(findEscapeCharactersRegex, "");
    }

    public get paragraphText(): string {
        return this.text;
    }

    public render(
        style: IDOMComputedStyle,
        insideTable: boolean,
        previousInlineElement: IDOMParagraphInline | undefined,
        isLastInlineElement: boolean = false
    ): HTMLElement {
        var span = ElementUtil.createSpan("inline-paragraph");
        this.hasHeaderInternal = false;

        // If empty, add a space so that the line break is respected
        if (!this.text || this.text.trim() == "") {
            this.text = "\u00a0";
        }

        // Split on line breaks
        let lines = this.text.split("\\n");
        let previousInlineText: string | undefined;

        for (let e: number = 0; e < lines.length; e++) {
            const lineText: string = lines[e];
            const inlineText: InlineTextElement | undefined =
                lineText.length > 0 ? new InlineTextElement(lineText) : undefined;

            // Note on dealing with new lines:
            //
            // Generally, we only need to handle with new lines inside table cells as in other
            // situations a new paragraph block will be created when a newline is inserted.
            //
            // In a table cell, line breaks will be denoted by \n and so we will split the string
            // by \n and we will add a <br> element for each text segment.

            // Trailing empty lines are not supported in the question renderer so we need to
            // remove it here as well.
            const isLastLineInParagraph: boolean = e == lines.length - 1 && isLastInlineElement;
            const insertLineBreak: boolean = e > 0 && !(isLastLineInParagraph && lineText.length === 0);

            if (insertLineBreak) {
                // Add a break tag
                span.appendChild(document.createElement("br"));
            }

            if (inlineText) {
                if (inlineText.hasHeader) {
                    this.hasHeaderInternal = true;

                    if (!insideTable && e > 0) {
                        throw new Error(`Invalid template at ${inlineText}, a header must not contain other nodes.`);
                    }

                    span.appendChild(inlineText.headingElement);
                    continue;
                }

                const lineElement: HTMLParagraphElement | HTMLSpanElement = this.hasHeaderInternal
                    ? document.createElement("p")
                    : span;

                // there may be multiple child spans because of formatting etc
                for (let element of inlineText.elements) {
                    lineElement.appendChild(element);
                }

                if (lineElement instanceof HTMLParagraphElement) {
                    span.appendChild(lineElement);
                }
            }
            previousInlineText = lineText;
        }

        // Remove <br> elements if we have headers in the inline text since we are converting
        // each line to a paragraph
        if (this.hasHeaderInternal) {
            const brElements: HTMLCollection = span.getElementsByTagName("br");
            while (brElements.length > 0) {
                span.removeChild(brElements[0]);
            }
        }

        span.style.maxWidth = style.containerWidth + "px";

        // TODO: consider below
        // outside of tables, all rich-text should be 100% wide
        // and aligned. Inside tables, we'll let the cell handle that.
        if (!insideTable) {
            // span.style.width = "100%";
        }

        if (this.hasHeaderInternal) {
            span.classList.add("header");
        }

        return span;
    }

    public get hasHeader(): boolean {
        if (this.hasHeaderInternal === undefined) {
            throw Error("render() must be called before hasHeader is valid");
        }

        return this.hasHeaderInternal;
    }

    public notifyDetached() {
        // TODO
    }
}
