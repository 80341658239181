import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

// tslint:disable-next-line: no-namespace
export namespace InlineBuildableCommands {
    export function createInlineBuildable(type: NodeType, componentId: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            let tr: Transaction = state.tr;
            const selection = tr.selection as TextSelection;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;

            if (!tr.selection || !tr.doc || !type || !componentId) {
                return false;
            }

            tr = state.tr.insert(position, type.create({ id: componentId }));
            if (dispatch) {
                dispatch(tr);
            }
            return true;
        };
    }
}
