import { MediaLibraryItemDetails } from "@educationperfect/ep-web-services/lib/services/MediaLibrary/BusinessObjects/MediaLibraryItemDetails";
import { MediaTypes, URLUtil } from "@educationperfect/ep-web-utils";
import { Vue } from "vue-property-decorator";

/** The name of the event that is called to close the dialog */
const ON_CLOSE_EVENT: string = "on-close";

/** The name of the event that is called to save and exit the dialog */
const ON_SAVE_EVENT: string = "on-save";

export abstract class MediaBaseController extends Vue {
    // Variables
    // ========================================

    // State

    /** The selected media item */
    protected selectedMediaItem: MediaLibraryItemDetails | null = null;

    // Fields

    /** The url of the media */
    protected mediaUrl: string = "";

    // Lifecycle Events
    // ========================================

    /**
     * Lifecycle Event:
     * Called when the component is first mounted to the DOM.
     * Inititalise the component
     */
    protected async mounted(): Promise<void> {
        this.initialise();
    }

    // Logic
    // ========================================

    /** [Ovveride] Inititalise the editors metadata */
    protected abstract initialise(): void;

    /** [Ovveride] Validate that the fields are correct */
    protected abstract validate(): boolean;

    /** [Ovveride] Reset all fields back to their original values */
    protected abstract reset(): void;

    /** Close the dialog */
    private close(): void {
        this.$emit(ON_CLOSE_EVENT);
    }

    /**
     * Called when the media gallery changes. Update media url,
     * store selected item
     *
     * @param item the newly selected media gallery item
     */
    protected onMediaGalleryChange(item: MediaLibraryItemDetails): void {
        this.mediaUrl = URLUtil.assetDomainRedirect(item.URL);
        this.selectedMediaItem = item;
    }

    // DOM Events
    // ========================================

    /**
     * DOM Click:
     * Called when the "Cancel" button is clicked.
     * Close the dialog.
     */
    protected onCancelClick(): void {
        this.close();
    }

    /**
     * DOM Click:
     * [Override]
     * Called when the "Done" button is clicked.
     * Validate the compoent then execture override subclass
     */
    protected onDoneClick(): void {
        if (!this.validate()) {
            return;
        }

        // Override code here
    }

    /**
     * DOM Emit:
     * Called when the modal dialog changes visibily.
     * Reset fields if closing
     *
     * @param visible whether or not the dialog is visible
     */
    protected onVisibleChange(visible: boolean): void {
        if (visible) {
            this.initialise();
            return;
        }

        this.reset();
    }

    /**
     * DOM Emit:
     * Called when the "Reset" button is clicked.
     * Reset the dialog back to empty state;
     */
    protected onReset(): void {
        this.mediaUrl = "";
        this.reset();
    }

    /**
     * DOM Emit:
     * Called when the URL field changes and has a valid URL.
     * Set the media url to be this url.
     *
     * @param url the validated url to set as the media url
     */
    protected onURLFieldChange(url: string): void {
        if (!url) {
            return;
        }

        this.mediaUrl = URLUtil.assetDomainRedirect(url);
    }

    // Gets
    // ========================================

    /** Get the media type allowed in the media picker */
    protected abstract get mediaType(): MediaTypes;
}
