import { Node as TiptapNode } from "@educationperfect/tiptap";
import { DOMOutputSpec, Node as ProsemirrorNode } from "prosemirror-model";
import { SoundView } from "./soundView/SoundView";
import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";

export class SoundNode extends TiptapNode {
    // Variables
    // =======================================

    /** The name of the node */
    public static readonly NODE_NAME = "sound";

    /** [Override] Get the name of the extension */
    public get name(): string {
        return SoundNode.NODE_NAME;
    }

    /** [Override] Get the extension schema */
    public get schema() {
        return {
            inline: true,
            attrs: {
                url: {
                    default: "",
                },
                title: {
                    default: "",
                },
                compact: {
                    default: null,
                },
                autoPlay: {
                    default: null,
                },
                playLimit: {
                    default: null,
                },
                canPause: {
                    default: null,
                },
            },
            group: "inline",
            draggable: true,
            selectable: true,
            parseDOM: [
                {
                    tag: SoundNode.NODE_NAME,
                    getAttrs: (dom: string | Node) => {
                        if (dom instanceof Element) {
                            return {
                                url: dom.getAttribute("url"),
                                title: dom.getAttribute("title"),
                                compact: dom.getAttribute("compact"),
                                autoPlay: dom.getAttribute("autoPlay"),
                                playLimit: dom.getAttribute("playLimit"),
                                canPause: dom.getAttribute("canPause"),
                            };
                        }
                    },
                },
            ],
            toDOM: (node: ProsemirrorNode) =>
                [
                    this.name,
                    {
                        url: node.attrs.url,
                        title: node.attrs.title,
                        compact: node.attrs.compact,
                        autoPlay: node.attrs.autoPlay,
                        playLimit: node.attrs.playLimit,
                        canPause: node.attrs.canPause,
                    },
                ] as DOMOutputSpec,
        };
    }

    /** [Override] Get the view for the sound player */
    public get view(): typeof SoundView { return SoundView; }

    /** Override: Return whether or not the event should be processed */
    public stopEvent(event: Event): boolean { return ProsemirrorUtils.handleDragNodeViewStopEvents(event); }
}
