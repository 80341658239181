/* eslint-disable class-methods-use-this */
import { CommandGetter, Node as TiptapNode } from "@educationperfect/tiptap";
import { NodeSpec, NodeType } from "prosemirror-model";
import { Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { ImageCommands } from "./ImageCommands";
import { ImageNodeView } from "./view/ImageNodeView";

export class ImageNode extends TiptapNode<ImageNodeView> {
    public static readonly NODE_NAME = "image";

    public get name(): string {
        return ImageNode.NODE_NAME;
    }

    public get schema(): NodeSpec {
        return {
            attrs: {
                src: {
                    default: null,
                },
                width: {
                    default: null,
                },
                height: {
                    default: null,
                },
                alt: {
                    default: null,
                },
            },
            inline: true,
            selectable: true,
            group: "inline",
            draggable: true,
            parseDOM: [
                {
                    tag: "img[src]",
                    getAttrs: (dom) => {
                        if (dom instanceof Element) {
                            return {
                                src: dom.getAttribute("src") || null,
                                width: dom.getAttribute("width") || null,
                                height: dom.getAttribute("height") || null,
                                alt: dom.getAttribute("alt") || null,
                            };
                        }
                        return null;
                    },
                },
            ],
            toDOM: (node) => ["img", node.attrs],
        };
    }

    /** [Override] Get the view for the dropdown */
    public get view(): typeof ImageNodeView {
        return ImageNodeView;
    }

    public get plugins(): Plugin[] {
        const plugin = new Plugin({
            props: {
                // When we backspace into an image node we want to select the image rather
                // than remove the whole node straight away
                handleKeyDown: (view: EditorView, event: KeyboardEvent) => {
                    const transaction: Transaction | null = ProsemirrorUtils.handleDeleteIntoInlineNodeContent(
                        view.state,
                        event,
                        ImageNode.NODE_NAME
                    );
                    if (transaction) {
                        view.dispatch(transaction);
                        return true;
                    }
                    return false;
                },
            },
        });

        return [plugin];
    }

    public commands({ type }: { type: NodeType }): CommandGetter {
        return {
            createImage: () => ImageCommands.createImage(type),
        };
    }

    /** Override: Return whether or not the event should be processed */
    public stopEvent(event: Event): boolean {
        return ProsemirrorUtils.handleDragNodeViewStopEvents(event);
    }
}
