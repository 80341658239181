import "./floating-highlight-bubbles.less";
import template from "./floating-highlight-bubbles.html";

import { EditorMenuBubble } from "@educationperfect/tiptap";
import { Component } from "vue-property-decorator";

import { HighlightCommands } from "../../../extensions/highlight/HighlightCommands";
import { HighlightNode } from "../../../extensions/highlight/HighlightNode";
import { TipTapComponents } from "../../../TipTapComponents";
import { BaseFloatingToolbar } from "../../floatingToolbar/BaseFloatingToolbar";
import { ToolbarIcon } from "../../toolbarIcon/ToolbarIcon";
import { Dictionary } from "@educationperfect/ep-web-services/lib/src/interfaces/Dictionary";

@Component({
    template,
    components: {
        [TipTapComponents.FloatingHighlightBubbles]: FloatingHighlightBubbles,
        EditorMenuBubble,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
    },
    name: TipTapComponents.FloatingHighlightBubbles,
})
export class FloatingHighlightBubbles extends BaseFloatingToolbar<HighlightCommands.Interface> {
    protected get showBubble(): boolean {
        // Check that there is not a disallowed node or an allowed node that is active
        for (const key of Object.keys(this.disallowedNodes)) {
            if (this.isActive[key] && this.isActive[key]()) {
                return false;
            }
        }

        // Check that the node feature is actually turned on
        if (!this.isActive[HighlightNode.NODE_NAME]) {
            return false;
        }

        return true;
    }
}
