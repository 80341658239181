import { GuidUtil } from "@educationperfect/ep-web-utils";

import { DOMComponentBlock } from "./DOMComponentBlock";
import { DOMComputedStyle } from "./DOMComputedStyle";
import { DOMParagraph } from "./DOMParagraph";
import { DOMTableCellStyle } from "./DOMTableCellStyle";
import { DOMTableRow } from "./DOMTableRow";
import { IDOMBlockBase } from "./IDOMBlockBase";

export class DOMTableCell {
    public id: string;
    public textToSpeechReadable: boolean;

    private block!: IDOMBlockBase;
    private row: DOMTableRow;
    private style: DOMTableCellStyle;

    constructor(
        row: DOMTableRow,
        block: IDOMBlockBase,
        style: DOMTableCellStyle,
        textToSpeechReadable: boolean = false
    ) {
        this.id = GuidUtil.create();
        this.textToSpeechReadable = textToSpeechReadable;

        this.row = row;
        this.style = style;

        this.setBlock(block);
    }

    public getBlock(): IDOMBlockBase {
        return this.block;
    }

    private setBlock(block: IDOMBlockBase): void {
        this.block = block;

        if (this.block instanceof DOMParagraph) {
            if (!this.block.hasSingleElement) {
                this.row.containsComponents = true;
            }
        } else if (this.block instanceof DOMComponentBlock) {
            // inform the row that it contains a component
            this.row.containsComponents = true;
        }
    }

    /**
     * If the cell content contains header elements, we need to lift them up to be the
     * cell's direct children, along with its siblings.
     */
    private handleContentWithHeader(
        cell: HTMLTableCellElement,
        blockElement: HTMLElement | null
    ): HTMLTableCellElement {
        const children: HTMLCollection | undefined = blockElement?.children;
        if (children) {
            while (children.length > 0) {
                const child: HTMLElement = children[0] as HTMLElement;
                if (child.className.includes("header")) {
                    const innerElements: HTMLCollection = child.children;
                    // tslint:disable-next-line
                    while (innerElements.length != 0) {
                        const element: Element | null = innerElements[0];
                        if (element) {
                            cell.appendChild(element);
                        }
                    }
                } else {
                    cell.appendChild(child);
                }
            }
        }

        return cell;
    }

    public render(style: DOMComputedStyle): HTMLTableCellElement {
        let cell: HTMLTableCellElement = document.createElement("td");

        let styleApplication = this.style.apply(cell);

        let blockElement: HTMLElement | null = this.block.render(style);

        if (this.block instanceof DOMParagraph) {
            cell = this.handleContentWithHeader(cell, blockElement);
        } else if (blockElement != null) {
            cell.appendChild(blockElement);
        }

        return cell;
    }
    // if (styleApplication.widthWasSet)
    // {
    //    var vgroup: VGroup = new VGroup();
    //    vgroup.percentWidth = 100;
    //    vgroup.addElement(blockElement);
    //    //					vgroup.setStyle("verticalScrollPolicy", "off");
    //    //					vgroup.setStyle("horizontalScrollPolicy", "off");
    //    cell.horizontalScrollPolicy = "off";
    //    cell.verticalScrollPolicy = "off";
    //    vgroup.verticalAlign = "bottom";
    //    if (blockElement instanceof RichText)
    //    {
    //        blockElement.percentWidth = 100;
    //    }

    //    if (this.block instanceof DOMParagraph)
    //    {

    //    }

    //    cell.addElement(vgroup);
    // }
    // else
    // {
    //    cell.addElement(blockElement);
    // }
}
