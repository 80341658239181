import { EditorState, Plugin, PluginKey } from "prosemirror-state";

import { EditorEvents } from "./EditorEvents";

export class EditorEventsPlugin extends Plugin<EditorEventsState> {
    public static readonly EditorEventsPluginKey: PluginKey = new PluginKey("editorEvents");

    constructor() {
        super({
            key: EditorEventsPlugin.EditorEventsPluginKey,
            state: {
                init: (config, instance: EditorState) => {
                    return new EditorEventsState();
                },
                apply: (tr, pluginState, oldState, newState) => {
                    return pluginState;
                },
            },
        });
    }
}

export type EventCallback = (event: EditorEvents, data?: any) => void;
type EventsStore = { [key in EditorEvents]: { listeners: EventCallback[] } };

export class EditorEventsState {
    private eventListener: EventCallback | undefined;

    public setEventListener(callback: EventCallback): void {
        this.eventListener = callback;
    }

    public dispatch(event: EditorEvents, data?: any): boolean {
        // Check if this event not exists
        if (this.eventListener === undefined) {
            return false;
        }

        this.eventListener(event, data);
        return true;
    }
}
