import { CommandGetter, Mark } from "@educationperfect/tiptap";
import { CommandFunction, toggleMark } from "@educationperfect/tiptap-commands";
import { MarkSpec, MarkType } from "prosemirror-model";

import { Subscript } from "./Subscript";

export class Superscript extends Mark {
    public static readonly MARK_NAME = "superscript";
    public get name(): string {
        return Superscript.MARK_NAME;
    }

    public get schema(): MarkSpec {
        return {
            excludes: `${Subscript.MARK_NAME} ${Superscript.MARK_NAME}`,
            parseDOM: [
                { tag: "sup" },
                {
                    style: "vertical-align",
                    getAttrs: (value) => {
                        return value === "super" && null;
                    },
                },
            ],
            toDOM() {
                return ["sup", 0];
            },
        };
    }

    public commands({ type }): CommandGetter {
        return () => toggleMark(type);
    }

    public keys({ type }: { type: MarkType }): { [keyCombo: string]: CommandFunction } {
        return { "Mod-.": toggleMark(type) };
    }
}
