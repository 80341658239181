export const enum RenderingContext {
    REVISION = 1,
    ASSESSMENT = 2,
    QUIZ = 3,
    PRINT = 4,
    /** Whether or not the question is showing in a Peer Review context */
    PEER_REVIEW = 5,
    /** Whether or not to show the Marking Criteria below the question */
    MARKING_CRITERIA = 6,
    /** Whether or not to show the expectations of a question */
    EXPECTATION = 7,
}
