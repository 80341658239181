import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";

// tslint:disable-next-line: no-namespace
export namespace TextEmitCommands {
    /**
     * Emit the currently selected text from the editor.
     */
    export function emitSelectedText(): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (state.selection.empty) {
                return false;
            }

            const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                state
            ) as EditorEventsState;
            if (!pluginState) {
                return false;
            }

            const textContent: string = ProsemirrorUtils.extractTextContentFromRange(view, {
                from: state.selection.from,
                to: state.selection.to,
            });
            if (!textContent) {
                return false;
            }

            pluginState.dispatch(EditorEvents.TextEmit, textContent);

            if (dispatch) {
                const tr: Transaction = state.tr;
                const anchor = tr.doc.resolve(state.selection.to);
                const head = tr.doc.resolve(state.selection.to);
                const selection: TextSelection = new TextSelection(anchor, head);

                tr.setSelection(selection);
                dispatch(tr);
            }

            return true;
        };
    }
}
