import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";
import { IImageMetadata } from "./imageDialog/interfaces/IImageMetadata";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ImageCommands {
    export function createImage(type: NodeType, imageMetadata?: IImageMetadata): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (!imageMetadata) {
                return false;
            }
            let { tr } = state;
            const selection = tr.selection as TextSelection;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            const node = createImageNode(type, tr, imageMetadata);

            tr = state.tr.insert(position, node);
            if (dispatch) {
                dispatch(tr);
            }
            return true;
        };
    }

    export function replaceImage(type: NodeType, imageMetadata?: IImageMetadata): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (!imageMetadata) {
                return false;
            }
            let { tr } = state;
            const selection = tr.selection as TextSelection;

            // if the current metadata matches the new metadata, the image doesn't need replaced.
            const currentMetadata: IImageMetadata = (selection as NodeSelection).node.attrs as IImageMetadata;
            if (
                imageMetadata.src === currentMetadata.src &&
                imageMetadata.width === currentMetadata.width &&
                imageMetadata.height === currentMetadata.height &&
                imageMetadata.alt === currentMetadata.alt
            ) {
                return false;
            }
            const node = createImageNode(type, tr, imageMetadata);

            tr.replaceWith(selection.from, selection.to, node); // replace the existing image with the new one with updated attrs
            if (dispatch) {
                dispatch(tr);
            }
            return true;
        };
    }

    function createImageNode(type: NodeType, tr: Transaction, imageMetadata: IImageMetadata): any {
        if (!tr.selection || !tr.doc || !type || !imageMetadata) {
            return false;
        }

        const src = imageMetadata.src || "";
        const alt = imageMetadata.alt || "";
        const width = imageMetadata.width || 0;
        const height = imageMetadata.height || 0;

        const node: any = type.create({ src, alt, width, height });
        return node;
    }

    export function getImageDataOnClick(newState: EditorState): IImageMetadata | undefined {
        let { tr } = newState;

        if (!tr.selection || !tr.doc) {
            return undefined;
        }
        const { selection } = tr;
        if (selection instanceof NodeSelection) {
            const { src } = selection.node.attrs;
            const { alt } = selection.node.attrs;
            const { width } = selection.node.attrs;
            const { height } = selection.node.attrs;
            const { aspectRatioNatural } = selection.node.attrs;

            // only allow an image to be clicked if it has a src, width and height.
            if (src && width && height) {
                const attrs: IImageMetadata = { src, alt, width, height, aspectRatioNatural };
                return attrs;
            }
        }
        return undefined;
    }

    /**
     * Emit the current image metadata
     *
     * @param src the image src
     */
    export function emitImageChanged(imageMetadata: IImageMetadata): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                state
            ) as EditorEventsState;
            if (!pluginState || !imageMetadata) {
                return false;
            }

            pluginState.dispatch(EditorEvents.ImageChanged, imageMetadata);
            return true;
        };
    }
}
