// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { AiAnnotationState } from "./AiAnnotationState";

export const AiAnnotationPluginKey = new PluginKey("ai-annotation");

let editorView: EditorView;

export const AiAnnotationPlugin = new Plugin<AiAnnotationState>({
    key: AiAnnotationPluginKey,
    state: {
        init(_, editorState) {
            // Initialise the annotation state when the editor is created.
            const state = new AiAnnotationState(editorState);
            return state;
        },
        apply(tr, state, _, newEditorState) {
            // This is called on each transaction to to keep the annotation in
            // sync with the editor content.
            state.updateContentMapping(tr, newEditorState);
            state.processAnnotations(tr, newEditorState, editorView);

            return state;
        },
    },
    props: {
        decorations(state) {
            const { decorations } = AiAnnotationPluginKey.getState(state) as AiAnnotationState;
            return decorations;
        },
    },
    view() {
        // Store the editorView so we can use it later to trigger a re-render
        // after we've finished processing the annotations.
        return {
            update(view) {
                editorView = view;
            },
        };
    },
});

export default AiAnnotationPlugin;
