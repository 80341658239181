import { CommandGetter, Mark as TipTapMark } from "@educationperfect/tiptap";
import { DOMOutputSpec, DOMOutputSpecArray, Mark, MarkSpec, MarkType } from "prosemirror-model";

import { ColorUtils } from "../../../../src/utils/ColorUtils";
import { ColorCommands } from "../ColorCommands";

export class TextColor extends TipTapMark {
    public static readonly MARK_NAME = "text_color";

    public get name(): string {
        return TextColor.MARK_NAME;
    }

    public get schema(): MarkSpec {
        return {
            attrs: {
                color: {
                    default: "",
                },
            },
            inline: true,
            group: "inline",
            parseDOM: [
                {
                    style: "color",
                    getAttrs: (color) => {
                        return {
                            color,
                        };
                    },
                },
            ],
            toDOM(mark: Mark, _inline: boolean): DOMOutputSpec {
                mark.attrs.color = ColorUtils.ensureColorPartFormat(mark.attrs.color);
                const hexColor = `#${mark.attrs.color}`;
                const isColor = mark.attrs.color && ColorUtils.HEX_REGEX.test(hexColor);
                const style: string = hexColor != null && isColor ? `color: ${hexColor};` : "";
                const output: DOMOutputSpecArray = ["span", { style }, 0];
                return output;
            }
        };
    }

    public commands({
        type,
        schema,
        attrs,
    }: {
        type: MarkType;
        schema: MarkSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        return (color: string) => {
            return ColorCommands.applyMark(type, { color });
        };
    }
}
