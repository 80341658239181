export const enum UnicodeRanges {
    HIRAGANA_START = 0x3040,
    HIRAGANA_END = 0x30a0,
    KATAKANA_START = 0x30a1,
    KATAKANA_END = 0x30ff,
    KANJI_START = 0x4e00,
    KANJI_END = 0x9faf,
    ROMAJI_START = 0x0041,
    ROMAJI_END = 0x007a,
    ARABIC_START = 0x0600,
    ARABIC_END = 0x06ff,
    ARABIC_SUPPLEMENT_START = 0x0750,
    ARABIC_SUPPLEMENT_END = 0x077f,
    ARABIC_EXTENDED_A_START = 0x08a0,
    ARABIC_EXTENDED_A_END = 0x08ff,
    ARABIC_PRESENTATION_FORMS_A_START = 0xfb50,
    ARABIC_PRESENTATION_FORMS_A_END = 0xfdff,
    ARABIC_PRESENTATION_FORMS_B_START = 0xfe70,
    ARABIC_PRESENTATION_FORMS_B_END = 0xfeff,
    RUMI_NUMERAL_SYMBOLS_START = 0x10e60,
    RUMI_NUMERAL_SYMBOLS_END = 0x10e7f,
    INDIC_SIYAQ_NUMBERS_START = 0x1ec70,
    INDIC_SIYAQ_NUMBERS_END = 0x1ecbf,
    ARABIC_MATHEMATICAL_ALPHABETIC_SYMBOLS_START = 0x1ee00,
    ARABIC_MATHEMATICAL_ALPHABETIC_SYMBOLS_END = 0x1eeff,
}
