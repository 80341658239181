import { StringUtil } from "@educationperfect/ep-web-utils";
import { EditorCommandSet } from "@educationperfect/tiptap";
import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { getMarkRange, markIsActive, nodeIsActive } from "@educationperfect/tiptap-utils";
import { MarkType, Node as ProsemirrorNode } from "prosemirror-model";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { isInTable } from "prosemirror-tables";
import { EditorView } from "prosemirror-view";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { EPHeading } from "../heading/EPHeading";

// tslint:disable-next-line:no-namespace
export namespace EPLinkCommands {
    export interface Interface extends EditorCommandSet {
        updateLink: ({ text, href }: { text: string; href: string }) => CommandFunction;
        toggleLink: () => CommandFunction;
    }

    /**
     * Toggle a link
     *
     * @param type the mark type
     */
    export function toggleLink(type: MarkType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            // If inside a heading, don't allow links to be created
            const nodeType: MarkType = view.state.schema.nodes[EPHeading.NODE_NAME];
            if (nodeIsActive(view.state, nodeType)) {
                return true;
            }
            if (markIsActive(state, type)) {
                return EPLinkCommands.removeLink(type)(state, dispatch, view);
            } else {
                return EPLinkCommands.addEmptyLink(type)(state, dispatch, view);
            }
        };
    }

    /**
     * Create an empty (placeholder) link for populating later
     *
     * @param type the mark type
     */
    export function addEmptyLink(type: MarkType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined): boolean => {
            if (state.selection.empty) {
                return false;
            }

            let linkAllowed: boolean = true;
            let paragraphCount: number = 0;
            let iteration: number = 0;

            state.doc.nodesBetween(state.selection.from, state.selection.to, (node: ProsemirrorNode, pos: number) => {
                const nodeName: string = node.type.name;
                if (nodeName == ExtensionNames.paragraph) {
                    paragraphCount++;
                } else if (!(state.selection instanceof TextSelection)) {
                    linkAllowed = false;
                    return false;
                } else if (
                    iteration == 0 &&
                    nodeName != ExtensionNames.paragraph &&
                    !isListNode(nodeName) &&
                    !isInTable(state)
                ) {
                    paragraphCount++;
                }

                if (paragraphCount > 1) {
                    linkAllowed = false;
                    return false;
                }

                iteration++;
            });

            const tr: Transaction = state.tr;
            tr.addMark(state.selection.from, state.selection.to, type.create({ href: null }));

            if (linkAllowed) {
                if (dispatch) {
                    dispatch(tr);
                }

                return true;
            }

            return false;
        };
    }

    /**
     * Checks if a node is a "list" node by looking at the name of the node.
     * Returns true if the node is either a list item, bullet list, or ordered list.
     * Otherwise returns false.
     * @param nodeName Name of the node to check.
     */
    function isListNode(nodeName: string): boolean {
        return (
            nodeName == ExtensionNames.listItem ||
            nodeName == ExtensionNames.bulletList ||
            nodeName == ExtensionNames.orderedList
        );
    }

    export function removeLink(type: MarkType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (!state.selection) {
                return false;
            }

            const range: { from: number; to: number } | null = getMarkRange(
                state.doc.resolve(state.selection.from),
                type
            );
            if (!range) {
                return false;
            }

            const tr: Transaction = state.tr;

            tr.removeMark(range.from, range.to, type);

            if (dispatch) {
                dispatch(tr);
            }

            return true;
        };
    }

    /**
     * Remove the link mark from specific range
     *
     * @param type the mark type from schema
     * @param range the from/to positions of the mark to remove
     */
    export function removeLinkAtRange(type: MarkType, range: { from: number; to: number } | null): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            if (!range) {
                return false;
            }

            tr.removeMark(range.from, range.to, type);

            if (dispatch) {
                dispatch(tr);
            }

            return true;
        };
    }

    /**
     * Update link href and text. If no href, remove link.
     *
     * @param type the mark type
     * @param attrs the new
     */
    export function updateLink(type: MarkType, text: string, url: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            if (!state.selection) {
                return false;
            }

            const range: { from: number; to: number } | null = getMarkRange(
                state.doc.resolve(state.selection.from),
                type
            );
            if (!range) {
                return false;
            }
            const href: string = StringUtil.startsWithAny(url, ["http://", "https://"]) ? url : "http://" + url;
            const tr: Transaction = state.tr;
            tr.insertText(text, range.from, range.to);
            tr.addMark(range.from, range.from + text.length, type.create({ href }));

            if (dispatch) {
                dispatch(tr);
            }
            return true;
        };
    }
}
