import { DropDownNode } from "../../../../../extensions/inlineBuildable/dropDown/DropDownNode";
import { InlineBuildableComponent } from "./InlineBuildableComponent";

export class InlineDropDownComponent extends InlineBuildableComponent {
    // Overrides
    // ===================================

    /** Get the name of the component to build */
    public get nodeName(): string {
        return DropDownNode.NODE_NAME;
    }
}
