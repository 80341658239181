import { CommandFunction } from "@educationperfect/tiptap-commands";
import { MarkType, Node as ProsemirrorNode } from "prosemirror-model";
import { EditorState, SelectionRange, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { DispatchFn } from "@educationperfect/tiptap-commands";

import { EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";

// tslint:disable-next-line: no-namespace
export namespace ColorCommands {
    function markApplies(doc: ProsemirrorNode, ranges: SelectionRange[], type: MarkType): boolean {
        for (const range of ranges) {
            let can: boolean = range.$from.depth == 0 ? doc.type.allowsMarkType(type) : false;
            doc.nodesBetween(range.$from.pos, range.$to.pos, (node) => {
                if (can) {
                    return false;
                }
                can = node.inlineContent && node.type.allowsMarkType(type);
                return true;
            });
            if (can) {
                return true;
            }
        }
        return false;
    }

    export function applyMark(type: MarkType, attrs?: object | null): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            let tr: Transaction = state.tr;
            tr.setSelection(state.selection);

            if (!tr.selection || !tr.doc || !type) {
                return false;
            }
            const selection = tr.selection as TextSelection;

            if ((selection.empty && !selection.$cursor) || !markApplies(tr.doc, selection.ranges, type)) {
                return false;
            }

            if (selection.$cursor) {
                tr.removeStoredMark(type);
                if (attrs) {
                    tr.addStoredMark(type.create(attrs));
                }

                if (dispatch) {
                    return dispatch(tr);
                } else {
                    return true;
                }
            }

            let hasMark: boolean = false;
            for (let i: number = 0; !hasMark && i < selection.ranges.length; i++) {
                const range = selection.ranges[i] as SelectionRange;
                hasMark = tr.doc.rangeHasMark(range.$from.pos, range.$to.pos, type);
            }

            for (const i of selection.ranges) {
                const range = i as SelectionRange;

                if (hasMark) {
                    tr.removeMark(range.$from.pos, range.$to.pos, type);
                }
                if (attrs) {
                    tr.addMark(range.$from.pos, range.$to.pos, type.create(attrs));
                }
            }

            if (dispatch) {
                return dispatch(tr);
            } else {
                return true;
            }
        };
    }

    /**
     * Emit the current selected colors.
     *
     * @param colors the list of colors to emit
     */
    export function emitColorsChanged(colors: string[]): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
                state
            ) as EditorEventsState;
            if (!pluginState || !colors) {
                return false;
            }

            pluginState.dispatch(EditorEvents.ColorPickerCustomColorsChanged, colors);
            return true;
        };
    }
}
