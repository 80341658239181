import "./media-picker-buttons.less";
import template from "./media-picker-buttons.html";

import { MediaLibraryItemDetails } from "@educationperfect/ep-web-services/lib/services/MediaLibrary/BusinessObjects/MediaLibraryItemDetails";
import { EdsPrimaryButton } from "@educationperfect/ep-web-ui-components";
import { MediaTypes } from "@educationperfect/ep-web-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../../TipTapComponents";
import { EditorMediaBus } from "../../editor/EditorMediaBus";
import { Seperator } from "../../seperator/Seperator";
import { ToolbarIcon } from "../../toolbarIcon/ToolbarIcon";
import { IMediaButtonsContextInfo } from "../mediaDialog/interfaces/IMediaButtonsContextInfo";

/** The name of the event that is called when the media gallery changes content */
const ON_MEDIA_CHANGE = "on-change";
/** The name of the event that is called when an audio file is recorded and submitted using the Sound Recorder, and needs sent to the Sound Recorder Editor */
const ON_AUDIO_RECORDED = "on-audio-recorded";
/** The name of the event that is called when the url field changes */
const ON_URL_FIELD_CHANGE: string = "on-url-field-change";

@Component({
    template,
    components: {
        [TipTapComponents.Seperator]: Seperator,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        EdsPrimaryButton,
    },
})
export class MediaPickerButtons extends Vue {
    // Props

    /** [Prop] The context of the media gallery */
    @Prop({ required: true, type: Number }) private readonly mediaType!: MediaTypes;

    /** [Prop, bound] Whether or not the currently logged in user is an admin */
    @Prop({ required: true, type: Boolean, default: false }) private readonly showUrlField!: boolean;

    /** [Prop, bound] //// */
    @Prop({ required: true, type: String, default: "" }) private readonly targetText!: string;
    // Errors

    /** The URL error field if any */
    private urlFieldError: string | null = null;

    /**
     * DOM CLick:
     * Called when the "Record your voice" button is clicked.
     * Open the voice recorder dialog. Only available for sounds.
     */
    private onRecordYourVoiceClick(): void {
        EditorMediaBus.openSoundRecorder.dispatch({
            callback: (item: MediaLibraryItemDetails) => {
                this.$emit(ON_AUDIO_RECORDED, item);
            },
        });
    }

    /**
     * DOM Click:
     * Called when the "Pick from media gallery" or "Change" button is clicked.
     * Open the media gallery with params
     */
    private onPickFromMediaGalleryClick(): void {
        EditorMediaBus.openMediaGallery.dispatch({
            mediaType: this.mediaType,
            callback: (item: MediaLibraryItemDetails) => {
                this.$emit(ON_MEDIA_CHANGE, item);
            },
        });
    }

    /**
     * DOM Click:
     * Called when the "Upload ___" button is clicked.
     * Open the media uploader with params
     */
    private onOpenFileUploaderClick(): void {
        // NOTE: this will need adjusted if the props are different, or if the type of item is not of type MediaLibraryItemDetails
        EditorMediaBus.openMediaUploader.dispatch({
            mediaType: this.mediaType,
            callback: (item: MediaLibraryItemDetails) => {
                this.$emit(ON_MEDIA_CHANGE, item);
            },
        });
    }

    /**
     * DOM Emit:
     * Called when the input of the url field changes.
     * Validate that the URL matches the correct type and is
     * hosted on EP. Set as media URL triggering editor.
     *
     * @param event the input event
     */
    private onURLFieldInput(event: Event): void {
        if (!event || !event.target) {
            return;
        }

        this.urlFieldError = null;
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        const inputValue: string = inputElement.value.trim();
        if (inputValue.length == 0) {
            return;
        }

        if (!this.validateURL(inputValue)) {
            this.urlFieldError = `URL's must be hosted on Education Perfect and be a supported file type`;
            return;
        }

        this.$emit(ON_URL_FIELD_CHANGE, inputValue);
    }

    /**
     * Checks if a file is valid by checking if it is hosted on `educationperfect.com`
     * or `languageperfect.com` and is of a supported file type.
     * Regex has been updated to include staging and preview enviroments within the `.io` space. (Feb 2021)
     * Regex updated to support multi-region, e.g. the Canada ".ca" region (Aug 2023)
     *
     * @param url The URL to validate
     */
    protected validateURL(url: string): boolean {
        const urlValidation: RegExp = new RegExp(
            `^https?:\\/\\/(?:(?:static|www|staging|preview).)?(?:[a-z]{2}\\.)?(?:education|language)perfect\\.(?:io|com)\\/.+\\.${this.filetypeInfo.supportedFileRegex}$`
        );
        return urlValidation.test(url);
    }

    // Gets
    // =========================================

    /**
     * Determine whether to show the "Record your voice" button"
     */
    private get showRecordVoice(): boolean {
        return this.mediaType == MediaTypes.AUDIO;
    }

    /** Get the file picker info relating to the current context */
    private get filetypeInfo(): IMediaButtonsContextInfo {
        switch (this.mediaType) {
            case MediaTypes.IMAGE:
                return {
                    supportedFormats: "JPEG, PNG, GIF, SVG",
                    maxSize: "5MB",
                    supportedFileRegex: "(png|jpg|jpeg|jfif|gif|svg)",
                };
            case MediaTypes.VIDEO:
                return { supportedFormats: "MP4", maxSize: "400MB", supportedFileRegex: "(mp4)" };
            case MediaTypes.AUDIO:
                return { supportedFormats: "MP3, WAV, M4A", maxSize: "10MB", supportedFileRegex: "(mp3|wav|m4a)" };
            case MediaTypes.PDF:
                return { supportedFormats: "PDF", maxSize: "10MB", supportedFileRegex: "(pdf)" };
            default:
                return { supportedFormats: "", maxSize: "", supportedFileRegex: "" };
        }
    }
}
