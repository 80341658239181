import "./trailing-paragraph.less";

import { GuidUtil } from "@educationperfect/ep-web-utils";
import { Extension } from "@educationperfect/tiptap";
import { nodeEqualsType } from "@educationperfect/tiptap-utils";
import { Node, NodeType } from "prosemirror-model";
import { EditorState, Plugin, PluginKey, Transaction } from "prosemirror-state";
import { findChildren, NodeWithPos } from "prosemirror-utils";
import { Decoration, DecorationSet, EditorView } from "prosemirror-view";

import { ExtensionNames } from "../../utils/ExtensionNames";

export default class TrailingParagraph extends Extension {
    public get name() {
        return "trailing_node";
    }

    public get defaultOptions(): any {
        return {
            node: ExtensionNames.paragraph,
            notAfter: [
                ExtensionNames.paragraph,
                ExtensionNames.heading,
                ExtensionNames.bulletList,
                ExtensionNames.orderedList,
            ],
        };
    }

    private findTrailingParagraph(state: EditorState): NodeWithPos | undefined {
        const trailingNodeType: NodeType = state.schema.nodes[this.options.node];

        const trailingNode: NodeWithPos[] = findChildren(state.doc, (node: Node) => {
            if (node.type === trailingNodeType && node.attrs.trailingNodeId) {
                return true;
            }
            return false;
        });

        if (trailingNode.length === 1) {
            return trailingNode[0];
        } else {
            return undefined;
        }
    }

    public get plugins() {
        if (!this.editor) {
            return;
        }

        const plugin = new PluginKey(this.name);
        const disabledNodes: NodeType[] = Object.entries(this.editor?.schema.nodes)
            .map(([, value]) => value)
            .filter((node: NodeType) => this.options.notAfter.includes(node.name));

        return [
            new Plugin({
                props: {
                    handleDOMEvents: {
                        focus: (view: EditorView, event: Event) => {
                            const pluginState: TrailingParagraphPluginState = plugin.getState(view.state);
                            pluginState.forceUpdate = true;
                            return false;
                        },
                        blur: (view: EditorView, event: Event) => {
                            const pluginState: TrailingParagraphPluginState = plugin.getState(view.state);
                            pluginState.forceUpdate = true;
                            return false;
                        },
                    },
                    decorations: (state: EditorState) => {
                        // Add a "trailing-paragraph" class to the trailing paragraph so
                        // the appropriate css styles can be applied.
                        const trailingNode: NodeWithPos | undefined = this.findTrailingParagraph(state);

                        if (trailingNode) {
                            const selection = state.selection;

                            // if (selection && selection.$from.pos === trailingNode.pos + 1)
                            // {
                            //     return;
                            // }

                            if (trailingNode.node.textContent !== "") {
                                return;
                            }

                            const startPos: number = trailingNode.pos;
                            const endPos: number = startPos + trailingNode.node.nodeSize;
                            const decoration = Decoration.node(startPos, endPos, {
                                class: "trailing-paragraph",
                            });

                            return DecorationSet.create(state.doc, [decoration]);
                        }
                    },
                },
            }),
            new Plugin({
                key: plugin,
                view: () => ({
                    update: (view) => {
                        const { state } = view;
                        const { doc, schema, tr } = state;
                        const pluginState: TrailingParagraphPluginState = plugin.getState(state);
                        const { insertNodeAtEnd, currentNodeId } = pluginState;

                        // Remove the trailing marker from the previous trailing node if a we are creating
                        // a new one or a node has been inserted after the trailing node.
                        const trailingNode: NodeWithPos | undefined = this.findTrailingParagraph(state);
                        if (trailingNode && trailingNode.node.attrs.trailingNodeId !== currentNodeId) {
                            tr.setNodeMarkup(trailingNode.pos, undefined, {
                                ...trailingNode.node.attrs,
                                trailingNodeId: false,
                            });
                        }

                        // // Remove the trailing node when the editor loses focus
                        if (!view.hasFocus() && trailingNode && trailingNode.node.content.size === 0) {
                            tr.delete(trailingNode.pos, trailingNode.pos + trailingNode.node.nodeSize);
                        }

                        // Insert the new trailing node into the document
                        if (insertNodeAtEnd) {
                            const type: NodeType = schema.nodes[this.options.node];
                            tr.insert(doc.content.size, type.create({ trailingNodeId: currentNodeId }));
                        }

                        if (tr.docChanged) {
                            view.dispatch(tr);
                        }
                    },
                }),
                state: {
                    init: (_, state): TrailingParagraphPluginState => {
                        const lastNode = state.tr.doc.lastChild;
                        return {
                            insertNodeAtEnd: !nodeEqualsType({ node: lastNode, types: disabledNodes }),
                        };
                    },
                    apply: (tr: Transaction, value: TrailingParagraphPluginState): TrailingParagraphPluginState => {
                        if (!tr.docChanged && !value.forceUpdate) {
                            return value;
                        }

                        // Remove the trailing paragraph when editor loses focus
                        if (!this.editor?.view.hasFocus()) {
                            return {
                                insertNodeAtEnd: false,
                                currentNodeId: undefined,
                            };
                        }

                        const lastNode: Node | null | undefined = tr.doc.lastChild;
                        const insertNodeAtEnd: boolean = !nodeEqualsType({ node: lastNode, types: disabledNodes });
                        value.insertNodeAtEnd = insertNodeAtEnd;

                        // If a node is inserted after the trailing node, we need to clear the currentNodeId
                        // so we can remove the trailing node in the view update stage.
                        if (!insertNodeAtEnd && lastNode?.attrs.trailingNodeId !== value.currentNodeId) {
                            const trailingNode: NodeWithPos | undefined = this.findTrailingParagraph(this.editor.state);

                            const nodeSize: number = lastNode ? lastNode.nodeSize : 0;
                            const lastChildPos: number = tr.doc.content.size - nodeSize;

                            if (trailingNode && trailingNode.pos !== lastChildPos) {
                                value.currentNodeId = undefined;
                            }
                        } else if (insertNodeAtEnd) {
                            const nodeId: string = GuidUtil.create();
                            value.currentNodeId = nodeId;
                        }

                        return value;
                    },
                },
            }),
        ];
    }
}

interface TrailingParagraphPluginState {
    insertNodeAtEnd: boolean;
    currentNodeId?: string;
    forceUpdate?: boolean;
}
