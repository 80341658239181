import { GuidUtil } from "@educationperfect/ep-web-utils";

import { DOMComposition } from "./DOMComposition";
import { DOMComputedStyle } from "./DOMComputedStyle";
import { DOMListItem } from "./DOMListItem";
import { IDOMBlockBase } from "./IDOMBlockBase";

const enum BulletTypes {
    NONE,
    ROUND,
    DECIMAL,
    LOWER_ALPHA,
    UPPER_ALPHA,
    LOWER_ROMAN,
    UPPER_ROMAN,
}

const TAG_MAP = {
    [BulletTypes.ROUND]: { tag: "ul", listStyle: "" },
    [BulletTypes.DECIMAL]: { tag: "ol", listStyle: "decimal" },
    [BulletTypes.LOWER_ALPHA]: { tag: "ol", listStyle: "lower-alpha" },
    [BulletTypes.UPPER_ALPHA]: { tag: "ol", listStyle: "upper-alpha" },
    [BulletTypes.LOWER_ROMAN]: { tag: "ol", listStyle: "lower-roman" },
    [BulletTypes.UPPER_ROMAN]: { tag: "ol", listStyle: "upper-roman" },
};

interface IBulletItem {
    depth: number;
    type: BulletTypes;
    bulletText: string;
    block: IDOMBlockBase;
}

const romanNumeralMatch: RegExp = /(X{1,3}|(?:X{0,3}(?:IX|IV|VI{0,3}|I{1,3})))\./i;

export class DOMListBlock extends DOMComposition {
    public id: string;
    public textToSpeechReadable: boolean;

    private compiledElement!: HTMLElement;
    private initialBulletType: BulletTypes;
    private items: IBulletItem[];
    private listItems: DOMListItem[];

    constructor(style: DOMComputedStyle, private insideBlock: boolean, textToSpeechReadable: boolean = false) {
        super(style);

        this.items = [];
        this.listItems = [];
        this.initialBulletType = BulletTypes.NONE;
        this.textToSpeechReadable = textToSpeechReadable;
        this.id = GuidUtil.create();
    }

    public get list(): DOMListItem[] {
        return this.listItems;
    }

    public render(): HTMLElement | null {
        if (this.initialBulletType === BulletTypes.NONE) {
            return null;
        }

        if (this.items.length == 0) {
            return null;
        }

        const list = this.createList(this.items[0]);
        this.appendItemsToList(list);

        const wrapperDiv = document.createElement("div");
        wrapperDiv.id = this.id;
        wrapperDiv.classList.add("h-group");
        wrapperDiv.classList.add(`h-align-${this.style.alignment}`);
        wrapperDiv.appendChild(list);
        return wrapperDiv;
    }

    private createList(firstItem: IBulletItem): HTMLElement {
        const { tag, listStyle } = TAG_MAP[firstItem.type];
        const list: HTMLElement = document.createElement(tag);
        list.classList.add("list-block");
        list.style.marginLeft = "2em";
        list.style.listStyle = listStyle;
        if (tag == "ol") {
            list.setAttribute("start", firstItem.bulletText);
        }

        list.setAttribute("align", this.style.alignment);
        return list;
    }

    private appendItemsToList(rootList: HTMLElement) {
        const stack: HTMLElement[] = [];
        let currentList: HTMLElement = rootList;

        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const listItem = this.listItems[i];
            while (stack.length < item.depth) {
                stack.push(currentList);
                const nextList = this.createList(item);
                currentList.appendChild(nextList);
                currentList = nextList;
            }
            while (item.depth >= 0 && stack.length > item.depth) {
                const poppedList = stack.pop();

                if (poppedList) {
                    currentList = poppedList;
                }
            }
            currentList.appendChild(listItem.render(this.style));
        }
    }

    public addBullet(bullet: string, depth: number, block: IDOMBlockBase): boolean {
        const { type, startText } = detectBulletType(bullet);
        const compatible =
            type !== BulletTypes.NONE &&
            (depth > 0 ||
                this.initialBulletType === BulletTypes.NONE ||
                TAG_MAP[type].listStyle === TAG_MAP[this.initialBulletType].listStyle);
        if (!compatible) {
            return false;
        }

        if (this.initialBulletType === BulletTypes.NONE) {
            this.initialBulletType = type;
        }

        const listItem = new DOMListItem(block, this.textToSpeechReadable);
        this.items.push({ depth, type, block, bulletText: startText });
        this.listItems.push(listItem);

        return true;
    }
}

function detectBulletType(this: void, bullet: string): { type: BulletTypes; startText: string } {
    const firstChar = bullet.charAt(0);
    const lastChar = bullet.charAt(bullet.length - 1);
    if (firstChar === "*" && lastChar === "*") {
        return { type: BulletTypes.ROUND, startText: "" };
    }

    if (firstChar >= "1" && firstChar <= "9" && lastChar == ".") {
        return { type: BulletTypes.DECIMAL, startText: bullet.substr(0, bullet.length - 1) };
    }

    if (romanNumeralMatch.test(bullet)) {
        if (firstChar.toUpperCase() != firstChar) {
            // Lower case
            return {
                type: BulletTypes.LOWER_ROMAN,
                startText: fromRoman(bullet.substr(0, bullet.length - 1).toUpperCase()).toString(),
            };
        } else {
            // Upper case
            return {
                type: BulletTypes.UPPER_ROMAN,
                startText: fromRoman(bullet.substr(0, bullet.length - 1).toUpperCase()).toString(),
            };
        }
    }

    if (firstChar.toLowerCase() >= "a" && firstChar.toLowerCase() <= "z" && lastChar == ".") {
        if (firstChar.toUpperCase() != firstChar) {
            // Lower case
            return { type: BulletTypes.LOWER_ALPHA, startText: bullet.substr(0, bullet.length - 1) };
        } else {
            // Upper case
            return { type: BulletTypes.UPPER_ALPHA, startText: bullet.substr(0, bullet.length - 1) };
        }
    }

    return { type: BulletTypes.NONE, startText: "" };
}

function fromRoman(input: string): number {
    var result = 0;
    // the result is now a number, not a string
    var decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    var roman = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
    for (var i = 0; i <= decimal.length; i++) {
        while (input.indexOf(roman[i]) === 0) {
            result += decimal[i];
            input = input.replace(roman[i], "");
        }
    }
    return result;
}
