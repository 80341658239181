import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { EditorState, TextSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { ISoundMetaData } from "./interfaces/ISoundMetaData";

// tslint:disable-next-line: no-namespace
export namespace SoundCommands {
    /**
     * Create a new sound node and insert it at current cursor position.
     *
     * @param type the sound player node type
     * @param audioMetaData the metaData for the audio player
     */
    export function createSound(type: NodeType, audioMetaData?: ISoundMetaData): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            if (!tr.selection || !tr.doc || !type || !audioMetaData) {
                return false;
            }

            const selection = tr.selection as TextSelection;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;

            const node = type.create({ ...audioMetaData });
            const transaction = state.tr.insert(position, node);
            if (dispatch) {
                dispatch(transaction);
                return true;
            }

            return false;
        };
    }
}
