import { Dictionary, GuidUtil } from "@educationperfect/ep-web-utils";

import { DOMComputedStyle } from "./DOMComputedStyle";
import { DOMTableCell } from "./DOMTableCell";
import { DOMTableCellStyle } from "./DOMTableCellStyle";
import { DOMTableRow } from "./DOMTableRow";
import { DOMTableStyle } from "./DOMTableStyle";
import { IDOMBlockBase } from "./IDOMBlockBase";

export class DOMTable implements IDOMBlockBase {
    public id: string;
    public textToSpeechReadable: boolean;

    private rows: DOMTableRow[];
    private currentRow!: DOMTableRow;
    private cellIndex: number;
    private style: DOMTableStyle;
    private cachedRender!: HTMLTableElement;

    constructor(private params: Dictionary<string> | null, textToSpeechReadable: boolean = false) {
        this.textToSpeechReadable = textToSpeechReadable;
        this.id = GuidUtil.create();
        this.rows = [];
        this.cellIndex = 0;

        this.style = new DOMTableStyle(params);
    }

    public getCurrentRow(): DOMTableRow {
        return this.currentRow;
    }

    public getRows(): DOMTableRow[] {
        return this.rows;
    }

    public addRow(row: DOMTableRow): void {
        if (row != null) {
            this.rows.push(row);
        }
    }

    public beginRow(params: Dictionary<string> | null = null): void {
        this.endPreviousRow();

        this.currentRow = new DOMTableRow(this, this.textToSpeechReadable);
        this.cellIndex = 0;
    }

    public endPreviousRow(): void {
        if (this.currentRow != null) {
            this.addRow(this.currentRow);
        }
    }

    public addCell(block: IDOMBlockBase, header: boolean, params: Dictionary<string> | null): void {
        if (this.currentRow == null) {
            return;
        }
        // if (block instanceof DOMComponentBlock)
        // {
        //     block.blockComponent.parentIsTableParam = true;
        // }

        var style: DOMTableCellStyle = new DOMTableCellStyle(this.cellIndex, header, params, this.style);
        var cell: DOMTableCell = new DOMTableCell(this.currentRow, block, style, this.textToSpeechReadable);
        this.currentRow.addCell(cell);
        this.cellIndex++;
    }

    public render(style: DOMComputedStyle): HTMLElement {
        if (this.cachedRender == null) {
            if (this.cellIndex > 0) {
                this.endPreviousRow();
            }

            var table: HTMLTableElement = this.createTable(style);
            var tbody: HTMLTableSectionElement = document.createElement("tbody");
            table.appendChild(tbody);

            for (let domRow of this.rows) {
                var tableRow: HTMLTableRowElement = domRow.render(style);
                tbody.appendChild(tableRow);
            }

            this.cachedRender = table;
        }

        return this.cachedRender;
    }

    // public async preload(): Promise<IPreloadError>
    // {
    //     return null;
    // }

    // public notifyAttached(): RenderPromise
    // {
    //     if (this.rows.length == 0)
    //     {
    //         return RenderPromise.resolve();
    //     }

    //     return RenderPromise.all(this.rows.map((row) => {
    //         if (row.containsComponents)
    //         {
    //             return row.notifyAttached();
    //         }
    //         else
    //         {
    //             return RenderPromise.resolve();
    //         }
    //     }));
    // }

    // public notifyDetached()
    // {
    //     for (let row of this.rows)
    //     {
    //         if (row.containsComponents)
    //         {
    //             row.notifyDetached();
    //         }
    //     }
    // }

    private createTable(contextStyle: DOMComputedStyle): HTMLTableElement {
        var grid: HTMLTableElement = document.createElement("table");
        grid.style.display = "inline-block";
        grid.style.borderCollapse = "collapse";

        // width
        if (!isNaN(this.style.percentWidth)) {
            grid.style.width = this.style.percentWidth + "%";
        } else if (!isNaN(this.style.width)) {
            grid.style.width = this.style.width + "px";
        }

        // height
        if (!isNaN(this.style.percentHeight)) {
            grid.style.height = this.style.percentHeight + "%";
        } else if (!isNaN(this.style.height)) {
            grid.style.height = this.style.height + "px";
        }

        if (this.params && this.params["border"]) {
            const showBorders: boolean = this.params["border"] !== "no";
            grid.setAttribute("show-borders", showBorders ? "true" : "false");
        }

        if (contextStyle["alignment"]) {
            grid.setAttribute("align", contextStyle["alignment"]);
        }

        return grid;
    }
}
