import "./number-box-editor.less";
import template from "./number-box-editor.html";

import { NumberBoxComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/NumberBoxComponentMetaData";
import { ArrayHelper, GuidUtil, StringUtil } from "@educationperfect/ep-web-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ToastStates } from "../../../../../components/toast/ToastStates";
import { ToolbarIcon } from "../../../../../components/toolbarIcon/ToolbarIcon";
import { TipTapComponents } from "../../../../../TipTapComponents";
import { InputBoxScriptType } from "../../common/enums/InputBoxScriptType";
import { AcceptanceErrorTypeCode } from "./enums/AcceptanceErrorTypeCode";
import { BoxTextAlign } from "./enums/BoxTextAlign";
import { RoundingTypeCode } from "./enums/RoundingTypeCode";
import { EdsPrimaryButton, EdsSecondaryButton, EdsTertiaryButton, EdsIconAdd } from "@educationperfect/ep-web-ui-components";

type ComponentMetaData = NumberBoxComponentMetaData;

/** The maximum width of the number box */
const MAX_NUMBER_BOX_WIDTH: number = 600;

/** The minimum width of the number box */
const MIN_NUMBER_BOX_WIDTH: number = 10;

/** The number of miliseconds an error toast stays open for */
const EDITOR_ERROR_TIME: number = 3000;

@Component({
    template,
    components: {
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        EdsPrimaryButton,
        EdsSecondaryButton,
        EdsTertiaryButton,
        EdsIconAdd,
    },
    name: TipTapComponents.NumberBoxEditor,
})
export class NumberBoxEditor extends Vue {
    // Variables
    // ====================================

    // Static

    public static createNumberBoxComponentMetaData(): NumberBoxComponentMetaData {
        return {
            ComponentID: GuidUtil.create(),
            Height: 0,
            Score: 0,
            Marked: 0,
            ComponentTypeCode: "NUMBER_BOX_COMPONENT", // definition is is renderer that can't be accessed in tiptap
            InitialText: "",
            Options: [],
            Width: 0,
            ScriptPosition: InputBoxScriptType.NORMAL,
            TextAlignment: BoxTextAlign.LEFT,
            RoundingTypeCode: RoundingTypeCode.NONE,
            RoundingAmount: 0,
            AcceptableErrorTypeCode: AcceptanceErrorTypeCode.ABSOLUTE,
            AcceptableErrorValue: 0,
        };
    }

    // Props

    /** [Prop] The component meta data for the question, prefilled with required data if new question */
    @Prop({ required: false, type: Object, default: () => NumberBoxEditor.createNumberBoxComponentMetaData() })
    private readonly componentMetaData!: ComponentMetaData;

    /** [Prop, Bound] If specified, whether or not the editor is open */
    @Prop({ required: true, type: Boolean, default: false }) public readonly visible!: boolean;

    // Instance

    /** Whether or not the settings dropdown is open */
    private settingsOpened: boolean = false;

    /** The add option input element reference */
    private inputElement!: HTMLInputElement;

    /** The value of the width field */
    private width: number | null = null;

    /** The list of possible correct options */
    private options: string[] = [];

    /** The value of the rounding type code dropdown */
    private roundingTypeCode: RoundingTypeCode = RoundingTypeCode.NONE;

    /** The value of the rounding amount field */
    private roundingAmount: number = 0;

    /** The value of the acceptance error dropdown */
    private acceptableErrorTypeCode: AcceptanceErrorTypeCode = AcceptanceErrorTypeCode.ABSOLUTE;

    /** The value of the acceptance error field */
    private acceptableErrorValue: number = 0;

    /** The value of the alignment tab bar */
    private textAlignment: BoxTextAlign = BoxTextAlign.LEFT;

    /** The value of the initial text field */
    private initialText: string = "";

    /** The value of the script position tab bar */
    private scriptPosition: InputBoxScriptType = InputBoxScriptType.NORMAL;

    // Lifecycle Events
    // ====================================

    /**
     * Lifecyle event: Called when the component is first mounted to the DOM.
     * Create reference to input element
     */
    private async mounted(): Promise<void> {
        await Vue.nextTick();
        this.inputElement = this.$refs.inputElement as HTMLInputElement;
    }

    // Logic
    // ====================================

    /**
     * Setup fields with new component metadata.
     */
    private async initializeComponentMetaData(): Promise<void> {
        if (!this.componentMetaData) {
            return;
        }

        this.options = ArrayHelper.copy(this.componentMetaData.Options);
        this.roundingAmount = this.componentMetaData.RoundingAmount;
        this.roundingTypeCode = this.componentMetaData.RoundingTypeCode as RoundingTypeCode;
        this.acceptableErrorValue = this.componentMetaData.AcceptableErrorValue;
        this.acceptableErrorTypeCode = this.componentMetaData.AcceptableErrorTypeCode as AcceptanceErrorTypeCode;
        this.textAlignment = this.componentMetaData.TextAlignment as BoxTextAlign;
        this.initialText = this.componentMetaData.InitialText;
        this.scriptPosition = this.componentMetaData.ScriptPosition as InputBoxScriptType;
        this.width = this.componentMetaData.Width !== 0 ? this.componentMetaData.Width : null;
    }

    /**
     * Create the save component metadata if valid.
     */
    private createNumberBoxMetaData(): ComponentMetaData {
        return {
            ...this.componentMetaData,
            InitialText: this.initialText?.toString() ?? "",
            Options: this.options,
            Width: this.width ?? 0,
            ScriptPosition: this.scriptPosition,
            TextAlignment: this.textAlignment,
            RoundingTypeCode: this.roundingTypeCode,
            RoundingAmount: +this.roundingAmount,
            AcceptableErrorTypeCode: this.acceptableErrorTypeCode,
            AcceptableErrorValue: +this.acceptableErrorValue,
        };
    }

    /**
     * Validate the component options
     * - At least two options, one correct
     * - No option can be whitespace only
     * - Options must be unique
     */
    private validateComponent(): void {
        if (this.options.length === 0) {
            throw new Error("You must enter at least one correct option");
        }

        const optionDescriptions: string[] = [];
        let numberOfEmptyOptions: number = 0;
        this.options.forEach((option: string) => {
            if (!this.validateIsNumberOrVariable(option)) {
                throw new Error("All options must be numbers or variables");
            }

            optionDescriptions.push(option);
            if (StringUtil.isNullOrWhitespace(option)) {
                numberOfEmptyOptions += 1;
            }
        });

        if (this.initialText) {
            if (!this.validateIsNumberOrVariable(this.initialText)) {
                throw new Error("Prefilled text must be a number or a variable");
            }
        }

        if (numberOfEmptyOptions > 0) {
            throw new Error("Options cannot be empty");
        }

        // Create unique list of options
        const uniqueOptionsCount: number = Array.from(new Set(optionDescriptions)).length;
        if (uniqueOptionsCount !== optionDescriptions.length) {
            throw new Error("Options must be unique");
        }

        if (this.width && (this.width < MIN_NUMBER_BOX_WIDTH || this.width > MAX_NUMBER_BOX_WIDTH)) {
            throw new Error(
                `Width of Numberbox must be between ${MIN_NUMBER_BOX_WIDTH}px and ${MAX_NUMBER_BOX_WIDTH}px`
            );
        }
    }

    /**
     * Reset fields back to their defaults
     */
    private reset(): void {
        this.options = [];
        this.roundingAmount = 0;
        this.roundingTypeCode = RoundingTypeCode.NONE;
        this.acceptableErrorValue = 0;
        this.acceptableErrorTypeCode = AcceptanceErrorTypeCode.ABSOLUTE;
        this.textAlignment = BoxTextAlign.LEFT;
        this.initialText = "";
        this.scriptPosition = InputBoxScriptType.NORMAL;
        this.width = null;
        this.clearTextField();
    }

    /**
     * Remove the contents of the new option text fields
     */
    private clearTextField(): void {
        this.inputElement.value = "";
    }

    /** Closes the editor window, optionally saving the changed data */
    private close(): void {
        this.$emit("on-close");
    }

    /**
     * Add a new option to the list of options
     * if passes validation
     *
     * @param option the new option to add
     */
    private addOption(option: string = ""): void {
        const trimmedOption: string = option.trim();
        if (this.options.includes(trimmedOption)) {
            throw new Error("The option you’re trying to add already exists");
        }

        if (!this.validateIsNumberOrVariable(option)) {
            throw new Error("Option must be a number or a variable");
        }

        this.options.push(option);
    }

    /**
     * Validate option meets these rules
     * - Must be a number
     * - OR must be a variable
     * - Or nust be a variable with aditional numbers ONLY
     *
     * @param option the new option to add
     */
    private validateIsNumberOrVariable(option: string): boolean {
        const textWithoutVariables: string = option.trim().replace(/\{[^}]*\}/g, "");
        return textWithoutVariables.length === 0 || !isNaN(+textWithoutVariables);
    }

    /**
     * Scroll the scroll container to the bottom
     * aka the last option
     */
    private scrollToLatestOption(): void {
        Vue.nextTick(() => {
            const scrollContainer: HTMLDivElement = this.$refs.scrollContainer as HTMLDivElement;
            if (!scrollContainer) {
                return;
            }

            scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
        });
    }

    /**
     * Show toast with the error message.
     * Dismiss after 3 seconds.
     *
     * @param err the error to display
     */
    private handleError(err: Error): void {
        this.$toast.show({ state: ToastStates.FAILURE, actionText: err?.message ?? "An unknown error occurred" });
        setTimeout(() => this.$toast.hide(), EDITOR_ERROR_TIME);
    }

    /**
     * Add the option with current
     * value.
     */
    private handleNewOption(): void {
        const trimmedText: string = this.inputElement.value.trim();
        if (!trimmedText) {
            return;
        }

        this.addOption(trimmedText);
        this.inputElement.value = "";
        this.scrollToLatestOption();
    }

    // DOM Events
    // ====================================

    /**
     * DOM Click:
     * Called when the save button is clicked. Save component metadata
     * and emit to parent
     */
    private onSaveClick(): void {
        try {
            this.handleNewOption();
            this.validateComponent();
            const componentMetaData: ComponentMetaData | null = this.createNumberBoxMetaData();
            this.$emit("on-save", componentMetaData);
            this.close();
        } catch (err) {
            if (err instanceof Error) {
                this.handleError(err);
            }
        }
    }

    /**
     * DOM Click:
     * Called when the close button is clicked. Emit
     * close event to parent.
     */
    private onCloseClick(): void {
        this.close();
    }

    /**
     * DOM Click: Called when the "Delete" button is clicked
     * on an option. Remove the item from the list of options
     */
    private onDeleteOptionClick(index: number): void {
        this.options.splice(index, 1);
    }

    /**
     * DOM Click:
     * Called when the "Add" button is clicked.
     * Focus the input element field. Add option
     * if text field has data
     */
    private onAddOptionClick(): void {
        if (this.inputElement.value) {
            this.onAddOptionEnter();
        }

        this.inputElement.focus();
    }

    /**
     * DOM Emit:
     * Called when the dialog changes visibility.
     * Reset the fields if closing or initialise new component
     * metadata.
     *
     * @param visible whether or not the dialog is visible
     */
    private onVisibleChange(visible: boolean): void {
        if (!visible) {
            this.reset();
            return;
        }

        this.initializeComponentMetaData();
    }

    /**
     * DOM Emit:
     * Called when the add option field enter key is pressed.
     * Add the option with current value.
     */
    private onAddOptionEnter(): void {
        try {
            this.handleNewOption();
        } catch (err) {
            this.handleError(err);
        }
    }

    /**
     * DOM Emite:
     * Called when content is pasted into the new option field.
     * Sperate words by commas and tabs and add new options
     */
    private onOptionFieldPaste($event: ClipboardEvent): void {
        if (!$event || !$event.clipboardData || !$event.target) {
            return;
        }

        // Only allow pasting into an empty field
        if (this.inputElement.value.length !== 0) {
            return;
        }

        // Get pasted data as plaintext
        const pasteData: string = $event.clipboardData.getData("text/plain");
        if (!pasteData) {
            return;
        }

        const lines: string[] = pasteData.trim().split("\n");
        try {
            lines.forEach((line: string) => {
                this.addOption(line);
            });

            this.scrollToLatestOption();
        } catch (err) {
            this.handleError(err);
        }

        // Stop paste event
        $event.preventDefault();
    }

    /**
     * DOM Emit:
     * Caleld when the rounding type dropdown changes.
     * Reset rounding amount back to 0 if the new
     * value is set to NONE.
     *
     * @param event the change event
     */
    private onRoundingTypeChange(event: Event): void {
        if (!event || !event.target) {
            return;
        }

        const selectEl: HTMLSelectElement = event.target as HTMLSelectElement;
        if (selectEl.selectedOptions.length > 1) {
            return;
        }

        if (selectEl.selectedOptions[0].value === RoundingTypeCode.NONE) {
            this.roundingAmount = 0;
        }
    }
}
