import { Dictionary, StringUtil } from "@educationperfect/ep-web-utils";

import { EditorFeatureFlags } from "../models/EditorFeatureFlags";
import { AttributesParser } from "./libs/epRenderer/AttributesParser";
import { BaseComponent } from "./libs/epRenderer/components/BaseComponent";
import { ComponentParser } from "./libs/epRenderer/components/ComponentParser";
import { IComponentParser } from "./libs/epRenderer/components/IComponentParser";
import { DOMComponentBlock } from "./libs/epRenderer/dom/DOMComponentBlock";
import { DOMComposition } from "./libs/epRenderer/dom/DOMComposition";
import { DOMComputedStyle } from "./libs/epRenderer/dom/DOMComputedStyle";
import { DOMFormatBlock } from "./libs/epRenderer/dom/DOMFormatBlock";
import { DOMListBlock } from "./libs/epRenderer/dom/DOMListBlock";
import { DOMParagraph } from "./libs/epRenderer/dom/DOMParagraph";
import { DOMTable } from "./libs/epRenderer/dom/DOMTable";
import { IDOMBlockBase } from "./libs/epRenderer/dom/IDOMBlockBase";
import { SizingModels } from "./libs/epRenderer/dom/SizingModels";
import { DOMParagraphRenderer } from "./libs/epRenderer/render/DOMParagraphRenderer";
import { IRenderingParameters } from "./libs/epRenderer/render/IRenderingParameters";
import { RenderingContext } from "./libs/epRenderer/render/RenderingContext";
import { RenderingMode } from "./libs/epRenderer/render/RenderingMode";
import { TextTemplateParser } from "./TextTemplateParser";

const BLOCK_START = "[block";
const LIST_ITEM_MATCH_REGEX: RegExp = /^( +)(\*|(?:\d{1,3}|[a-z])\.|(?:X{1,3}|(?:X{0,3}(?:IX|IV|V|I)?I{0,2}))\.) (\S.*)$/i;

export interface ParserOptions {
    renderingParameters?: IRenderingParameters;
    style?: DOMComputedStyle;
    allowEmbeddedTextboxComponent?: boolean;
    isNested?: boolean;
}

// WorkingFormulaEditorConverter.convertFormulas(editorStep.step.Formula, mathEquationManager, formattingOptions);

export class TemplateParser {
    private composition!: DOMComposition;
    private currentTable!: DOMTable | null;
    private currentFormatBlock!: DOMFormatBlock | null;
    private currentListBlock!: DOMListBlock | null;
    private alignment!: string;
    private fontSizeRichtext!: number;
    private fontSizeMath!: number;
    private containerWidth!: number;
    private renderingParameters!: IRenderingParameters;
    private style!: DOMComputedStyle;
    private allowEmbeddedTextboxComponent!: boolean;
    private componentParser: IComponentParser = new ComponentParser();

    constructor(private enabledFeatures: EditorFeatureFlags) {}

    public parse(template: string, options?: ParserOptions): string {
        if (!template) {
            return "";
        }

        const parseOptions = {
            style: new DOMComputedStyle(null, SizingModels.MAXIMUM),
            renderingParameters: { mode: RenderingMode.MODE_QUESTION, context: RenderingContext.REVISION },
            allowEmbeddedTextboxComponent: false,
            isNested: false,
        };

        if (options) {
            Object.assign(parseOptions, options);
        }

        this.currentTable = null;
        this.renderingParameters = parseOptions.renderingParameters;
        this.allowEmbeddedTextboxComponent = parseOptions.allowEmbeddedTextboxComponent;

        // initialise outputs
        this.style = parseOptions.style;
        this.composition = new DOMComposition(this.style, parseOptions.isNested);

        this.parseInternal(template);

        const renderedElement: HTMLElement | null = this.composition.render();
        if (renderedElement) {
            return renderedElement.innerHTML;
        } else {
            return "";
        }
    }

    private get compositionTarget(): DOMComposition {
        return this.currentFormatBlock || this.composition;
    }

    private parseInternal(markup: string) {
        // split by line breaks
        const markupLines: string[] = markup.split("\n");
        let match: RegExpMatchArray;

        for (const line of markupLines) {
            if (this.currentTable) {
                this.parseLineWithinTable(line);
            } else if (StringUtil.startsWith(line, "[table")) {
                this.closeListIfActive();
                this.currentTable = this.beginTable(line);
            } else if (!this.currentFormatBlock && StringUtil.startsWith(line, BLOCK_START)) {
                this.closeListIfActive();
                this.currentFormatBlock = this.beginFormatBlock(line);
            } else if (this.currentFormatBlock && StringUtil.startsWith(line, "]")) {
                this.closeListIfActive();
                this.endFormatBlock();
            } else if (this.checkIsListItem(line)) {
                if (!this.currentListBlock) {
                    const insideBlock: boolean = this.compositionTarget instanceof DOMFormatBlock;
                    this.currentListBlock = new DOMListBlock(this.compositionTarget.style, insideBlock);
                }
                this.parseBulletLine(line);
            } else {
                this.closeListIfActive();
                this.closeTableIfActive();

                // regular block
                const block: IDOMBlockBase | null = this.parseBlock(line);
                if (block != null) {
                    this.compositionTarget.addBlock(block);
                }
            }
        }

        this.closeListIfActive();
    }

    private closeListIfActive() {
        if (this.currentListBlock) {
            this.compositionTarget.addBlock(this.currentListBlock);
            this.currentListBlock = null;
        }
    }

    private closeTableIfActive(): void {
        this.currentTable = null;
    }

    private beginFormatBlock(line: string): DOMFormatBlock {
        const attribsReader = new AttributesParser(line.substring(BLOCK_START.length));
        const formatBlockStyle = this.style.clone();
        for (const name of Object.keys(attribsReader.attributes)) {
            const value = attribsReader.attributes[name];
            switch (name) {
                case "width":
                    formatBlockStyle.containerWidth = parseInt(value, 10);
                    break;

                case "align":
                    formatBlockStyle.alignment = value;
                    break;

                default:
                // ignore others
            }
        }

        return new DOMFormatBlock(formatBlockStyle, this.style);
    }

    private endFormatBlock() {
        // end table
        if (this.currentFormatBlock) {
            this.composition.addBlock(this.currentFormatBlock);
            this.currentFormatBlock = null;
        } else {
            throw new Error("Cannot end format block, current format block is null");
        }
    }

    private checkIsListItem(line: string): boolean {
        return !!line.match(LIST_ITEM_MATCH_REGEX);
    }

    private parseBulletLine(line: string): void {
        //                    e.g. |
        //                         | * Some text
        //        start of line -->|  1. Some text
        //                         |   a. Some text
        const match = line.match(LIST_ITEM_MATCH_REGEX);

        //                         [ bullet /  digit. / alpha. ] [text]
        if (!match) {
            return;
        }

        const [_, space, bullet, text] = match;
        const depth: number = space.length - 1;
        const block = this.parseBlock(text, true);

        if (this.currentListBlock && block) {
            this.currentListBlock.addBullet(bullet, depth, block);
        }
    }

    private parseBlock(text: string, overrideTextToSpeechEnabled: boolean = false): IDOMBlockBase | null {
        if (text == null) {
            return null;
        }

        const insideTable: boolean = this.currentTable != null;

        // old format for image component
        // [[http://some.url.com/image.png]]
        if (StringUtil.startsWith(text, "[[") && StringUtil.endsWith(text, "]]")) {
            const imageURL: string = StringUtil.trimEnds(text, 2);
            text = '[image url="' + imageURL + '"]';
        }

        // block image, video, textbox, external component etc..
        // [component attrib="value" ...]
        if (StringUtil.startsEndsWithChar(text, "[", "]")) {
            if (text.indexOf("]", 1) === text.length - 1) {
                // make sure text doesn't contain other closing brackets
                const componentSyntax: string = StringUtil.trimEnds(text);
                const blockComponent: IDOMBlockBase | null = this.parseBlockComponent(componentSyntax);
                if (blockComponent != null) {
                    return blockComponent;
                }
            }
        }

        const paragraphRenderer: DOMParagraphRenderer = new DOMParagraphRenderer(
            this.compositionTarget.style,
            this.componentParser,
            this.renderingParameters,
            this.enabledFeatures,
            this.allowEmbeddedTextboxComponent
        );
        const paragraph: DOMParagraph = paragraphRenderer.renderParagraph(text, insideTable);
        // if (this.textToSpeechEnabled && this.checkTextReadableForMode(this.renderingParameters.mode))
        // {
        //     this.identifiedComponents.registerTextReadableComponent(paragraph);
        // }
        return paragraph;
    }

    private parseBlockComponent(componentSyntax: string): IDOMBlockBase | null {
        var defaultAttributes: Dictionary<string> = this.compositionTarget.style.defaultParameters;

        var component: BaseComponent = this.componentParser.parse(
            componentSyntax,
            this.renderingParameters,
            defaultAttributes,
            this.enabledFeatures
        );

        let isTextboxComponent = component && component.type === "textbox";
        let shouldRenderComponent = !isTextboxComponent || this.allowEmbeddedTextboxComponent;

        if (component != null && shouldRenderComponent) {
            return new DOMComponentBlock(component);
        }

        return null;
    }

    private parseLineWithinTable(line: string) {
        if (StringUtil.startsWith(line, "]")) {
            // end table
            if (this.currentTable) {
                this.compositionTarget.addBlock(this.currentTable);
                this.closeTableIfActive();
            } else {
                throw new Error("Current table is undefined");
            }
        } else if (StringUtil.startsWith(line, "=")) {
            this.parseTableRow(line);
        } else if (StringUtil.startsWithAny(line, ["|", "!"])) {
            if (
                this.currentTable &&
                this.currentTable.getCurrentRow() == null &&
                this.currentTable.getRows().length == 0
            ) {
                this.currentTable.beginRow();
            }

            // table line
            this.parseTableLine(line);
        }
    }

    private beginTable(line: string): DOMTable {
        line = StringUtil.trim(line.substr(6)); // trim off [table

        var params: Dictionary<string> | null = null;
        if (line != null) {
            params = AttributesParser.Parse(line);
        }

        return new DOMTable(params);
    }

    private parseTableRow(line: string): void {
        // A table row will look like this in it's simplest form
        // |-
        //
        // May have options after it
        // |-

        var trimmedLine: string = StringUtil.trim(line);
        var params: Dictionary<string> | null = null;
        if (line.length > 1) {
            var parser: AttributesParser = new AttributesParser(line);
            params = parser.attributes;
        }

        if (this.currentTable) {
            this.currentTable.beginRow(params);
        }
    }

    private parseTableLine(line: string): void {
        var header: boolean = false;
        var firstChar: string = line.charAt(0);
        if (firstChar == "") {
            return;
        }

        if (firstChar == "!") {
            header = true;
            line = line.replace("!!", "||");
        }

        line = line.substr(1);
        if (line.trim() == "") {
            return;
        }

        var cells: string[] = line.split("||");
        var cellIndex: number = 0;
        for (let cell of cells) {
            cell = cell.replace(/\\\|/g, "Ð");
            var parts: string[] = cell.split("|", 2);
            var params: Dictionary<string> | null = null;
            var contentString: string;

            if (parts.length == 2) {
                contentString = parts[1];
                params = AttributesParser.Parse(parts[0]);
            } else {
                contentString = parts[0];
            }

            contentString = contentString.replace(/Ð/g, "|");

            contentString = StringUtil.trim(contentString); // cell.substr(i, len-i);
            const content: IDOMBlockBase | null = this.parseBlock(contentString);
            if (content != null && this.currentTable) {
                this.currentTable.addCell(content, header, params);
            }
        }
    }
}

// private parser!: MarkdownParser;

// constructor(schema: Schema)
// {
//     const markdownIt: MarkdownIt = new MarkdownIt("zero").enable(["emphasis"]);
//     this.parser = new MarkdownParser(schema, markdownIt, this.generateTokens());
// }

// public parse(template: string): Node
// {
//     return this.parser.parse(template);
// }

// private generateTokens(): any
// {
//     return {
//         strong: this.parseBold,
//         paragraph: this.parseParagraph,
//         em: {mark: TemplateSerializer.bold},
//     };
// }

// private parseBold = {
//     mark: TemplateSerializer.bold,
// }

// private parseParagraph = {
//     block: TemplateSerializer.paragraph,
// }
// }
