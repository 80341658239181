import "./formula-view.less";
import template from "./formula-view.html";

import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { MathQuillFacade } from "@educationperfect/ep-web-math";
import { StringUtil } from "@educationperfect/ep-web-utils";
import { Editor, NodeView } from "@educationperfect/tiptap";
import { Input, Poptip } from "@educationperfect/view-design";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorState, NodeSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import { EditorEvent, EditorEvents } from "../editorEvents/EditorEvents";
import { EditorEventsPlugin, EditorEventsState } from "../editorEvents/EditorEventsPlugin";
import { Formula, FormulaPluginState } from "./Formula";
import { FormulaUtils } from "./FormulaUtils";
import { SymbolType } from "./SymbolDefinitions";

const FORMULA_ELEMENT_REGEX: RegExp = /<formula.*latex="[^"]*".*><\/formula>/;
const LATEX_EXTRACTION_REGEX: RegExp = /latex="([^"]*)"/;

@Component({
    components: { Poptip, Input },
    template,
})
export default class FormulaView extends Vue implements NodeView {
    @Prop() public node!: ProsemirrorNode;
    @Prop() public updateAttrs!: (attrs: object) => any;
    @Prop() public view!: EditorView;
    @Prop(Boolean) public selected!: boolean;
    @Prop() public editor!: Editor;

    /** Instance of the attached MathField */
    protected mathField: mq.IEditableMathField | undefined;
    /** Whether latex input mode is enabled */
    protected latexMode: boolean = false;
    /** Value indicating whether mathquill can render the current latex. */
    private canRenderMathquill: boolean = true;
    /** Value indicating whether katex can render the current latex. */
    private canRenderKatex: boolean = true;
    /** Value indicating whether the formula has exited latex mode */
    private exitedLatexMode: boolean = false;
    /** Regular expression to test whether a LATEX format equation contains variables. */
    private latexWasChanged: boolean = false;
    private static latexContainsVar: RegExp = /\\var\{.*?\}/;
    /** Regular expression matching TeX commands before \var{..}. We need a insert a space in case the variable evaluates to text, since \timesA is invalid. */
    private static insertLatexSpaces: RegExp = /(\\[a-z]+)(\\var\{)/gi;

    // ===========================================================================
    // Event handlers
    // ===========================================================================

    /**
     * Called when the node has been updated. Ie making an edit in either latex or mathquill mode. This means that the document state has been
     * updated in some ways.
     */
    @Watch("node")
    private onNodeChange(newNode: ProsemirrorNode, oldNode: ProsemirrorNode): void {
        const newLatex: string = newNode.attrs.latex;
        // If latex mode is on, update the latex attribute. Mathquill updates are handled by its own handler.
        if (this.latexMode) {
            this.updateNodeLatex(newLatex);
        }
        if (newNode !== oldNode) {
            this.latexWasChanged = true;

            // When undo/redo is pressed in the toolbar we need to keep the Mathquill
            // element in sync with the attribute latex value.
            if (this.mathField?.latex() !== newLatex) {
                this.mathField?.latex(newLatex);
            }
        }
    }

    /** Called when the selection of the node has been changed. */
    @Watch("selected")
    private onNodeSelectionChange(isSelected: boolean, oldValue: boolean): void {
        if (!this.mathField) {
            return;
        }

        this.setFormulaMode(false);

        const pluginState: FormulaPluginState = Formula.FORMULA_PLUGIN_KEY.getState(
            this.editor.state
        ) as FormulaPluginState;
        /** Track whether to focus the input. Mathquill always requires focus when selected */
        let requiresFocus = !this.latexMode && isSelected

        if (pluginState) {
            /** update mathfield to include the inserted symbol (if it exists) at the cursor position (handled by MQ) */
            if (pluginState.latexSymbol && pluginState.symbolType) {
                this.insertSymbol(pluginState.latexSymbol, pluginState.symbolType);

                /** Once symbol is inserted into mathfield, reset the plugin state */
                pluginState.latexSymbol = null;
                pluginState.symbolType = null;

                /** input needs to be focused */
                requiresFocus = true;
            } else if (pluginState.fromBackspace) {
                pluginState.fromBackspace = false;
                this.mathField.moveToRightEnd();

                /** move to right end of latex input */
                this.startEditingLatexInput(false);
            } else if (pluginState.fromDelete) {
                pluginState.fromDelete = false;
                this.mathField.moveToLeftEnd();

                /** move to left end of latex input */
                this.startEditingLatexInput(true);
            }
            /** Note: previous selection is only defined if we have used a left or right arrow to enter a formula */
            else if (pluginState.previousSelection) {
                /** entering formula from the left */
                if (pluginState.previousSelection.$anchor.nodeAfter === this.node) {
                    this.latexMode ? this.startEditingLatexInput(true) : this.mathField.moveToLeftEnd();

                    /** input needs to be focused */
                    requiresFocus = true;
                }
                /** entering formula from the right */
                else if (pluginState.previousSelection.$anchor.nodeBefore === this.node) {
                    this.latexMode ? this.startEditingLatexInput(false) : this.mathField.moveToRightEnd();

                    /** input needs to be focused */
                    requiresFocus = true;
                }

                /** Clear the previous selection */
                pluginState.previousSelection = undefined;
            }
        }

        /** change focus of the input, if required */
        if (requiresFocus && this.editable) {
            this.mathField.focus();
        } else {
            this.mathField.blur();
        }
    }

    protected insertSymbol(latexSymbol: string, symbolType: string): void {
        if (!this.mathField) {
            return;
        }

        // If the latex input is focused, or the symbol we are inserting can't be rendered by MQ, write to the latex field.
        if (this.latexMode || symbolType === SymbolType.OTHER) {
            // replace selection with latex symbol
            const firstHalf: string = this.latexInputTextArea.value.substring(
                0,
                this.latexInputTextArea.selectionStart
            );
            const secondHalf: string = this.latexInputTextArea.value.substring(
                this.latexInputTextArea.selectionEnd,
                this.latexInputTextArea.value.length
            );

            // Append space to any proper latex symbol/command
            if (latexSymbol.startsWith("\\") && !latexSymbol.endsWith("}")) {
                latexSymbol += " ";
            }
            const newLatex = firstHalf + latexSymbol + secondHalf;
            this.updateNodeLatex(newLatex);
            this.latexMode ? this.latexInputTextArea.focus() : this.mathField.focus();
            setTimeout(() => {
                this.latexInputTextArea.selectionEnd = newLatex.length - secondHalf.length; // set cursor position after the inserted symbol.
            });
            // this.mathField.latex(newLatex);
            this.validateFormula();
        } else {
            if (symbolType == SymbolType.SYMBOL) {
                this.mathField.write(latexSymbol);
                this.mathField.focus();
            } else if (symbolType == SymbolType.COMMAND) {
                this.mathField.cmd(latexSymbol);
                this.mathField.focus();
            }
        }
    }

    // Handle keyboard events within latex mode
    protected onLatexKeydown(event: KeyboardEvent): void {
        switch (event.which) {
            case Keyboard.SPACE:
                // Prevent the input of trailing spaces
                const match: RegExpMatchArray | null = this.latex.match(/(\s+)$/);

                if (match && match.length > 0) {
                    event.preventDefault();
                }
                return;
            case Keyboard.ESCAPE:
                ProsemirrorUtils.exitNode(false, this.view); // exit to the right of the formula node
                break;
            case Keyboard.TAB:
                event.preventDefault();
                this.exitFormula(1, false);
                break;
            case Keyboard.LEFT_ARROW:
            case Keyboard.RIGHT_ARROW:
            case Keyboard.UP_ARROW:
            case Keyboard.DOWN_ARROW:
                // Deselect the gap when user uses arrow keys to navigate away
                let direction: "top" | "right" | "bottom" | "left" = "left";

                switch (event.which) {
                    case Keyboard.LEFT_ARROW:
                        direction = "left";
                        break;
                    case Keyboard.RIGHT_ARROW:
                        direction = "right";
                        break;
                    case Keyboard.UP_ARROW:
                        direction = "top";
                        break;
                    case Keyboard.DOWN_ARROW:
                        direction = "bottom";
                        break;
                }
                // handles exiting latex mode using left or right arrow keys
                this.handleArrowKeyNavigation(direction);
            case Keyboard.BACKSPACE:
                if (this.latex.length == 0) {
                    this.deleteFormulaNode();
                }
                break;
            case Keyboard.F2:
                event.preventDefault();
                this.exitLatexMode(event);
                break;
        }
    }

    protected onFormulaKeydown(event: KeyboardEvent): void {
        switch (event.which) {
            case Keyboard.KEY_Z:
                if (event.metaKey || event.ctrlKey) {
                    if (event.shiftKey) {
                        this.editor.commands.redo();
                        this.validateFormula();
                    } else {
                        this.editor.commands.undo();
                        if (this.mathField) {
                            this.validateFormula();
                        }
                    }
                }
                break;

            case Keyboard.KEY_Y:
                if (event.metaKey || event.ctrlKey) {
                    this.editor.commands.redo();
                    this.validateFormula();
                }
                break;

            case Keyboard.ESCAPE:
                this.exitFormula(MathQuill.R, true);
                break;
            case Keyboard.TAB:
                event.preventDefault();
                this.exitFormula(MathQuill.R, false);
                break;
            case Keyboard.F2:
                event.preventDefault();
                this.enterLatexMode();
                break;
        }
    }

    protected onLatexButtonClick(event: MouseEvent): void {
        if (this.latexMode) {
            this.exitLatexMode();
        } else {
            this.enterLatexMode();
        }
    }

    private onCopy(event: ClipboardEvent): void {
        let selection: Selection | null = window.getSelection();
        if (!event.clipboardData || !selection) {
            return;
        }
        const text: string = selection.toString();
        event.clipboardData.setData("text/plain", text);
        event.preventDefault();
    }

    private onPaste(event: ClipboardEvent): void {
        if (!event.clipboardData || !this.mathField) {
            return;
        }
        const indexOfHtml: number = event.clipboardData.types.findIndex((t: string) => t === "text/html");

        if (indexOfHtml !== -1) {
            const htmlStr: string = event.clipboardData.getData("text/html");
            const formulaHtml: RegExpMatchArray | null = htmlStr.match(FORMULA_ELEMENT_REGEX);
            if (formulaHtml) {
                const latex: RegExpMatchArray | null = formulaHtml[0].match(LATEX_EXTRACTION_REGEX);

                if (latex && latex.length > 1) {
                    this.mathField.write(latex[1]);
                    this.validateFormula();
                    event.preventDefault();
                    return;
                }
            }
        }
        const plainText: string = event.clipboardData.getData("text/plain");
        if (plainText != null) {
            this.insertSymbol(plainText, SymbolType.SYMBOL);
        }
        event.preventDefault();
    }

    protected async enterLatexMode(): Promise<void> {
        this.latexMode = true;
        await this.$nextTick();
        this.startEditingLatexInput(false); // focus latex input, putting cursor at the end of the field
    }

    protected onLatexFocus(event: FocusEvent): void {
        this.setSelectionOnFocus(); // need to find another way to do this.
        this.dispatchFocusEvent(EditorEvents.Blur, event);
    }

    /**
     * Only update the mathquill field when we exit the latex mode, and only if it is valid. Otherwise,
     * stay in latex mode.
     */
    protected async exitLatexMode(event?: KeyboardEvent): Promise<void> {
        // Disable newlines within latex mode
        if (event?.keyCode === Keyboard.ENTER) {
            event?.preventDefault();
        }
        // When the user finished typing latex, clean up their input to handle an edge case with variables
        this.updateNodeLatex(this.latex.replace(FormulaView.insertLatexSpaces, "$1 $2"));
        this.onLatexBlur();
        this.exitedLatexMode = true;

        // This ensures the editor toolbar is updated properly
        setTimeout(() => {
            this.dispatchFocusEvent(EditorEvents.Focus);
        });
    }

    protected onLatexBlur(): void {
        this.validateFormula();
        this.setFormulaMode();
    }

    private dispatchFocusEvent(event: EditorEvents.Blur | EditorEvents.Focus, focusEvent?: FocusEvent): void {
        if (event == EditorEvents.Blur && this.view.hasFocus()) {
            return;
        }

        const editorState: EditorState = this.editor.state;
        const pluginState: EditorEventsState = EditorEventsPlugin.EditorEventsPluginKey.getState(
            editorState
        ) as EditorEventsState;
        if (pluginState) {
            let eventData: EditorEvent = {
                type: event,
                focusEvent,
                fromFormula: true,
            };
            pluginState.dispatch(event, eventData);
        }
    }

    /**
     * Check if the current latex attribute can be rendered by Mathquill and/or KaTeX.
     */
    protected validateFormula(): boolean | undefined {
        if (!this.mathField) {
            return undefined;
        }
        // checking if the latex is valid mathquill may change the latex of the mathfield even if it fails, so we need to store the original.
        const storeLatex = this.latex.trim();
        this.canRenderMathquill = FormulaUtils.isValidMathquill(storeLatex, this.mathField);
        this.canRenderKatex = FormulaUtils.isValidKatex(storeLatex);

        // logs validation info to console.
        FormulaUtils.validationDebugging(false, storeLatex, this.canRenderMathquill, this.canRenderKatex);
    }

    protected async setFormulaMode(refreshMathField: boolean = true): Promise<void> {
        if (!this.mathField) {
            return;
        }
        if (this.canRenderMathquill && (this.canRenderKatex || FormulaView.latexContainsVar.test(this.latex))) {
            this.latexMode = false;
            if (refreshMathField && this.editable) {
                /**
                 * The same logic is run when a formula is created as when it is loaded in a slide. Therefore, we must focus formulas manually in specific cases.
                 * These include:
                 *    - If new formula has been created, as it is empty, so we should focus it.
                 *    - If a formula has just exited latex mode, it should be focused.
                 */
                if (StringUtil.isNullOrEmpty(this.mathField?.latex()) || this.exitedLatexMode) {
                    this.mathField.focus(); // When exiting latex mode, we need to wait for the mathfield to be focused before we can reflow.
                    this.mathField.reflow();
                    this.exitedLatexMode = false;
                }
            }
        } else {
            this.latexMode = true;
            if (refreshMathField) {
                this.mathField.blur();
            }
        }
    }

    // =========================================================================
    // Component lifecycle
    // =========================================================================

    /**
     * Set up the MathField when the component is first created.
     */
    protected async mounted(): Promise<void> {
        this.mathField = await MathQuillFacade.makeEditable(this.formulaSpan);
        this.validateFormula();
        this.setFormulaMode();
        this.dispatchFocusEvent(EditorEvents.Focus);

        // Add keydown listener to the mathquill input
        const mathFieldTextArea: HTMLTextAreaElement | null = this.mathField
            .el()
            .getElementsByTagName<"textarea">("textarea")
            .item(0);
        mathFieldTextArea?.addEventListener("keydown", (e) => {
            this.onFormulaKeydown(e);
        });

        // We have to set the config after setting the latex to prevent the edit handler from
        // being called at the start, which will cause a render loop.
        this.mathField.config({
            autoCommands: "alpha beta gamma Delta lambda mu pi sigma theta omega Omega",
            autoOperatorNames: "sin cos tan sinh cosh tanh cot cosec log lg ln arg deg",
            spaceBehavesLikeTab: false,
            leftRightIntoCmdGoes: "up",
            restrictMismatchedBrackets: true,
            sumStartsWithNEquals: true,
            supSubsRequireOperand: true,
            typingSlashWritesDivisionSymbol: false, // flag to use division symbol rather than create \frac{}{}
            typingAsteriskWritesTimesSymbol: true, // flag to use \times rather than \cdot
            charsThatBreakOutOfSupSub: "=<>)",
            // autoSubscriptNumerals: true,
            handlers: {
                edit: (mathField) => {
                    // only doing this when latex mode is false ensures that when the mathquill validation fails, mathquill will not perform changes to the latex when in latex mode.
                    // not sure if we should do this though, as for MOST cases, mathquill cleaning up the latex is useful
                    // (for example, removing uneeded curly braces added by the ShaneTeX -> LaTeX conversion) is actually helpful!
                    if (this.latex != mathField?.latex() && !this.latexMode) {
                        // Updates the latex model whenever a change is made in MQ mode.
                        // Note the use of fieldLatex here, which is required to add back any spaces Mathquill has removed that we want to keep
                        this.updateNodeLatex(this.modifiedMathfieldLatex());
                    }
                },

                moveOutOf: (direction, _) => {
                    this.exitFormula(direction, false);
                },
                deleteOutOf: (direction) => {
                    this.exitFormula(direction, true);
                },
                enter: () => {
                    this.validateFormula();
                    this.setFormulaMode();
                    this.exitFormula(MathQuill.R, false);
                },
                onblur: (e) => {
                    const focusEvent: FocusEvent | undefined = (e as any).data?.event?.originalEvent;
                    this.dispatchFocusEvent(EditorEvents.Blur, focusEvent);
                },
                onfocus: () => {
                    this.dispatchFocusEvent(EditorEvents.Focus);
                },
            },
        });
    }

    protected updated(): void {
        if (this.latexWasChanged) {
            !this.latexMode && this.mathField ? this.mathField.focus() : this.latexInputTextArea.focus();
            this.latexWasChanged = false;
        }
    }

    /** Clean up the MathField when the component is destroyed. */
    protected destroyed(): void {
        if (this.mathField) {
            this.mathField.revert();
        }
    }

    // ===========================================================================
    // Helper functions
    // ===========================================================================

    /** Exit the formula node when in the direction supplied. */
    private exitFormula(direction: number, isDelete: boolean): void {
        if (this.isEmpty) {
            this.deleteFormulaNode();
        } else {
            // this.validateFormula();
            const exitLeft: boolean = direction === MathQuill.L;
            ProsemirrorUtils.exitNode(exitLeft, this.view);
        }
    }

    private deleteFormulaNode(): void {
        const { selection, tr } = this.editor.state;

        tr.deleteSelection();
        this.editor.view.dispatch(tr);
        this.editor.focus();
    }

    /**
     * prosemirror-view 1.9.14 introduced code that deselected the formula node during
     * edits. This is a workaround to ensure that the formula node is selected
     * when the Mathquill editor has been updated.
     */
    private updateNodeLatex(newLatex: string): void {
        if (this.latex !== newLatex) {
            const nodePos: number = this.view.state.tr.selection.$from.pos;

            this.updateAttrs({ latex: newLatex });

            const newTransaction = this.view.state.tr;
            const resolvedPos = newTransaction.doc.resolve(nodePos);
            const nodeSelection = new NodeSelection(resolvedPos);
            newTransaction.setSelection(nodeSelection);
            this.view.dispatch(newTransaction);
        }
    }

    /**
     * Returns the value of the MathQuill field, making suitable
     * last-minute adjustments as needed.
     */
    private modifiedMathfieldLatex(): string {
        if (this.mathField) {
            return this.mathField.latex().replace(FormulaView.insertLatexSpaces, "$1 $2");
        }
        return "";
    }

    private startEditingLatexInput(fromLeft: boolean): void {
        // We need to call this asynchronously because sometime the formula is updated and rerendered right before
        // this is called.
        setTimeout(() => {
            if (fromLeft) {
                this.latexInputTextArea.setSelectionRange(0, 0);
            } else {
                this.latexInputTextArea.setSelectionRange(
                    this.latexInputTextArea.value.length,
                    this.latexInputTextArea.value.length
                );
            }
            this.latexInputTextArea.focus();
        });
    }

    // Handles arrow-keying out of latex mode
    private handleArrowKeyNavigation(direction: "right" | "left" | "top" | "bottom"): void {
        const textValue: string = this.latexInputTextArea.value;
        const lastPosition: number = textValue.length;
        const selectionStart = this.latexInputTextArea.selectionStart;
        const selectionEnd = this.latexInputTextArea.selectionEnd;

        const singleLine: boolean = this.latexInputTextArea.offsetHeight <= 24;
        const cursorAtStart: boolean = selectionStart === 0 && selectionEnd === 0;
        const cursorAtEnd: boolean = selectionStart === lastPosition && selectionEnd === lastPosition;

        // Don't interfere with the keyboard behaviour unless we are at the
        // boundary.
        if (cursorAtStart && direction === "left") {
            this.view.focus();
        } else if (cursorAtEnd && direction === "right") {
            this.view.focus();
        } else if (direction == "bottom" && cursorAtEnd) {
            ProsemirrorUtils.exitNode(false, this.view);
        } else if (direction === "top" && cursorAtStart) {
            this.view.focus();
        }
    }

    /**
     * prosemirror-view 1.9.14 introduced code that prevented formula node selection
     * by clicking. This is a workaround to ensure that the formula node is selected
     * when the Mathquill editor has been focused into.
     */
    private setSelectionOnFocus(): void {
        if (this.view.root instanceof Document) {
            const formulaView: Element = this.$el;
            const pos: number = this.view.posAtDOM(formulaView, 0);
            const tr: Transaction = this.view.state.tr;
            tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));
            this.view.dispatch(tr);
        }
    }

    // ===========================================================================
    // Computed properties
    // ===========================================================================

    protected get latex(): string {
        return this.node.attrs.latex;
    }

    protected set latex(latex: string) {
        this.updateNodeLatex(latex);
    }

    protected get formulaSpan(): HTMLSpanElement {
        return this.$refs.formulaSpan as HTMLSpanElement;
    }

    private get latexInput(): Input {
        return this.$refs.latexInput as Input;
    }

    private get latexInputTextArea(): HTMLTextAreaElement {
        return (this.$refs.latexInput as Vue).$el.querySelector(".ivu-input") as HTMLTextAreaElement;
    }

    private get isEmpty(): boolean {
        return this.latex === "";
    }

    /** Whether or not the editor is editable */
    private get editable(): boolean {
        return this.editor.view.editable;
    }
}
