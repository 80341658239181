import { ISymbol } from "../../components/toolbar/interfaces/ISymbol";
import { ISymbolGroup } from "../../components/toolbar/interfaces/ISymbolGroup";

export enum SymbolType {
    SYMBOL = "symbol",
    COMMAND = "command",
    OTHER = "other",
}

const GREEK_GROUP: ISymbol[] = [
    {
        name: "omicron",
        latex: "\\omicron", // fall back to kstec
        symbol: "o",
        symbolType: SymbolType.OTHER,
    },
    {
        name: "Gamma",
        latex: "\\Gamma",
        symbol: "Γ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Delta",
        latex: "\\Delta",
        symbol: "Δ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Theta",
        latex: "\\Theta",
        symbol: "Θ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Lambda",
        latex: "\\Lambda",
        symbol: "Λ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Xi",
        latex: "\\Xi",
        symbol: "Ξ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Pi",
        latex: "\\Pi",
        symbol: "Π",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Sigma",
        latex: "\\Sigma",
        symbol: "Σ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Phi",
        latex: "\\Phi",
        symbol: "Φ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Psi",
        latex: "\\Psi",
        symbol: "Ψ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Omega",
        latex: "\\Omega",
        symbol: "Ω",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "alpha",
        latex: "\\alpha",
        symbol: "α",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "beta",
        latex: "\\beta",
        symbol: "β",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "gamma",
        latex: "\\gamma",
        symbol: "γ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "delta",
        latex: "\\delta",
        symbol: "δ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "zeta",
        latex: "\\zeta",
        symbol: "ζ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "eta",
        latex: "\\eta",
        symbol: "η",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "theta",
        latex: "\\theta",
        symbol: "θ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "iota",
        latex: "\\iota",
        symbol: "ι",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "kappa",
        latex: "\\kappa",
        symbol: "κ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "lambda",
        latex: "\\lambda",
        symbol: "λ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "mu",
        latex: "\\mu",
        symbol: "μ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "nu",
        latex: "\\nu",
        symbol: "ν",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "xi",
        latex: "\\xi",
        symbol: "ξ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "pi",
        latex: "\\pi",
        symbol: "π",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "rho",
        latex: "\\rho",
        symbol: "ρ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "sigma",
        latex: "\\sigma",
        symbol: "σ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "tau",
        latex: "\\tau",
        symbol: "τ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "upsilon",
        latex: "\\upsilon",
        symbol: "υ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "phi",
        latex: "\\phi",
        symbol: "φ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "chi",
        latex: "\\chi",
        symbol: "χ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "psi",
        latex: "\\psi",
        symbol: "ψ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "omega",
        latex: "\\omega",
        symbol: "ω",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Upsilon",
        latex: "\\Upsilon",
        symbol: "ϒ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "epsilon",
        latex: "\\epsilon",
        symbol: "ϵ",
        symbolType: SymbolType.SYMBOL,
    },
];

const LETTER_LIKE_GROUP: ISymbol[] = [
    {
        name: "imath",
        latex: "\\imath", // fall back katec
        symbol: "ı",
        symbolType: SymbolType.OTHER,
    },
    {
        name: "jmath",
        latex: "\\jmath", // fall back katec
        symbol: "ȷ",
        symbolType: SymbolType.OTHER,
    },
    {
        name: "varphi",
        latex: "\\varphi",
        symbol: "φ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "vartheta",
        latex: "\\vartheta",
        symbol: "ϑ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "varpi",
        latex: "\\varpi",
        symbol: "ϖ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "varrho",
        latex: "\\varrho",
        symbol: "ϱ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "varepsilon",
        latex: "\\varepsilon",
        symbol: "ϵ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "C",
        latex: "\\C",
        symbol: "ℂ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "hbar",
        latex: "\\hbar",
        symbol: "ℏ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "ell",
        latex: "\\ell",
        symbol: "ℓ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "N",
        latex: "\\N",
        symbol: "ℕ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Q",
        latex: "\\Q",
        symbol: "ℚ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "R",
        latex: "\\R",
        symbol: "ℝ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "Z",
        latex: "\\Z",
        symbol: "ℤ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "A-ring",
        latex: "\\AA", // this renders in MQ but not in the question renderer
        symbol: "Å",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "partial",
        latex: "\\partial",
        symbol: "∂",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "nabla",
        latex: "\\nabla",
        symbol: "∇",
        symbolType: SymbolType.SYMBOL,
    },
];

const BASIC_ARITHMETIC_GROUP: ISymbol[] = [
    {
        name: "plus",
        latex: "+",
        symbol: "+",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "minus",
        latex: "-",
        symbol: "-",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "multiplication",
        latex: "\\times",
        symbol: "×",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "division",
        latex: "\\div",
        symbol: "÷",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "degree",
        latex: "\\degree",
        symbol: "°",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "plus or minus",
        latex: "\\pm",
        symbol: "±",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "minus or plus",
        latex: "\\mp",
        symbol: "∓",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "greater than",
        latex: "\\gt",
        symbol: ">",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "less than",
        latex: "\\lt",
        symbol: "<",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "greater than or equal to",
        latex: "\\geq",
        symbol: "≥",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "less than or equal to",
        latex: "\\leq",
        symbol: "≤",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "percentage",
        latex: "\\%",
        symbol: "%",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "infinity",
        latex: "\\infty",
        symbol: "∞",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "fraction",
        latex: "\\frac",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-frac",
    },
    {
        name: "square root",
        latex: "\\sqrt",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-sqrt",
    },
    {
        name: "nth root",
        latex: "\\nthroot",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-sqrtx",
    },
    {
        name: "superscript",
        latex: "^",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-superscript",
    },
    {
        name: "subscript",
        latex: "_",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-subscript",
    },
];

const ARROWS_AND_LOGIC_GROUP: ISymbol[] = [
    {
        name: "right-left arrows",
        latex: "\\rightleftarrows", // fall back katec
        symbol: "⇄",
        symbolType: SymbolType.OTHER,
    },
    {
        name: "right-left harpoons",
        latex: "\\rightleftharpoons",
        symbol: "⇌",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "long right arrow",
        latex: "\\longrightarrow",
        symbol: "⟶",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "long left arrow",
        latex: "\\longleftarrow",
        symbol: "⟵",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "implies",
        latex: "\\Rightarrow",
        symbol: "⟹",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "implied by",
        latex: "\\impliedby",
        symbol: "⟸",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "material equivalence",
        latex: "\\iff",
        symbol: "⟺",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "angle",
        latex: "\\angle",
        symbol: "∠ ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "triangle",
        latex: "\\triangle",
        symbol: "△",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "perp",
        latex: "\\perp",
        symbol: "⊥",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "prime",
        latex: "\\prime",
        symbol: "′",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "dagger",
        latex: "\\dagger",
        symbol: "†",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "cap",
        latex: "\\cap",
        symbol: "∩",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "cup",
        latex: "\\cup",
        symbol: "∪",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "not equal to",
        latex: "\\neq",
        symbol: "≠",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "approx",
        latex: "\\approx",
        symbol: "≈",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "similar",
        latex: "\\sim",
        symbol: "~",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "propto",
        latex: "\\propto",
        symbol: "∝",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "approximately equal",
        latex: "\\approxeq",
        symbol: "≊",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "congruent",
        latex: "\\cong",
        symbol: "≅",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "equiv",
        latex: "\\equiv",
        symbol: "≡",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "exists",
        latex: "\\exists",
        symbol: "∃",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "for all",
        latex: "\\forall",
        symbol: "∀",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "maps to",
        latex: "\\mapsto",
        symbol: "↦",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "empty",
        latex: "\\empty",
        symbol: "∅ ",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "in",
        latex: "\\in",
        symbol: "∈",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "therefore",
        latex: "\\therefore",
        symbol: "∴",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "because",
        latex: "\\because",
        symbol: "∵",
        symbolType: SymbolType.SYMBOL,
    },
];

const ARGUMENTS_GROUP: ISymbol[] = [
    {
        name: "sum",
        latex: "\\sum",
        symbol: "∑",
        symbolType: SymbolType.COMMAND,
    },
    {
        name: "product",
        latex: "\\prod",
        symbol: "∏",
        symbolType: SymbolType.COMMAND,
    },
    {
        name: "limit",
        latex: "\\lim",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-lim",
    },
    {
        name: "derivative",
        latex: "\\frac{d}{dx}",
        symbol: "",
        symbolType: SymbolType.SYMBOL,
        iconName: "latex-ddx",
    },
    {
        name: "integral",
        latex: "\\int",
        symbol: "∫",
        symbolType: SymbolType.COMMAND,
    },
    {
        name: "contour integral",
        latex: "\\oint",
        symbol: "∮",
        symbolType: SymbolType.COMMAND,
    },
    {
        name: "hat",
        latex: "\\hat",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-hat",
    },
    {
        name: "binomial",
        latex: "\\binom",
        symbol: "",
        symbolType: SymbolType.COMMAND,
        iconName: "latex-binom",
    },
    {
        name: "matrix",
        latex: "\\begin{pmatrix}a&b\\\\c&d\\end{pmatrix}",
        symbol: "",
        symbolType: SymbolType.OTHER,
        iconName: "latex-matrix",
    },
    {
        name: "vertical bar",
        latex: "\\vert",
        symbol: "|",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "slash",
        latex: "/",
        symbol: "/",
        symbolType: SymbolType.SYMBOL,
    },
    {
        name: "backslash",
        latex: "\\backslash",
        symbol: "\\",
        symbolType: SymbolType.SYMBOL,
    },
];

export const SymbolDefinitions: ISymbolGroup[] = [
    {
        groupTitle: "Greek letters",
        groupSymbol: "greek-symbols",
        symbolList: GREEK_GROUP,
    },
    {
        groupTitle: "Letter-like symbols",
        groupSymbol: "letter-like",
        symbolList: LETTER_LIKE_GROUP,
    },
    {
        groupTitle: "Basic Arithmetic",
        groupSymbol: "arithmetic",
        symbolList: BASIC_ARITHMETIC_GROUP,
    },
    {
        groupTitle: "Arrows & Logic",
        groupSymbol: "arrows",
        symbolList: ARROWS_AND_LOGIC_GROUP,
    },
    {
        groupTitle: "Arguments",
        groupSymbol: "arguments",
        symbolList: ARGUMENTS_GROUP,
    },
];
