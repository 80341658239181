import { CommandFunction, DispatchFn } from "@educationperfect/tiptap-commands";
import { NodeType } from "prosemirror-model";
import { EditorState, NodeSelection, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";

import { GapNodeCommands } from "../gapNode/GapNodeCommands";
import { AnnotationColorDefinitions } from "./AnnotationColorDefinitions";
import { AnnotationData } from "./AnnotationData";
import { AnnotationNode, AnnotationPluginState } from "./AnnotationNode";

// tslint:disable-next-line:no-namespace
export namespace AnnotationNodeCommands {
    export interface Interface extends GapNodeCommands.Interface {
        updateAnnoationColor: ({ id, color }: { id: string; color: string }) => CommandFunction;
    }

    /**
     * Update the text/color of an annoation node
     *
     * @param type the node type
     * @param gapID the id of the annoation
     * @param text the text of the annoation
     * @param color the color index of the annoation
     */
    export function updateAnnoationColor(type: NodeType, id: string, color: string): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const tr: Transaction = state.tr;
            let nodeSelection: NodeSelection = GapNodeCommands.getGapNodeSelection(
                state.doc,
                id,
                AnnotationNode.NODE_NAME
            );
            if (nodeSelection.empty) {
                return false;
            } else {
                const gapNodePosition: number = nodeSelection.$from.pos;
                const attrs: AnnotationData = { id, text: nodeSelection.node.attrs.text, color };

                tr.setNodeMarkup(gapNodePosition, type, attrs);
                nodeSelection = GapNodeCommands.getGapNodeSelection(tr.doc, id, AnnotationNode.NODE_NAME);
                tr.setSelection(nodeSelection);
                view.dispatch(tr);
                return true;
            }
        };
    }

    /**
     * Create an annotation node from selection
     *
     * @param type the type of node
     */
    export function markGapCommand(type: NodeType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const color: string | null = getColorState(state);
            if (!color) {
                return false;
            }

            return GapNodeCommands.markGapCommand(type, AnnotationNode.NODE_NAME, { color })(state, dispatch, view);
        };
    }

    /**
     * Toggle the state of the annoation
     *
     * @param type the type of node
     */
    export function toggleGapCommand(type: NodeType): CommandFunction {
        return (state: EditorState, dispatch: DispatchFn | undefined, view: EditorView): boolean => {
            const color: string | null = getColorState(state);
            if (!color) {
                return false;
            }

            return GapNodeCommands.toggleGapCommand(type, AnnotationNode.NODE_NAME, { color })(state, dispatch, view);
        };
    }

    /**
     * Get the current color index from plugin state
     *
     * @param state the current doc state
     */
    function getColorState(state: EditorState): string | null {
        const pluginState: AnnotationPluginState = AnnotationNode.GapColorPluginKey.getState(
            state
        ) as AnnotationPluginState;
        if (!pluginState) {
            return null;
        }

        const colorIndex: number = pluginState.gapCount % AnnotationColorDefinitions.length;
        return colorIndex.toString();
    }
}
