import "./gap-floating-bubble.less";
import template from "./gap-floating-bubble.html";

import { EditorMenuBubble } from "@educationperfect/tiptap";
import { Component, Prop } from "vue-property-decorator";

import AddIcon from "../../assets/icons/add-white.svg?inline";
import { AnnotationNode } from "../../extensions/annotations/AnnotationNode";
import { FillInTheGapsNode } from "../../extensions/fitg/FillInTheGapsNode";
import { GapNodeCommands } from "../../extensions/gapNode/GapNodeCommands";
import { HighlightNode } from "../../extensions/highlight/HighlightNode";
import { TipTapComponents } from "../../TipTapComponents";
import { BaseFloatingToolbar } from "../floatingToolbar/BaseFloatingToolbar";

@Component({
    template,
    components: {
        [TipTapComponents.GapFloatingBubble]: GapFloatingBubble,
        EditorMenuBubble,
        AddIcon,
    },
    name: TipTapComponents.GapFloatingBubble,
})
export class GapFloatingBubble extends BaseFloatingToolbar<GapNodeCommands.Interface> {
    @Prop() private readonly title?: string;

    protected get showBubble(): boolean {
        // Check that there is not a disallowed node or an allowed node that is active
        for (const key of Object.keys(this.disallowedNodes)) {
            if (this.isActive[key] && this.isActive[key]()) {
                return false;
            }
        }

        // Check that the node feature is actually turned on
        if (
            !this.isActive[FillInTheGapsNode.NODE_NAME] &&
            !this.isActive[HighlightNode.NODE_NAME] &&
            !this.isActive[AnnotationNode.NODE_NAME]
        ) {
            return false;
        }

        return true;
    }
}
