import "./sound-view.less";
import template from "./sound-view.html";

import { Editor, NodeView } from "@educationperfect/tiptap";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue } from "vue-property-decorator";

import { EditorPluginState } from "../../../components/editor/interfaces/EditorPluginState";
import { EditorPlugin } from "../../../components/editor/plugins/EditorPlugin";
import { InlineComponentFloatingToolbar } from "../../../components/inlineComponentFloatingToolbar/InlineComponentFloatingToolbar";
import { ToolbarIcon } from "../../../components/toolbarIcon/ToolbarIcon";
import { TipTapComponents } from "../../../TipTapComponents";
import { ProsemirrorUtils } from "../../../utils/ProsemirrorUtils";
import { SoundEditorDialog } from "../dialog/SoundEditorDialog";
import { ISoundMetaData } from "../interfaces/ISoundMetaData";

@Component({
    template,
    components: {
        [TipTapComponents.SoundEditorDialog]: SoundEditorDialog,
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        [TipTapComponents.InlineComponentFloatingToolbar]: InlineComponentFloatingToolbar,
    },
})
export class SoundView extends Vue implements NodeView {
    // Variables
    // ======================================

    // TipTap Props

    /** The current node */
    @Prop() public node!: ProsemirrorNode;

    /** Fn to update the attributes of current node */
    @Prop() public updateAttrs!: (attrs: object) => any;

    /** The current editor view */
    @Prop() public view!: EditorView;

    /** Whether or not the node is selected */
    @Prop(Boolean) public selected!: boolean;

    /** The instance of the editor */
    @Prop() public editor!: Editor;

    /** The node options */
    @Prop() public options!: { [key: string]: any };

    /** Keeps track of whether the editor has focus - used for displaying/hiding the inline toolbar */
    private hasFocus: boolean = false;

    // Editing Sound

    /** Whether or not the editor audio dialig is open */
    private isEditAudioDialogOpen: boolean = false;

    /** The audio props for editing */
    private audioMetaData: Partial<ISoundMetaData> | null = null;

    // Lifecycle Events
    // ======================================

    /** Lifecyle Hook: Called when the component is first mounted to the DOM. Get the components metadata */
    private mounted(): void {
        this.editor.on("focus", () => {
            this.hasFocus = true;
        });
        this.editor.on("blur", ({ event }: { event: FocusEvent }) => {
            this.hasFocus = ProsemirrorUtils.handleToolbarClickBlurEvent(event);
        });
    }

    private destroyed(): void {
        // this.editor.off("focus", () => {});
        // this.editor.off("blur", () => {});
    }

    // DOM Events
    // ======================================

    /**
     * DOM Click:
     * Called when the element is double clciked out.
     * Open the editing dialog.
     */
    private onDoubleClick(): void {
        const metaData: Partial<ISoundMetaData> = {
            url: this.url,
            title: this.title,
            autoPlay: false,
            compact: true,
        };

        this.audioMetaData = metaData;
        this.isEditAudioDialogOpen = true;
    }

    /**
     * DOM Emit:
     * Called when the audio editor dialog
     * closes. Update the attrs
     *
     * @param metaData the new audio metadata
     */
    private onAudioDialogChange(metaData: ISoundMetaData): void {
        this.isEditAudioDialogOpen = false;
        if (!metaData) {
            return;
        }

        this.updateAttrs({ ...metaData });
    }

    // Gets
    // ======================================

    /** Get the url of the audio file */
    private get url(): string {
        return this.node.attrs.url;
    }

    /** Get the title of the audio file */
    private get title(): string {
        return this.node.attrs.title;
    }

    /**
     * Get whether or not the current user is an admin
     */
    private get isAdmin(): boolean {
        const pluginState: EditorPluginState = EditorPlugin.EditorPluginKey.getState(this.editor.state);
        if (!pluginState) {
            return false;
        }

        return pluginState.isAdmin() ?? false;
    }

    /**
     * Check that the inline node is selected and the editor it lives in is also focused.
     * This is done because we want to keep the selected state when the user clicks away
     * from a given editor instance (ie: clicking from one text block to another), but
     * we don't want the selected state border to persist.
     */
    private get isSelected(): boolean {
        return this.selected && this.hasFocus;
    }
}
