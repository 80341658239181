import "./ep-table.less";

import { CommandGetter } from "@educationperfect/tiptap";
import { Table } from "@educationperfect/tiptap-extensions";
import { Node as ProsemirrorNode, NodeSpec, Schema } from "prosemirror-model";
import { Plugin, Selection, Transaction } from "prosemirror-state";
import { tableEditing } from "prosemirror-tables";
import { ContentNodeWithPos, findTable } from "prosemirror-utils";

import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { columnResizing } from "./EPTableColumnResizing";
import { EPTableCommands } from "./EPTableCommands";
import { EPTableNodes } from "./EPTableNodes";
import { EPTableView } from "./EPTableView";

export interface IEPTableOptions {
    resizable?: boolean;
}

export class EPTable extends Table {
    constructor(options?: IEPTableOptions) {
        super(options);
    }

    public get plugins(): Plugin[] {
        return [
            ...(this.options.resizable ? [columnResizing({ View: EPTableView })] : []),
            new Plugin({
                appendTransaction: (transactions, oldState, newState) => {
                    // The fix width meta key is used to indicate that a fix width action is required.
                    const fixWidthTransaction: Transaction | undefined = transactions.find((tr) =>
                        tr.getMeta(EPTableCommands.FIX_WIDTH_META_KEY)
                    );

                    if (fixWidthTransaction) {
                        const overrideWidth: number = fixWidthTransaction.getMeta(
                            EPTableCommands.FIX_WIDTH_META_KEY
                        ) as number;
                        return EPTableCommands.generateFixWidthsTransaction(newState, overrideWidth);
                    }
                },
                props: {
                    transformPastedHTML: (html) => {
                        // handles a strange pasting bug from spreadsheets
                        return html.replace(new RegExp('"></td>', "g"), '"><p></p></td>');
                    },
                },
            }),
            tableEditing(),
        ];
    }

    public get schema(): NodeSpec {
        const tableSchema: NodeSpec = EPTableNodes().table;
        tableSchema.attrs = { showBorders: { default: true }, align: { default: TextAlignments.CENTRE } };
        tableSchema.toDOM = (node) => {
            return [
                "table",
                {
                    border: node.attrs.showBorders,
                    align: node.attrs.align,
                },
                0,
            ];
        };
        tableSchema.parseDOM = [
            {
                tag: "table",
                getAttrs: (dom) => {
                    let showBorders: boolean = true;
                    let align: TextAlignments = TextAlignments.LEFT;

                    if (dom instanceof HTMLElement) {
                        if (dom.getAttribute("show-borders") === "false" || dom.getAttribute("border") === "false") {
                            showBorders = false;
                        }

                        if (dom.hasAttribute("align")) {
                            align = dom.getAttribute("align") as TextAlignments;
                        }
                    }

                    return { showBorders, align };
                },
            },
        ];
        return tableSchema;
    }

    public commands({
        type,
        schema,
        attrs,
    }: {
        type: any;
        schema: NodeSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        if (!super.commands) {
            return {};
        }
        return {
            ...super.commands({ schema, type, attrs }),
            createTableWithColumnWidths: ({ rowsCount, colsCount, withHeaderRow, width }) =>
                EPTableCommands.createTableWithColumnWidths(schema, { rowsCount, colsCount, withHeaderRow, width }),
            addColumnBeforeWithColumnWidths: ({ width }) => EPTableCommands.addColumnBeforeWithColumnWidths({ width }),
            addColumnAfterWithColumnWidths: ({ width }) => EPTableCommands.addColumnAfterWithColumnWidths({ width }),
            addRowBeforeWithColumnWidths: ({ width }) => EPTableCommands.addRowBeforeWithColumnWidths({ width }),
            addRowAfterWithColumnWidths: ({ width }) => EPTableCommands.addRowAfterWithColumnWidths({ width }),
            toggleBorderVisibility: () => EPTableCommands.toggleBorderVisibility(),
            toggleCellBorderOverride: () => EPTableCommands.toggleCellBorderOverride(),
            toggleHeaderAndUpdateRow: () => EPTableCommands.toggleHeaderAndUpdateRow(),
            setTableAlignment: ({ alignment }: { alignment: TextAlignments }) =>
                EPTableCommands.setTableAlignment({ alignment }),
        };
    }

    public static getTableAlignment(schema: Schema, selection: Selection): TextAlignments {
        const table: ContentNodeWithPos | undefined = findTable(selection);

        if (table) {
            const tableNode: ProsemirrorNode = table.node;

            return tableNode.attrs.align as TextAlignments;
        }

        return TextAlignments.CENTRE;
    }
}
