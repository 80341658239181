import { CommandGetter, Extension } from "@educationperfect/tiptap";
import { CommandFunction } from "@educationperfect/tiptap-commands";
import { MarkSpec, Node, NodeSpec, Schema } from "prosemirror-model";
import { AllSelection, EditorState, NodeSelection, Selection, TextSelection } from "prosemirror-state";
import { CellSelection } from "prosemirror-tables";
import { isCellSelection, isTableSelected } from "prosemirror-utils";

import { ExtensionNames } from "../../utils/ExtensionNames";
import { TextAlignments } from "./enums/TextAlignments";
import { TextAlignmentCommands } from "./TextAlignCommands";

export class TextAlign extends Extension {
    // Variables
    // =======================================

    // Static
    public static readonly NAME: string = "text_align";

    public static readonly ALLOWED_NODE_TYPES: Set<string> = new Set([
        ExtensionNames.paragraph,
        ExtensionNames.orderedList,
        ExtensionNames.bulletList,
        ExtensionNames.heading,
        ExtensionNames.tableCell,
    ]);

    // Overrides
    // =======================================

    /**
     * Override:
     * Return an object with all commands that can be run
     * from the toolbar
     */
    public commands({
        schema,
        attrs,
    }: {
        schema: Schema | NodeSpec | MarkSpec;
        attrs: { [key: string]: string };
    }): CommandGetter {
        return (alignment: TextAlignments) => TextAlignmentCommands.alignCommand({ alignment });
    }

    /**
     * Override:
     * Return object with keyboard shortcuts and the action they run
     *
     * @param schema the active schema
     */
    public keys({ schema }: { schema: Schema | NodeSpec | MarkSpec }): { [keyCombo: string]: CommandFunction } {
        return {
            "Mod-Alt-l": TextAlignmentCommands.alignCommand({ alignment: TextAlignments.LEFT }),
            "Mod-Alt-e": TextAlignmentCommands.alignCommand({ alignment: TextAlignments.CENTRE }),
            "Mod-Alt-r": TextAlignmentCommands.alignCommand({ alignment: TextAlignments.RIGHT }),
            "Mod-Alt-j": TextAlignmentCommands.alignCommand({ alignment: TextAlignments.JUSTIFIED }),
        };
    }

    // Logic
    // =======================================

    /**
     * Get the current active text alignment for the selection
     *
     * @param state the current editor state
     */
    public static activeAlignment(state: EditorState | null): TextAlignments | undefined {
        if (!state) {
            return undefined;
        }

        let keepLooking = true;
        let activeAlignment: TextAlignments | undefined = TextAlignments.LEFT;
        let commonAlignment: TextAlignments | undefined;

        let nodesInSelection: Node[] = [];
        const selection: Selection = state.selection;

        if (selection instanceof CellSelection) {
            selection.forEachCell((cell) => {
                nodesInSelection.push(cell);
            });
        } else {
            state.doc.nodesBetween(state.selection.from, state.selection.to, (node, pos) => {
                nodesInSelection.push(node);
            });
        }

        for (const node of nodesInSelection) {
            const alignment = node.attrs.align;
            if (TextAlign.ALLOWED_NODE_TYPES.has(node.type.name) && TextAlign.isValidAlignment(alignment)) {
                if (commonAlignment && commonAlignment !== alignment) {
                    activeAlignment = undefined;
                    break;
                } else {
                    commonAlignment = alignment;
                    activeAlignment = alignment;
                }
            }
        }

        return activeAlignment;
    }

    private static isValidAlignment(alignment: string): boolean {
        return (
            alignment === TextAlignments.LEFT ||
            alignment === TextAlignments.RIGHT ||
            alignment === TextAlignments.CENTRE ||
            alignment === TextAlignments.RIGHT ||
            alignment === TextAlignments.JUSTIFIED
        );
    }

    /**
     * Get whether or not the text alignment options
     * should be enabled.
     *
     * @param state the current state of the editor
     * @param alignment the current alignment of the selection
     */
    public static isEnabled(state: EditorState, alignment?: TextAlignments): boolean {
        const selection: Selection = state.selection;

        if (isCellSelection(selection)) {
            // Allow setting the alignment of the entire table
            return isTableSelected(selection);
        } else {
            return (
                selection instanceof TextSelection ||
                selection instanceof AllSelection ||
                selection instanceof NodeSelection
            );
        }
    }

    // Gets
    // =======================================

    /** Get the name of the extension */
    public get name(): string {
        return TextAlign.NAME;
    }
}
