import "./assets/less/app.less";

import { epTipTapPlugin } from "../../src/index";
import { Sortable }  from "../../src/directives/dragging/Sortable";

import * as ViewUI from "@educationperfect/view-design";
import * as locale from "@educationperfect/view-design/dist/locale/en-US";
import Vue from "vue";
import { epUiComponentPlugin } from "@educationperfect/ep-web-ui-components";

// import { ToastPlugin } from "./../../src/plugins/plugins/ToastPlugin";

import { Demo } from "./Demo";

const IViewAny = ViewUI as any;
Vue.use(IViewAny, { locale: locale.default });
Vue.use(epTipTapPlugin);
// Vue.use(ToastPlugin);
Vue.use(epUiComponentPlugin);
Vue.directive("sortable", Sortable);

new Vue({
  render: (h) => h(Demo),
}).$mount("#app");

