import "./gap-node.less";

import { DeviceUtil, Keyboard } from "@educationperfect/ep-web-browser-utils";
import { Editor, NodeView } from "@educationperfect/tiptap";
import { Input, Poptip } from "@educationperfect/view-design";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ProsemirrorUtils } from "../../utils/ProsemirrorUtils";
import RTLMode, { RTLPluginState } from "../RTLMode/RTLMode";
import { GapNode, GapSelectionPluginState } from "./GapNode";
import { GapNodeCommands } from "./GapNodeCommands";

@Component({
    components: { Poptip, Input },
    template: `
        <div
            :gap-id="id"
            :text="text"
            :color="color"
            class="gap-node"
            @click="onClick"
            @mousedown="onMousedown"
            :class="{selected: selected, empty: text == '' }"
        >
            <span ref="textSpan" class="text-span">{{ text }}</span>
            <Input
                class="gap-node-input"
                ref="textInput"
                type="textarea"
                @on-focus="onInputFocus"
                :rows="1"
                :autosize="{ minRows: 1, maxRows: 999 }"
                @input="onChange"
                @on-keydown="onInputKeydown"
                @on-keydown.enter.prevent="onInputEnter"
                @on-keydown.delete="onInputDelete"
                :value="text"
            />
        </div>
    `,
})
export default class GapNodeView extends Vue implements NodeView {
    protected inputWidth: string = "";

    @Prop() public node!: ProsemirrorNode;
    @Prop() public updateAttrs!: (attrs: object) => any;
    @Prop() public view!: EditorView;
    @Prop(Boolean) public selected!: boolean;
    @Prop() public editor!: Editor;
    @Prop() public options!: { [key: string]: any };

    // ===========================================================================
    // Event handlers
    // ===========================================================================

    @Watch("selected")
    private onSelectionChange(isSelected: boolean, oldValue: boolean): void {
        if (!isSelected) {
            // This is to ensure that when use clicks aways from the gap node the inner
            // text input selection is cleared, otherwise when the user clicks on the gap
            // node the old selection will still be displayed.
            //
            // NOTE: On Safari, the browser will always refocus on the text input when the
            // selection is set causing the cursor to be stuck in the gap node. Fortunately,
            // Safari always clears the old selection when the user click on a gap node making
            // this step unecessary.
            if (!DeviceUtil.safari) {
                this.clearTextInputSelection();
            }

            return;
        }

        const pluginState: GapSelectionPluginState = GapNode.GapSelectionPluginKey.getState(
            this.editor.state
        ) as GapSelectionPluginState;

        if (pluginState.fromBackspace) {
            pluginState.fromBackspace = false;
            this.startEditingGapInput(false);
        } else if (pluginState.fromDelete) {
            pluginState.fromDelete = false;
            this.startEditingGapInput(true);
        } else if (pluginState.previousSelection) {
            if (pluginState.previousSelection.$anchor.nodeAfter === this.node) {
                this.startEditingGapInput(true);
            } else if (pluginState.previousSelection.$anchor.nodeBefore === this.node) {
                this.startEditingGapInput(false);
            }
        }
    }

    protected onInputDelete(): void {
        if (this.text === "") {
            this.view.focus();
        }
    }

    protected onMousedown(event: MouseEvent): void {
        if (this.selected) {
            this.textInput.focus();
            event.stopPropagation();
        }
    }

    protected onInputFocus(event: FocusEvent): void {
        // Mark the gap as active when the input is being focused by pressing Tab.
        if (!this.selected) {
            this.editorCommands.selectGap({ gapID: this.id });
            this.textInput.focus();
        }
    }

    protected onClick(event: MouseEvent): void {
        // By default clicking anywhere on the gap node will trigger a gap node selection and the
        // editor will be focused. We need to make sure the input is focused when that is the click
        // target.
        if (document.activeElement !== this.textInput && event.target === this.textInput) {
            this.textInput.focus();
        }
    }

    protected onInputEnter(): void {
        ProsemirrorUtils.exitNode(false, this.view);
    }

    protected onChange(text: string): void {
        this.updateAttrs({ text });
        this.editorCommands.selectGap({ gapID: this.id });
        this.textInput.focus();
    }

    protected onInputKeydown(event: KeyboardEvent): void {
        switch (event.which) {
            case Keyboard.SPACE:
                // Prevent the input of trailing spaces
                if (event.metaKey || event.ctrlKey) {
                    // Trigger toggle shortcut
                    this.editorCommands.toggleGap();
                }

                const match: RegExpMatchArray | null = this.text.match(/(\s+)$/);

                if (match && match.length > 0) {
                    event.preventDefault();
                }
                return;
            case Keyboard.KEY_Z:
            case Keyboard.KEY_Y:
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.editor.commands.redo();
                    } else {
                        this.editor.commands.undo();
                    }
                }
                break;
            case Keyboard.LEFT_ARROW:
            case Keyboard.RIGHT_ARROW:
            case Keyboard.UP_ARROW:
            case Keyboard.DOWN_ARROW:
                // Deselect the gap when user uses arrow keys to navigate away
                let direction: "top" | "right" | "bottom" | "left" = "left";

                switch (event.which) {
                    case Keyboard.LEFT_ARROW:
                        direction = "left";
                        break;
                    case Keyboard.RIGHT_ARROW:
                        direction = "right";
                        break;
                    case Keyboard.UP_ARROW:
                        direction = "top";
                        break;
                    case Keyboard.DOWN_ARROW:
                        direction = "bottom";
                        break;
                }

                this.handleArrowKeyNavigation(direction);
                break;
            case Keyboard.KEY_C:
            case Keyboard.KEY_X:
                if (event.metaKey || event.ctrlKey) {
                    this.handleCopyAndCut(event);
                }
                break;
            case Keyboard.KEY_S:
                if (event.metaKey || event.ctrlKey) {
                    this.handleToggleGap();
                }
                break;
        }
    }

    // =========================================================================
    // Component lifecycle
    // =========================================================================
    /**
     * Set up the textinput dir attr when the component is first created.
     */
    protected mounted(): void {
        this.textInput.setAttribute("dir", "auto");
    }

    // ===========================================================================
    // Helper functions
    // ===========================================================================

    private clearTextInputSelection(): void {
        const textLength: number = this.textInput.value.length;
        this.textInput.setSelectionRange(textLength, textLength);
    }

    private handleToggleGap(): void {
        this.editorCommands.toggleGap();
    }

    protected handleCopyAndCut(event: KeyboardEvent): void {
        this.view.focus();
    }

    private handleExternalDeletionAction(fromLeft: boolean): void {
        const trimmedText: string = fromLeft ? this.text.slice(1) : this.text.slice(0, -1);
        this.text = trimmedText;
        this.startEditingGapInput(fromLeft);
    }

    private startEditingGapInput(fromLeft: boolean): void {
        const pluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(this.editor.state) as RTLPluginState;
        // If the gap contains Arabic text, we need to flip whether we enter at the start or end of the gap.
        if (pluginState.RTLMode) {
            fromLeft = !fromLeft;
        }
        // We need to call this asynchronously because sometime the gap node is updated and rerendered right before
        // this is called.
        setTimeout(() => {
            if (fromLeft) {
                this.textInput.setSelectionRange(0, 0);
            } else {
                this.textInput.setSelectionRange(this.textInput.value.length, this.textInput.value.length);
            }
            this.textInput.focus();
        });
    }

    protected handleArrowKeyNavigation(direction: "top" | "right" | "bottom" | "left"): void {
        const textValue: string = this.textInput.value;
        const lastPosition: number = textValue.length;
        const selectionStart = this.textInput.selectionStart;
        const selectionEnd = this.textInput.selectionEnd;

        const singleLine: boolean = this.textInput.offsetHeight <= 24;
        const cursorAtStart: boolean = selectionStart === 0 && selectionEnd === 0;
        const cursorAtEnd: boolean = selectionStart === lastPosition && selectionEnd === lastPosition;

        const pluginState: RTLPluginState = RTLMode.RTLPluginKey.getState(this.editor.state) as RTLPluginState;

        // Don't interfere with the keyboard behaviour unless we are at the
        // boundary.
        if (direction == "bottom" && cursorAtEnd) {
            ProsemirrorUtils.exitNode(false, this.view);
        } else if (direction === "top" && cursorAtStart) {
            this.view.focus();
        } else if (pluginState.RTLMode) {
            if ((cursorAtStart && direction === "right") || (cursorAtEnd && direction === "left")) {
                this.view.focus();
            }
        } else {
            if ((cursorAtStart && direction === "left") || (cursorAtEnd && direction === "right")) {
                this.view.focus();
            }
        }
    }

    protected get editorCommands(): GapNodeCommands.Interface {
        return this.editor.commands as GapNodeCommands.Interface;
    }

    // ===========================================================================
    // Computed properties
    // ===========================================================================

    private get textSpan(): HTMLSpanElement {
        return this.$refs.textSpan as HTMLSpanElement;
    }

    private get textInput(): HTMLTextAreaElement {
        return (this.$refs.textInput as Vue).$el.querySelector(".ivu-input") as HTMLTextAreaElement;
    }

    protected get id(): string {
        return this.node.attrs.id;
    }

    protected get text(): string {
        return this.node.attrs.text;
    }

    protected get color(): string | null {
        return this.node.attrs.color != null ? this.node.attrs.color : null;
    }

    protected set text(text: string) {
        this.updateAttrs({ text });
        this.editorCommands.selectGap({ gapID: this.id });
        this.textInput.focus();
    }
}
