import "./toolbar-icon.less";
import template from "./toolbar-icon.html";

import { Component, Prop, Vue } from "vue-property-decorator";

import { TipTapComponents } from "../../TipTapComponents";

// Font preview: static/toolbarCustomIcon/toolbar-custom-icon-preview.html

@Component({
    template,
    name: TipTapComponents.ToolbarIcon,
})
export class ToolbarIcon extends Vue {
    // Variables
    // =============================================

    /** [Prop, Bound] Type of icon to create, "add-subject"|"assessment"|"cheer" etc. */
    @Prop() private readonly icon: string | undefined;

    /** [Prop] Font size to use, if specified. */
    @Prop() private readonly size: number | undefined;

    // Gets
    // =============================================
}
