import { Extension } from "@educationperfect/tiptap";
import { Node } from "prosemirror-model";
import { EditorState, Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

interface PlaceholderOptions {
    emptyNodeClass?: string;
    emptyNodeText?: string | ((node: Node) => string);
    showOnlyWhenEditable?: boolean;
    showOnlyCurrent?: boolean;
}

export default class Placeholder extends Extension<PlaceholderOptions> {
    public get name() {
        return "placeholder";
    }

    public get defaultOptions() {
        return {
            emptyNodeClass: "is-empty",
            emptyNodeText: "Write something …",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
        };
    }

    public get update() {
        return (view) => {
            view.updateState(view.state);
        };
    }

    public get plugins() {
        return [
            new Plugin({
                props: {
                    decorations: (state: EditorState) => {
                        const { doc, plugins, selection } = state;
                        const editablePlugin: Plugin | undefined = plugins.find((plugin) =>
                            plugin.key.startsWith("editable$")
                        );

                        if (!editablePlugin || !editablePlugin.props.editable || !this.options) {
                            return;
                        }

                        const editable = editablePlugin.props.editable(state);
                        const active = editable || !this.options.showOnlyWhenEditable;
                        const { anchor } = selection;
                        const decorations: Decoration[] = [];

                        if (!active) {
                            return;
                        }

                        const isDocEmpty =
                            state.doc.textContent === "" &&
                            state.doc.childCount === 1 &&
                            state.doc.firstChild!.childCount === 0;

                        if (!isDocEmpty) {
                            return;
                        }

                        doc.descendants((node, pos) => {
                            const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize;
                            const isEmpty = node.content.size === 0;

                            if (!this.options) {
                                return;
                            }

                            if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                                    class: this.options.emptyNodeClass,
                                    "data-empty-text":
                                        typeof this.options.emptyNodeText === "function"
                                            ? this.options.emptyNodeText(node)
                                            : this.options.emptyNodeText,
                                });

                                decorations.push(decoration);
                            }

                            return;
                        });

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    }
}
