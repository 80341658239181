export enum EditorEvents {
    // Gap events
    GapSelectionChanged = "gap-selection-changed",
    GapAddedOrRemoved = "gap-added-or-removed",

    // Ime events
    ImePositionUpdated = "update-ime-position",
    CaretPositionUpdated = "caret-position-updated",
    KeyPress = "keypress",
    KeyDown = "keydown",
    Click = "click",
    Paste = "paste",
    Composition = "composition",
    InputChanged = "input-changed",
    MouseOver = "mouse-over",
    MouseOut = "mouse-out",

    // Image events
    ImageOpened = "image-opened",
    ImageClosed = "image-closed",
    ImageChanged = "image-changed",

    // General events
    Focus = "on-focus",
    Blur = "on-blur",

    // Other events
    TextEmit = "text-emit",
    ColorPickerCustomColorsChanged = "color-picker-custom-colors-changed",
    TextDirectionChanged = "text-direction-changed",
    FormulaConverted = "formula-converted",

    // Component metadata
    ComponentMetaDataChange = "on-component-metadata-change",
}

export interface EditorEvent {
    type: EditorEvents;
}

export interface EditorEvent {
    type: EditorEvents;
    focusEvent?: FocusEvent;
    fromFormula?: boolean;
}