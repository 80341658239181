import { GapNode } from "../gapNode/GapNode";
import { GapData } from "../../models/GapData";

export class FillInTheGapsNode extends GapNode {
    public static readonly NODE_NAME: string = "fitg";
    public get nodeName(): string {
        return FillInTheGapsNode.NODE_NAME;
    }

    /** [Override] Get the gaps from the document */
    public getGaps(htmlContent: string): GapData[] {
        return FillInTheGapsNode.getGapsFromHtml(htmlContent);
    }

    /** Get the gaps from the document */
    public static getGapsFromHtml(documentHtml: string): GapData[] {
        const parser = new DOMParser();
        const html: Document = parser.parseFromString(documentHtml, "text/html");
        const gapNodes: Element[] = Array.from(html.getElementsByTagName(FillInTheGapsNode.NODE_NAME));

        const gaps: GapData[] = [];

        for (const gap of gapNodes) {
            const id = gap.getAttribute("gap-id");
            const text = gap.getAttribute("text");

            if (id != null && text != null) {
                let gapData: GapData = { id, text };
                gaps.push(gapData);
            } else {
                throw Error("Gap missing id or text");
            }
        }

        return gaps;
    }
}
