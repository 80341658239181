import { ElementUtil } from "@educationperfect/ep-web-browser-utils";
import { Dictionary } from "@educationperfect/ep-web-utils";

import { IRenderingParameters } from "../../render/IRenderingParameters";
import { BaseEmbeddedComponent } from "./BaseEmbeddedComponent";

export class InlineBuildableComponent extends BaseEmbeddedComponent {
    constructor(type: string, renderingParameters: IRenderingParameters, parameters: Dictionary<string>) {
        super(type, renderingParameters, parameters);
    }

    // Overrides
    // =============================================

    /**
     * Override:
     * Create visual element for display
     *
     * @param parameters the dictionary of options
     */
    public createVisualElement(parameters: Dictionary<string>): HTMLElement {
        const dropDownContainer: HTMLElement = ElementUtil.create(this.nodeName);

        if ("id" in parameters) {
            dropDownContainer.setAttribute("id", parameters["id"].trim());
        }

        return dropDownContainer;
    }

    // Gets
    // =============================================

    /**
     * The name of the node to create.
     * NOOP.
     */
    public get nodeName(): string {
        return "";
    }
}
