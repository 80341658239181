import { FormulaFormats } from "@educationperfect/ep-web-math";
import { GuidUtil } from "@educationperfect/ep-web-utils";

import { InlineFormulaGraphic } from "../render/InlineFormulaGraphic";
import { IDOMComputedStyle } from "./IDOMComputedStyle";
import { IDOMParagraphInline } from "./IDOMParagraphInline";

export class DOMParagraphInlineFormula implements IDOMParagraphInline {
    public id!: string;
    private cachedRender!: HTMLElement;

    constructor(private formula: string, private style: IDOMComputedStyle, private format: FormulaFormats) {}

    public get formulaString(): string {
        return this.formula;
    }

    private getElementInternal(): HTMLElement {
        if (this.cachedRender === undefined) {
            var inlineFormula = new InlineFormulaGraphic(this.formula, null, this.style, this.format);
            this.cachedRender = inlineFormula.elements[0];

            if (!this.id) {
                this.id = GuidUtil.create();
            }
            this.cachedRender.id = this.id;
            this.cachedRender.classList.add("inline-formula");
        }

        return this.cachedRender || null;
    }

    public render(style: IDOMComputedStyle, insideTable: boolean): HTMLElement {
        const renderedElement: HTMLElement = this.getElementInternal();
        return renderedElement;
    }
}
