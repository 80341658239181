import "./drop-down-editor.less";
import template from "./drop-down-editor.html";

import { SortableSortEvent } from "@educationperfect/draggable";
import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { EPServices } from "@educationperfect/ep-web-services";
import { DropDownComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/DropDownComponentMetaData";
import { DropDownOption } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/DropDownOption";
import {
    EdsIconAdd,
    EdsPrimaryButton,
    EdsSecondaryButton,
    EdsTertiaryButton,
} from "@educationperfect/ep-web-ui-components";
import { GuidUtil, SortingUtil, StringUtil } from "@educationperfect/ep-web-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ToastStates } from "../../../../components/toast/ToastStates";
import { TipTapComponents } from "../../../../TipTapComponents";
import { DropDownOptionModel } from "./models/DropDownOptionModel";

/** The user fact key to for dropdown help text */
const DROPDOWN_DRAGGING_HELP_TEXT_KEY: string = "tiptap/editors/drop-down/show-help-text";

/** The user fact key for the dropdown defaulot answer help text */
const DROPDOWN_DEFAULT_SELECTION_KEY: string = "tiptap/editors/drop-down/show-default-answer-help-text";

/** The minumum width of the option */
const MIN_DROPDOWN_WIDTH: number = 50;

/** The maximum width of the option if prop not set */
const MAX_DROPDOWN_WIDTH: number = 906;

/** The default selection for a "None" option. Don't change, from flash, used in renderer */
const DEFAULT_NONE_OBJECT: DropDownOption = {
    Description: " < None > ",
    Correct: "false",
    Validity: "true",
    SortOrder: 0,
};

type ComponentMetaData = DropDownComponentMetaData;

@Component({
    template,
    components: {
        EdsPrimaryButton,
        EdsSecondaryButton,
        EdsTertiaryButton,
        EdsIconAdd,
    },
    name: TipTapComponents.DropDownEditor,
})
export class DropDownEditor extends Vue {
    // Variables
    // ====================================

    // Static

    public static createDropDownComponentMetaData(): DropDownComponentMetaData {
        return {
            ComponentID: GuidUtil.create(),
            Width: 0,
            Height: 0,
            Score: 0,
            Marked: 0,
            ComponentTypeCode: "DROPDOWN_COMPONENT", // definition is is renderer that can't be accessed in tiptap
            Options: [],
            DefaultSelection: { ...DEFAULT_NONE_OBJECT },
        };
    }

    // Props
    /** [Prop] The component meta data for the question, prefilled with required data if new question */
    @Prop({ required: false, type: Object, default: () => DropDownEditor.createDropDownComponentMetaData() })
    private readonly componentMetaData?: ComponentMetaData;

    /** [Prop, Bound] If specified, whether or not the editor is open */
    @Prop({ required: true, type: Boolean, default: false }) public readonly visible!: boolean;

    /** [Prop, Bound] the max width for the dropdown  */
    @Prop({ required: false, type: Number, default: 906 }) public readonly maxPxWidth?: number;

    // Data

    /** The default dropdown option */
    private defaultOption: DropDownOptionModel | null = null;

    /** The list of drop down options */
    private options: DropDownOptionModel[] = [];

    // Fields

    /** The width that individual cells will be, null = auto */
    private optionWidth: number | null = null;

    // State

    /** Whether or not the options appear randomised */
    private randomiseRaw: string = "true";

    /** Whether or not the settings dropdown is open */
    private settingsOpened: boolean = false;

    /** Whether or not to show the help text */
    private showDraggingHelpText: boolean = false;

    /** Whether or not to show the help text */
    private showDefaultSelectionHelpText: boolean = false;

    // Lifecycle Hooks
    // ====================================

    /**
     * Lifecycle Hook:
     * Called when the component is first mounted to the DOM.
     * Initialise the component metadata.
     */
    private async mounted(): Promise<void> {
        if (EPServices.UserFactsFacade) {
            EPServices.UserFactsFacade.getFact(DROPDOWN_DRAGGING_HELP_TEXT_KEY, true)
                .then((val: boolean) => (this.showDraggingHelpText = val))
                .catch((err) => true);

            EPServices.UserFactsFacade.getFact(DROPDOWN_DEFAULT_SELECTION_KEY, true)
                .then((val: boolean) => (this.showDefaultSelectionHelpText = val))
                .catch((err) => true);
        }
    }

    // Logic
    // ====================================

    /**
     * Setup fields with new component metadata.
     */
    private async initializeComponentMetaData(): Promise<void> {
        if (!this.componentMetaData) {
            return;
        }

        // Having to use description as a key is gross, but that is just how the component
        // works. Another weird thing is the default selection is a duplicate of one of the options
        // instead of a default property...

        this.defaultOption = DropDownOptionModel.fromDropDownObject(this.componentMetaData.DefaultSelection);

        const options: DropDownOption[] = this.componentMetaData.Options;
        if (options.length == 0) {
            this.options = [
                new DropDownOptionModel(GuidUtil.create(), "", true, 0),
                new DropDownOptionModel(GuidUtil.create(), "", false, 0),
            ];
        } else {
            for (const option of options) {
                // Add default option instead for reference updates
                if (option.Description == this.defaultOption.description) {
                    this.options.push(this.defaultOption);
                } else {
                    const newOption: DropDownOptionModel = DropDownOptionModel.fromDropDownObject(option);
                    this.options.push(newOption);
                }
            }

            this.options.sort(SortingUtil.compareNumberProperties((item: DropDownOptionModel) => item.sortOrder));
        }

        // Only set option width if not null fo 0, this shows auto placeholder
        if (this.componentMetaData.Width) {
            this.optionWidth = this.componentMetaData.Width;
        }

        this.randomiseRaw = options.every((option: DropDownOption) => option.SortOrder == 0).toString();
        document.addEventListener("keydown", this.onKeydownEvent);
    }

    /**
     * Create the save component metadata if valid.
     */
    private createDropDownMetadata(): ComponentMetaData {
        const options: DropDownOption[] = this.options.map((option: DropDownOptionModel) => option.getDropDownOption());

        // @ts-ignore
        return {
            ...this.componentMetaData,
            Options: options,
            DefaultSelection: this.defaultOption?.getDropDownOption() ?? { ...DEFAULT_NONE_OBJECT },
            Width: this.optionWidth ? this.optionWidth : 0,
        };
    }

    /**
     * Validate the component options
     * - At least two options, one correct
     * - No option can be whitespace only
     * - Options must be unique
     */
    private validateComponent(): void {
        if (this.options.length < 2 || this.correctOptions.length == 0) {
            throw new Error("You must create at least two options, one of which must be correct.");
        }

        const optionDescriptions: string[] = [];
        let numberOfEmptyOptions: number = 0;

        for (const option of this.options) {
            optionDescriptions.push(option.description);
            if (StringUtil.isNullOrWhitespace(option.description)) {
                numberOfEmptyOptions++;
            }
        }

        if (numberOfEmptyOptions > 0) {
            throw new Error(`${numberOfEmptyOptions == 1 ? "Option" : "Options"} cannot be empty`);
        }

        // Create unique list of options
        const uniqueOptionsCount: number = Array.from(new Set(optionDescriptions)).length;
        if (uniqueOptionsCount != optionDescriptions.length) {
            throw new Error("Options must be unique");
        }

        if (this.optionWidth) {
            const maxWidth: number = this.maxPxWidth ?? MAX_DROPDOWN_WIDTH;
            if (this.optionWidth < MIN_DROPDOWN_WIDTH || this.optionWidth > maxWidth) {
                throw new Error(`Width of options must be between ${MIN_DROPDOWN_WIDTH}px and ${maxWidth}px`);
            }
        }
    }

    /**
     * Reset fields back to their defaults
     */
    private reset(): void {
        this.options = [];
        this.defaultOption = null;
        this.optionWidth = null;
        this.randomiseRaw = "false";
        this.settingsOpened = false;
        this.showDraggingHelpText = false;
        document.removeEventListener("keydown", this.onKeydownEvent);
    }

    /** Closes the editor window, optionally saving the changed data */
    private close(): void {
        this.$emit("on-close");
    }

    /**
     * Loop through all options and ensure that their
     * sort order is correct based of the context.
     */
    private updateSortIndexes(): void {
        if (!this.options) {
            return;
        }

        this.options.forEach((option: DropDownOptionModel, index: number) => {
            option.sortOrder = this.randomise ? 0 : index;
        });
    }

    /**
     * Add a new option to the list of options.
     */
    private addOption(): void {
        const newOption: DropDownOptionModel = new DropDownOptionModel(
            GuidUtil.create(),
            "",
            false,
            this.randomise ? 0 : this.options.length
        );

        this.options.push(newOption);

        Vue.nextTick(() => {
            const element: HTMLInputElement | null = this.$el.querySelector(`#input-${newOption.id}`);
            element?.focus();
        });
    }

    /**
     * Close help text if not already closed. Set user
     * fact to remember this setting.
     */
    private closeHelpText(): void {
        if (this.showDraggingHelpText) {
            this.showDraggingHelpText = false;
            EPServices.UserFactsFacade.setFact(DROPDOWN_DRAGGING_HELP_TEXT_KEY, false);
        }
    }

    /**
     * Close default selection helpt text
     */
    private closeDefaultSelectionHelpText(): void {
        if (this.showDefaultSelectionHelpText) {
            this.showDefaultSelectionHelpText = false;
            EPServices.UserFactsFacade.setFact(DROPDOWN_DEFAULT_SELECTION_KEY, false);
        }
    }

    // DOM Events
    // ====================================

    /**
     * DOM Click:
     * Called when the save button is clicked. Save component metadata
     * and emit to parent
     */
    private onSaveClick(): void {
        try {
            this.validateComponent();
            const componentMetaData: ComponentMetaData | null = this.createDropDownMetadata();
            this.$emit("on-save", componentMetaData);
            this.close();
        } catch (err) {
            if (err instanceof Error) {
                this.$toast.show({ state: ToastStates.FAILURE, actionText: err.message, autoHide: true });
                setTimeout(() => this.$toast.hide(), 3000);
            }
        }
    }

    /**
     * DOM Click:
     * Called when the close button is clicked. Emit
     * close event to parent.
     */
    private onCloseClick(): void {
        this.close();
    }

    /**
     * DOM Click:
     * Called when the default option radio button is clicked.
     * Update the correct option and toggle help text if visible.
     *
     * @param option the clicked on option
     */
    private onDefaultOptionClick(option: DropDownOptionModel): void {
        if (!option) {
            return;
        }

        if (this.showDefaultSelectionHelpText) {
            this.closeDefaultSelectionHelpText();
        }

        this.defaultOption = option;
    }

    /**
     * DOM Click: Called when the "Delete" button is clicked
     * on an option. Remove the item from the list of options
     * and update sort order if not randomised.
     */
    private onDeleteOptionClick(index: number): void {
        if (this.options[index].id === this.defaultOption?.id) {
            this.defaultOption = DropDownOptionModel.fromDropDownObject({ ...DEFAULT_NONE_OBJECT });
        }

        this.options.splice(index, 1);
        this.updateSortIndexes();
    }

    /**
     * DOM Click:
     * Called when the "Add" button is clicked.
     * Pipe through to the add option event
     */
    private onAddOptionClick(): void {
        this.addOption();
    }

    /** DOM Click: Called when the close icon is pressed in help text. Pipe event to close help text */
    private onCloseHelpTextClick(): void {
        this.closeHelpText();
    }

    /** DOM Click: Called when the default "None" option is clicked. Set default option to null */
    private onDefaultNoneClick(): void {
        this.defaultOption = DropDownOptionModel.fromDropDownObject(DEFAULT_NONE_OBJECT);
    }

    /**
     * DOM Click:
     * Callled when the default selection help text "x" is clicked.
     * Hide the help text and store user fact
     */
    private onCloseDefaultSelectionHelpTextClick(): void {
        this.closeDefaultSelectionHelpText();
    }

    /**
     * DOM Callback:
     * Called when the drag event stops from the
     * dragable list. Swap elements in list and update their sort
     * indexes.
     *
     * [Note: Technically vue won't react without using Vue.set
     * but in this case the items have been re-ordered by drag so it's okay ]
     *
     * @param event the sortable event containing new indexes
     */
    private onSorted(event: SortableSortEvent): void {
        if (!event) {
            return;
        }

        const temp = this.options[event.oldIndex];
        this.options[event.oldIndex] = this.options[event.newIndex];
        this.options[event.newIndex] = temp;

        this.updateSortIndexes();
    }

    /**
     * DOM Emit:
     * Called when the randomise toggle changes.
     * Update sort order for each item to be either index (ordered)
     * or random (0)
     */
    private onRandomiseChange(): void {
        this.updateSortIndexes();
    }

    /**
     * DOM Emit:
     * Called when the dialog changes visibility.
     * Reset the fields if closing or initialise new component
     * metadata.
     *
     * @param visible whether or not the dialog is visible
     */
    private onVisibleChange(visible: boolean): void {
        if (!visible) {
            this.reset();
            return;
        }

        this.initializeComponentMetaData();
    }

    // Listeners
    // ====================================

    /**
     * DOM Keydown
     * Called when any key is pressed.
     * Check if shift enter and add option if so.
     *
     * @param event the key down event
     */
    private onKeydownEvent(event: KeyboardEvent): void {
        if (event.shiftKey && event.keyCode === Keyboard.ENTER) {
            this.addOption();
        }
    }

    // Gets
    // ====================================

    /** Get boolean representation of randomise */
    private get randomise(): boolean {
        return this.randomiseRaw === "true";
    }

    /** Get a list of option filtered to only onces marked as correct */
    private get correctOptions(): DropDownOptionModel[] {
        return this.options.filter((option: DropDownOptionModel) => option.correct);
    }

    /** Get the name (key) of the object that is represented as none */
    private get defaultNoneKey(): string {
        return DEFAULT_NONE_OBJECT.Description;
    }

    /** Get the configuration for swappable directive */
    private get sortableOptions(): object {
        return {
            options: {
                draggable: "li",
                handle: ".list-handle",
            },
            onSorted: this.onSorted.bind(this),
            onStart: this.closeHelpText.bind(this),
        };
    }
}
