// eslint-disable-next-line max-classes-per-file
import { Dictionary } from "@educationperfect/ep-web-utils";

export class InlineTextElement {
    public static HIGHLIGHT_MAP: Dictionary<string> = {
        "##1#": "#FFFF00",
        "##2#": "#FF0000",
        "##3#": "#00FF00",
        "##4#": "#0000FF",
        "##5#": "#FFFFFF",
        "##6#": "#010101",
        "##7#": "#B81AE0",
        "##8#": "#FB6611",
        "##9#": "#00B6EE",
    };

    public static COLOR_MAP: Dictionary<string> = {
        "@@1@": "#EEEE00",
        "@@2@": "#FF0000",
        "@@3@": "#009900",
        "@@4@": "#0000FF",
        "@@5@": "#FFFFFF",
        "@@6@": "#000000",
        "@@7@": "#B81AE0",
        "@@8@": "#FB6611",
        "@@9@": "#00B6EE",
    };

    private renderedElement: HTMLSpanElement[] | HTMLHeadingElement;

    private colourMatchRegex: RegExp = new RegExp("^@@([0-9a-fA-F]+)@");

    private highlightMatchRegex: RegExp = new RegExp("^##([0-9a-fA-F]+)#");

    public hasHeader: boolean;

    constructor(text: string) {
        const headerPattern = /^(#{1,6}) (.*)/;
        const headerMatch = text && text.match(headerPattern);
        let header!: HTMLHeadingElement;
        if (headerMatch) {
            const [_, headerLevel, rest] = headerMatch;
            const level = headerLevel.length;
            header = <HTMLHeadingElement>document.createElement(`h${level}`);
            text = rest;
        }

        const formattedParts: string[] = InlineTextElement.splitFormattedText(text);
        if (formattedParts != null) {
            this.renderedElement = this.parseFormattedText(formattedParts);
        } else {
            const span: HTMLSpanElement = new HTMLSpanElement();
            span.textContent = text;
            this.renderedElement = [span];
        }

        if (header) {
            this.renderedElement.forEach((span) => header.appendChild(span));
            this.renderedElement = header;
            this.hasHeader = true;
        } else {
            this.hasHeader = false;
        }
    }

    private static splitFormattedText(text: string): string[] {
        return text.split(
            /(\\?(?:__|\*\*|~~|\/\/|\u208d|\u208e|\u207d|\u207e|(?:##(?:(?:[0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9])[#])?)|(?:@@(?:(?:[0-9a-fA-F]{3}|[0-9a-fA-F]{6}|[0-9])[@])?)))/
        );
    }

    private parseFormattedText(parts: string[]): HTMLSpanElement[] {
        const spans: HTMLSpanElement[] = [];
        let atFormattingMark: boolean = false;
        const elementStack = new InlineElementStack();
        const format: IFontFormat = {
            bold: false,
            italic: false,
            strikethrough: false,
            underline: false,
            highlight: null,
            color: null,
        };

        for (let part of parts) {
            if (atFormattingMark) {
                if (part.charAt(0) == "\\") {
                    // escaped formatting mark
                    part = part.slice(1);
                    const escapeSpan: HTMLSpanElement = this.createFormattedInline(elementStack.peek(), part, format);
                    spans.push(escapeSpan);
                }

                // change formatting
                else if (part == "**") {
                    format.bold = !format.bold;
                } else if (part == "__") {
                    format.underline = !format.underline;
                } else if (part == "//") {
                    format.italic = !format.italic;
                } else if (part == "~~") {
                    format.strikethrough = !format.strikethrough;
                } else if (part == "##") {
                    format.highlight = format.highlight != null ? null : "#FF0";
                } else if (part == "@@") {
                    format.color = format.color != null ? null : "#000";
                } else if (part == "₍") {
                    // open sub
                    elementStack.push("sub");
                } else if (part == "₎") {
                    // close sub
                    elementStack.popIf("sub");
                } else if (part == "⁽") {
                    // open super
                    elementStack.push("sup");
                } else if (part == "⁾") {
                    // close super
                    elementStack.popIf("sup");
                }

                if (part in InlineTextElement.HIGHLIGHT_MAP) {
                    format.highlight = InlineTextElement.HIGHLIGHT_MAP[part];
                } else if (part in InlineTextElement.COLOR_MAP) {
                    format.color = InlineTextElement.COLOR_MAP[part];
                } else if (part.startsWith("@@") && part.endsWith("@")) {
                    const colourMatches: RegExpMatchArray | null = part.match(this.colourMatchRegex);
                    if (colourMatches && colourMatches.length == 2) {
                        const colourMatch: string = colourMatches[1];
                        if (colourMatch.length == 3 || colourMatch.length == 6) {
                            format.color = `#${colourMatch}`;
                        }
                    }
                } else if (part.startsWith("##") && part.endsWith("#")) {
                    const colourMatches: RegExpMatchArray | null = part.match(this.highlightMatchRegex);
                    if (colourMatches && colourMatches.length == 2) {
                        const colourMatch: string = colourMatches[1];
                        if (colourMatch.length == 3 || colourMatch.length == 6) {
                            format.highlight = `#${colourMatch}`;
                        }
                    }
                }
            } else {
                const span: HTMLSpanElement = this.createFormattedInline(elementStack.peek(), part, format);
                spans.push(span);
            }

            atFormattingMark = !atFormattingMark; // flip the bit
        }

        return spans;
    }

    private createFormattedInline(elementType: InlineElementTypes, text: string, format: IFontFormat): HTMLSpanElement {
        const span: HTMLSpanElement = document.createElement(elementType);
        span.textContent = text;

        if (format.bold) {
            span.style.fontWeight = "bold";
        }
        if (format.italic) {
            span.style.fontStyle = "italic";
        }
        if (format.underline) {
            span.style.textDecoration = "underline";
        }
        if (format.strikethrough) {
            span.style.textDecoration += "line-through";
        }
        if (format.highlight) {
            span.style.backgroundColor = format.highlight;
        }
        if (format.color) {
            span.style.color = format.color;
        }

        return span;
    }

    public get elements(): HTMLElement[] {
        return this.renderedElement as HTMLElement[];
    }

    public get headingElement(): HTMLHeadingElement {
        return this.renderedElement as HTMLHeadingElement;
    }
}

class InlineElementStack {
    public elements: InlineElementTypes[] = [];

    public push(type: InlineElementTypes) {
        this.elements.push(type);
    }

    public popIf(type: InlineElementTypes) {
        if (this.peek() === type) {
            this.elements.pop();
        }
    }

    public peek(): InlineElementTypes {
        return this.elements[this.elements.length - 1] || "span";
    }
}

interface IFontFormat {
    bold: boolean;
    italic: boolean;
    underline: boolean;
    strikethrough: boolean;
    highlight: string | null;
    color: string | null;
}

type InlineElementTypes = "span" | "sub" | "sup";
