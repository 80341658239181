import "./text-box-editor.less";
import template from "./text-box-editor.html";

import { SubjectUtil } from "@educationperfect/ep-web-browser-utils";
import { TextBoxComponentMetaData } from "@educationperfect/ep-web-services/lib/services/Questions/BusinessObjects/Questions/TextBoxComponentMetaData";
import {
    EdsIconAdd,
    EdsPrimaryButton,
    EdsSecondaryButton,
    EdsTertiaryButton,
} from "@educationperfect/ep-web-ui-components";
import { ArrayHelper, GuidUtil, StringUtil } from "@educationperfect/ep-web-utils";
import { Component, Prop, Vue } from "vue-property-decorator";

import { ToastStates } from "../../../../../components/toast/ToastStates";
import { ToolbarIcon } from "../../../../../components/toolbarIcon/ToolbarIcon";
import { TipTapComponents } from "../../../../../TipTapComponents";
import { InputBoxScriptType } from "../../common/enums/InputBoxScriptType";
import { InputBoxTextAlign } from "../../common/enums/InputBoxTextAlign";

type ComponentMetaData = TextBoxComponentMetaData;

/** The maximum width of the text box */
const MAX_TEXT_BOX_WIDTH: number = 600;

/** The minimum width of the text box */
const MIN_TEXTBOX_BOX_WIDTH: number = 10;

/** The number of miliseconds an error toast stays open for */
const EDITOR_ERROR_TIME: number = 3000;

@Component({
    template,
    components: {
        [TipTapComponents.ToolbarIcon]: ToolbarIcon,
        EdsPrimaryButton,
        EdsSecondaryButton,
        EdsTertiaryButton,
        EdsIconAdd,
    },
    name: TipTapComponents.TextBoxEditor,
})
export class TextBoxEditor extends Vue {
    // Variables
    // ====================================

    // Static

    public static createTextBoxComponentMetaData(): ComponentMetaData {
        return {
            ComponentID: GuidUtil.create(),
            Height: 0,
            Score: 0,
            Marked: 0,
            ComponentTypeCode: "TEXT_BOX_COMPONENT", // definition is is renderer that can't be accessed in tiptap
            InitialText: "",
            Options: [],
            Width: 0,
            ScriptPosition: InputBoxScriptType.NORMAL,
            TextAlignment: InputBoxTextAlign.LEFT,
            CaseSensitive: false,
            IgnoredCharacters: "",
            Language: -1,
            MarkPunctuation: false,
        };
    }

    // Props

    /** [Prop] The component meta data for the question, prefilled with required data if new question */
    @Prop({ required: false, type: Object, default: () => TextBoxEditor.createTextBoxComponentMetaData() })
    private readonly componentMetaData!: ComponentMetaData;

    /** [Prop, Bound] If specified, whether or not the editor is open */
    @Prop({ required: true, type: Boolean, default: false }) public readonly visible!: boolean;

    /** [Prop] The base language id of the lesson */
    @Prop({ required: false, type: Number }) private baseLanguageId?: number;

    /** [Prop] The target language id of the lesson */
    @Prop({ required: false, type: Number }) private targetLanguageId?: number;

    // Instance

    /** Whether or not the settings dropdown is open */
    private settingsOpened: boolean = false;

    /** The add option input element reference */
    private inputElement!: HTMLInputElement;

    // Fields

    /** The value of the initial text field */
    private initialText: string = "";

    /** The list of possible correct options */
    private options: string[] = [];

    /** The value of the width field */
    private width: number | null = null;

    /** The value of the script position tab bar */
    private scriptPosition: InputBoxScriptType = InputBoxScriptType.NORMAL;

    /** The value of the alignment tab bar */
    private textAlignment: InputBoxTextAlign = InputBoxTextAlign.LEFT;

    /** The value of the case sensitive checkbox */
    private caseSensitive: boolean = false;

    /** The value of the ignored characters field */
    private ignoredCharacters: string = "";

    /** The value of the language picker dropdown */
    private languageId: number = -1;

    /** The value of the mark punctuation checkbox */
    private markPunctuation: boolean = false;

    /** The value of the ignore spaces checkbox */
    private ignoreSpaces: boolean = false;

    // Lifecycle Events
    // ====================================

    /**
     * Lifecyle event: Called when the component is first mounted to the DOM.
     * Create reference to input element
     */
    private async mounted(): Promise<void> {
        await Vue.nextTick();
        this.inputElement = this.$refs.inputElement as HTMLInputElement;
    }

    // Logic
    // ====================================

    /**
     * Setup fields with new component metadata.
     */
    private async initializeComponentMetaData(): Promise<void> {
        if (!this.componentMetaData) {
            return;
        }

        this.initialText = this.componentMetaData.InitialText;
        this.options = ArrayHelper.copy(this.componentMetaData.Options);
        this.width = this.componentMetaData.Width !== 0 ? this.componentMetaData.Width : null;
        this.scriptPosition = this.componentMetaData.ScriptPosition as InputBoxScriptType;
        this.textAlignment = this.componentMetaData.TextAlignment as InputBoxTextAlign;
        this.caseSensitive = this.componentMetaData.CaseSensitive;
        this.markPunctuation = this.componentMetaData.MarkPunctuation;
        this.ignoreSpaces = this.componentMetaData.IgnoredCharacters?.indexOf(" ") !== -1;
        this.ignoredCharacters = this.componentMetaData.IgnoredCharacters.replace(" ", "");

        if (this.isLanguageSubject) {
            this.languageId =
                this.componentMetaData.Language === -1 ? this.baseLanguageId ?? -1 : this.componentMetaData.Language;
        }
    }

    /**
     * Create the save component metadata if valid.
     */
    private createTextBoxMetaData(): ComponentMetaData {
        let { ignoredCharacters } = this;
        if (this.ignoreSpaces && ignoredCharacters.indexOf(" ") === -1) {
            ignoredCharacters += " ";
        }

        return {
            ...this.componentMetaData,
            InitialText: this.initialText,
            Options: this.options,
            Width: this.width ?? 0,
            ScriptPosition: this.scriptPosition,
            TextAlignment: this.textAlignment,
            CaseSensitive: this.caseSensitive,
            IgnoredCharacters: ignoredCharacters,
            Language: this.languageId,
            MarkPunctuation: this.markPunctuation,
        };
    }

    /**
     * Validate the component options
     * - At least two options, one correct
     * - No option can be whitespace only
     * - Options must be unique
     */
    private validateComponent(): void {
        if (this.options.length === 0) {
            throw new Error("You must enter at least one correct option");
        }

        const optionDescriptions: string[] = [];
        let numberOfEmptyOptions: number = 0;
        for (const option of this.options) {
            optionDescriptions.push(option);
            if (StringUtil.isNullOrWhitespace(option)) {
                numberOfEmptyOptions += 1;
            }
        }

        if (numberOfEmptyOptions > 0) {
            throw new Error("Options cannot be empty");
        }

        // Create unique list of options
        const uniqueOptionsCount: number = Array.from(new Set(optionDescriptions)).length;
        if (uniqueOptionsCount !== optionDescriptions.length) {
            throw new Error("Options must be unique");
        }

        if (this.width && (this.width < MIN_TEXTBOX_BOX_WIDTH || this.width > MAX_TEXT_BOX_WIDTH)) {
            throw new Error(`Width of Textbox must be between ${MIN_TEXTBOX_BOX_WIDTH}px and ${MAX_TEXT_BOX_WIDTH}px`);
        }
    }

    /**
     * Reset fields back to their defaults
     */
    private reset(): void {
        this.initialText = "";
        this.options = [];
        this.width = 0;
        this.scriptPosition = InputBoxScriptType.NORMAL;
        this.textAlignment = InputBoxTextAlign.LEFT;
        this.caseSensitive = false;
        this.ignoredCharacters = "";
        this.languageId = -1;
        this.markPunctuation = false;
        this.ignoreSpaces = false;
        this.clearTextField();
    }

    /**
     * Remove the contents of the new option text fields
     */
    private clearTextField(): void {
        this.inputElement.value = "";
    }

    /** Closes the editor window, optionally saving the changed data */
    private close(): void {
        this.$emit("on-close");
    }

    /**
     * Add a new option to the list of options
     * if passes validation
     *
     * @param option the new option to add
     */
    private addOption(option: string = ""): void {
        const trimmedOption: string = option.trim();
        if (this.options.includes(trimmedOption)) {
            throw new Error("The option you’re trying to add already exists");
        }

        this.options.push(option);
    }

    /**
     * Scroll the scroll container to the bottom
     * aka the last option
     */
    private scrollToLatestOption(): void {
        Vue.nextTick(() => {
            const scrollContainer: HTMLDivElement = this.$refs.scrollContainer as HTMLDivElement;
            if (!scrollContainer) {
                return;
            }

            scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
        });
    }

    /**
     * Show toast with the error message.
     * Dismiss after 3 seconds.
     *
     * @param err the error to display
     */
    private handleError(err: Error): void {
        this.$toast.show({ state: ToastStates.FAILURE, actionText: err?.message ?? "An unknown error occurred" });
        setTimeout(() => this.$toast.hide(), EDITOR_ERROR_TIME);
    }

    /**
     * Add the option with current
     * value.
     */
    private handleNewOption(): void {
        const trimmedText: string = this.inputElement.value.trim();
        if (!trimmedText) {
            return;
        }

        this.addOption(trimmedText);
        this.inputElement.value = "";
        this.scrollToLatestOption();
    }

    // DOM Events
    // ====================================

    /**
     * DOM Click:
     * Called when the save button is clicked. Save component metadata
     * and emit to parent
     */
    private onSaveClick(): void {
        try {
            this.handleNewOption();
            this.validateComponent();
            const componentMetaData: ComponentMetaData | null = this.createTextBoxMetaData();
            this.$emit("on-save", componentMetaData);
            this.close();
        } catch (err) {
            if (err instanceof Error) {
                this.$toast.show({ state: ToastStates.FAILURE, actionText: err.message });
                setTimeout(() => this.$toast.hide(), EDITOR_ERROR_TIME);
            }
        }
    }

    /**
     * DOM Click:
     * Called when the close button is clicked. Emit
     * close event to parent.
     */
    private onCloseClick(): void {
        this.close();
    }

    /**
     * DOM Click: Called when the "Delete" button is clicked
     * on an option. Remove the item from the list of options
     */
    private onDeleteOptionClick(index: number): void {
        this.options.splice(index, 1);
    }

    /**
     * DOM Click:
     * Called when the "Add" button is clicked.
     * Focus the input element field. Add option
     * if text field has data
     */
    private onAddOptionClick(): void {
        if (this.inputElement.value) {
            this.onAddOptionEnter();
        }

        this.inputElement.focus();
    }

    /**
     * DOM Emit:
     * Called when the dialog changes visibility.
     * Reset the fields if closing or initialise new component
     * metadata.
     *
     * @param visible whether or not the dialog is visible
     */
    private onVisibleChange(visible: boolean): void {
        if (!visible) {
            this.reset();
            return;
        }

        this.initializeComponentMetaData();
    }

    /**
     * DOM Emit:
     * Called when the add option field blurs
     * or enter is pressed. Add the option with current
     * value.
     */
    private onAddOptionEnter(): void {
        try {
            this.handleNewOption();
        } catch (err) {
            this.handleError(err);
        }
    }

    /**
     * DOM Emite:
     * Called when content is pasted into the new option field.
     * Sperate words by commas and tabs and add new options
     */
    private onOptionFieldPaste($event: ClipboardEvent): void {
        if (!$event || !$event.clipboardData || !$event.target) {
            return;
        }

        // Only allow pasting into an empty field
        const inputElement: HTMLInputElement = $event.target as HTMLInputElement;
        if (inputElement.value.length !== 0) {
            return;
        }

        // Get pasted data as plaintext
        const pasteData: string = $event.clipboardData.getData("text/plain");
        if (!pasteData) {
            return;
        }

        const lines: string[] = pasteData.trim().split("\n");
        try {
            lines.forEach((line: string) => {
                this.addOption(line);
            });

            this.scrollToLatestOption();
        } catch (err) {
            this.handleError(err);
        }

        // Stop paste event
        $event.preventDefault();
    }

    // Gets
    // ====================================

    /** Get the base subject text */
    private get baseLanguageText(): string {
        return this.baseLanguageId ? SubjectUtil.idToName(this.baseLanguageId) : "";
    }

    /** Get the target subject text */
    private get targetLanguageText(): string {
        return this.targetLanguageId ? SubjectUtil.idToName(this.targetLanguageId) : "";
    }

    /** Get whether or not the target language is a language subject */
    private get isLanguageSubject(): boolean {
        return this.targetLanguageId ? SubjectUtil.isLanguageSubject(this.targetLanguageId) : false;
    }
}
