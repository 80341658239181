import { Node } from "prosemirror-model";
import { TableMap } from "prosemirror-tables";
import { Decoration, NodeView } from "prosemirror-view";

import { TextAlignments } from "../textAlign/enums/TextAlignments";
import { EPTableMap } from "./EPTableMap";

// Unfortunately prosemirror-tables doesn't export TableView, so I have to rewrite it to make it work
export class EPTableView implements NodeView {
    public node: Node;
    public dom: Element;
    public contentDOM: HTMLElement;
    public draggingDOM: HTMLElement;
    private tableElem: Element;
    private table: Element;
    private colgroup: HTMLElement;

    constructor(node: Node, private cellMinWidth: number, view?: any) {
        this.node = node;
        this.dom = document.createElement("div");
        this.dom.className = "tableWrapper";
        this.tableElem = document.createElement("table");
        this.table = this.dom.appendChild(this.tableElem);
        this.colgroup = this.table.appendChild(document.createElement("colgroup"));
        this.updateBorderVisibility(node, this.tableElem);
        updateColumns(node, this.colgroup, this.table, this.cellMinWidth);
        this.updateAlignment(node, this.dom);
        this.contentDOM = this.table.appendChild(document.createElement("tbody"));
        this.draggingDOM = this.table.appendChild(document.createElement("div"));
        this.draggingDOM.className = "dragging-info-bubble";
        this.draggingDOM.spellcheck = false;
    }

    public update(node: Node, decorations: Decoration[]): boolean {
        if (node.type !== this.node.type) {
            return false;
        }
        this.node = node;
        this.updateBorderVisibility(node, this.tableElem);
        updateColumns(node, this.colgroup, this.table, this.cellMinWidth);
        this.updateAlignment(node, this.dom);
        return true;
    }

    public ignoreMutation(record: any): boolean {
        if (record.target.className == "dragging-info-bubble") {
            return true;
        }

        return record.type == "attributes" && (record.target == this.table || this.colgroup.contains(record.target));
    }

    private updateBorderVisibility(node: Node, tableElem: Element): void {
        if (tableElem && node) {
            tableElem.setAttribute("hide-borders", node.attrs.showBorders ? "false" : "true");
        }
    }

    private updateAlignment(node: Node, tableWrapper: Element): void {
        const alignment: TextAlignments | undefined = node.attrs.align;

        if (tableWrapper instanceof HTMLElement && alignment) {
            tableWrapper.style.textAlign = alignment;
        }
    }
}

export function updateColumns(
    node: Node,
    colgroup: Element,
    table: Element,
    cellMinWidth: number,
    overrideCol?: number,
    overrideValue?: number
): void {
    let row: Node | null | undefined = node.firstChild;
    if (row == null) {
        return;
    }
    let totalWidth: number = 0;
    let fixedWidth: boolean = true;
    let nextDOM: HTMLElement = colgroup.firstChild as HTMLElement;

    let draggingInfoPosition: number | null = null;
    if (table && overrideValue) {
        let tables: HTMLCollectionOf<Element> = document.getElementsByClassName("table-being-dragged");
        [].forEach.call(tables, (t: Element) => {
            t.classList.remove("table-being-dragged");
        });
        table.className += "table-being-dragged";

        let draggingInfoBubble = table.querySelector(".dragging-info-bubble");
        if (draggingInfoBubble instanceof HTMLElement) {
            draggingInfoBubble.textContent = overrideValue + "px";
        }
    }

    for (let i = 0, col = 0; i < row.childCount; i++) {
        const colspan: number = row.child(i).attrs.colspan;
        const colwidth = row.child(i).attrs.colwidth;

        for (let j = 0; j < colspan; j++, col++) {
            const hasWidth: number = overrideCol == col ? overrideValue : colwidth && colwidth[j];
            const cssWidth: string = hasWidth ? hasWidth + "px" : "";
            totalWidth += hasWidth || cellMinWidth;

            if (overrideCol == col && overrideValue) {
                draggingInfoPosition = totalWidth;
            }

            if (!hasWidth) {
                fixedWidth = false;
            }
            if (!nextDOM) {
                colgroup.appendChild(document.createElement("col")).style.width = cssWidth;
            } else {
                if (nextDOM.style.width != cssWidth) {
                    nextDOM.style.width = cssWidth;
                }
                nextDOM = nextDOM.nextSibling as HTMLElement;
            }
        }
    }

    if (table && overrideValue && draggingInfoPosition) {
        let draggingInfoBubble = table.querySelector(".dragging-info-bubble");
        if (draggingInfoBubble instanceof HTMLElement) {
            draggingInfoBubble.style.left = draggingInfoPosition - draggingInfoBubble.offsetWidth - 2 + "px";
        }
    }

    while (nextDOM) {
        const after: HTMLElement | null = nextDOM.nextSibling as HTMLElement;
        if (nextDOM.parentNode) {
            nextDOM.parentNode.removeChild(nextDOM);
        }
        nextDOM = after;
    }

    const tableMap: EPTableMap = TableMap.get(node) as EPTableMap;

    if (fixedWidth) {
        (table as HTMLElement).style.width = totalWidth + "px";
        (table as HTMLElement).style.minWidth = "";
        tableMap.pixelWidth = totalWidth; // this line was added to affect the overall table size
    } else {
        (table as HTMLElement).style.width = "";
        (table as HTMLElement).style.minWidth = totalWidth + "px";
        tableMap.pixelWidth = undefined;
    }
}
