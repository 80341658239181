import "./highlight-node-view.less";

import { Keyboard } from "@educationperfect/ep-web-browser-utils";
import { Input, Poptip } from "@educationperfect/view-design";
import { Component } from "vue-property-decorator";

import { ToolbarIcon } from "../../components/toolbarIcon/ToolbarIcon";
import GapNodeView from "../gapNode/GapNodeView";
import { HighlightCommands } from "./HighlightCommands";

@Component({
    components: { Poptip, Input, ToolbarIcon },
    template: `
    <div :gap-id="id" :text="text" :correct="correct" class="gap-node" @click="onClick" @mousedown="onMousedown" :class="{selected: selected, empty: text == '' }">
        <span ref="textSpan" class="text-span">{{text}}</span>
        <ToolbarIcon class="tick" icon="success" size="20" />
        <Input class="gap-node-input" ref="textInput" type="textarea" @on-focus="onInputFocus" :rows="1" :autosize="{ minRows: 1, maxRows: 999 }" @input="onChange" @on-keydown="onInputKeydown"
        @on-keydown.enter.prevent="onInputEnter" @on-keydown.delete="onInputDelete" :value="text" />
    </div>
  `,
})
export class HighlightNodeView extends GapNodeView {
    // DOM Events
    // ===========================================

    /**
     * DOM Emit:
     * Called when the textfield changes. Have key input.
     *
     * @param event the field event
     */
    protected onInputKeydown(event: KeyboardEvent): void {
        switch (event.which) {
            case Keyboard.SPACE:
                // Prevent the input of trailing spaces
                if (event.metaKey || event.ctrlKey) {
                    if (event.shiftKey) {
                        this.editorCommands.toggleHighlightGap({ actionCorrect: true });
                    } else {
                        this.editorCommands.toggleHighlightGap({ actionCorrect: false });
                    }
                }

                const match: RegExpMatchArray | null = this.text.match(/(\s+)$/);

                if (match && match.length > 0) {
                    event.preventDefault();
                }
                return;
            case Keyboard.KEY_Z:
            case Keyboard.KEY_Y:
                if (event.metaKey || event.ctrlKey) {
                    event.preventDefault();
                    if (event.shiftKey) {
                        this.editor.commands.redo();
                    } else {
                        this.editor.commands.undo();
                    }
                }
                break;
            case Keyboard.LEFT_ARROW:
            case Keyboard.RIGHT_ARROW:
            case Keyboard.UP_ARROW:
            case Keyboard.DOWN_ARROW:
                // Deselect the gap when user uses arrow keys to navigate away
                let direction: "top" | "right" | "bottom" | "left" = "left";

                switch (event.which) {
                    case Keyboard.LEFT_ARROW:
                        direction = "left";
                        break;
                    case Keyboard.RIGHT_ARROW:
                        direction = "right";
                        break;
                    case Keyboard.UP_ARROW:
                        direction = "top";
                        break;
                    case Keyboard.DOWN_ARROW:
                        direction = "bottom";
                        break;
                }

                this.handleArrowKeyNavigation(direction);
                break;
            case Keyboard.KEY_C:
            case Keyboard.KEY_X:
                if (event.metaKey || event.ctrlKey) {
                    this.handleCopyAndCut(event);
                }
                break;
        }
    }

    // Gets
    // ===========================================

    /** Get whether or not the specific highlight node is correct */
    private get correct(): boolean {
        return this.node.attrs.correct;
    }

    /** The the editor commands */
    protected get editorCommands(): HighlightCommands.Interface {
        return this.editor.commands as HighlightCommands.Interface;
    }
}
